import * as actionTypes from '../actionTypes';

const reducer = (state = 0, action) => {
  switch (action.type) {
    case actionTypes.HIDE_NPS: return 0;
    case actionTypes.SET_NPS: return action.payload;
    default: return state;
  }
};

export default reducer;
