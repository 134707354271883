import React from 'react'
import { Modal, Carousel, Card, Button } from 'antd'
import { injectIntl } from 'react-intl'
import { CloseOutlined, ForwardOutlined, BackwardOutlined } from '@ant-design/icons'
import Player from 'components/UI/ModalPlayer/Player'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { broadcastSelector } from 'redux/selectors'
import { hideBroadcast, markAsRead } from 'redux/broadcast/actions'

const Media = ({ videoId, imageUrl, title }) => {
  if (!_.isEmpty(videoId)) {
    return (
      <Player
        containerClassName="video-responsive shadow"
        className="youtube-player"
        videoId={videoId}
        autoplay={0}
      />
    )
  }

  if (!_.isEmpty(imageUrl) && _.isEmpty(videoId)) {
    return <img src={imageUrl} alt={title} className="shadow" />
  }

  return null
}

const BroadcastModal = ({ intl }) => {
  const dispatch = useDispatch()
  const carouselRef = React.useRef()
  const [activeSlide, setActiveSlide] = React.useState(0)
  const [viewedSlide, setViewedSlide] = React.useState([0])

  const broadcast = useSelector(broadcastSelector)
  const hideHandler = () => dispatch(hideBroadcast())
  const afterCloseHandler = () => dispatch(markAsRead({ data: getViewedIds() }))

  const goBackHandler = () => carouselRef.current.prev()
  const goNextHandler = () => carouselRef.current.next()
  const beforeChangeHandler = (current, next) => {
    setActiveSlide(next)
    if (!_.includes(viewedSlide, next)) {
      setViewedSlide([...viewedSlide, next])
    }
  }

  const getViewedIds = React.useCallback(() => {
    const ids = []
    viewedSlide.forEach((i) => {
      if (broadcast.data[i] && !_.includes(ids, i)) {
        ids.push(broadcast.data[i].id)
      }
    })
    return ids
  }, [viewedSlide, broadcast])

  const actions = React.useMemo(() => {
    const act = []
    if (activeSlide > 0) {
      act.push(
        <Button
          onClick={goBackHandler}
          size="small"
          type="primary"
          key="0"
          icon={<BackwardOutlined />}
        >
          {intl.formatMessage({ id: 'button.prev' })}
        </Button>,
      )
    }
    if (activeSlide < broadcast.data.length - 1) {
      act.push(
        <Button
          onClick={goNextHandler}
          size="small"
          type="primary"
          key="1"
          icon={<ForwardOutlined />}
        >
          {intl.formatMessage({ id: 'button.next' })}
        </Button>,
      )
    }
    return act
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcast, activeSlide])

  return (
    <Modal
      maskTransitionName=""
      visible={broadcast.show}
      onCancel={hideHandler}
      footer={actions.length > 0 ? actions : null}
      closable={false}
      width={460}
      bodyStyle={{ textAlign: 'center', padding: 0 }}
      maskClosable={false}
      afterClose={afterCloseHandler}
      style={{ paddingBottom: 0 }}
    >
      <Button
        className="ant-modal-close"
        type="primary"
        shape="circle"
        icon={<CloseOutlined />}
        style={{ top: -6, right: -6 }}
        danger
        size="small"
        onClick={hideHandler}
      />
      <Carousel ref={carouselRef} dots={false} infinite={false} beforeChange={beforeChangeHandler}>
        {broadcast.data.map((row) => {
          return (
            <div key={row.id}>
              <Card
                style={{ width: 450 }}
                cover={
                  <Media
                    videoId={row.data?.url}
                    imageUrl={row.data?.image}
                    title={row.data?.title}
                  />
                }
              >
                <Card.Meta title={row.data?.title} description={row.data?.isi} />
              </Card>
            </div>
          )
        })}
      </Carousel>
    </Modal>
  )
}

export default injectIntl(BroadcastModal)
