import React, { useRef, useState } from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { Button, DatePicker, Form, Modal, Progress, Result, Skeleton, Table } from 'antd'
import { formatDateText, qtyToCurrency } from 'utils/formatting'
import { transType } from 'utils/static'
import { useSelector } from 'react-redux'
import { titleSelector } from 'redux/selectors'
import { Cancel } from 'components/UI'
import withPusherProgress from 'utils/withPusherProgress'

const MassCreateTransModal = ({
  intl,
  show,
  onCancel,
  loading,
  getInfo,
  onExecute,
  onCompleted,
  info,
  type,
  onSetChannel,
  statusImported,
  dataImported,
  progress: { progress, imported, total },
  disconnect,
}) => {
  const formRef = useRef()

  const titleName = React.useMemo(() => {
    switch (type) {
      case transType.INVOICE:
        return 'invoices'
      case transType.DELIVERY:
        return 'deliveries'
      case transType.ORDER:
        return 'orders'
      case transType.PURCHASE_INVOICE:
        return 'purchase_invoices'
      case transType.PURCHASE_DELIVERY:
        return 'purchase_deliveries'
      case transType.PURCHASE_ORDER:
        return 'purchase_orders'
      default:
        return 'invoices'
    }
  }, [type])

  const title = useSelector((state) => titleSelector(state, titleName))
  const [step, setStep] = useState(0)
  const [loadingExecute, setLoadingExecute] = useState(false)

  React.useEffect(() => {
    if (statusImported) {
      setStep(2)
      setLoadingExecute(false)
      onCompleted()
      disconnect()
    }
  }, [statusImported, onCompleted, disconnect])

  React.useEffect(() => {
    if (show && step === 0) {
      getInfo()
    }
  }, [getInfo, show, step])

  const setChannel = (data) => {
    setLoadingExecute(false)
    if (data) {
      const { channel } = data
      onSetChannel(channel, 'App\\Events\\MassInvoiceProgress')
      setLoadingExecute(false)
      setStep(1)
    }
  }

  const submitHandler = (values) => {
    values.trans_date = values.trans_date.format('YYYY-MM-DD')
    setLoadingExecute(true)
    onExecute(values, setChannel)
  }

  const onClose = () => {
    onCancel()
    if (statusImported) {
      setLoadingExecute(false)
      setStep(0)
    }
  }

  const getDefaultPaymentDate = () => {
    const now = moment()
    if (moment(info.min_trans_date).isAfter(now)) {
      return moment(info.min_trans_date)
    }
    return now
  }

  const disabledTransDate = (current) => {
    return current && current < moment(info.min_trans_date).startOf('day')
  }

  return (
    <Modal
      title={intl.formatMessage({ id: 'financeInvoices.create_mass_trans' }, { title })}
      visible={show}
      onCancel={onClose}
      destroyOnClose
      maskClosable={info.valid <= 0}
      maskTransitionName=""
      footer={
        info.valid > 0 && step === 0
          ? [
              <Cancel key={0} onClick={onClose} />,
              <Button
                key="submit"
                form="massPaymentForm"
                htmlType="submit"
                type="primary"
                loading={loadingExecute}
              >
                {intl.formatMessage({ id: 'button.next' })}
              </Button>,
            ]
          : null
      }
    >
      {step === 0 && (
        <>
          {loading && <Skeleton active />}
          {!loading && (
            <>
              <Table
                bordered
                showHeader={false}
                pagination={false}
                className="ant-descriptions ant-descriptions-bordered mb-3"
                dataSource={[
                  {
                    key: 'valid',
                    label: intl.formatMessage(
                      { id: 'financeInvoices.can_be_created_to' },
                      { title: title.toLowerCase() },
                    ),
                    count: info.valid,
                  },
                  {
                    key: 'invalid',
                    label: intl.formatMessage(
                      { id: 'financeInvoices.cannot_be_created_to' },
                      { title: title.toLowerCase() },
                    ),
                    count: info.invalid,
                  },
                ]}
                columns={[
                  {
                    dataIndex: 'label',
                    className: 'ant-descriptions-item-label',
                  },
                  {
                    dataIndex: 'count',
                    className: 'text-right ant-descriptions-item-content',
                    render: (text) => qtyToCurrency(text),
                  },
                ]}
              />
              {info.valid > 0 && (
                <Form
                  ref={formRef}
                  id="massPaymentForm"
                  layout="vertical"
                  onFinish={submitHandler}
                  initialValues={{
                    trans_date: getDefaultPaymentDate(),
                  }}
                >
                  <Form.Item
                    label={intl.formatMessage({ id: 'financePurchaseInvoices.transaction_date' })}
                    name="trans_date"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'financePurchaseInvoices.please_select_transaction_date',
                        }),
                      },
                      {
                        validator: (rule, value) => {
                          if (value < moment(info.min_trans_date)) {
                            return Promise.reject(
                              intl.formatMessage({
                                id: 'financePurchaseInvoices.the_payment_date_max_higger_than_transaction_date',
                              }),
                            )
                          }
                          return Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <DatePicker
                      format={formatDateText()}
                      style={{ width: '100%' }}
                      disabledDate={disabledTransDate}
                    />
                  </Form.Item>
                </Form>
              )}
            </>
          )}
        </>
      )}
      {step === 1 && (
        <>
          <Progress percent={progress} status="active" className="mb-3" />
          <p className="text-center">
            {intl.formatMessage(
              { id: 'financeInvoices.data_progress_create_trans' },
              { count: imported, total, title: title.toLowerCase() },
            )}
          </p>
        </>
      )}
      {step === 2 && (
        <>
          <Result
            status="success"
            title={
              <h5>
                {intl.formatMessage(
                  {
                    id: 'financeInvoices.data_has_been_create_trans',
                  },
                  { count: dataImported.valid, title: title.toLowerCase() },
                )}
              </h5>
            }
          />
          <div className="d-flex justify-content-center">
            <Button onClick={onClose}>Ok</Button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default withPusherProgress(injectIntl(MassCreateTransModal))
