import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
// import SentryErrorBoundary from 'components/SentryErrorBoundary'
// import { connect } from 'react-redux'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
import ErrorPage from 'pages/Error'
import ServerError from 'pages/ServerError'
import routes from 'routes'
import { Button } from 'antd'

import Loader from 'components/LayoutComponents/Loader'

import withTracker from 'utils/withTracker'

// const mapStateToProps = ({ user }) => ({
//   user,
// })
// @connect(mapStateToProps, null)
class Router extends React.Component {
  errorComponent = () => {
    return (
      <React.Fragment>
        <h4>You have encountered an error</h4>
        <Button
          type="primary"
          onClick={() => {
            window.location.reload()
          }}
        >
          Click here to reload
        </Button>
      </React.Fragment>
    )
  }

  renderRoutes = () => {
    // const { demo } = this.props;
    try {
      return (
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              // if (process.env.REACT_APP_NAME === 'FINANCE') {
              //   if (demo === '2') {
              //     return <Redirect to="/dashboard" />
              //   }
              // }
              return <Redirect to="/dashboard" />
            }}
          />
          <Route
            exact
            path="/profile"
            render={() => {
              return <Redirect to="/dashboard" />
            }}
          />
          {routes.map((route) => (
            <Route
              path={route.path}
              component={withTracker(route.component)}
              key={route.path}
              exact={route.exact}
            />
          ))}
          <Route exact path="/error" component={withTracker(ErrorPage)} />
          <Route exact path="/server-error" component={withTracker(ServerError)} />
          <Route component={withTracker(NotFoundPage)} />
        </Switch>
      )
    } catch (e) {
      return this.errorComponent()
    }
  }

  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Suspense fallback={<Loader />}>{this.renderRoutes()}</Suspense>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
