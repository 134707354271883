import axios from 'axios'
import { stringify } from 'query-string'
import { getOnce } from 'utils/helper'

export const getFinanceBankReconciles = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/bankReconciles?${query}`)
}

export const getFinanceBankReconcileTrans = (params) => {
  const query = stringify(params)
  return axios.get(`/finance/bankReconciles/bankTransactions?${query}`)
}

export const reconcileFinanceBank = (data) => {
  return axios.patch('/finance/bankReconciles/reconcile', data)
}
export const massReconcileFinanceBank = ({ data, selectedRowKeys }) => {
  const reconcilesData = []
  data.data.forEach((d) => {
    selectedRowKeys.forEach((selected) => {
      if (selected === d.id) {
        reconcilesData.push({
          bank_account_id: d.bank_account_id,
          bank_statement_id: d.id,
          bank_tran_ids: [d.suggestion.id],
        })
      }
    })
  })

  return axios.post('/finance/bankReconciles/executeMassReconcile', reconcilesData)
}

export const inputMassDeleteFinanceBank = ({ data, selectedRowKeys }) => {
  const reconcilesData = []
  data.data.forEach((d) => {
    selectedRowKeys.forEach((selected) => {
      if (selected === d.id) {
        reconcilesData.push({
          bank_account_id: d.bank_account_id,
          bank_statement_id: d.id,
          bank_tran_ids: [d.suggestion.id],
        })
      }
    })
  })
  return axios.post('/finance/bankReconciles/inputMassReconcile', reconcilesData)
}
