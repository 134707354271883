import React from 'react';
import { Editor as RdwEditor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "assets/styles/Rdw/rdw.cleanui.scss";

class MessageEditor extends React.Component {
  render() {
    const { setRef, editorState, editorStateChangeHandler, ...props } = this.props;

    return (
      <RdwEditor
        editorRef={setRef}
        editorState={editorState}
        toolbarClassName="rdw-custom-toolbar-wrapper"
        editorClassName="rdw-custom-editor-wrapper"
        wrapperClassName="rdw-custom-wrapper"
        onEditorStateChange={editorStateChangeHandler}
        toolbar={{
          options: ['inline', 'list', 'fontSize'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48],
          },
        }}
        {...props}
      />
    );
  }
}

export default MessageEditor;
