import React, { memo, useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { Modal, Result, Button } from 'antd'
import useAppUpdate from 'utils/hooks/useAppUpdate'

const COUNTDOWN_TIME = 15
let intervalCountDown
const UpdateAppWrapper = ({ intl }) => {
  const [countDown, setCountDown] = useState(COUNTDOWN_TIME)
  const { showReload, onReloadPage } = useAppUpdate()

  useEffect(() => {
    intervalCountDown = setInterval(() => {
      setCountDown((prev) => prev - 1)
    }, 1000)
    return () => clearInterval(intervalCountDown)
  }, [])

  useEffect(() => {
    if (countDown === 0) {
      clearInterval(intervalCountDown)
    }
  }, [countDown])

  return (
    <Modal
      title="Information!"
      visible={showReload}
      destroyOnClose
      width={350}
      closable={false}
      footer={null}
    >
      <Result
        title={<h4>{intl.formatMessage({ id: 'notification.a_new_version_is_available' })}</h4>}
        subTitle={intl.formatMessage(
          { id: 'notification.please_wait_prepare_to_reload_page' },
          {
            countDown,
            errorAutoReloadMessage:
              countDown === 0
                ? intl.formatMessage({ id: 'notification.autoreload_failed' })
                : '',
          },
        )}
        extra={
          countDown === 0 ? (
            <Button key="submit" type="primary" onClick={onReloadPage}>
              {intl.formatMessage({ id: 'notification.reload_immediately' })}
            </Button>
          ) : null
        }
      />
    </Modal>
  )
}

export default memo(injectIntl(UpdateAppWrapper))
