const transType = {
  EXPENSE: 1,
  PURCHASE_ORDER: 2,
  PURCHASE_INVOICE: 3,
  QUOTE: 4,
  INVOICE: 5,
  ORDER: 6,
  DELIVERY: 7,
  RETURN: 9,
  SPEND_MONEY: 11,
  RECEIVE_MONEY: 12,
  SEND_TRANSFER: 13,
  RECEIVE_TRANSFER: 14,
  EXPENSE_PAYMENT: 15,
  PURCHASE_PAYMENT: 16,
  RECEIVE_INVOICE_PAYMENT: 17,
  BANK_REVERSAL: 18,
  BANK_OPENING_BALANCE: 19,
  REGISTER_FIXED_ASSET: 20,
  BANK_MANUAL_JOURNAL: 32,
  REVERSAL: 21,
  OPENING_BALANCE: 22,
  RECEIVE_SALES_ORDER_PAYMENT: 23,
  CREDIT_MEMO_PAYMENT: 24,
  DEBIT_MEMO_PAYMENT: 25,
  BANK_CREDIT_MEMO: 26,
  BANK_DEBIT_MEMO: 27,
  PURCHASE_ORDER_PAYMENT: 28,
  PAYMENT_RECAP: 29,
  MANUAL_JOURNAL: 31,
  INVOICE_WITHOLDING: 33,
  PURCHASE_INVOICE_WITHOLDING: 34,
  CREATE_FIXED_ASSET: 41,
  INITIAL_DEPRECIATION: 42,
  DEPRECIATION: 43,
  DISPOSAL: 44,
  WAREHOUSE_TRANSFER: 51,
  STOCK_ADJUSTMENT: 52,
  PRODUCT_CONVERSION: 53,
  CREDIT_MEMO: 61,
  DEBIT_MEMO: 62,
  PURCHASE_QUOTE: 63,
  PURCHASE_DELIVERY: 8,
  PURCHASE_RETURN: 64,
  CLOSING: 71,
  USER: 1001,
  TRANSLATION: 1002,
  ROLE: 1003,
  OPTION: 1005,
  WAREHOUSE: 1006,
  TERM: 1007,
  TAX: 1008,
  PRODUCT: 1009,
  NUMBER: 1011,
  LAYOUT_INVOICE: 1012,
  FIXED_ASSET: 1013,
  CONVERSION_BALANCE: 1014,
  CONTACT: 1015,
  ACCOUNT: 1017,
  BILLING: 1019,
  CASH_BANK: 1100,
  RECURRING_INVOICE: 1101,
  RECURRING_ORDER: 1102,
  RECURRING_PURCHASEINVOICE: 1103,
  RECURRING_PURCHASEORDER: 1104,
  RECURRING_EXPENSE: 1105,
  RECURRING_MANUALJOURNAL: 1106,
  RECURRING_CASHBANK: 1107,
  TAG: 1108,
  INVOICE_PAYMENT_CREDIT_MEMO: 81,
  PURCHASE_INVOICE_PAYMENT_DEBIT_MEMO: 82,
  UNREALIZED_GAIN: 73,
}

const dbFormat = 'YYYY-MM-DD';
const paymentConst = {
  UNPAID: 1,
  PARTIAL: 2,
  PAID: 3
}

const statusColors = [
  { id: transType.UNRECONCILED, color: '#CF2A28' },
  { id: transType.RECONCILED, color: '#11A428' },
  { id: paymentConst.UNPAID, name: 'Unpaid', color: '#CF2A28' },
  { id: paymentConst.PARTIAL, name: 'Partial', color: '#EB9234' },
  { id: paymentConst.PAID, name: 'Paid', color: '#11A428' },
]

const permissions = {
  INVOICES_ADD: 'invoices_add',
  INVOICES_EDIT: 'invoices_edit',
  INVOICES_DELETE: 'invoices_delete',
};

const productBundleType = {
  GENERAL: 0,
  PACKAGE: 1,
  MANUFACTURE: 2,
};

const everyType = {
  MONTH: 2,
  WEEK: 1,
};

const bankTransStatus = {
  RECONCILED: 32,
  UNRECONCILED: 31,
};

const availableTransType = [
  transType.INVOICE, transType.ORDER,
  transType.PURCHASE_INVOICE, transType.PURCHASE_ORDER,
  transType.EXPENSE, transType.MANUAL_JOURNAL, transType.CASH_BANK
];

export {
  transType, statusColors, dbFormat, paymentConst,
  permissions, productBundleType, everyType,
  availableTransType, bankTransStatus
};