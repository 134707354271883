import { setup, updateOptions } from 'utils/apis';
import { resetDashboard } from 'redux/finance/actions';
import { inits } from 'redux/inits/actions';

import * as actionTypes from '../actionTypes';

export const clearDemoDataRedux = () => {
    return {
        type: actionTypes.SETUP_CLEAR_DEMO_DATA
    };
};

export const setupFinishRedux = () => {
    return {
        type: actionTypes.SETUP_FINISH
    };
};

export const setupClearDemoDataFinance = () => {
    return (dispatch) => {
        return setup({ demo: 2 })
            .then((response) => {
                dispatch(clearDemoDataRedux());
                dispatch(inits());
                dispatch(resetDashboard());

                updateOptions({ demo: 0 })
                    .then((response) => {
                        dispatch(setupFinishRedux());

                        return response;
                    })

                return response;
            })
    }
}

export const setupComplete = () => {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch(setupFinishRedux());
            dispatch(resetDashboard());
            dispatch(inits());
            resolve(true);
        });
    }
}

export const setupFinish = () => {
    return dispatch => {
        return updateOptions({ demo: 0 })
            .then((response) => {
                dispatch(setupFinishRedux());

                return response;
            })
    };
};
