import { useSelector } from 'react-redux'
import { useEffect, useMemo, useState, useCallback } from 'react'

import { useClearBrowserCache } from 'react-clear-browser-cache'
import { locationSelector } from 'redux/selectors'

import { useServiceWorker } from '../../ServiceWorkerProvider'

// Hook untuk handle jika ada update dari server
const useAppUpdate = () => {
  const [showReload, setShowReload] = useState(false)
  const { isLatestVersion, clearCacheAndReload, latestVersion } = useClearBrowserCache()
  const worker = useServiceWorker()

  const location = useSelector(locationSelector)

  const isSafeToReload = useMemo(() => {
    const avoidUrl = [
      'add',
      'edit',
      'import',
      'transfer',
      'spend',
      'receive',
      'register',
      'dispose',
    ]
    let safeToReload = true

    avoidUrl.forEach((url) => {
      if (location.pathname.includes(url)) {
        safeToReload = false
      }
    })
    return safeToReload
  }, [location.pathname])

  const reloadPage = useCallback(() => {
    worker.updateAssets(false)
    setShowReload(false)
    clearCacheAndReload()
  }, [clearCacheAndReload, worker])

  useEffect(() => {
    if (isLatestVersion === false || worker.assetsUpdateReady) {
      if (isSafeToReload) {
        console.log(`meta ${latestVersion} is ${isLatestVersion}`)
        console.log(`worker asset ready ${worker.assetsUpdateReady}`)
        setShowReload(true)
        worker.updateAssets(false)
        clearCacheAndReload()
      }
    }

    return () => setShowReload(false)
  }, [
    clearCacheAndReload,
    isLatestVersion,
    isSafeToReload,
    latestVersion,
    worker,
    worker.assetsUpdateReady,
  ])

  return { showReload, setShowReload, onReloadPage: reloadPage }
}

export default useAppUpdate
