import React from 'react';
import { Modal, Skeleton, Form, Input, message, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { openNewTab } from 'utils/helper';
import { Cancel, Send } from 'components/UI';
import HideFeature from 'containers/Billing/HideFeature';

const { TextArea } = Input;

const SendWaModal = ({
  intl,
  sendTitle,
  getWaTemplate,
  show = false,
  data = {contact: {}},
  onCloseWaModal,
  goToWaTamplate,
}) => {
  const formRef = React.createRef();

  const [loadingWaTemplate, setLoadingWaTemplate] = React.useState(false);
  const [waTemplate, setWaTemplate] = React.useState({body: '', phone_number: ''});

  React.useEffect(() => {
    const getTemplate = async () => {
      try {
        setLoadingWaTemplate(true);
        const response = await getWaTemplate();
        setLoadingWaTemplate(false);

        setWaTemplate({ ...response.data.data, phone_number: response.data.data.phone });
      } catch (e) {
        console.log(e);
        message.error(intl.formatMessage({ id: 'sms.cannot_send_wa' }));
      }
    }

    if (show) {
      getTemplate();
    }
  }, [show, getWaTemplate, intl]);

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldsValue({
        phone_number: waTemplate.phone_number,
        body: waTemplate.body,
      });
    }
  }, [data, waTemplate, formRef]);

  const onSendWa = async (values) => {
    const body = values.body.replace(/(?:\r\n|\r|\n)/g, '%0a').replaceAll('#', encodeURIComponent('#'))
    let phone = values.phone_number;
    if (phone.charAt(0) === '+') {
      phone = phone.substring(1)
    }
    if (phone.charAt(0) === '0') {
      phone = phone.substring(1)
      phone = `62${phone}`;
    }
    openNewTab(`https://api.whatsapp.com/send?phone=${phone}&text=${body}`, false)
    onCloseWaModal()
  }

  return (
    <Modal
      title={sendTitle === "button.send_wa" || !sendTitle ? intl.formatMessage({ id: 'button.send_wa' }) : sendTitle}
      visible={show}
      onCancel={onCloseWaModal}
      destroyOnClose
      maskTransitionName=""
      footer={[
        <React.Fragment key="template">
          {goToWaTamplate && (
            <Button style={{ float: 'left' }} icon={<EditOutlined />} onClick={goToWaTamplate}>
              {intl.formatMessage({ id: 'wa.change_template' })}
            </Button>
          )}
        </React.Fragment>,
        <Cancel key={`_${0}`} onClick={onCloseWaModal} />,
        <Send
          form="sendWaForm"
          key="submit"
          htmlType="submit"
        />
      ]}
    >
      <HideFeature
        code="whatsapp"
        feature={sendTitle === "button.send_wa" || !sendTitle ? intl.formatMessage({ id: 'button.send_wa' }) : sendTitle}
      >
        {loadingWaTemplate ? <Skeleton active /> : (
          <Form ref={formRef} onFinish={onSendWa} layout='vertical' id="sendWaForm">
            <Form.Item
              label={intl.formatMessage({ id: 'sms.destination_number' })}
              name='phone_number'
              rules={[
                { required: true, message: intl.formatMessage({ id: 'sms.please_input_phone' }) },
                { min: 6, message: intl.formatMessage({ id: 'financeContacts.min_characters' }, { value: 6 }) },
                { pattern: /^[\d\+]+$/, message: intl.formatMessage({ id: 'financeContacts.only_number_allowed' }) },
              ]}
            >
              <Input placeholder={intl.formatMessage({ id: 'sms.destination_number' })} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'sms.wa_content' })}
              name='body'
              rules={[
                { required: true, message: intl.formatMessage({ id: 'sms.please_input_wa_content' }) },
                { min: 20, message: intl.formatMessage({ id: 'financeContacts.min_characters' }, { value: 20 }) },
              ]}
            >
              <TextArea
                placeholder={intl.formatMessage({ id: 'sms.wa_content' })}
                autoSize={{ minRows: 2, maxRows: 8 }}
              />
            </Form.Item>
          </Form>
        )}
      </HideFeature>
    </Modal>
  );
};

export default SendWaModal;
