import React from 'react';
import { Select, Spin, Space } from "antd";
import { injectIntl } from 'react-intl';
import DropdownItemAction from './DropdownItemAction';

class SearchInput extends React.Component {
  renderLoaderInfinite = () => {
    const { infiniteScroll, infiniteData, withExtra } = this.props;
    if (infiniteScroll && infiniteData && infiniteData.loading) {
      return (
        <div style={{ position: 'absolute', bottom: withExtra ? 40 : 0, width: '100%', textAlign: 'center' }}>
          <div className="ant-select-item ant-select-item-option" style={{ backgroundColor: '#F2F4F8' }}>
            <Space>
              <Spin size="small" />
              <div className="ant-select-item-option-content">Loading...</div>
            </Space>
          </div>
        </div>
      )
    }
    return null;
  }

  dropdownRender = (menu) => {
    const { onExtraClick, extraTitle, withExtra} = this.props;
    if (!withExtra) {
      return (
        <div>
          {menu}
          {this.renderLoaderInfinite()}
        </div>
      );
    }

    return (
      <div>
        <DropdownItemAction
          menu={menu}
          title={extraTitle}
          onClick={onExtraClick}
          withExtra={withExtra}
        />
        {this.renderLoaderInfinite()}
      </div>
    )
  }

  render() {
    const {
      children, onExtraClick, extraIcon,
      extraTitle, intl, withExtra,
      infiniteScroll, infiniteData,
      autoFocus,
      innerRef,
      ...props
    } = this.props;

    return (
      <Select
        onFocus={() => this.props.onSearch('')}
        notFoundContent={intl.formatMessage({ id: 'suggestion.not_found' })}
        {...props}
        dropdownRender={this.dropdownRender}
        autoFocus={autoFocus}
        ref={innerRef}
      >
        {children}
      </Select>
    );
  }
};

SearchInput.defaultProps = {
  showSearch: true,
  defaultActiveFirstOption: false,
  showArrow: false,
  filterOption: false,
  extraIcon: 'plus',
  extraTitle: '',
  withExtra: true,
  onExtraClick: () => {},
  onBlur: () => {},
  dropdownClassName: 'ant-select-custom',
  dropdownMatchSelectWidth: false
}

const Component = injectIntl(SearchInput);
export default React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />);
