import axios from 'axios';

export const resetTranslation = (data) => {
  return axios.post('/translations/reset', data);
};

export const getTranslation = () => {
  return axios.get('/translations');
};

export const getDefaultTranslation = () => {
  return axios.get('/translations/default');
};

export const editTranslation = (data) => {
  return axios.put('/translations', data);
}
