import React from 'react'
import { connect } from 'react-redux'
// import { FormattedMessage } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout, Skeleton, Space } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { changeSetting } from 'redux/settings/actions'
import { checkAlurFlow } from 'redux/options/selectors'
import { withAppConsumer } from 'components/AppProvider'
import { DemoWarning, TrialWarning } from 'components/UI'

import styles from './style.module.scss'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

const mapStateToProps = ({ menu, settings, options, translation, inits }) => ({
  menuData: menu.menuLeftData,
  // isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  // isMobileMenuOpen: settings.isMobileMenuOpen,
  property: options.options,
  translation,
  loadedInit: inits?.loadedInit,
})

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeSetting: (payload) => dispatch(changeSetting(payload)),
  }
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
@withAppConsumer
class MenuLeft extends React.PureComponent {
  state = {
    selectedKeys: store.get('app.menu.selectedKeys') || [],
    openedKeys: store.get('app.menu.openedKeys') || [],
  }

  // componentDidMount() {
  //   this.setSelectedKeys(this.props)
  // }

  // componentWillReceiveProps(newProps) {
  //   if (newProps.isMenuCollapsed && !newProps.isMobileView) {
  //     this.setState({
  //       openedKeys: [],
  //     })
  //   }
  //   this.setSelectedKeys(newProps)
  // }

  componentDidUpdate(prevProps) {
    const prevAppContext = prevProps.appContext
    const { appContext } = this.props
    if (prevAppContext?.settings?.isMenuCollapsed !== appContext?.settings?.isMenuCollapsed) {
      if (appContext?.settings?.isMenuCollapsed && !this.props.isMobileView) {
        this.setState({
          openedKeys: [],
        })
      }
    }

    if (
      !_.isEqual(prevProps.menuData, this.props.menuData) ||
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.setSelectedKeys()
    }
  }

  flattenItems = (items, key) =>
    items.reduce((flattenedItems, item) => {
      flattenedItems.push(item)
      if (Array.isArray(item[key])) {
        return flattenedItems.concat(this.flattenItems(item[key], key))
      }
      return flattenedItems
    }, [])

  setSelectedKeys = () => {
    const { menuData, location } = this.props
    const pathName = location.pathname === '/dashboard' ? '/' : location.pathname
    const flattenData = this.flattenItems(menuData, 'children')
    let selectedItem = null

    if (pathName === '/') {
      selectedItem = _.find(flattenData, { url: pathName })
    } else if (pathName.includes('/settings')) {
      selectedItem = _.find(menuData, { name: 'settings' })
    } else {
      flattenData.forEach((item) => {
        if (item.url !== '/' && item.url !== '' && pathName.includes(item.url)) {
          selectedItem = item
        }
      })
    }

    store.set('app.menu.selectedKeys', selectedItem ? [selectedItem.key] : [])

    this.setState({
      selectedKeys: selectedItem ? [selectedItem.key] : [],
    })
  }

  onCollapse = (value, type) => {
    const {
      appContext: {
        onChangeSettings,
        settings: { isMenuCollapsed },
      },
    } = this.props
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    // onChangeSetting({
    //   setting: 'isMenuCollapsed',
    //   value: !isMenuCollapsed,
    // });
    onChangeSettings({ isMenuCollapsed: value })
    // this.setState({
    //   openedKeys: [],
    // })
  }

  onOpenChange = (openedKeys) => {
    this.setState({
      openedKeys,
    })
  }

  handleClick = (e) => {
    // const { isSettingsOpen, onChangeSetting } = this.props
    store.set('app.menu.selectedKeys', [e.key])
    if (e.keyPath[1]) {
      store.set('app.menu.openedKeys', [e.keyPath[1]])
    } else {
      store.set('app.menu.openedKeys', [])
    }

    // custom action on settings menu item
    // if (e.key === 'settings') {
    //   onChangeSetting({
    //     setting: 'isSettingsOpen',
    //     value: !isSettingsOpen,
    //   });
    //   return
    // }
    this.setState({
      selectedKeys: [e.key],
      openedKeys: e.keyPath[1] ? [e.keyPath[1]] : [],
      // openKeys: e.keyPath,
    })
  }

  generateMenuItems = () => {
    const menuData = _.cloneDeep(this.props.menuData || [])
    const menu = menuData.map((item) => {
      if (item.name === 'settings') {
        item.children = null
      }
      return { ...item }
    })

    const generateItem = (item) => {
      const { key, title, url, icon, disabled } = item
      const id = `kledo-menu-${item.name}`
      if (item.divider) {
        return <Divider key={Math.random()} />
      }
      if (item.url) {
        return (
          <Menu.Item id={id} key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && (
                  <FontAwesomeIcon icon={icon} className={`${styles.icon} icon-collapsed-hidden`} />
                )}
                <span className={styles.title}>{title}</span>
              </a>
            ) : (
              <Link to={url} className={styles.titleLink}>
                {icon && (
                  <FontAwesomeIcon icon={icon} className={`${styles.icon} icon-collapsed-hidden`} />
                )}
                <span className={styles.title}>{title}</span>
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && (
            <FontAwesomeIcon icon={icon} className={`${styles.icon} icon-collapsed-hidden`} />
          )}
          <span className={styles.title}>{title}</span>
        </Menu.Item>
      )
    }

    const generateSubmenu = (items) => {
      return items.map((menuItem) => {
        if (!checkAlurFlow(menuItem.name)) {
          return null
        }
        const id = `kledo-menu-${menuItem.name}`

        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && (
                <FontAwesomeIcon icon={menuItem.icon} className={`${styles.icon}`} />
              )}
            </span>
          )
          return (
            <SubMenu id={id} title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })
    }

    return menu.map((menuItem) => {
      const id = `kledo-menu-${menuItem.name}`
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            {!_.isEmpty(menuItem.url) ? (
              <Link to={menuItem.url}>
                {menuItem.icon && (
                  <FontAwesomeIcon icon={menuItem.icon} className={`${styles.icon}`} />
                )}
                <span className={styles.title}>{menuItem.title}</span>
              </Link>
            ) : (
              <React.Fragment>
                <span className={styles.title}>{menuItem.title}</span>
                {menuItem.icon && (
                  <FontAwesomeIcon icon={menuItem.icon} className={`${styles.icon}`} />
                )}
              </React.Fragment>
            )}
          </span>
        )
        return (
          <SubMenu id={id} title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }

      return generateItem(menuItem)
    })
  }

  makeLoader = () => {
    const loaders = []
    for (let i = 1; i <= 10; i += 1) {
      loaders.push(
        <Space key={i}>
          <Skeleton.Avatar active size="small" shape="circle" />
          <Skeleton.Input style={{ width: 185 }} active size="small" />
        </Space>,
      )
    }
    return loaders
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const {
      isMobileView,
      isLightTheme,
      property,
      scrollBarRef,
      appContext: {
        settings: { isMenuCollapsed },
      },
    } = this.props
    const menuSettings = isMobileView
      ? {
          width: 256,
          collapsible: false,
          collapsed: false,
          collapsedWidth: 0,
          onCollapse: this.onCollapse,
        }
      : {
          width: 256,
          collapsible: true,
          collapsed: isMenuCollapsed,
          onCollapse: this.onCollapse,
          breakpoint: 'lg',
        }

    const menu = this.generateMenuItems()

    return (
      <Sider
        {...menuSettings}
        className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
      >
        <div className={styles.logo}>
          <div className={styles.logoContainer}>
            <Link to="/">
              {!_.isEmpty(property.property_logo) ? (
                <img
                  src={menuSettings.collapsed ? 'favicon.png' : property.property_logo}
                  alt={property.property_name}
                />
              ) : (
                <img
                  src={
                    menuSettings.collapsed
                      ? 'favicon.png'
                      : 'resources/images/logo-color-kledo-front.png'
                  }
                  alt="Kledo"
                />
              )}
            </Link>
          </div>
        </div>
        {(!isMenuCollapsed || isMobileView) && [
          <DemoWarning key="demo" showIcon={!isMenuCollapsed || isMobileView} />,
          <TrialWarning key="trial" showIcon={!isMenuCollapsed || isMobileView} />,
        ]
        // <Alert
        //   className="notification-demo"
        //   style={{ backgroundColor: '#ffefb3' }}
        //   message={
        //     <FormattedMessage
        //       id="setup.demo"
        //       values={{
        //         setup: (
        //           <Link to="/settings/setup">
        //             <strong>
        //               <FormattedMessage id="setup.demo_link" />
        //             </strong>
        //           </Link>
        //         ),
        //       }}
        //     />
        //   }
        //   type="warning"
        //   showIcon={!isMenuCollapsed || isMobileView}
        // />
        }
        <Scrollbars
          ref={scrollBarRef}
          className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '4px',
                borderRadius: 'inherit',
                backgroundColor: '#c5cdd2',
                left: '1px',
              }}
            />
          )}
          autoHide
        >
          {!this.props.loadedInit ? (
            <Space className="mt-5 ml-4" direction="vertical" size={30}>
              {this.makeLoader()}
            </Space>
          ) : (
            <Menu
              theme={isLightTheme ? 'light' : 'dark'}
              onClick={this.handleClick}
              selectedKeys={selectedKeys}
              openKeys={openedKeys}
              onOpenChange={this.onOpenChange}
              mode="inline"
              className={styles.navigation}
            >
              {menu}
            </Menu>
          )}
        </Scrollbars>
        {!isMobileView && <div style={{ height: 46 }} />}
      </Sider>
    )
  }
}

export default MenuLeft
