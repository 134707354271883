import { updateObject } from '../utility';

import * as actionTypes from '../actionTypes';

const initialState = {
  options: {}
};

const setOptions = (state, { payload }) => {
  return updateObject(state, {
    options: payload.options
  });
};

const editOptions = (state, { payload }) => {
  return updateObject(state, {
    options: {
      ...state.options,
      ...payload
    }
  });
};

const setup = (state, demo) => {
  // const setupBtn = setup_button ? { setup_button } : {};
  return updateObject(state, {
    options: {
      ...state.options,
      demo,
      // ...setupBtn,
    }
  });
};

const removeLockDate = (state) => {
  const { lock_date, ...prevOptions } = state.options;
  return {
    options: prevOptions
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OPTIONS: return setOptions(state, action);

    case actionTypes.SET_LOCK_DATE_OPTION:
    case actionTypes.EDIT_OPTIONS:
      return editOptions(state, action);
    case actionTypes.SETUP_CLEAR_DEMO_DATA: return setup(state, 2);
    case actionTypes.SETUP_FINISH: return setup(state, 0);
    case actionTypes.REMOVE_LOCK_DATE_OPTION: return removeLockDate(state);
    default: return state;
  }
};

export default reducer;
