import React, { Component, } from "react";
import ReactGa from "react-ga";
import ReactPixel from 'react-facebook-pixel';

export const initializeGa = () => {
  if (process.env.REACT_APP_TRACKING_CODE) {
    ReactGa.initialize(process.env.REACT_APP_TRACKING_CODE);
  }
}

export const initializeFp = (email) => {
  if (process.env.REACT_APP_FACEBOOK_PIXEL_ID) {
    const advancedMatching = email ? { em: email } : {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, 	// set pixel's autoConfig
      debug: false, 		// enable logs
    };
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, advancedMatching, options);
  }
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if (process.env.NODE_ENV === 'production') {
      ReactGa.set({
        page,
        ...options,
      });
      ReactGa.pageview(page);

      ReactPixel.pageView(page);
    }
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      if (this.props.location) {
        const page = this.props.location.pathname + this.props.location.search;
        trackPage(page);
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.location) {
        const currentPage = prevProps.location.pathname + prevProps.location.search;
        const nextPage = this.props.location.pathname + this.props.location.search;

        if (currentPage !== nextPage) {
          trackPage(nextPage);
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;