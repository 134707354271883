import { editCompanyName } from 'redux/companies/actions';
import {updateOptions, setLockDate, deleteLockDate, onBoarding, confirmsEmail} from 'utils/apis';
import * as actionTypes from '../actionTypes';

export const setOptions = (payload) => {
  return {
    type: actionTypes.SET_OPTIONS,
    payload
  };
};

export const editOptionsRedux = ( payload ) => {
  return {
    type: actionTypes.EDIT_OPTIONS,
    payload
  };
};

export const editOptions = (payload) => {
  return dispatch => {
    return updateOptions( payload )
      .then( response => {
        dispatch(editOptionsRedux(response.data.data));
        if (response.data.data.property_name) {
          dispatch(editCompanyName(response.data.data.property_name));
        }

        return response;
      });
  };
};

export const editOption = (payload) => {
  return async (dispatch, getState) => {
    const prevOption = await getState().options.options;
    dispatch(editOptionsRedux(payload));
    return updateOptions(payload)
    .catch(() => {
      Object.keys(payload).forEach(name => {
          payload[name] = prevOption[name];
      });
      dispatch(editOptionsRedux(payload));
    });
  };
};

export const confirmEmail = (payload) => {
  return async (dispatch) => {
    return confirmsEmail(payload)
      .then(response => {
        dispatch(editOptionsRedux({ email_confirmed: '1' }));

        return response;
      });
  }
}

export const setLockDateRedux = (payload) => {
  return {
    type: actionTypes.SET_LOCK_DATE_OPTION,
    payload
  };
};

export const removeLockDateRedux = (payload) => {
  return {
    type: actionTypes.REMOVE_LOCK_DATE_OPTION,
    payload
  };
};

export const setLockDateOption = (payload) => {
  return dispatch => {
    return setLockDate(payload)
      .then(response => {
        if (response.data.success) {
            dispatch(setLockDateRedux({ lock_date: response.data.data}));
        }

        return response;
      });
  };
};

export const removeLockDateOption = () => {
  return dispatch => {
    return deleteLockDate()
      .then(response => {
        if (response.data.success) {
            dispatch(removeLockDateRedux());
        }

        return response;
      });
  };
};

export const onBoardingProcess = (payload) => {
  return dispatch => {
    return onBoarding(payload.api)
      .then(response => {
        if (response.data.success) {
          dispatch(editOptionsRedux(payload.redux));
        }
        return response;
      });
  };
};