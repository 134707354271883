import axios from 'axios';

export const addFinanceProductCategory = (data) => {
  return axios.post('/finance/productCategories', data);
};

export const editFinanceProductCategory = (data) => {
  return axios.put(`/finance/productCategories/${data.id}`, data);
};

export const deleteFinanceProductCategory = (id) => {
  return axios.delete(`/finance/productCategories/${id}`);
};

export const moveFinanceProductCategory = (srcId, destId) => {
  return axios.patch(`/finance/productCategories/${srcId}/move`, { dest_id: destId });
};

export const activateFinanceProductCategory = (id) => {
  return axios.patch(`/finance/productCategories/${id}/activate`);
};

export const deactivateFinanceProductCategory = (id) => {
  return axios.patch(`/finance/productCategories/${id}/deactivate`);
};

export const canBeDeletedProductCategory = () => {
  return axios.get('/finance/productCategories/canBeDeleted');
};