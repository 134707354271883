import * as api  from 'utils/apis/financeExpenseTerm';
import * as actionTypes from '../actionTypes';

export const getCanDeletedExpenseTermsRedux = (payload) => {
  return {
    type: actionTypes.GET_CAN_DELETED_FINANCE_EXPENSE_TERMS,
    payload
  }
}

export const getCanDeletedExpenseTerms = () => {
  return dispatch => {
    return api.getCanDeletedFinanceExpenseTerm()
      .then(response => {
        dispatch(getCanDeletedExpenseTermsRedux(response.data.data));
        return response;
      });
  }
}

export const setFinanceExpenseTerms = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_EXPENSE_TERMS,
    payload
  };
};

export const addFinanceExpenseTermRedux = (payload) => {
  return {
    type: actionTypes.ADD_FINANCE_EXPENSE_TERM,
    payload
  };
};

export const editFinanceExpenseTermRedux = (payload) => {
  return {
    type: actionTypes.EDIT_FINANCE_EXPENSE_TERM,
    payload
  };
};

export const addFinanceExpenseTerm = (payload) => {
  return dispatch => {
    return api.addFinanceExpenseTerm(payload)
      .then(response => {
        dispatch(addFinanceExpenseTermRedux(response.data.data));
        return response;
      });
  };
};

export const editFinanceExpenseTerm = (payload) => {
  return dispatch => {
    return api.editFinanceExpenseTerm(payload)
      .then(response => {
        dispatch(editFinanceExpenseTermRedux(response.data.data));
        return response;
      })
  };
};

export const deleteFinanceExpenseTermRedux = (payload) => {
  return {
    type: actionTypes.DELETE_FINANCE_EXPENSE_TERM,
    payload
  };
};

export const deleteFinanceExpenseTerm = (payload) => {
  return dispatch => {
    return api.deleteFinanceExpenseTerm(payload)
      .then(response => {
        dispatch(deleteFinanceExpenseTermRedux(payload.id));

        return response;
      });
  };
};

export const moveFinanceExpenseTermRedux = (payload) => {
  return {
    type: actionTypes.MOVE_FINANCE_EXPENSE_TERM,
    payload
  };
};

export const moveFinanceExpenseTerm = (payload) => {
  return dispatch => {
    dispatch(moveFinanceExpenseTermRedux(payload));

    return api.moveFinanceExpenseTerm(payload.srcId, payload.destId)
      .then(response => {
        return response;
      })
      .catch(() => {
        dispatch(moveFinanceExpenseTermRedux({
          dragIndex: payload.hoverIndex,
          hoverIndex: payload.dragIndex,
          srcId: payload.destId,
          destId: payload.srcId,
        }));
      });
  };
};

export const changeStatusFinanceExpenseTermRedux = (payload) => {
  return {
    type: actionTypes.CHANGE_STATUS_FINANCE_EXPENSE_TERM,
    payload
  };
};

export const changeStatusFinanceExpenseTerm = (payload) => {
  return dispatch => {
    dispatch(changeStatusFinanceExpenseTermRedux(payload));

    if (payload.checked) {
      return api.activateFinanceExpenseTerm(payload.id)
    }

    return api.deactivateFinanceExpenseTerm(payload.id);
  };
};

export const filterFinanceExpenseTerm = (payload) => {
  return {
    type: actionTypes.FILTER_FINANCE_EXPENSE_TERM,
    payload
  };
};
