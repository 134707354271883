import axios from 'axios';

export const getFinanceNumbers = () => {
  return axios.get('/finance/numbers/format');
};

export const getFinanceNumberFormat = (id, params) => {
  return axios.get(`/finance/numbers/${id}`, { params });
};

export const editFinanceNumber = (id, data) => {
  return axios.put(`/finance/numbers/${id}`, data);
};

export const getFinanceNumberNext = (name, params) => {
  return axios.get(`/finance/numbers/next/${name}`, { params });
};

export const isFinanceNumberExist = (name, params) => {
  return axios.get(`/finance/numbers/exists/${name}`, { params });
};
