import {message} from 'antd';
import {push} from 'connected-react-router';
import localStore from 'store'
import {getInits, getInitsPublic} from 'utils/apis';
import { removeToken } from 'utils/cookies';
import * as actionTypes from '../actionTypes';
// import {posStore} from '../posSettings/actions';
import {setFinance} from '../finance/actions';
import {setBroadcast} from '../broadcast/actions';
import {changeSetting} from '../settings/actions';
import {setTutorials} from '../tutorials/actions';
import {initialState as initialStateUser} from '../user/reducers';
import { setSelectedEndpoint } from 'redux/companies/actions';
import { hideLoginForm } from 'redux/user/actions';
import { setDefaultMessage } from 'redux/defaultMessage/actions';
import { setCustomColumns } from 'redux/customColumn/actions';
import { setCurrencies } from 'redux/currencies/actions';

export const startLoading = () => {
    return {
        type: actionTypes.START_LOADING
    };
};

export const stopLoading = () => {
    return {
        type: actionTypes.STOP_LOADING
    };
};

export const startInit = () => {
    return {
        type: actionTypes.START_INIT
    };
};

export const stopInit = () => {
    return {
        type: actionTypes.STOP_INIT
    };
};

export const setLoadedInit = (payload = true) => {
    return {
        type: actionTypes.SET_LOADED_INIT,
        payload,
    };
};

export const increaseAttemp = () => {
    return {
        type: actionTypes.INCREASE_ATTEMP
    };
};

export const resetAttemp = () => {
    return {
        type: actionTypes.RESET_ATTEMP
    };
};

export const setRefs = ( payload ) => {
    return {
        type: actionTypes.SET_REFS,
        payload
    };
};

export const setPayments = (payload) => {
    return {
        type: actionTypes.SET_PAYMENTS,
        payload
    };
}

export const setUser = (payload) => {
    return {
        type: actionTypes.SET_USER,
        payload
    };
};

export const setMenu = (payload) => {
    return {
        type: actionTypes.SET_MENU,
        payload
    };
};

export const setOptions = (payload) => {
    return {
        type: actionTypes.SET_OPTIONS,
        payload
    };
};

export const setBilling = payload => {
    return {
        type: actionTypes.SET_BILLING,
        payload,
    }
}

export const setCompanies = payload => {
    return {
        type: actionTypes.SET_COMPANIES,
        payload,
    }
}

export const setTranslation = payload => {
    return {
        type: actionTypes.SET_TRANSLATION,
        payload,
    }
}

export const setNPS = payload => {
    return {
        type: actionTypes.SET_NPS,
        payload,
    }
}

export const setReviews = payload => {
    return {
        type: actionTypes.SET_REVIEWS,
        payload,
    }
}

export const setInits = (data) => {
    return (dispatch, getState) => {
        if (data.user) {
            dispatch(changeSetting({
                setting: 'locale',
                value: data.user.lang,
            }));
            dispatch(setUser(data));
            dispatch(setMenu(data.user.menus));
        }
        dispatch(setCompanies({
            companies: data.companies,
            website_id: data.website_id,
            endpoint: data.endpoint,
        }));

        // Jika belum ada endpoint disetkan dari init
        if (data.endpoint && data.endpoint !== localStore.get('app.endpoint') && !localStore.get('app.endpoint')) {
            if (getState()?.router?.location?.pathname !== '/user/select-company') {
                dispatch(setSelectedEndpoint(data.endpoint));
            }
        }
        if (typeof data.show_nps !== 'undefined') {
            dispatch(setNPS(data.show_nps));
        }
        if (data.tutorials) {
            dispatch(setTutorials(data.tutorials));
        }
        if (data.message_default) {
            dispatch(setDefaultMessage(data.message_default));
        }
        if (data.translation) dispatch(setTranslation(data.translation));
        if (data.lang) dispatch(setRefs(data));
        if (data.payments) dispatch(setPayments(data));
        if (data.options) dispatch(setOptions(data));
        if (data.finance) dispatch(setFinance(data.finance));
        if (data.billing) dispatch(setBilling(data.billing));
        if (data.broadcast) {
            dispatch(setBroadcast(data.broadcast));
        }
        if (data.custom_columns) {
            dispatch(setCustomColumns({
                custom_column_types: data.custom_column_types,
                custom_columns: data.custom_columns,
                custom_column_items: data.custom_column_items,
            }));
        }
        if (data.currencies) {
            dispatch(setCurrencies(data.currencies));
        }
        dispatch(setLoadedInit())
    }
}

export const inits = () => {
    return async (dispatch, getState)=> {
        const state = getState();
        await dispatch(startInit());

        return getInits().then( response => {
            if (response.data.success) {
                dispatch(setInits(response.data.data));
                dispatch(stopInit());
            } else {
                message.error(response.data.message);
                dispatch(stopInit());
            }
            return true;
        })
        .catch((e) => {
            if (e && e.status !== 401) {
                const maxAttemp = 4;
                if (state.inits.attemp === maxAttemp) {
                    dispatch(push('/server-error'));
                } else {
                    dispatch(increaseAttemp());
                }
            } else {
                removeToken();
                dispatch(resetInits());
                dispatch(push('/user/login'));
                dispatch(hideLoginForm());
            }
            dispatch(stopInit());
            return false;
        });
    };
};

export const initsPublic = () => {
    return dispatch => {
        dispatch(startInit());

        return getInitsPublic().then(response => {
            if (response.data.success) {
                const data = response.data.data;
                dispatch(setReviews(data.quotes));
                dispatch(changeSetting({
                    setting: 'locale',
                    value: data.options.property_language || 'id-ID',
                }));
                dispatch(setOptions(data));
                dispatch(stopInit());
            } else {
                message.error(response.data.message);
                dispatch(stopInit());
            }
            return true;
        })
        .catch(() => {
            dispatch(stopInit());
            return false;
        });
    };
};

// Reset redux ketika logout
export const resetInits = () => {
    return dispatch => {
        dispatch(setUser({user: initialStateUser}));
        dispatch(setMenu({dashboard: [], settings: []}));
        dispatch(setCompanies({
            companies: [],
            website_id: null,
            endpoint: null,
        }));
        dispatch(setSelectedEndpoint(null));
        dispatch(setNPS(0));
        dispatch(setRefs({lang: []}));
        dispatch(setPayments({payments: []}));
        dispatch(setCustomColumns({
            custom_column_types: [],
            custom_columns: [],
            custom_column_items: [],
        }));
        dispatch(setFinance({
            accountCategories: [],
            bankAccounts: [],
            config: {},
            dashboard: [],
            expenseTerms: [],
            productCategories: [],
            shippingComps: [],
            taxes: [],
            units: [],
            warehouses: [],
        }));
    };
};
