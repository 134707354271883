import { startCase, toLower } from 'lodash';
import { appMessage } from 'utils/formatting';
import { updateObject } from '../utility';
import * as actionTypes from '../actionTypes';

const initialState = {
  menuLeftData: [],
}

//Memastikan format title huruf depan besar
const formatedTitle = (title) => startCase(toLower(title));

const formatedDashboardMenu = (dashboardMenus) => {
  let menus = [];
  menus = dashboardMenus.map((item, itemIndex) => {
    let subMenu = {
      key: `${item.title}-${itemIndex}`,
      title: item.title,
      name: item.name,
      icon: item.icon,
    }

    if (item.divider) {
      subMenu.divider = item.divider;
    }

    if (item.sub) {
      subMenu['children'] = item.sub.map((row, rowIndex) => {
        const subChildrenMenu = {
          key: `${row.title}-${itemIndex}-${rowIndex}`,
          title: formatedTitle(row.title),
          name: row.name,
          url: row.url,
          icon: row.icon,
        };

        if (row.divider) {
          subChildrenMenu.divider = row.divider;
        }

        return subChildrenMenu
      })
    } else {
      subMenu['url'] = item.url;
    }

    return subMenu;
  });

  return menus;
}

const formatedSettingMenu = (settingMenus) => {
  let menus = [];
  menus = settingMenus.map((item, itemIndex) => {
    let subMenu = {
      key: `${item.title}-${itemIndex}`,
      title: item.title,
      name: item.name,
      icon: item.icon,
      group_id: item.group_id,
    }

    if (item.divider) {
      subMenu.divider = item.divider;
    }

    if (item.children) {
      subMenu['children'] = item.children.map((row, rowIndex) => {
        const subChildrenMenu = {
          key: `${row.title}-${rowIndex}`,
          title: formatedTitle(row.title),
          name: row.name,
          url: `/settings${row.url}`,
          icon: row.icon,
          group_id: row.group_id,
        }

        if (row.divider) {
          subChildrenMenu.divider = row.divider;
        }

        return subChildrenMenu;
      })
    } else {
      if (!subMenu.divider) {
        subMenu['url'] = `/settings${item.url}`;
      }
    }

    return subMenu;
  });

  return menus;
}
//Ubah struktur menu dari api sesuai kebutuhan client
const formatedMenu = (menus) => {
  let menuLeftData = [];
  const dashboard = formatedDashboardMenu(menus.dashboard);
  menuLeftData = [...dashboard]
  const menuSetting = {
    title: appMessage('settings'),
    name: 'settings',
    key: 'Settings',
    icon: 'cogs',
    children: formatedSettingMenu(menus.settings),
    url: '/settings'
  };
  menuLeftData.push(menuSetting);

  return menuLeftData;
}

const setMenu = (state, { payload }) => {
  return updateObject(state, {
    menuLeftData: formatedMenu(payload)
  });
};

const editMenu = (state, { payload }) => {
  const menuLeftData = state.menuLeftData.map(menu => {
    if (menu.name === payload.name) {
      menu.title = payload.value;
      return menu;
    }
    if (menu.children) {
      menu.children = menu.children.map(child => {
        if (child.name === payload.name) {
          child.title = payload.value;
          return child;
        }
        return child;
      })
    }
    return menu;
  })

  return { menuLeftData }
}

const resetMenu = (state, { payload }) => {
  const menuLeftData = state.menuLeftData.map(menu => {
    let found = false;
    Object.keys(payload).map(e => {
      if (found) return menu;
      if (e === menu.name) {
        menu.title = payload[e];
        found = true;
        return menu;
      }
    })
    if (menu.children) {
      menu.children = menu.children.map(child => {
        let foundChild = false;
        Object.keys(payload).map(e => {
          if (foundChild) return child;
          if (e === child.name) {
            child.title = payload[e];
            foundChild = true;
            return child;
          }
        })
        return child;
      })
    }
    return menu;
  })

  return { menuLeftData }
}

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_MENU:
      return setMenu(state, action);
    case actionTypes.EDIT_MENU:
      return editMenu(state, action);
    case actionTypes.RESET_MENU:
      return resetMenu(state, action);
    default:
      return state
  }
}
