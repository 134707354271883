import { updateObject } from '../utility';

import * as actionTypes from '../actionTypes';

export const initialState = {
  id: 0,
  name: '',
  email: '',
  is_active: 0,
  phone_number: '',
  permissions: [],
  loading: false,
  logout: false,
  profile_image: null,
}

const setUser = (state, { payload }) => {
  delete payload.user.menu;
  return updateObject(state, {
    ...payload.user,
    loading: false
  });
};

const loginRedux = (state) => {
  return updateObject(state, {
    loading: true
  });
};

const loginReduxFailure = (state) => {
  return updateObject(state, {
    loading: false
  });
};

const updateProfile = (state, { payload }) => {
  return updateObject(state, payload);
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_USER:
      return setUser(state, action)
    case actionTypes.USER_LOGIN:
      return loginRedux(state, action)
    case actionTypes.USER_LOGIN_FAILURE:
      return loginReduxFailure(state, action)
    case actionTypes.TOKEN_EXPIRED:
    case actionTypes.USER_LOGOUT:
      return { ...initialState, logout: true}
    case actionTypes.USER_UPDATE_PROFILE:
      return updateProfile(state, action)
    default:
      return state
  }
}
