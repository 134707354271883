import { updateObject } from '../utility'

import * as actionTypes from '../actionTypes'

const initialState = {
  units: [],
  canDeletedUnits: [],
  filter: {
    searchText: '',
  },
}

const getCanDeletedUnits = (state, action) => {
  return updateObject(state, {
    canDeletedUnits: action.payload,
  })
}

const setFinanceUnits = (state, { payload }) => {
  return updateObject(state, {
    units: payload,
  })
}

const addFinanceUnit = (state, action) => {
  const units = [...state.units, action.payload]
  return updateObject(state, { units })
}

const editFinanceUnit = (state, action) => {
  const items = state.units.map((item) => {
    if (item.id === action.payload.id) {
      return action.payload
    }
    return item
  })
  return updateObject(state, { units: items })
}

const moveFinanceUnit = (state, action) => {
  const { dragIndex, hoverIndex } = action.payload
  const units = [...state.units]
  const srcFinanceUnit = units[dragIndex]
  units.splice(dragIndex, 1)
  units.splice(hoverIndex, 0, srcFinanceUnit)
  return updateObject(state, { units })
}

const filterFinanceUnit = (state, { payload }) => {
  return updateObject(state, {
    filter: {
      ...state.filter,
      searchText: payload.searchText,
    },
  })
}

const deleteFinanceUnit = (state, { payload }) => {
  const units = state.units.filter((unit) => unit.id !== payload.id)
  return updateObject(state, { units })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_UNITS:
      return setFinanceUnits(state, action)
    case actionTypes.ADD_FINANCE_UNIT:
      return addFinanceUnit(state, action)
    case actionTypes.EDIT_FINANCE_UNIT:
      return editFinanceUnit(state, action)
    case actionTypes.MOVE_FINANCE_UNIT:
      return moveFinanceUnit(state, action)
    case actionTypes.FILTER_FINANCE_UNIT:
      return filterFinanceUnit(state, action)
    case actionTypes.GET_CAN_DELETED_FINANCE_UNIT:
      return getCanDeletedUnits(state, action)
    case actionTypes.DELETE_FINANCE_UNIT:
      return deleteFinanceUnit(state, action)
    default:
      return state
  }
}

export default reducer
