import React from 'react';
import classNames from 'classnames';
import { Spin } from 'antd';
import styles from './style.module.css';

const Spinner = ({ spinning = true, style = {} }) => (
  <Spin
    className={classNames(styles.loader, {
      [styles.hidden]: !spinning
    })}
    style={style}
  />
)

export default Spinner;
