import { updateObject } from '../utility'

import * as actionTypes from '../actionTypes'

const initialState = {
  taxes: [],
  canDeletedTaxes: [],
  filter: {
    searchText: '',
  },
}

export const getActiveFinanceTaxes = (taxes) => {
  const filterFinanceTaxes = taxes.filter((financeTax) => financeTax.is_active === 1)

  return filterFinanceTaxes
}

const getCanDeletedTaxes = (state, action) => {
  return updateObject(state, {
    canDeletedTaxes: action.payload,
  })
}

const setFinanceTaxes = (state, { payload }) => {
  return updateObject(state, {
    taxes: payload,
  })
}

const addFinanceTax = (state, action) => {
  const taxes = [...state.taxes, action.payload]
  return updateObject(state, { taxes })
}

const editFinanceTax = (state, action) => {
  const items = state.taxes.map((item) => {
    if (item.id === action.payload.id) {
      return action.payload
    }
    return item
  })
  return updateObject(state, { taxes: items })
}

const moveFinanceTax = (state, action) => {
  const { dragIndex, hoverIndex } = action.payload
  const taxes = [...state.taxes]
  const srcFinanceTax = taxes[dragIndex]
  taxes.splice(dragIndex, 1)
  taxes.splice(hoverIndex, 0, srcFinanceTax)
  return updateObject(state, { taxes })
}

const changeStatusFinanceTax = (state, action) => {
  const taxes = state.taxes.map((item) => {
    if (item.id === action.payload.id) {
      return {
        ...item,
        is_active: action.payload.checked ? 1 : 0,
      }
    }
    return item
  })
  return updateObject(state, { taxes })
}

const filterFinanceTax = (state, { payload }) => {
  return updateObject(state, {
    filter: {
      ...state.filter,
      searchText: payload.searchText,
    },
  })
}

const deleteFinanceTax = (state, { payload }) => {
  const taxes = state.taxes.filter((tax) => tax.id !== payload.id)
  return updateObject(state, { taxes })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_TAXES:
      return setFinanceTaxes(state, action)
    case actionTypes.ADD_FINANCE_TAX:
      return addFinanceTax(state, action)
    case actionTypes.EDIT_FINANCE_TAX:
      return editFinanceTax(state, action)
    case actionTypes.MOVE_FINANCE_TAX:
      return moveFinanceTax(state, action)
    case actionTypes.CHANGE_STATUS_FINANCE_TAX:
      return changeStatusFinanceTax(state, action)
    case actionTypes.FILTER_FINANCE_TAX:
      return filterFinanceTax(state, action)
    case actionTypes.GET_CAN_DELETED_FINANCE_TAX:
      return getCanDeletedTaxes(state, action)
    case actionTypes.DELETE_FINANCE_TAX:
      return deleteFinanceTax(state, action)
    default:
      return state
  }
}

export default reducer
