import axios from 'axios'
import { stringify } from 'query-string'
import { cleanBlankValue, getOnce } from 'utils/helper'

export const getFinanceManualJournals = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/manualJournals?${query}`)
}

export const addFinanceManualJournal = (data) => {
  return axios.post('/finance/manualJournals', data)
}

export const editFinanceManualJournal = (data) => {
  return axios.put(`/finance/manualJournals/${data.id}`, data)
}

export const deleteOnAddFinanceManualJournalAttachment = (url) => {
  return axios.delete('/finance/manualJournals/attachments', { data: { url } })
}

export const deleteOnEditFinanceManualJournalAttachment = (id, url) => {
  return axios.delete(`/finance/manualJournals/${id}/attachments`, { data: { url } })
}

export const getFinanceManualJournal = (id) => {
  return axios.get(`/finance/manualJournals/${id}`)
}

export const getFinanceManualJournalLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/manualJournals/${id}/logs${query}`)
}

export const deleteFinanceManualJournal = (id) => {
  return axios.delete(`/finance/manualJournals/${id}`)
}

export const uploadImportFinanceManualJournal = (data) => {
  return axios.post('/finance/manualJournals/uploadImport', data)
}

export const executeImportFinanceManualJournal = (data) => {
  return axios.post('/finance/manualJournals/executeImport', data)
}

export const exportFinanceManualJournal = (params) => {
  const URL =
    params.status_id === 'recurring' ? 'manualJournals/recurring/export' : 'manualJournals/export'
  if (params.status_id === 'recurring') {
    delete params.status_id
  }
  const query = stringify(cleanBlankValue(params))
  return axios.get(`/finance/${URL}?${query}`, { responseType: 'arraybuffer' })
}

export const addRecurringManualJournal = (data) => {
  return axios.post('/finance/manualJournals/recurring', data)
}

export const editRecurringManualJournal = (data) => {
  return axios.put(`/finance/manualJournals/recurring/${data.id}`, data)
}

export const getRecurringManualJournal = (id) => {
  return axios.get(`/finance/manualJournals/recurring/${id}`)
}

export const deleteRecurringManualJournal = (id) => {
  return axios.delete(`/finance/manualJournals/recurring/${id}`)
}

export const getRecurringManualJournals = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/manualJournals/recurring?${query}`)
}

export const inputMassDeleteManualJournal = (data) => {
  return axios.post('/finance/manualJournals/inputMassDelete', data)
}

export const executeMassDeleteManualJournal = (data) => {
  return axios.delete('/finance/manualJournals/executeMassDelete', { data })
}
