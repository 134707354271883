import axios from 'axios';

export const getPriceRules = (params) => {
  return axios.get('/finance/priceRules', { params });
};

export const addPriceRule = (data) => {
  return axios.post('/finance/priceRules', data);
};

export const editPriceRule = (data) => {
  return axios.put(`/finance/priceRules/${data.id}`, data);
};

export const getPriceRule = (id) => {
  return axios.get(`/finance/priceRules/${id}`);
};

export const deletePriceRule = (id) => {
  return axios.delete(`/finance/priceRules/${id}`);
};