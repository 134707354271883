import React from 'react'
import { Menu, Dropdown } from 'antd'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import styles from './style.module.scss'

class CompanySelector extends React.PureComponent {
  componentDidMount() {
    if (this.props.isMobileView) {
      document.querySelector('header.ant-layout-header').classList.add(styles.lineHeightInherit)
    }
  }

  changeCompany = ({ key }) => {
    const { onChangeCompany, companies, push } = this.props
    if (key === 'list') {
      push('/companies')
    } else if (key === 'add') {
      push('/companies/add')
    } else if (companies.selectedEndpoint !== key) {
      onChangeCompany(key)
    }
  }

  render() {
    const { companies, intl } = this.props

    const selectedCompany = companies.companies.filter(
      (e) => e.endpoint === companies.selectedEndpoint,
    )
    const selected = selectedCompany[0] ? selectedCompany[0].name : ''

    const langMenu = (
      <Menu
        className={styles.menu}
        selectedKeys={[companies.selectedEndpoint]}
        onClick={this.changeCompany}
      >
        {companies.companies.map((item) => {
          return <Menu.Item key={item.endpoint}>{item.name}</Menu.Item>
        })}
        <Menu.Divider />
        {companies.companies.length > 1 ? (
          <Menu.Item key="list">{intl.formatMessage({ id: 'companies.company_list' })}</Menu.Item>
        ) : (
          <Menu.Item key="add">{intl.formatMessage({ id: 'companies.add_company' })}</Menu.Item>
        )}
      </Menu>
    )
    return (
      <Dropdown overlay={langMenu} trigger={['click']}>
        <div className={styles.dropdown}>
          <strong className="text-uppercase" style={{ lineHeight: 'inherit' }}>
            {selected}
          </strong>
        </div>
      </Dropdown>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isMobileView: state.settings.isMobileView,
  }
}

export default connect(mapStateToProps)(injectIntl(CompanySelector))
