import React from 'react';
import { PageHeader as PageHeaderAntd, Skeleton } from 'antd';
import PropTypes from 'prop-types';

function PageHeader({loading, ...props}) {
  const newExtra = props.extra ? (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {props.extra.map(e => <div key={e.key} style={{ margin: 2 }}>{e}</div>)}
    </div>
  ) : null;

  return (
    <PageHeaderAntd
    {...props}
      title={loading ? <Skeleton.Input style={{ width: 300 }} active /> : props.title}
      extra={newExtra}
    />
  )
}

PageHeader.propTypes = {
  style: PropTypes.object,
  loading: PropTypes.bool,
}

PageHeader.defaultProps = {
  style: { padding: 0, marginBottom: 30},
  loading: false
}

export default PageHeader;