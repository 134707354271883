import axios from 'axios';
import { stringify } from 'query-string';
import { cleanBlankValue } from 'utils/helper';

export const getFinanceLogs = (params) => {
  const query = stringify(params);
  return axios.get(`/finance/logs?${query}`);
};

export const exportFinanceLogs = (params) => {
  params = cleanBlankValue(params);
  const query = stringify(params);
  return axios.get(`/finance/logs?${query}`, { responseType: 'arraybuffer' });
};
