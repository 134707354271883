import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SendOutlined } from '@ant-design/icons';

function Send(props) {
  const title = props.title === 'button.send' ? props.intl.formatMessage({ id: props.title }) : props.title
  return (
    <Button
      {...props}
      title={title}
    >
      {title}
    </Button>
  )
}

Send.propTypes = {
  icon: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
}

Send.defaultProps = {
  icon: <SendOutlined />,
  type: "success",
  title: "button.send",
  onClick: () => { },
  id: "btn-send"
}

export default injectIntl(Send);
