import { updateObject } from '../utility'

import * as actionTypes from '../actionTypes'

const initialState = {
  warehouses: [],
  warehouses_has_role: [],
  filter: {
    searchText: '',
  },
  adjustmentTypes: [
    { id: 1, name: 'Stock Count' },
    { id: 2, name: 'Stock In & Out' },
  ],
}

export const getActiveFinanceWareHouses = (warehouses) => {
  const filterFinanceWareHouses = warehouses.filter(
    (financeWareHouse) => financeWareHouse.is_active === 1,
  )

  return filterFinanceWareHouses
}

const setFinanceWareHouses = (state, { payload }) => {
  return updateObject(state, {
    warehouses: payload,
  })
}

const addFinanceWareHouse = (state, action) => {
  const warehouses = [...state.warehouses, action.payload]
  const warehouses_has_role = [...state.warehouses_has_role, action.payload]
  return updateObject(state, { warehouses, warehouses_has_role })
}

const editFinanceWareHouse = (state, action) => {
  const warehouses = state.warehouses.map((item) => {
    if (item.id === action.payload.id) {
      return action.payload
    }
    return item
  })

  const warehouses_has_role = state.warehouses_has_role.map((item) => {
    if (item.id === action.payload.id) {
      return action.payload
    }
    return item
  })

  return updateObject(state, { warehouses, warehouses_has_role })
}

const moveFinanceWareHouse = (state, action) => {
  const { dragIndex, hoverIndex } = action.payload
  const warehouses = [...state.warehouses]
  const srcFinanceWareHouse = warehouses[dragIndex]
  warehouses.splice(dragIndex, 1)
  warehouses.splice(hoverIndex, 0, srcFinanceWareHouse)
  return updateObject(state, { warehouses })
}

const changeStatusFinanceWareHouse = (state, action) => {
  const warehouses = state.warehouses.map((item) => {
    if (item.id === action.payload.id) {
      return {
        ...item,
        is_active: action.payload.checked ? 1 : 0,
      }
    }
    return item
  })
  return updateObject(state, { warehouses })
}

const filterFinanceWareHouse = (state, { payload }) => {
  return updateObject(state, {
    filter: {
      ...state.filter,
      searchText: payload.searchText,
    },
  })
}

const deleteFinanceWareHouse = (state, { payload }) => {
  const warehouses = state.warehouses.filter((warehouse) => warehouse.id !== payload.id)
  const warehouses_has_role = state.warehouses_has_role.filter(
    (warehouse) => warehouse.id !== payload.id,
  )
  return updateObject(state, { warehouses, warehouses_has_role })
}

const archiveFinanceWareHouse = (state, { payload }) => {
  const warehouses = state.warehouses.map((warehouse) => ({
    ...warehouse,
    is_archive: warehouse.id === payload.id ? 1 : warehouse.is_archive || 0,
  }))
  const warehouses_has_role = state.warehouses_has_role.map((warehouse) => ({
    ...warehouse,
    is_archive: warehouse.id === payload.id ? 1 : warehouse.is_archive || 0,
  }))
  return updateObject(state, { warehouses, warehouses_has_role })
}

const unarchiveFinanceWareHouse = (state, { payload }) => {
  const warehouses = state.warehouses.map((warehouse) => ({
    ...warehouse,
    is_archive: warehouse.id === payload.id ? 0 : warehouse.is_archive || 0,
  }))
  const warehouses_has_role = state.warehouses_has_role.map((warehouse) => ({
    ...warehouse,
    is_archive: warehouse.id === payload.id ? 0 : warehouse.is_archive || 0,
  }))
  return updateObject(state, { warehouses, warehouses_has_role })
}

const setFinanceWareHousesRoles = (state, { payload }) => {
  return updateObject(state, {
    warehouses_has_role: payload,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_WAREHOUSES:
      return setFinanceWareHouses(state, action)
    case actionTypes.ADD_FINANCE_WAREHOUSE:
      return addFinanceWareHouse(state, action)
    case actionTypes.EDIT_FINANCE_WAREHOUSE:
      return editFinanceWareHouse(state, action)
    case actionTypes.MOVE_FINANCE_WAREHOUSE:
      return moveFinanceWareHouse(state, action)
    case actionTypes.DELETE_FINANCE_WAREHOUSE:
      return deleteFinanceWareHouse(state, action)
    case actionTypes.ARCHIVE_FINANCE_WAREHOUSE:
      return archiveFinanceWareHouse(state, action)
    case actionTypes.UNARCHIVE_FINANCE_WAREHOUSE:
      return unarchiveFinanceWareHouse(state, action)
    case actionTypes.CHANGE_STATUS_FINANCE_WAREHOUSE:
      return changeStatusFinanceWareHouse(state, action)
    case actionTypes.FILTER_FINANCE_WAREHOUSE:
      return filterFinanceWareHouse(state, action)
    case actionTypes.SET_FINANCE_WAREHOUSES_ROLES:
      return setFinanceWareHousesRoles(state, action)
    default:
      return state
  }
}

export default reducer
