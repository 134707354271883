import React from 'react'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { push } from 'connected-react-router';

import { logout } from 'redux/user/actions';
import { checkPermission } from 'redux/user/selectors';
import { formatDate } from 'utils/formatting';
import { Upgrade } from 'components/UI/Button'

import styles from './style.module.scss'

@connect((state) => {
  return {
    user: state.user,
    billing: state.billing,
    hasAccessToBilling: checkPermission(state, 'billing')
  }
}, (dispatch => {
  return {
    onLogout: () => dispatch(logout()),
    push: (path) => dispatch(push(path)),
  }
}))
class ProfileMenu extends React.Component {
  logout = async (e) => {
    e.preventDefault();
    await this.props.onLogout();
    this.props.push('/user/login');
  }

  goToUpgradeHandler = () => {
    if (this.props.billing.can_be_renewed) {
      this.props.push('/settings/billing/upgrade?isRenew=1');
      return;
    }
    this.props.push('/settings/billing/upgrade');
  };

  render() {
    const { user, billing, intl, hasAccessToBilling } = this.props

    const menu = (
      <Menu selectable={false}>
        <Menu.Item key="topBar.profileMenu.hello">
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
          </strong>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="topBar.billing.plan">
          <strong>
            <FormattedMessage id="topBar.billing.plan" />:{' '}
          </strong>
          {billing.plan}
          <br />
          <strong>
            <FormattedMessage id="topBar.billing.active_until" />:{' '}
          </strong>
          {billing.expiry_date ? formatDate(billing.expiry_date) : <FormattedMessage id="topBar.billing.forever" />}
          <br />
          {
            ((billing.can_be_upgraded || billing.can_be_renewed) && hasAccessToBilling) &&
            <Upgrade
              onClick={this.goToUpgradeHandler}
              title={intl.formatMessage({ id: billing.can_be_renewed ? 'button.renew' : 'button.upgrade' })}
            />
          }
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="topBar.profileMenu.email">
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {user.email}
            <br />
            <strong>
              <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
            </strong>
            {user.phone_number || '-'}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="referral">
          <Link to="/referral">
            <FontAwesomeIcon icon="link" className={`${styles.menuIcon}`} />
            <FormattedMessage id="referral.referral" />
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="topBar.profileMenu.editProfile">
          <Link to="/settings/profile">
            <FontAwesomeIcon icon="user" className={`${styles.menuIcon}`} />
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="topBar.profileMenu.logout">
          <a href="#" onClick={this.logout}>
            <FontAwesomeIcon icon="sign-out-alt" className={`${styles.menuIcon}`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} src={user.profile_image} />
        </div>
      </Dropdown>
    );
  }
}

export default injectIntl(ProfileMenu)
