export const getPermissions = (state) => state.user.permissions || []
export const getMenus = (state) => state.user.menus || {}
export const getOptions = (state) => state.options.options || {}
export const getModulePermissions = (state, name) => {
  const permissions = getPermissions(state)
  const findData = permissions.find((row) => row.name === name)
  const actions = {
    isCanAdd: false,
    isCanEdit: false,
    isCanDelete: false,
  }
  if (findData && findData.child) {
    Object.keys(findData.child).forEach((index) => {
      const rowAction = findData.child[index]
      if (rowAction.name === `${name}_add`) {
        actions.isCanAdd = true
      } else if (rowAction.name === `${name}_edit`) {
        actions.isCanEdit = true
      } else if (rowAction.name === `${name}_delete`) {
        actions.isCanDelete = true
      } else if (rowAction.name === `${name}_reconcile`) {
        actions.isCanReconciliation = true
      } else if (rowAction.name === 'conversion_balance') {
        actions.isCanConversionBalance = true
      }
      actions[rowAction.name] = true
    })
  }

  return actions
}
const checkPermissionMain = (permissions, name) => {
  let findPermission = false
  permissions.forEach((perms) => {
    if (perms.name === name) findPermission = true
    if (findPermission) return findPermission
    if (perms.child && Object.keys(perms.child).length > 0) {
      const childPerms = Object.keys(perms.child).map((key) => perms.child[key])
      findPermission = checkPermissionMain(childPerms, name)
    }
  })
  return findPermission
}
export const checkPermission = (state, name) => {
  const permissions = getPermissions(state)
  return checkPermissionMain(permissions, name)
}
const getFirstUrl = (data, url) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].sub) {
      url = getFirstUrl(data[i].sub, url)
      break
    } else {
      if (!data[i].divider && data[i].url && data[i].url !== '/') {
        url = data[i].url
        break
      }
    }
  }
  return url
}
export const isGrantToDashboard = (state) => {
  const menus = getMenus(state)
  const dashboard = menus.dashboard || []
  const findDashboard = dashboard.find((row) => row.name === 'home')

  return !!findDashboard
}
export const getRedirectUrl = (menus) => {
  if (!menus) {
    return '/'
  }
  const dashboard = menus.dashboard || []
  const settings = menus.settings ? menus.settings.map((e) => (e.url = `/settings${e.url}`)) : []
  let url = getFirstUrl(dashboard, '')
  if (url === '') {
    url = getFirstUrl(settings, '')
  }

  return url === '' ? '/' : url
}
