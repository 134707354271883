import axios from 'axios';
import { stringify } from 'query-string';
import { getOnce } from 'utils/helper';

export const getReturns = (params) => {
  const query = stringify(params);
  return getOnce(`/finance/returns?${query}`);
};

export const addReturn = (data) => {
  return axios.post('/finance/returns', data);
};

export const editReturn = (data) => {
  return axios.put(`/finance/returns/${data.id}`, data);
};

export const deleteOnAddReturnAttachment = (url) => {
  return axios.delete('/finance/returns/attachments', { data: { url } });
};

export const deleteOnEditReturnAttachment = (id, url) => {
  return axios.delete(`/finance/returns/${id}/attachments`, { data: { url } });
};

export const getReturn = (id, params) => {
  const query = params ? `?${stringify(params)}` : '';
  return axios.get(`/finance/returns/${id}${query}`);
};

export const deleteReturn = (id) => {
  return axios.delete(`/finance/returns/${id}`);
};

export const getReturnLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : '';
  return axios.get(`/finance/returns/${id}/logs${query}`);
};