import React from 'react'
import styles from './style.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className="d-flex justify-content-center">
        <a href="https://play.google.com/store/apps/details?id=com.kledo.app" target="_blank" rel="noopener noreferrer">
          <img src="/resources/images/download-gplay.png" alt="Google Play" className={styles.downloadImg} />
        </a>
        <a href="https://apps.apple.com/id/app/kledo/id1586502518" target="_blank" rel="noopener noreferrer">
          <img src="/resources/images/download-appstore.png" alt="App Store" className={styles.downloadImg} />
        </a>
      </div>
      <div className={styles.bottom}>
        <div className="row">
          <div className="col-sm-12">
            <div className={styles.copyright}>
              <span className="d-flex align-items-center justify-content-center flex-wrap">
                © {new Date().getFullYear()}
                &nbsp;
                <a href="https://kledo.com/" target="_blank" rel="noopener noreferrer">
                  {`Kledo Software v${process.env.REACT_APP_VERSION}`}
                </a>
                &nbsp;All rights reserved
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
