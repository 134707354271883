import * as api from 'utils/apis/multiCurrency'
import * as actionTypes from '../actionTypes'
import { editOptionsRedux } from '../options/actions'

export const setCurrencies = (payload) => {
  return {
    type: actionTypes.SET_CURRENCIES,
    payload,
  }
}

export const setMultiCurrenciesRedux = (payload) => {
  return {
    type: actionTypes.SET_MULTI_CURRENCIES,
    payload,
  }
}

export const getTenantCurrencies = (payload) => {
  return (dispatch) => {
    return api.getTenantCurrencies(payload).then((response) => {
      if (response.data.success) {
        dispatch(setMultiCurrenciesRedux(response.data.data))
        const currencies = response.data.data?.multi_currencies?.map((row) => ({
          code: row.currency?.code,
          name: row.currency?.name,
          id: row.currency?.id,
          symbol: row.currency?.symbol,
        }))
        dispatch(setCurrencies(currencies))
      }
      return response
    })
  }
}

export const addTenantCurrencyRedux = (payload) => {
  return {
    type: actionTypes.ADD_CURRENCY,
    payload,
  }
}

export const editTenantCurrencyRedux = (payload) => {
  return {
    type: actionTypes.EDIT_CURRENCY,
    payload,
  }
}

export const setBaseCurrencyRedux = (payload) => {
  return {
    type: actionTypes.SET_BASE_CURRENCY,
    payload,
  }
}

export const activatedCurrencyRedux = () => {
  return {
    type: actionTypes.ACTIVATED_CURRENCY,
  }
}

export const deactivatedCurrencyRedux = () => {
  return {
    type: actionTypes.DEACTIVATED_CURRENCY,
  }
}

export const activatedTenantCurrency = (payload) => {
  return (dispatch, getState) => {
    return api.activateTenantCurrency(payload).then((response) => {
      // dispatch(activatedCurrencyRedux(response.data.data))
      if (response.data.success) {
        const state = getState()
        // Cari currencynya dulu
        const currency = state.options?.options?.currency
        const findCurrency = currency.find((item) => item.id === payload.base_currency)
        if (findCurrency) {
          dispatch(
            editOptionsRedux({
              multi_currency: 1,
              property_currency: findCurrency.code,
              property_currency_symbol: findCurrency.symbol,
            }),
          )
        }
      }
      return response
    })
  }
}

export const deactivatedTenantCurrency = () => {
  return (dispatch) => {
    return api.deactivateTenantCurrency().then((response) => {
      dispatch(deactivatedCurrencyRedux(response.data.data))
      dispatch(
        editOptionsRedux({
          multi_currency: 0,
        }),
      )
      return response
    })
  }
}

export const addTenantCurrency = (payload) => {
  return (dispatch) => {
    return api.addTenantCurrency(payload).then((response) => {
      if (response.data.success) {
        dispatch(addTenantCurrencyRedux(response.data.data))
      }
      return response
    })
  }
}

export const editTenantCurrency = (id, payload) => {
  return (dispatch) => {
    return api.editTenantCurrencySource(id, payload).then((response) => {
      if (response.data.success) {
        dispatch(editTenantCurrencyRedux(response.data.data))
      }
      return response
    })
  }
}

export const setBaseCurrency = (payload) => {
  return (dispatch, getState) => {
    return api.editBaseCurrency(payload).then((response) => {
      if (response.data.success) {
        const state = getState()
        // Cari currencynya dulu
        const currency = state.options?.options?.currency
        const findCurrency = currency.find((item) => item.id === payload.base_currency_id)
        const param = {
          currency: findCurrency || {},
          ...response.data.data,
          is_deletable: 0,
        }
        dispatch(setBaseCurrencyRedux(param))
      }
      return response
    })
  }
}

export const deleteTenantCurrencyRedux = (payload) => {
  return {
    type: actionTypes.DELETE_CURRENCY,
    payload,
  }
}

export const deleteTenantCurrency = (payload) => {
  return (dispatch) => {
    return api.deleteTenantCurrency(payload.id).then((response) => {
      dispatch(deleteTenantCurrencyRedux(payload))
      return response
    })
  }
}
