import axios from 'axios';

export const getMarketplaceConnects = (params) => {
  return axios.get('/marketplaceConnects', { params });
};

export const getMarketplaceConnectShopeeAuthUrl = (params) => {
  return axios.get('/marketplaceConnects/shopee/partnerAuthUrl', { params });
};

export const getMarketplaceConnectLazadaAuthUrl = (params) => {
  return axios.get('/marketplaceConnects/lazada/partnerAuthUrl', { params });
};

export const getMarketplaceConnect = (id) => {
  return axios.get(`/marketplaceConnects/${id}`);
};

export const syncMarketplaceConnect = (id) => {
  return axios.post(`/marketplaceConnects/${id}/sync`);
};

export const addTempMarketplaceConnect = (data) => {
  return axios.post(`/marketplaceConnects/tmp`, data);
};

export const deleteMarketplaceConnect = (id) => {
  return axios.delete(`/marketplaceConnects/${id}`);
};

export const editMarketplaceConnect = (data) => {
  return axios.put(`/marketplaceConnects/${data.id}`, data);
};
