/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo } from 'react'
import { Table, Row, Col, Card, Typography, ConfigProvider, Skeleton } from 'antd'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Add } from '../Button'

const { Text } = Typography

const ResponsiveTableSummary = ({ title, titleColSpan, datas = [], isMobileView, isTabView }) => {
  const getContent = useCallback(() => {
    const content = []

    if (isMobileView || isTabView) {
      content.push(
        <Table.Summary.Cell key="title" colSpan={2}>
          <Card
            title={
              <div style={{ fontSize: 18, fontWeight: 'bold', padding: '4px 0' }}>{title}</div>
            }
            size="small"
            bodyStyle={{ background: '#f1f4f8' }}
            headStyle={{ background: '#f1f4f8' }}
          >
            <Row>
              {datas.map((data, i) => {
                if (data.hide) return <React.Fragment key={data.title ? data.title : i} />
                return (
                  <Col xs={24} sm={12} key={data.title ? data.title : i} className="mt-3 px-1">
                    {data.title}
                    <h5>{data.value}</h5>
                  </Col>
                )
              })}
            </Row>
          </Card>
        </Table.Summary.Cell>,
      )
    } else {
      content.push(
        <Table.Summary.Cell colSpan={titleColSpan} key="title">
          <Text strong>{title}</Text>
        </Table.Summary.Cell>,
      )
      datas.forEach((data, i) => {
        if (data.hide) return
        content.push(
          <Table.Summary.Cell
            align={data.align ? data.align : 'right'}
            colSpan={data.colSpan}
            key={data.title ? data.title : i}
          >
            <Text strong>{data.value}</Text>
          </Table.Summary.Cell>,
        )
      })
    }
    return content
  }, [datas, isMobileView, isTabView, title, titleColSpan])

  return <Table.Summary.Row style={{ background: '#f1f4f8' }}>{getContent()}</Table.Summary.Row>
}

// Responsive table component
const ResponsiveTable = ({
  intl,
  name,
  onAdd,
  withTitle = true,
  isMobileView,
  isTabView,
  rowKey,
  columns,
  dataSource,
  pagination,
  expandable,
  footer,
  loading,
  rowClassName,
  onChange,
  onRow,
  sortDirections,
  summary,
  rowSelection,
  className,
  style,
  showHeader,
  components,
  scroll,
  expandedRowKeys,
  expandedRowRender,
  onExpandedRowsChange,
  onExpand,
  expandRowByClick,
  bordered,
  ...props
}) => {
  columns = columns.map((column) => {
    return {
      ...column,
      dataIndex:
        typeof column.dataIndex === 'string' ? column.dataIndex.split('.') : column.dataIndex,
      responsive: isMobileView || isTabView ? [] : null,
      render: loading ? () => null : column.render,
    }
  })

  const renderMobileRow = (row) => {
    return columns.map((column, index) => {
      if (withTitle && index === 0) return null

      let val = ''
      let colVal = ''

      if (column && column.dataIndex && column.dataIndex.length > 0) {
        colVal = row
        for (let i = 0; i < column.dataIndex.length; i += 1) {
          if (column && (colVal[column.dataIndex[i]] || colVal[column.dataIndex[i]] === 0)) {
            colVal = colVal[column.dataIndex[i]]
          } else {
            colVal = null
            break
          }
        }
      }

      val = column.render && column.key !== 'mobileView' ? column.render(colVal, row) : colVal
      if (!val) return null

      return (
        <Col xs={24} sm={12} key={column.key ? column.key : index} className="mt-3 px-1">
          {column.title}
          <h5>{val}</h5>
        </Col>
      )
    })
  }

  if (isMobileView || isTabView) {
    columns.push({
      key: 'mobileView',
      render: loading
        ? () => null
        : (text, row) => {
            let val = ''

            if (
              withTitle &&
              columns[0] &&
              columns[0].dataIndex &&
              columns[0].dataIndex.length > 0
            ) {
              val = row
              for (let i = 0; i < columns[0].dataIndex.length; i += 1) {
                if (columns[0] && val[columns[0].dataIndex[i]]) {
                  val = val[columns[0].dataIndex[i]]
                } else {
                  val = null
                  break
                }
              }

              val =
                columns[0].render && columns[0].key !== 'mobileView'
                  ? columns[0].render(val, row)
                  : val
              if (val)
                val = (
                  <div style={{ fontSize: 18, fontWeight: 'bold', padding: '4px 0' }}>{val}</div>
                )
            }

            return (
              <Card title={val} size="small">
                <Row>{renderMobileRow(row)}</Row>
              </Card>
            )
          },
    })
  }

  const emptyMessage = useMemo(() => {
    return name
      ? intl.formatMessage({ id: 'table.data_x_empty' }, { name })
      : intl.formatMessage({ id: 'table.data_empty' })
  }, [intl, name])

  return (
    <ConfigProvider
      renderEmpty={() =>
        loading ? (
          <div style={{ height: 140 }} />
        ) : (
          <>
            <div style={{ backgroundColor: '#b5e3ff' }} className="mb-4">
              <img
                alt="empty"
                src="resources/images/rocket.svg"
                width="100%"
                className="mx-auto d-block"
              />
            </div>
            <div style={!isMobileView && !isTabView ? { marginTop: -50, marginLeft: -50 } : {}}>
              {isMobileView ? <h5>{emptyMessage}</h5> : <h3>{emptyMessage}</h3>}
              {onAdd && (
                <>
                  <p className="text-black">
                    {intl.formatMessage({ id: 'table.lets_create_your_first_x' }, { name })}
                  </p>
                  <Add
                    onClick={onAdd}
                    title={name && `${intl.formatMessage({ id: 'button.add' })} ${name}`}
                  />
                </>
              )}
            </div>
          </>
        )
      }
    >
      <Table
        style={{ minHeight: loading ? 180 : undefined, ...style }}
        className={className}
        showHeader={showHeader}
        rowKey={rowKey}
        columns={columns}
        components={components}
        scroll={scroll}
        dataSource={dataSource}
        pagination={pagination}
        expandable={expandable}
        footer={footer}
        loading={
          loading && {
            indicator: <Skeleton className="ml-2 pr-2" active />,
            style: { left: '-50%', top: 'calc(-50% + 50px)' },
          }
        }
        rowClassName={rowClassName}
        onChange={onChange}
        onRow={onRow}
        sortDirections={sortDirections}
        summary={loading ? null : summary}
        rowSelection={
          rowSelection
            ? {
                ...rowSelection,
                renderCell: (checked, record, index, originNode) => (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      left: 0,
                      top: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'default',
                    }}
                  >
                    {originNode}
                  </div>
                ),
              }
            : undefined
        }
        expandedRowKeys={expandedRowKeys}
        expandedRowRender={expandedRowRender}
        onExpandedRowsChange={onExpandedRowsChange}
        onExpand={onExpand}
        expandRowByClick={expandRowByClick}
        bordered={bordered}
        {...props}
      />
    </ConfigProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    isMobileView: state.settings.isMobileView,
    isTabView: state.settings.isTabView,
  }
}

export default connect(mapStateToProps, {})(injectIntl(ResponsiveTable))

const Summary = connect(mapStateToProps, {})(ResponsiveTableSummary)

export { Summary }
