import * as actionTypes from '../actionTypes';

const setTutorials = (state, action) => {
  return action.payload.data;
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_TUTORIALS: return setTutorials(state, action);
    default: return state;
  }
};

export default reducer;
