import { createSelector } from 'reselect'
import _ from 'lodash'

export const initsSelector = createSelector(
  (state) => state.inits,
  (inits) => inits,
)
export const loadedInitSelector = createSelector(
  (state) => state.inits.loadedInit,
  (loadedInit) => loadedInit,
)
export const mobileViewSelector = createSelector(
  (state) => state.settings.isMobileView,
  (isMobileView) => isMobileView,
)
export const settingsSelector = createSelector(
  (state) => state.settings,
  (settings) => settings,
)
export const tabViewSelector = createSelector(
  (state) => state.settings.isTabView,
  (isTabView) => isTabView,
)
export const warehousesSelector = createSelector(
  (state) => state.finance.warehouse.warehouses,
  (warehouses) => warehouses,
)
export const warehousesHasRoleSelector = createSelector(
  (state) => state.finance.warehouse.warehouses_has_role,
  (warehouses) => warehouses,
)
export const taxesSelector = createSelector(
  (state) => state.finance.tax.taxes,
  (taxes) => taxes,
)
export const tutorialsSelector = createSelector(
  (state) => state.tutorials,
  (tutorials) => tutorials,
)
export const activeTaxesSelector = createSelector(taxesSelector, (taxes) =>
  taxes.filter((tax) => tax.is_active),
)
export const optionsSelector = createSelector(
  (state) => state.options.options,
  (options) => options,
)
export const shippingCompsSelector = createSelector(
  (state) => state.finance.shippingComp.shippingComps,
  (shippingComps) => shippingComps,
)
export const productCategoriesSelector = createSelector(
  (state) => state.finance.productCategories,
  (productCategories) => productCategories,
)
export const titleSelector = createSelector(
  (state) => state.translation,
  (_, name) => name,
  (translation, name) => (translation.menu ? translation.menu[name] : ''),
)
export const reportTitleSelector = createSelector(
  (state) => state.translation,
  (_, name) => name,
  (translation, name) => (translation.reportmenu ? translation.reportmenu[name] : ''),
)
export const termTitleSelector = createSelector(
  (state) => state.translation,
  (translation) => translation?.term,
)
export const financeConfigSelector = createSelector(
  (state) => state.finance.config,
  (config) => config,
)
export const desktopLayoutSelector = createSelector(
  (state) => state.settings,
  (settings) => !settings.isMobileView && !settings.isTabView,
)
export const auditModalSelector = createSelector(
  (state) => state.finance.auditModal,
  (auditModal) => auditModal,
)
export const accountTransReportSelector = createSelector(
  (state) => state.finance.accountTransactionReport,
  (accountTransactionReport) => accountTransactionReport,
)
export const locationSelector = createSelector(
  (state) => state.router.location,
  (location) => location,
)
export const termSelector = createSelector(
  (state) => state.finance.expenseTerm,
  (expenseTerm) => expenseTerm,
)
export const findTermSelector = createSelector(
  (state) => state.finance.expenseTerm.expenseTerms,
  (_, id) => id,
  (terms, id) => terms.find((tax) => tax.id === id),
)
export const activeTermsSelector = createSelector(
  (state) => state.finance.expenseTerm.expenseTerms,
  (terms) => terms.filter((term) => term.is_active),
)
export const countActiveTermSelector = createSelector(activeTermsSelector, (terms) => terms.length)
export const canDeletedTermsSelector = createSelector(
  (state) => state.finance.expenseTerm.canDeletedExpenseTerms,
  (canDeletedExpenseTerms) => canDeletedExpenseTerms,
)
export const firstTermSelector = createSelector(activeTermsSelector, (terms) => _.first(terms))
export const firstWarehouseSelector = createSelector(warehousesHasRoleSelector, (warehouses) => {
  const unArchiveWarehouses = (warehouses || []).filter((row) => row.is_archive !== 1)
  if (Array.isArray(unArchiveWarehouses) && unArchiveWarehouses.length > 0) {
    return unArchiveWarehouses[0]
  }

  return {}
})
export const unitsSelector = createSelector(
  (state) => state.finance.unit.units,
  (units) => units,
)
export const broadcastSelector = createSelector(
  (state) => state.broadcast,
  (broadcast) => broadcast,
)
export const showNPSSelector = createSelector(
  (state) => state.show_nps,
  (show_nps) => show_nps,
)
export const billingSelector = createSelector(
  (state) => state.billing,
  (billing) => billing,
)
export const userSelector = createSelector(
  (state) => state.user,
  (user) => user,
)
export const loginFormSelector = createSelector(
  (state) => state.loginForm,
  (loginForm) => loginForm,
)
export const companiesSelector = createSelector(
  (state) => state.companies.companies,
  (companies) => companies,
)
export const selectedEndpointSelector = createSelector(
  (state) => state.companies.selectedEndpoint,
  (selectedEndpoint) => selectedEndpoint,
)
export const hiddenFeatureSelector = createSelector(
  (state) => state.billing.hidden_feature,
  (hidden_feature) => hidden_feature,
)
export const contactGroupsSelector = createSelector(
  (state) => state.finance.contactGroups,
  (contactGroups) => contactGroups,
)
export const defaultMessageSelector = createSelector(
  (state) => state.defaultMessage,
  (defaultMessage) => defaultMessage,
)
export const customColumnSelector = createSelector(
  (state) => state.customColumn,
  (customColumn) => customColumn,
)
export const multiCurrencySelector = createSelector(
  (state) => state.multiCurrency,
  (multiCurrency) => multiCurrency,
)
