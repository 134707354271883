import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import store from 'store'
import { changeLayoutSetting } from 'redux/settings/actions'
import { mobileViewSelector, tabViewSelector } from 'redux/selectors'

// Hook untuk handle responsive UI dari redux
const useResponsive = () => {
  const dispatch = useDispatch()
  const isMobileView = useSelector(mobileViewSelector)
  const isTabView = useSelector(tabViewSelector)

  const isDesktopLayout = useMemo(() => !isMobileView && !isTabView, [isMobileView, isTabView])

  const setLayoutView = useCallback((load = false) => {
    const windowWidth = global.window.innerWidth
    const currentMobileState = windowWidth < 768
    const prevMobileState = store.get('app.settings.isMobileView')
    const currentTabState = windowWidth >= 768 && windowWidth <= 992
    const prevTabState = store.get('app.settings.isTabView')

    if (currentMobileState !== prevMobileState || currentTabState !== prevTabState || load) {
      dispatch(
        changeLayoutSetting({
          isMobileView: currentMobileState,
          isTabView: currentTabState,
        }),
      )
    }
  }, [dispatch])

  return { isMobileView, isTabView, isDesktopLayout, setLayoutView }
}

export default useResponsive
