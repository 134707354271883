import axios from 'axios';

export const getCustomColumns = () => {
  return axios.get('/finance/customColumns/invoices');
};

export const getCustomColumn = (id) => {
  return axios.get(`/finance/customColumns/invoices/${id}`);
};

export const addCustomColumn = (data) => {
  return axios.post('/finance/customColumns/invoices', data);
};

export const editCustomColumn = (data) => {
  return axios.put(`/finance/customColumns/invoices/${data.id}`, data);
};

export const deleteCustomColumn = (data) => {
  return axios.delete(`/finance/customColumns/invoices/${data.id}`);
};

export const moveCustomColumn = (srcId, destId) => {
  return axios.patch(`/finance/customColumns/invoices/${srcId}/move`, { dest_id: destId });
};

export const activateCustomColumn = (id) => {
  return axios.patch(`/finance/customColumns/invoices/${id}/activate`);
};

export const deactivateCustomColumn = (id) => {
  return axios.patch(`/finance/customColumns/invoices/${id}/deactivate`);
};

export const getCustomColumnsItem = () => {
  return axios.get('/finance/customColumns/invoiceItems');
};

export const getCustomColumnItem = (id) => {
  return axios.get(`/finance/customColumns/invoiceItems/${id}`);
};

export const addCustomColumnItem = (data) => {
  return axios.post('/finance/customColumns/invoiceItems', data);
};

export const editCustomColumnItem = (data) => {
  return axios.put(`/finance/customColumns/invoiceItems/${data.id}`, data);
};

export const deleteCustomColumnItem = (data) => {
  return axios.delete(`/finance/customColumns/invoiceItems/${data.id}`);
};

export const moveCustomColumnItem = (srcId, destId) => {
  return axios.patch(`/finance/customColumns/invoiceItems/${srcId}/move`, { dest_id: destId });
};

export const activateCustomColumnItem = (id) => {
  return axios.patch(`/finance/customColumns/invoiceItems/${id}/activate`);
};

export const deactivateCustomColumnItem = (id) => {
  return axios.patch(`/finance/customColumns/invoiceItems/${id}/deactivate`);
};
