import { updateObject } from '../utility';

import * as actionTypes from '../actionTypes';

const initialState = {
  loading: false,
  loadedInit: false,
  attemp: 1,
};

const startLoading = (state) => {
  return updateObject( state, {
    loading: true
  } );
};

const stopLoading = (state) => {
  return updateObject( state, {
    loading: false
  } );
};

const startInit = (state) => {
  return updateObject(state, {
    loading: true,
    loadedInit: false,
  });
};

const stopInit = (state) => {
  return updateObject(state, {
    loading: false,
  });
};

const setLoadedInit = (state, payload) => {
  return updateObject(state, {
    loadedInit: payload,
  });
};

const increaseAttemp = (state) => {
  return updateObject(state, {
    attemp: state.attemp + 1,
  });
};

const resetAttemp = (state) => {
  return updateObject(state, {
    attemp: 1,
  });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
      case actionTypes.START_LOADING: return startLoading( state );
      case actionTypes.STOP_LOADING: return stopLoading( state );

      case actionTypes.START_INIT: return startInit(state);
      case actionTypes.STOP_INIT: return stopInit(state);
      case actionTypes.SET_LOADED_INIT: return setLoadedInit(state, action.payload);

      case actionTypes.INCREASE_ATTEMP: return increaseAttemp(state);
      case actionTypes.RESET_ATTEMP: return resetAttemp(state);
      default: return state;
    }
};

export default reducer;