import { updateObject } from '../utility'

import * as actionTypes from '../actionTypes'

const initialState = {
  expenseTerms: [],
  canDeletedExpenseTerms: [],
  filter: {
    searchText: '',
  },
}

export const getActiveFinanceExpenseTerms = (expenseTerms) => {
  const filterFinanceExpenseTerms = expenseTerms.filter(
    (financeExpenseTerm) => financeExpenseTerm.is_active === 1,
  )

  return filterFinanceExpenseTerms
}

const getCanDeletedFinanceExpenseTerms = (state, action) => {
  return updateObject(state, {
    canDeletedExpenseTerms: action.payload,
  })
}

const setFinanceExpenseTerms = (state, { payload }) => {
  return updateObject(state, {
    expenseTerms: payload,
  })
}

const addFinanceExpenseTerm = (state, action) => {
  const expenseTerms = [...state.expenseTerms, action.payload]
  return updateObject(state, { expenseTerms })
}

const editFinanceExpenseTerm = (state, action) => {
  const items = state.expenseTerms.map((item) => {
    if (item.id === action.payload.id) {
      return action.payload
    }
    return item
  })
  return updateObject(state, { expenseTerms: items })
}

const deleteFinanceExpenseTerm = (state, action) => {
  const expenseTerms = state.expenseTerms.filter((data) => data.id !== action.payload)
  return updateObject(state, { expenseTerms })
}

const moveFinanceExpenseTerm = (state, action) => {
  const { dragIndex, hoverIndex } = action.payload
  const expenseTerms = [...state.expenseTerms]
  const srcFinanceExpenseTerm = expenseTerms[dragIndex]
  expenseTerms.splice(dragIndex, 1)
  expenseTerms.splice(hoverIndex, 0, srcFinanceExpenseTerm)
  return updateObject(state, { expenseTerms })
}

const changeStatusFinanceExpenseTerm = (state, action) => {
  const expenseTerms = state.expenseTerms.map((item) => {
    if (item.id === action.payload.id) {
      return {
        ...item,
        is_active: action.payload.checked ? 1 : 0,
      }
    }
    return item
  })
  return updateObject(state, { expenseTerms })
}

const filterFinanceExpenseTerm = (state, { payload }) => {
  return updateObject(state, {
    filter: {
      ...state.filter,
      searchText: payload.searchText,
    },
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_EXPENSE_TERMS:
      return setFinanceExpenseTerms(state, action)
    case actionTypes.ADD_FINANCE_EXPENSE_TERM:
      return addFinanceExpenseTerm(state, action)
    case actionTypes.EDIT_FINANCE_EXPENSE_TERM:
      return editFinanceExpenseTerm(state, action)
    case actionTypes.MOVE_FINANCE_EXPENSE_TERM:
      return moveFinanceExpenseTerm(state, action)
    case actionTypes.CHANGE_STATUS_FINANCE_EXPENSE_TERM:
      return changeStatusFinanceExpenseTerm(state, action)
    case actionTypes.FILTER_FINANCE_EXPENSE_TERM:
      return filterFinanceExpenseTerm(state, action)
    case actionTypes.GET_CAN_DELETED_FINANCE_EXPENSE_TERMS:
      return getCanDeletedFinanceExpenseTerms(state, action)
    case actionTypes.DELETE_FINANCE_EXPENSE_TERM:
      return deleteFinanceExpenseTerm(state, action)
    default:
      return state
  }
}

export default reducer
