import React from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import PropTypes from 'prop-types';

function ToggleLabel({ showTitle, hideTitle, show, onToggle }) {
  function clickHandler(e) {
    e.preventDefault();
    onToggle();
  }

  return (
    <strong>
      <a
        id="more-action"
        href="#"
        className="text-primary"
        onClick={clickHandler}
      >
        {!show ? (
          <Space>
            <PlusOutlined className="mr-2" />
            {showTitle}
          </Space>
        ) : (
            <Space>
              <MinusOutlined className="mr-2" />
              {hideTitle}
            </Space>
          )}
      </a>
    </strong>
  );
}

ToggleLabel.propTypes = {
  showTitle: PropTypes.string.isRequired,
  hideTitle: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

ToggleLabel.defaultProps = {
  show: false
}

export default ToggleLabel;