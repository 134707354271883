import axios from 'axios';
import { stringify } from 'query-string';
import { cleanBlankValue, getOnce } from 'utils/helper';

export const getFinanceAccounts = (params) => {
  const query = stringify(params);
  return axios.get(`/finance/accounts?${query}`);
};

export const addFinanceAccount = (data) => {
  return axios.post('/finance/accounts', data);
};

export const editFinanceAccount = (data) => {
  return axios.put(`/finance/accounts/${data.id}`, data);
};

export const moveFinanceAccount = (srcId, destId) => {
  return axios.patch(`/finance/accounts/${srcId}/move`, {dest_id: destId});
};

export const activateFinanceAccount = (id) => {
  return axios.patch(`/finance/accounts/${id}/activate`);
};

export const deactivateFinanceAccount = (id) => {
  return axios.patch(`/finance/accounts/${id}/deactivate`);
};

export const deleteFinanceAccount = (ids) => {
  return axios.delete('/finance/accounts/massDelete', { data: { id: ids } });
};

export const getSuggestionFinanceAccount = (params) => {
  const query = stringify(params);

  return getOnce(`/finance/accounts/suggestionPerPage?${query}`);
};

export const getSuggestionFinanceParentAccount = (params) => {
  const query = stringify(params);

  return getOnce(`/finance/accounts/suggestionParentAccountsPerpage?${query}`);
};

export const getFinanceAccount = (id) => {
  return axios.get(`/finance/accounts/${id}`);
};

export const getFinanceBankAccounts = () => {
  return axios.get('/finance/accounts/banks');
};

export const uploadImportFinanceAccount = (data) => {
  return axios.post('/finance/accounts/uploadImport', data);
};

export const executeImportFinanceAccount = (data) => {
  return axios.post('/finance/accounts/executeImport', data);
};

export const exportAccounts = (params) => {
  params = cleanBlankValue(params);
  const query = stringify(params);
  return axios.get(`/finance/accounts/export?${query}`, { responseType: 'arraybuffer' });
}