import { updateObject } from '../utility';

import * as actionTypes from '../actionTypes';

const initialState = {};

const setTranslation = (state, { payload }) => {
  return updateObject(state, {
    ...payload
  });
};

const editTranslation = (state, { payload }) => {
  return { ...state, [payload.category]: { ...state[payload.category], [payload.name]: payload.value } };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TRANSLATION: return setTranslation(state, action);
    case actionTypes.EDIT_TRANSLATION: return editTranslation(state, action);
    default: return state;
  }
};

export default reducer;
