import React from 'react';
import { injectIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from "antd";
import { escapeRegExp } from 'lodash';

function SearchWidget({intl, onChange = () => {}, escape = false, ...props}) {
  return (
    <Input
      prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
      placeholder={intl.formatMessage({ id: 'input.placeholder.search' })}
      id="input-search"
      onChange={e => {
        if (escape) {
          e.target.value = escapeRegExp(e.target.value);
        }
        onChange(e);
      }}
      allowClear
      {...props}
    />
  );
}

export default injectIntl(SearchWidget);
