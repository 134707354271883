import React from 'react';
import { Editor as RdwEditor } from 'react-draft-wysiwyg';
import TagsOption from './TagsOption';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "assets/styles/Rdw/rdw.cleanui.scss";

class Editor extends React.Component {
  render() {
    const { email, editorState, editorStateChangeHandler,
      getTagsHandler, tagLoading, tags
    } = this.props;

    return (
      <RdwEditor
        editorState={editorState}
        toolbarClassName="rdw-custom-toolbar-wrapper"
        editorClassName="rdw-custom-editor-wrapper"
        wrapperClassName="rdw-custom-wrapper"
        toolbarCustomButtons={tags ? [
          <TagsOption
            emailId={email.id}
            getTagsHandler={getTagsHandler}
            loading={tagLoading}
            tags={tags}
          />
        ] : undefined}
        onEditorStateChange={editorStateChangeHandler}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'history', 'emoji', 'image'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          list: {
            options: ['unordered', 'ordered'],
          }
        }}
      />
    );
  }
}

export default Editor;
