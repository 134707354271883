import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { UnorderedListOutlined } from '@ant-design/icons';

function ShowColumn(props) {
  return (
    <Button {...props} />
  )
}

ShowColumn.propTypes = {
  icon: PropTypes.object,
  id: PropTypes.string,
  onClick: PropTypes.func,
}

ShowColumn.defaultProps = {
  icon: <UnorderedListOutlined />,
  onClick: () => { },
  id: "btn-show-column"
}

export default injectIntl(ShowColumn);