import React from 'react'
import { Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import { changeLanguage } from 'redux/settings/actions'

import styles from './style.module.scss'

@connect(
  ({ settings, refs: {lang} }) => ({ settings, lang }),
  (dispatch => {
    return {
      onChangeLanguage: (payload) => dispatch(changeLanguage(payload))
    }
  })
)
class LanguageSelector extends React.PureComponent {
  changeLang = ({ key }) => {
    const { onChangeLanguage, settings: { locale } } = this.props;
    if (locale !== key) {
      onChangeLanguage({
        setting: 'locale',
        value: key,
      })
    }
  }

  render() {
    const {
      settings: { locale },
    } = this.props
    const language = locale.substr(0, 2)

    const langMenu = (
      <Menu className={styles.menu} selectedKeys={[locale]} onClick={this.changeLang}>
        {this.props.lang.map(item => {
          return (
            <Menu.Item key={item.value}>
              <span role="img" aria-label={item.name} className="mr-2 text-uppercase">
                {item.value.substr(0, 2)}
              </span>
              {item.name}
            </Menu.Item>
          )
        })
        }
      </Menu>
    )
    return (
      <Dropdown overlay={langMenu} trigger={['click']}>
        <div className={styles.dropdown}>
          <strong className="text-uppercase">{language}</strong>
        </div>
      </Dropdown>
    )
  }
}

export default LanguageSelector
