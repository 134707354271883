import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Rate } from 'antd';
import { showNPSSelector } from 'redux/selectors';
import { ignoreNPS, postNPS } from 'redux/nps/actions';
import { Send } from 'components/UI';

import styles from './style.module.scss'

const Character = ({ index, value }) => {
  return (
    <div className={`${styles.char} ${value >= index + 1 ? styles.focusedChar : ''}`}>
      {index}
    </div>
  );
}

const NPS = ({ intl }) => {
  const [rate, setRate] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingClose, setLoadingClose] = useState(false);
  const [sent, setSent] = useState(false);

  const dispatch = useDispatch();
  const showNPS = useSelector(showNPSSelector);

  const closeNPS = async () => {
    if (sent) {
      setSent(false);
      return;
    }
    if (loadingClose) return;
    try {
      setLoadingClose(true);
      const response = await dispatch(ignoreNPS());
      setLoadingClose(false);
      if (response.data.success) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (e) {
      console.log(e);
      setLoadingClose(false);
      message.error(intl.formatMessage({ id: 'nps.cannot_hide_nps' }));
    }
  }

  const sendNPS = async (values) => {
    try {
      setLoading(true);
      const response = await dispatch(postNPS({
        ...values,
        score: rate - 1,
      }));
      setLoading(false);
      if (response.data.success) {
        setSent(true);
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      message.error(intl.formatMessage({ id: 'nps.cannot_send_nps' }));
    }
  }

  if ((showNPS === 0 || showNPS === null) && !sent) {
    return <></>;
  }

  return (
    <Card
      className={styles.npsContainer}
      title={intl.formatMessage({ id: sent ? 'nps.thank_you_your_input' : 'nps.how_likely_you_recommend' })}
      headStyle={{
        backgroundColor: 'rgb(1, 144, 254)',
        color: 'white',
        fontWeight: 'bold',
      }}
      bodyStyle={sent ? {
        padding: 0
      } : {}}
    >
      <Button
        type="primary"
        shape="circle"
        className={styles.closeBtn}
        icon={loadingClose ? <LoadingOutlined /> : <CloseOutlined />}
        onClick={closeNPS}
      />
      {!sent && (
        <>
          <Rate count={11} character={Character} onChange={val => setRate(val)} value={rate} />
          <div className={styles.tooltipWrapper}>
            <span><b>0</b>{` - ${intl.formatMessage({ id: 'nps.not_likely' })}`}</span>
            <span><b>10</b>{` - ${intl.formatMessage({ id: 'nps.very_likely' })}`}</span>
          </div>
          <Form
            layout="vertical"
            onFinish={sendNPS}
            className={`${styles.formWrapper} ${rate ? styles.showForm : ''}`}
          >
            <Form.Item name="message" label={intl.formatMessage({ id: 'nps.please_mention_another' })}>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
            </Form.Item>
            <Form.Item>
              <Send htmlType="submit" style={{ float: 'right' }} loading={loading} />
            </Form.Item>
          </Form>
        </>
      )}
    </Card>
  );
}

export default injectIntl(NPS);
