import React from 'react'
import { Row, Col, Space, Skeleton } from 'antd'
import useResponsive from 'utils/hooks/useResponsive'
import Placeholder from './Placeholder'

const DetailPlaceholder = () => {
  const { isMobileView } = useResponsive()
  if (isMobileView)
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row>
          <Col xs={24}>
            <Placeholder style={{ height: 28 }} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Skeleton paragraph={{ rows: 5 }} active />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space style={{ width: '100%' }} direction="vertical">
              <Placeholder style={{ height: 20 }} />
              <Placeholder style={{ height: 20 }} />
              <Placeholder style={{ height: 20 }} />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Skeleton paragraph={{ rows: 3 }} active />
          </Col>
        </Row>
      </Space>
    )

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row>
        <Col span={8}>
          <Placeholder style={{ height: 28 }} />
        </Col>
        <Col span={8} offset={8}>
          <Placeholder style={{ height: 28 }} />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Skeleton paragraph={{ rows: 4, width: 300 }} active />
        </Col>
        <Col span={6} offset={2}>
          <Skeleton paragraph={{ rows: 4, width: 300 }} active />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Skeleton paragraph={{ rows: 2, width: '100%' }} active />
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={12}>
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active />
        </Col>
      </Row>
    </Space>
  )
}

export default DetailPlaceholder
