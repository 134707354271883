import React from 'react';
import { Menu, Dropdown } from 'antd';
import { injectIntl } from 'react-intl';
import { DownOutlined, SolutionOutlined, PlayCircleOutlined, BookOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const HelpDropdown = ({
  intl,
  onClickVideo,
  onClickArticle,
  onClickTour,
  isMobileView,
  ...props
}) => {
  const [visible, setVisible] = React.useState(false);

  const visibleChangeHandler = (flag) => setVisible(flag);
  const toggleHandler = () => setVisible(!visible);
  const clickVideoHandler = () => {
    setVisible(false);
    if (typeof onClickVideo === 'function') {
      onClickVideo();
    }
  };
  const clickTourHandler = () => {
    setVisible(false);
    if (typeof onClickTour === 'function') {
      onClickTour();
    }
  };
  const clickArticleHandler = () => {
    setVisible(false);
    if (typeof onClickArticle === 'function') {
      onClickArticle();
    }
  };

  const menu = (
    <Menu>
      {typeof onClickTour === 'function' && (
        <Menu.Item key="1" icon={<SolutionOutlined />} onClick={clickTourHandler}>
          {intl.formatMessage({ id: 'button.tour' })}
        </Menu.Item>
      )}
      {typeof onClickVideo === 'function' && (
        <Menu.Item key="2" icon={<PlayCircleOutlined />} onClick={clickVideoHandler}>
          {intl.formatMessage({ id: 'button.video' })}
        </Menu.Item>
      )}
      {typeof onClickArticle === 'function' && (
        <Menu.Item key="3" icon={<BookOutlined />} onClick={clickArticleHandler}>
          {intl.formatMessage({ id: 'button.article' })}
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <Dropdown.Button
      icon={isMobileView ? <QuestionCircleOutlined /> : <DownOutlined />}
      overlay={menu}
      trigger={['click']}
      onVisibleChange={visibleChangeHandler}
      onClick={toggleHandler}
      visible={visible}
      {...props}
    >
      {!isMobileView && (
        <>
          <QuestionCircleOutlined /> {intl.formatMessage({ id: 'button.guide' })}
        </>
      )}
    </Dropdown.Button>
  )
};

const Component = injectIntl(HelpDropdown);
export default React.memo(Component);
