import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined, FileExcelOutlined, MailOutlined, PrinterOutlined, SettingOutlined, ShareAltOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import { SendWaModal } from 'components/UI';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import SendEmailModal from 'containers/EmailTemplate/SendEmailModal';

// Print and export xls/csv Dropdown
const PrintDropdown = ({
  intl,
  exportUrl,
  content,
  removeAfterPrint,
  isPrint = true,
  noPrintAction = () => {},
  goToLayout,
  withLandscape,
  getEmailTemplate,
  sendEmail,
  getWaTemplate,
  sendWaTitle,
  sendTitle,
  withoutPrint,
}) => {
  const [sendEmailModal, setSendEmailModal] = React.useState(false)
  const [emailSent, setEmailSent] = React.useState(false)
  const [sendWaModal, setSendWaModal] = React.useState(false)

  const onCloseEmailModal = () => {
    setEmailSent(false)
    setSendEmailModal(false)
  }

  const exportTo = (exportType) => {
    axios.get(`${exportUrl}&export=${exportType}`, { responseType: 'blob' }).then((r) => {
      const fileName = r.request.getResponseHeader('Content-Disposition').split('filename=')[1]
      const blobURL = URL.createObjectURL(r.data)
      const tempLink = document.createElement('a')
      tempLink.href = blobURL
      tempLink.setAttribute('download', fileName)
      tempLink.click()
    })
  }

  const shareMenu = (
    <Menu>
      {getEmailTemplate && sendEmail && (
        <Menu.Item
          key="5"
          onClick={() => {
            setSendEmailModal(true)
          }}
          icon={<MailOutlined />}
        >
          {sendTitle === 'button.send_email' || !sendTitle
            ? intl.formatMessage({ id: 'button.send_email' })
            : sendTitle}
        </Menu.Item>
      )}
      {getWaTemplate && (
        <Menu.Item
          key="8"
          onClick={() => {
            setSendWaModal(true)
          }}
          icon={<WhatsAppOutlined />}
        >
          {sendWaTitle === 'button.send_wa' || !sendWaTitle
            ? intl.formatMessage({ id: 'button.send_wa' })
            : sendWaTitle}
        </Menu.Item>
      )}
    </Menu>
  )

  const menu = (
    <Menu>
      {isPrint ? (
        <Menu.Item icon={<PrinterOutlined />} key="1">
          <ReactToPrint
            content={content}
            removeAfterPrint={removeAfterPrint}
            trigger={() => <div>Print</div>}
          />
        </Menu.Item>
      ) : withLandscape ? (
        <>
          <Menu.Item
            key="potrait"
            icon={<PrinterOutlined />}
            onClick={() => noPrintAction({ landscape: 0 })}
          >
            Print Potrait
          </Menu.Item>
          <Menu.Item
            key="landscape"
            icon={<PrinterOutlined />}
            onClick={() => noPrintAction({ landscape: 1 })}
          >
            Print Landscape
          </Menu.Item>
        </>
      ) : (
        <Menu.Item key="1" icon={<PrinterOutlined />} onClick={noPrintAction}>
          Print
        </Menu.Item>
      )}
      {exportUrl && (
        <>
          <Menu.Item key="2" icon={<FileExcelOutlined />} onClick={() => exportTo('xls')}>
            XLS
          </Menu.Item>
          <Menu.Item key="3" icon={<FileExcelOutlined />} onClick={() => exportTo('csv')}>
            CSV
          </Menu.Item>
        </>
      )}
      {goToLayout && (
        <Menu.Item key="4" icon={<SettingOutlined />} onClick={goToLayout}>
          {intl.formatMessage({ id: 'button.change_layout' })}
        </Menu.Item>
      )}
    </Menu>
  )

  if (!isPrint) {
    return (
      <>
        {((getEmailTemplate && sendEmail) || getWaTemplate) && (
          <Dropdown overlay={shareMenu} trigger={['click']}>
            <Button style={{ marginRight: 4 }} id="btn-share">
              <ShareAltOutlined /> {intl.formatMessage({ id: 'button.share' })} <DownOutlined />
            </Button>
          </Dropdown>
        )}
        {!withoutPrint && (
          <Dropdown.Button
            overlay={menu}
            trigger={['click']}
            icon={<DownOutlined />}
            onClick={() => noPrintAction()}
          >
            <PrinterOutlined /> Print
          </Dropdown.Button>
        )}
        <SendWaModal
          intl={intl}
          sendTitle={sendWaTitle}
          getWaTemplate={getWaTemplate}
          show={sendWaModal}
          onCloseWaModal={() => setSendWaModal(false)}
        />
        <SendEmailModal
          intl={intl}
          sendTitle={sendTitle}
          getEmailTemplate={getEmailTemplate}
          sendEmail={sendEmail}
          show={sendEmailModal}
          emailSent={emailSent}
          setEmailSent={setEmailSent}
          onCloseEmailModal={onCloseEmailModal}
          onPrintPdf={() => (withLandscape ? noPrintAction({ landscape: 0 }) : noPrintAction())}
        />
      </>
    )
  }
  return (
    <ReactToPrint
      content={content}
      removeAfterPrint={removeAfterPrint}
      trigger={() => {
        return (
          <Dropdown.Button overlay={menu} trigger={['click']} icon={<DownOutlined />}>
            <PrinterOutlined /> Print
          </Dropdown.Button>
        )
      }}
    />
  )
}

export default injectIntl(PrintDropdown);
