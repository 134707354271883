import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Alert } from 'antd'
import { useSelector } from 'react-redux'
import { billingSelector, optionsSelector } from 'redux/selectors'

function TrialWarning({showIcon = true, style, ...props}) {
  const { plan, expiry_date } = useSelector(billingSelector)
  const { demo } = useSelector(optionsSelector)
  const remainingDays = moment(expiry_date).diff(moment(), 'days')

  if (!plan || !expiry_date || (plan && !plan.includes('Trial') || demo === 1) || remainingDays < 0) {
    return null
  }
  return (
    <Alert
      className="notification-demo"
      style={{ backgroundColor: '#ffefb3', ...style }}
      message={
        <FormattedMessage
          id="setup.trial"
          values={{
            days: remainingDays,
            setup: (
              <Link to="/settings/billing/upgrade">
                <strong>
                  <FormattedMessage id="billing.upgrade_now" />
                </strong>
              </Link>
            ),
          }}
        />
      }
      type="warning"
      showIcon={showIcon}
      {...props}
    />
  )
}

export default TrialWarning
