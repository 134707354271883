import { updateObject } from '../utility'

import * as actionTypes from '../actionTypes'

const initialState = {
  shippingComps: [],
  canDeletedShippingComps: [],
  filter: {
    searchText: '',
  },
}

export const getActiveShippingComps = (shippingComps) => {
  const filterShippingComps = shippingComps.filter(
    (financeShippingComp) => financeShippingComp.is_active === 1,
  )

  return filterShippingComps
}

const getCanDeletedShippingComps = (state, action) => {
  return updateObject(state, {
    canDeletedShippingComps: action.payload,
  })
}

const setShippingComps = (state, { payload }) => {
  return updateObject(state, {
    shippingComps: payload,
  })
}

const addShippingComp = (state, action) => {
  const shippingComps = [...state.shippingComps, action.payload]
  return updateObject(state, { shippingComps })
}

const editShippingComp = (state, action) => {
  const items = state.shippingComps.map((item) => {
    if (item.id === action.payload.id) {
      return action.payload
    }
    return item
  })
  return updateObject(state, { shippingComps: items })
}

const deleteShippingComp = (state, action) => {
  const shippingComps = state.shippingComps.filter((data) => data.id !== action.payload)
  return updateObject(state, { shippingComps })
}

const moveShippingComp = (state, action) => {
  const { dragIndex, hoverIndex } = action.payload
  const shippingComps = [...state.shippingComps]
  const srcShippingComp = shippingComps[dragIndex]
  shippingComps.splice(dragIndex, 1)
  shippingComps.splice(hoverIndex, 0, srcShippingComp)
  return updateObject(state, { shippingComps })
}

const changeStatusShippingComp = (state, action) => {
  const shippingComps = state.shippingComps.map((item) => {
    if (item.id === action.payload.id) {
      return {
        ...item,
        is_active: action.payload.checked ? 1 : 0,
      }
    }
    return item
  })
  return updateObject(state, { shippingComps })
}

const filterShippingComp = (state, { payload }) => {
  return updateObject(state, {
    filter: {
      ...state.filter,
      searchText: payload.searchText,
    },
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHIPPING_COMPS:
      return setShippingComps(state, action)
    case actionTypes.ADD_SHIPPING_COMP:
      return addShippingComp(state, action)
    case actionTypes.EDIT_SHIPPING_COMP:
      return editShippingComp(state, action)
    case actionTypes.MOVE_SHIPPING_COMP:
      return moveShippingComp(state, action)
    case actionTypes.CHANGE_STATUS_SHIPPING_COMP:
      return changeStatusShippingComp(state, action)
    case actionTypes.FILTER_SHIPPING_COMP:
      return filterShippingComp(state, action)
    case actionTypes.GET_CAN_DELETED_SHIPPING_COMPS:
      return getCanDeletedShippingComps(state, action)
    case actionTypes.DELETE_SHIPPING_COMP:
      return deleteShippingComp(state, action)
    default:
      return state
  }
}

export default reducer
