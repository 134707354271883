import store from 'store';
import {message} from 'antd';
import {changeLanguage as apiChangeLang} from 'utils/apis';
import {startLoading, stopLoading, setInits} from 'redux/inits/actions';

const actions = {
  SET_STATE: 'settings/SET_STATE',
  CHANGE_SETTING: 'settings/CHANGE_SETTING',
}

export const changeSetting = (payload) => {
  return dispatch => {
    store.set(`app.settings.${payload.setting}`, payload.value)
    dispatch({
      type: actions.SET_STATE,
      payload: {
        [payload.setting]: payload.value,
      },
    })
  };
};

export const changeLayoutSetting = (payload) => {
  return dispatch => {
    store.set('app.settings.isMobileView', payload.isMobileView);
    store.set('app.settings.isTabView', payload.isTabView);
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isMobileView: payload.isMobileView,
        isTabView: payload.isTabView,
      },
    })
  };
};

export const changeLanguage = (payload) => {
  return dispatch => {
    dispatch(startLoading());
    return apiChangeLang(payload.value).then(response => {
      if (response.data.success) {
        message.success(response.data.message);
        const data = response.data.data;
        dispatch(setInits(data.init));
      } else {
        message.error(response.data.message);
      }
      dispatch(stopLoading());
      return response;
    }).catch(() => {
      message.error('Error:: Cannot update language!');
      dispatch(stopLoading());
    })
  };
};

export default actions
