/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Modal, Skeleton, Form, Input, message, Result } from 'antd';
import { Cancel, Send } from 'components/UI';
import HideFeature from 'containers/Billing/HideFeature';

const { TextArea } = Input;

const SendSmsModal = ({
  intl,
  sendTitle,
  getSmsTemplate,
  sendSms,
  show = false,
  data = {contact: {}},
  smsSent = false,
  setSmsSent,
  onCloseSmsModal,
}) => {
  const formRef = React.createRef();

  const [loadingSendSms, setLoadingSendSms] = React.useState(false);
  const [loadingSmsTemplate, setLoadingSmsTemplate] = React.useState(false);
  const [smsTemplate, setSmsTemplate] = React.useState({body: '', phone_number: ''});

  React.useEffect(() => {
    const getTemplate = async () => {
      try {
        setLoadingSmsTemplate(true);
        const response = await getSmsTemplate();
        setLoadingSmsTemplate(false);

        setSmsTemplate({ ...response.data.data, phone_number: response.data.data.phone });
      } catch (e) {
        console.log(e);
        message.error(intl.formatMessage({ id: 'sms.cannot_send_sms' }));
      }
    }

    if (show) {
      getTemplate();
    }
  }, [show, getSmsTemplate, intl]);

  React.useEffect(() => {
    if (formRef.current && !loadingSendSms) {
      formRef.current.setFieldsValue({
        phone_number: smsTemplate.phone_number,
        body: smsTemplate.body,
      });
    }
  }, [data, smsTemplate, formRef, loadingSendSms]);

  const onSendSms = async (values) => {
    setLoadingSendSms(true);

    try {
      const response = await sendSms(data.id, values);
      setLoadingSendSms(false);

      if (response.data.success) {
        setSmsSent(true);
        setSmsTemplate({ ...smsTemplate, phone_number: values.phone_number });
      } else {
        message.error(response.data.message);
      }
    } catch (e) {
      setLoadingSendSms(false);
      message.error(intl.formatMessage({ id: 'sms.cannot_send_sms' }));
    }
  }

  return (
    <Modal
      title={sendTitle === "button.send_sms" || !sendTitle ? intl.formatMessage({ id: 'button.send_sms' }) : sendTitle}
      visible={show}
      onCancel={onCloseSmsModal}
      destroyOnClose
      maskTransitionName=""
      footer={(smsSent) ? [
        <Button key={1} onClick={onCloseSmsModal} type="primary">Ok</Button>
      ] : [
        <Cancel key={`_${0}`} onClick={onCloseSmsModal} />,
        <Send
          loading={loadingSendSms}
          form="sendSmsForm"
          key="submit"
          htmlType="submit"
        />
      ]}
    >
      <HideFeature
        code="sms"
        feature={sendTitle === "button.send_sms" || !sendTitle ? intl.formatMessage({ id: 'button.send_sms' }) : sendTitle}
      >
        {loadingSmsTemplate ? <Skeleton active /> : smsSent ? (
          <Result
            status="success"
            title={
              <h5>{`${intl.formatMessage({ id: 'sms.sms_has_been_sent_to' })} ${smsTemplate.phone_number}`}</h5>
            }
          />
        ) : (
          <Form ref={formRef} onFinish={onSendSms} layout='vertical' id="sendSmsForm">
            <Form.Item
              label={intl.formatMessage({ id: 'sms.destination_number' })}
              name='phone_number'
              rules={[
                { required: true, message: intl.formatMessage({ id: 'sms.please_input_phone' }) },
                { min: 6, message: intl.formatMessage({ id: 'financeContacts.min_characters' }, { value: 6 }) },
                { pattern: /^[\d\+]+$/, message: intl.formatMessage({ id: 'financeContacts.only_number_allowed' }) },
              ]}
            >
              <Input placeholder={intl.formatMessage({ id: 'sms.destination_number' })} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'sms.sms_content' })}
              name='body'
              rules={[
                { required: true, message: intl.formatMessage({ id: 'sms.please_input_content' }) },
                { max: 155, message: intl.formatMessage({ id: 'financeContacts.max_characters' }, { value: 155 }) },
                { min: 20, message: intl.formatMessage({ id: 'financeContacts.min_characters' }, { value: 20 }) },
              ]}
            >
              <TextArea
                showCount
                placeholder={intl.formatMessage({ id: 'sms.sms_content' })}
                autoSize={{ minRows: 2, maxRows: 8 }}
              />
            </Form.Item>
          </Form>
        )}
      </HideFeature>
    </Modal>
  );
};

export default SendSmsModal;
