import React from 'react'
import { message } from 'antd'

import Timeline from 'components/apps/Notification/Timeline'
import { getFinanceLogs } from 'utils/apis'

const TimelineContainer = ({ isReload, onHideParent, isMobileView }) => {
  const [data, setData] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [loadingMore, setLoadingMore] = React.useState(false)
  const [hasMore, setHasMore] = React.useState(true)

  const loadData = React.useCallback((mounted) => {
    setLoading(true)
    getFinanceLogs()
      .then((response) => {
        if (mounted) {
          if (response.data.success) {
            setData(response.data.data)
            setLoading(false)
          } else {
            message.error(response.data.message)
          }
          setLoading(false)
        }
      })
      .catch(() => {
        if (mounted) {
          setLoading(false)
        }
      })
  }, [])

  const onLoadMore = React.useCallback(
    (page) => {
      if (page >= data.last_page) {
        setHasMore(false)
      } else {
        setLoadingMore(true)
        getFinanceLogs({ page })
          .then((response) => {
            if (response.data.success) {
              const resData = response.data.data
              setData({
                ...resData,
                data: [...data.data, ...resData.data],
              })
            } else {
              message.error(response.data.message)
            }
            setLoadingMore(false)
          })
          .catch(() => {
            setLoadingMore(false)
          })
      }
    },
    [data],
  )

  React.useEffect(() => {
    let mounted = true
    if (isReload) {
      loadData(mounted)
    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload])

  return (
    <Timeline
      loading={loading}
      data={data.data || []}
      loadingMore={loadingMore}
      onLoadMore={onLoadMore}
      hasMore={hasMore}
      onHideParent={onHideParent}
      isMobileView={isMobileView}
    />
  )
}

export default TimelineContainer
