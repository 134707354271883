import axios from 'axios';
import NProgress from 'nprogress'
import { notification } from 'antd';
import * as Sentry from '@sentry/react';
import { showLoginForm } from 'redux/user/actions';
import { getToken, setToken } from './cookies';
import { generateBaseUri } from './helper';

const customAxios = axios.create({
  baseURL: generateBaseUri(),
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 100000,
  withCredentials: true,
});

const isAuthorization = (config = {}) => {
  // const hasAuthorizationProperty = Object.prototype.hasOwnProperty.call(config, "authorization");
  return !!config.headers.Authorization
}

export const requestHandler = (request) => {
  if (isAuthorization(request)) {
    request.headers.Authorization = `Bearer ${getToken()}`
  } else {
    delete request.headers.Authorization;
  }
  return request
}

export const errorHandler = (error, store, history) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  const status = error.response && error.response.status;
  const errorMessage = error.response && error.response.data.message ? error.response.data.message : 'Network error';
  switch (status) {
    case 401:
      // const currentLocation = history.location.pathname;
      if (history.location.pathname !== '/user/login') {
        NProgress.done();
        store.dispatch(showLoginForm());
        // localStore.set('app.previousPathname', currentLocation);
        // store.dispatch(push('/user/login'));
      }
      break;

    case 400: // Bad Request
      break;
    case 404: // Not Found
      break;
    case status >= 500: // Server error
      break;

    case 422:
      Sentry.captureException(errorMessage);
      break;
    default:
      break;
  }
  notification.warning({
    // message: title,
    description: errorMessage,
    key: errorMessage
  })

  return Promise.reject(error.response);
}

export const successHandler = (response) => {
  // Jika ada response access_token di set kan ke cookies
  const newToken = response.data?.access_token
  if (newToken && newToken !== getToken()) {
    setToken(response.data?.access_token)
  }
  return response
}

// Create custom axios GET with cancellation
export const makeGetRequestCreator = () => {
  let call;
  return url => {
    if (call) {
      call.cancel("Only one request allowed at a time.");
    }
    call = axios.CancelToken.source();
    return customAxios.get(url, {
      cancelToken: call.token
    });
  };
};

export const getOnce = makeGetRequestCreator();

// customAxios.interceptors.request.use((request) => requestHandler(request));
// customAxios.interceptors.response.use(
//     (response) => successHandler(response),
//     (error) => errorHandler(error),
//   );

export default customAxios;