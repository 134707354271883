import axios from 'axios';
import { stringify } from 'query-string';

export const getFinanceWareHouses = (params) => {
  return axios.get('/finance/warehouses', { params });
};

export const getFinanceWareHouse = (id, params) => {
  const query = stringify(params);
  return axios.get(`/finance/warehouses/${id}?${query}`);
};

export const getFinanceWareHouseLog = (id, params) => {
  const query = stringify(params);
  return axios.get(`/finance/warehouses/${id}/logs?${query}`);
};

export const getFinanceWareHouseTransactions = (id, params) => {
  const query = stringify(params);
  return axios.get(`/finance/warehouses/${id}/transactions?${query}`);
};

export const addFinanceWareHouse = (data) => {
  return axios.post('/finance/warehouses', data);
};

export const editFinanceWareHouse = (data) => {
  return axios.put(`/finance/warehouses/${data.id}`, data);
};

export const moveFinanceWareHouse = (srcId, destId) => {
  return axios.patch(`/finance/warehouses/${srcId}/move`, {dest_id: destId});
};

export const activateFinanceWareHouse = (id) => {
  return axios.patch(`/finance/warehouses/${id}/activate`);
};

export const deactivateFinanceWareHouse = (id) => {
  return axios.patch(`/finance/warehouses/${id}/deactivate`);
};

export const getFinanceWareHouseTransfers = (params) => {
  const query = stringify(params);
  return axios.get(`/finance/warehouses/transfers?${query}`);
};

export const getFinanceWareHouseTransfer = (id, includeStockBefore=0) => {
  let query = '';
  if (includeStockBefore === 1) {
    query = stringify({include_stock_before: 1});
  }
  return axios.get(`/finance/warehouses/transfers/${id}?${query}`);
};

export const getFinanceWareHouseTransferLog = (id, params) => {
  const query = stringify(params);
  return axios.get(`/finance/warehouses/transfers/${id}/logs?${query}`);
};

export const addFinanceWareHouseTransfer = (data) => {
  return axios.post('/finance/warehouses/transfers', data);
};

export const editFinanceWareHouseTransfer = (data) => {
  return axios.put(`/finance/warehouses/transfers/${data.id}`, data);
};

export const deleteFinanceWareHouseTransfer = (id) => {
  return axios.delete(`/finance/warehouses/transfers/${id}`);
};

export const deleteOnAddFinanceWareHouseTransferAttachment = (url) => {
  return axios.delete('/finance/warehouses/transfers/attachments', { data: { url } });
};

export const deleteOnEditFinanceWareHouseTransferAttachment = (id, url) => {
  return axios.delete(`/finance/warehouses/transfers/${id}/attachments`, { data: { url } });
};

export const uploadImportFinanceWarehouseTransfer = (data) => {
  return axios.post('/finance/warehouses/transfers/uploadImport', data);
};

export const executeImportFinanceWarehouseTransfer = (data) => {
  return axios.post('/finance/warehouses/transfers/executeImport', data);
};

export const deleteFinanceWareHouse = (id) => {
  return axios.delete(`/finance/warehouses/${id}`);
};

export const archiveFinanceWareHouse = (id) => {
  return axios.patch(`/finance/warehouses/${id}/archive`);
};

export const unarchiveFinanceWareHouse = (id) => {
  return axios.patch(`/finance/warehouses/${id}/unarchive`);
};

export const getFinanceWarehouseRoles = (id) => {
  return axios.get(`/finance/warehouses/${id}/roles`);
};

export const addFinanceWarehouseRoles = (id, data) => {
  return axios.post(`/finance/warehouses/${id}/roles`, data);
};
