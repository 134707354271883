import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { LinkTransType } from 'components/UI';
import { transType } from 'utils/static';

const RefNumberTitle = ({intl}) => {
  return (
    <FormattedMessage
      id="financeInvoices.the_number_will_be_automatically_generated_by_the_system"
      values={{
        link: <LinkTransType
          title={intl.formatMessage({ id: 'financeInvoices.here' })}
          transTypeId={transType.NUMBER}
          data={{}}
        />
      }}
    />
  )
}

const Component = injectIntl(RefNumberTitle);

export default React.memo(Component);
