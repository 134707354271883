import React from 'react'
import { Button, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ProfileMenu from './ProfileMenu'
import LanguageSelector from './LanguageSelector'
import CompanySelector from './CompanySelector'
import Actions from './Actions'

import './style.scss'

class CustomTopBar extends React.PureComponent {
  render() {
    const {
      intl,
      translation,
      invoicePermissions,
      purchaseInvoicePermissions,
      expensePermissions,
      isMobileView,
    } = this.props

    return (
      <div className="topbar" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!isMobileView && (
          <Space>
            {invoicePermissions.isCanAdd && (
              <Button
                href="/#/sales/invoices/add"
                icon={<FontAwesomeIcon icon="shopping-basket" className="icon-main-btn" />}
              >
                {intl.formatMessage({ id: 'button.sell' })}
              </Button>
            )}
            {purchaseInvoicePermissions.isCanAdd && (
              <Button
                href="/#/purchases/purchaseinvoices/add"
                icon={<FontAwesomeIcon icon="shopping-bag" className="icon-main-btn" />}
              >
                {intl.formatMessage({ id: 'button.buy' })}
              </Button>
            )}
            {expensePermissions.isCanAdd && (
              <Button
                href="/#/expenses/add"
                icon={<FontAwesomeIcon icon="credit-card" className="icon-main-btn" />}
              >
                {translation.menu
                  ? translation.menu.expenses
                  : intl.formatMessage({ id: 'financeExpenses.financeExpenses' })}
              </Button>
            )}
            &nbsp;
          </Space>
        )}
        <Space>
          {(this.props.companies.companies.length > 1 || this.props.role_id === 1) && (
            <div className="mr-4">
              <CompanySelector
                companies={this.props.companies}
                onChangeCompany={this.props.onChangeCompany}
                push={this.props.push}
              />
            </div>
          )}
          <div className="mr-4">
            <LanguageSelector />
          </div>
          <div className="mr-4">
            <Actions />
          </div>
          <ProfileMenu />
        </Space>
      </div>
    )
  }
}

export default CustomTopBar
