import React from 'react'
import localStore from 'store'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import { store as reduxStore } from '../index'

export const nth = (n) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100

  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const arraymove = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

export const priceValidator = (rule, value) => {
  if (value <= 0) {
    return Promise.reject(rule.message)
  }
  return Promise.resolve()
}

export const disabledTransDate = (current) => {
  const { options } = reduxStore.getState().options

  if (!options.lock_date) {
    return false
  }

  return current && current.isSameOrBefore(moment(options.lock_date), 'day')
}

export const transDateValidator = (rule, value) => {
  const { options } = reduxStore.getState().options
  // if (!options.lock_date) {
  //   callback();
  // }

  if (value && options.lock_date && value.isSameOrBefore(moment(options.lock_date), 'day')) {
    return Promise.reject(rule.message)
  }
  return Promise.resolve()
}

// Get import template link
export const getTemplateUrl = (fileName = '') => {
  return `${generateBaseUri()}/download/import/${fileName}`
}

export const generateBaseUri = () => {
  let apiUrl = process.env.REACT_APP_API_URL
  let baseUrl = apiUrl ? apiUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '') : ''
  if (process.env.NODE_ENV === 'production') {
    const splitHostname = window.location.hostname.split('.')
    if (splitHostname[0] && localStore.get('app.endpoint')) {
      baseUrl = `${splitHostname[0]}.${baseUrl}`
    }
  }

  const protocol = _.includes(apiUrl, 'https://') ? 'https://' : 'http://'
  if (localStore.get('app.endpoint')) {
    apiUrl = `${protocol}${localStore.get('app.endpoint')}/api/v1`
  } else {
    apiUrl = `${protocol}${baseUrl}`
  }
  return apiUrl
}

export const getMax = (arr = []) => {
  let max
  arr.forEach((e, i) => {
    if (i === 0) max = e
    if (max < e) max = e
  })
  return max
}

export const cleanBlankValue = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key]
    }
  })

  return obj
}

// Create custom axios GET with cancellation
export const makeGetRequestCreator = () => {
  let call
  return (url) => {
    if (call) {
      call.cancel('Only one request allowed at a time.')
    }
    call = axios.CancelToken.source()
    return axios.get(url, {
      cancelToken: call.token,
    })
  }
}

export const getOnce = makeGetRequestCreator()

// To check layout is desktop
export const isDesktopLayout = () => {
  const { isMobileView, isTabView } = reduxStore.getState().settings
  // const isMobileView = localStore.get('app.settings.isMobileView');
  // const isTabView = localStore.get('app.settings.isTabView');

  return !isMobileView && !isTabView
}

export const discountType = (type) => {
  const { options } = reduxStore.getState().options
  let isDiscountNumeric = false
  if (type === 'purchase') {
    isDiscountNumeric = options.purchase_discount_numeric === 1
  } else {
    isDiscountNumeric = options.sale_discount_numeric === 1
  }

  return isDiscountNumeric ? 'discount_amount' : 'discount_percent'
}

export const openNewTabBlobFile = (data) => {
  const file = new Blob([data], { type: 'application/pdf' })
  // Build a URL from the file
  const fileURL = URL.createObjectURL(file)
  const tempLink = document.createElement('a')
  tempLink.href = fileURL
  tempLink.setAttribute('target', '_blank')
  tempLink.click()
}

export const openNewTab = (moduleUrl = '', withBaseUri = true) => {
  const tempLink = document.createElement('a')
  if (withBaseUri) tempLink.href = `${generateBaseUri()}${moduleUrl}`
  else tempLink.href = moduleUrl
  tempLink.setAttribute('target', '_blank')
  tempLink.click()
}

export const filterOption = (input, option) => {
  if (option.children)
    return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
  return true
}

export const copyclip = (text) => {
  const textField = document.createElement('textarea')
  textField.innerText = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
}

export const clearCache = (reload = false) => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      const delAll = new Promise((resolve) => {
        if (names.length === 0) resolve()
        names.forEach(async (name, i) => {
          await caches.delete(name)
          if (i === names.length - 1) resolve()
        })
      })

      delAll.then(() => {
        if (reload) {
          window.location.reload(true)
        }
      })
    })
  }
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'ios', 'android', 'windows_phone', or 'other'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows_phone'
  }

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }

  return 'other'
}

export const useDebounce = (callback, delay) => {
  const debouncedFn = React.useCallback(
    _.debounce((...args) => callback(...args), delay),
    [delay], // will recreate if delay changes
  )
  return debouncedFn
}

export const parseUrlToFileName = (url) => {
  let name = ''
  if (url) {
    const { pathname } = new URL(url)
    if (pathname) {
      const splitPath = pathname.split('/')
      name = splitPath ? decodeURI(_.last(splitPath)) : ''
    }
  }
  return name
}

// Replace slash for uri
export const replaceSlash = (text = '', replaceable = ':slash:') => text.replace(/\//g, replaceable)

export const isFeaturePro = ({ billing, code }) =>
  billing?.hidden_feature && billing.hidden_feature.filter((e) => e === code).length > 0

export const isFeatureElite = ({ billing, code }) =>
  billing?.hidden_feature_elite && billing.hidden_feature_elite.filter((e) => e === code).length > 0
