import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Alert } from 'antd'
import { useSelector } from 'react-redux'
import { optionsSelector } from 'redux/selectors'

function DemoWarning({showIcon = true, style, ...props}) {
  const { demo } = useSelector(optionsSelector)

  if (demo !== 1) {
    return null
  }
  return (
    <Alert
      className="notification-demo"
      style={{ backgroundColor: '#ffefb3', ...style }}
      message={
        <FormattedMessage
          id="setup.demo"
          values={{
            setup: (
              <Link to="/settings/setup">
                <strong>
                  <FormattedMessage id="setup.demo_link" />
                </strong>
              </Link>
            ),
          }}
        />
      }
      type="warning"
      showIcon={showIcon}
      {...props}
    />
  )
}

export default DemoWarning
