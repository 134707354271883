import React from 'react';
import {Pagination as PaginationAntd} from 'antd';
import PropTypes from 'prop-types';

function Pagination (props) {
  return (
    <PaginationAntd
      pageSizeOptions={[ 15, 25, 50, 100 ]}
      {...props}
    />
  )
}

Pagination.propTypes = {
  current: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  hideOnSinglePage: PropTypes.bool,
  showSizeChanger: PropTypes.bool,
}

Pagination.defaultProps = {
  current: 1,
  pageSize: 15,
  onChange: () => {},
  onShowSizeChange: () => {},
  total: 0,
  hideOnSinglePage: true,
  showSizeChanger: false,
}

export default Pagination;