import React from 'react';
import Joyride from 'react-joyride';

const ProductTour = ({innerRef, ...props}) => {
  return (
    <Joyride
      ref={innerRef}
      continuous
      scrollToFirstStep
      showProgress
      showSkipButton={false}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: '#4777F3',
        },
        tooltipContent: {
          fontSize: 16,
          lineHeight: 1.6
        }
      }}
      spotlightPadding={0}
      {...props}
    />
  );
};

const Component = React.forwardRef((props, ref) => <ProductTour innerRef={ref} {...props} />);
export default React.memo(Component);
