export const SET_REFS = 'SET_REFS';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const SET_A_DASHBOARD = 'SET_A_DASHBOARD';
export const ADD_DASHBOARD = 'ADD_DASHBOARD';
export const EDIT_DASHBOARD = 'EDIT_DASHBOARD';
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';
export const ADD_ACCOUNT_WATCH_LIST = 'ADD_ACCOUNT_WATCH_LIST';
export const DELETE_ACCOUNT_WATCH_LIST = 'DELETE_ACCOUNT_WATCH_LIST';
export const MOVE_ACCOUNT_WATCH_LIST = 'MOVE_ACCOUNT_WATCH_LIST';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

// punya e inits
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const START_INIT = 'START_INIT';
export const STOP_INIT = 'STOP_INIT';
export const SET_LOADED_INIT = 'SET_LOADED_INIT';
export const INCREASE_ATTEMP = 'INCREASE_ATTEMP';
export const RESET_ATTEMP = 'RESET_ATTEMP';

// Billing
export const SET_BILLING = 'SET_BILLING';

// Translation
export const SET_TRANSLATION = 'SET_TRANSLATION';
export const EDIT_TRANSLATION = 'EDIT_TRANSLATION';

// Companes
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_WEBSITE_ID= 'SET_WEBSITE_ID';
export const SET_ENDPOINT = 'SET_ENDPOINT';
export const SET_SELECTED_ENDPOINT = 'SET_SELECTED_ENDPOINT';
export const EDIT_COMPANY_NAME = 'EDIT_COMPANY_NAME';

// Pages Payments
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const EDIT_PAYMENT = 'EDIT_PAYMENT';
export const MOVE_PAYMENT = 'MOVE_PAYMENT';
export const CHANGE_STATUS_PAYMENT = 'CHANGE_STATUS_PAYMENT';

// Authentication User
export const SET_USER = 'SET_USER';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UPDATE_PROFILE = 'USER_UPDATE_PROFILE';
export const SHOW_LOGIN_FORM = 'SHOW_LOGIN_FORM';
export const SET_ERROR_LOGIN_FORM = 'SET_ERROR_LOGIN_FORM';

// Menu
export const SET_MENU = 'SET_MENU';
export const EDIT_MENU = 'EDIT_MENU';
export const RESET_MENU = 'RESET_MENU';

// Options
export const SET_OPTIONS = 'SET_OPTIONS';
export const EDIT_OPTIONS = 'EDIT_OPTIONS';

// Setup
export const SETUP_CLEAR_DEMO_DATA = 'SETUP_CLEAR_DEMO_DATA';
export const SETUP_FINISH = 'SETUP_FINISH';

// POS Settings
export const POS_SETTINGS_STORE = 'POS_SETTINGS_STORE'

// Finance Products
export const SET_FINANCE_PRODUCTS = 'SET_FINANCE_PRODUCTS'

// Bank Accounts
export const SET_FINANCE_BANK_ACCOUNTS = 'SET_FINANCE_BANK_ACCOUNTS';
export const ADD_FINANCE_BANK_ACCOUNT = 'ADD_FINANCE_BANK_ACCOUNT';
export const EDIT_FINANCE_BANK_ACCOUNT = 'EDIT_FINANCE_BANK_ACCOUNT';
export const DELETE_FINANCE_BANK_ACCOUNT = 'DELETE_FINANCE_BANK_ACCOUNT';

// Custom Column
export const SET_CUSTOM_COLUMNS = 'SET_CUSTOM_COLUMNS';
export const ADD_CUSTOM_COLUMN = 'ADD_CUSTOM_COLUMN';
export const DELETE_CUSTOM_COLUMN = 'DELETE_CUSTOM_COLUMN';
export const EDIT_CUSTOM_COLUMN = 'EDIT_CUSTOM_COLUMN';
export const MOVE_CUSTOM_COLUMN = 'MOVE_CUSTOM_COLUMN';
export const CHANGE_STATUS_CUSTOM_COLUMN = 'CHANGE_STATUS_CUSTOM_COLUMN';
export const ADD_CUSTOM_COLUMN_ITEM = 'ADD_CUSTOM_COLUMN_ITEM';
export const DELETE_CUSTOM_COLUMN_ITEM = 'DELETE_CUSTOM_COLUMN_ITEM';
export const EDIT_CUSTOM_COLUMN_ITEM = 'EDIT_CUSTOM_COLUMN_ITEM';
export const MOVE_CUSTOM_COLUMN_ITEM = 'MOVE_CUSTOM_COLUMN_ITEM';
export const CHANGE_STATUS_CUSTOM_COLUMN_ITEM = 'CHANGE_STATUS_CUSTOM_COLUMN_ITEM';

// Finance Expense Term
export const GET_CAN_DELETED_FINANCE_EXPENSE_TERMS = 'GET_CAN_DELETED_FINANCE_EXPENSE_TERMS';
export const SET_FINANCE_EXPENSE_TERMS = 'SET_FINANCE_EXPENSE_TERMS';
export const ADD_FINANCE_EXPENSE_TERM = 'ADD_FINANCE_EXPENSE_TERM';
export const DELETE_FINANCE_EXPENSE_TERM = 'DELETE_FINANCE_EXPENSE_TERM';
export const EDIT_FINANCE_EXPENSE_TERM = 'EDIT_FINANCE_EXPENSE_TERM';
export const MOVE_FINANCE_EXPENSE_TERM = 'MOVE_FINANCE_EXPENSE_TERM';
export const CHANGE_STATUS_FINANCE_EXPENSE_TERM = 'CHANGE_STATUS_FINANCE_EXPENSE_TERM';
export const FILTER_FINANCE_EXPENSE_TERM = 'FILTER_FINANCE_EXPENSE_TERM';

// Finance Tax
export const GET_CAN_DELETED_FINANCE_TAX = 'GET_CAN_DELETED_FINANCE_TAX';
export const SET_FINANCE_TAXES = 'SET_FINANCE_TAXES';
export const ADD_FINANCE_TAX = 'ADD_FINANCE_TAX';
export const DELETE_FINANCE_TAX = 'DELETE_FINANCE_TAX';
export const EDIT_FINANCE_TAX = 'EDIT_FINANCE_TAX';
export const MOVE_FINANCE_TAX = 'MOVE_FINANCE_TAX';
export const CHANGE_STATUS_FINANCE_TAX = 'CHANGE_STATUS_FINANCE_TAX';
export const FILTER_FINANCE_TAX = 'FILTER_FINANCE_TAX';

// Finance Unit
export const GET_CAN_DELETED_FINANCE_UNIT = 'GET_CAN_DELETED_FINANCE_UNIT';
export const SET_FINANCE_UNITS = 'SET_FINANCE_UNITS';
export const ADD_FINANCE_UNIT = 'ADD_FINANCE_UNIT';
export const DELETE_FINANCE_UNIT = 'DELETE_FINANCE_UNIT';
export const EDIT_FINANCE_UNIT = 'EDIT_FINANCE_UNIT';
export const MOVE_FINANCE_UNIT = 'MOVE_FINANCE_UNIT';
export const FILTER_FINANCE_UNIT = 'FILTER_FINANCE_UNIT';

export const SET_FINANCE_CONFIG = 'SET_FINANCE_CONFIG';
export const SET_FINANCE_CONFIG_DEFAULT_ACCOUNT = 'SET_FINANCE_CONFIG_DEFAULT_ACCOUNT';
export const SET_FINANCE_ACCOUNT_CATEGORIES = 'SET_FINANCE_ACCOUNT_CATEGORIES';
export const SET_FINANCE_CONTACTS = 'SET_FINANCE_CONTACTS';

// Finance Contact Group
export const SET_FINANCE_CONTACT_GROUP = 'SET_FINANCE_CONTACT_GROUP';
export const ADD_FINANCE_CONTACT_GROUP = 'ADD_FINANCE_CONTACT_GROUP';
export const EDIT_FINANCE_CONTACT_GROUP = 'EDIT_FINANCE_CONTACT_GROUP';
export const MOVE_FINANCE_CONTACT_GROUP = 'MOVE_FINANCE_CONTACT_GROUP';
export const DELETE_FINANCE_CONTACT_GROUP = 'DELETE_FINANCE_CONTACT_GROUP';

export const SET_FINANCE_PRODUCT_CATEGORIES = 'SET_FINANCE_PRODUCT_CATEGORIES';
export const ADD_FINANCE_PRODUCT_CATEGORIES = 'ADD_FINANCE_PRODUCT_CATEGORIES';
export const EDIT_FINANCE_PRODUCT_CATEGORIES = 'EDIT_FINANCE_PRODUCT_CATEGORIES';
export const MOVE_FINANCE_PRODUCT_CATEGORIES = 'MOVE_FINANCE_PRODUCT_CATEGORIES';
export const DELETE_FINANCE_PRODUCT_CATEGORIES = 'DELETE_FINANCE_PRODUCT_CATEGORIES';

// Lock Date
export const SET_LOCK_DATE_OPTION = 'SET_LOCK_DATE_OPTION';
export const REMOVE_LOCK_DATE_OPTION = 'REMOVE_LOCK_DATE_OPTION';

// Finance Accounts
export const SET_FINANCE_WAREHOUSES = 'SET_FINANCE_WAREHOUSES';
export const ADD_FINANCE_WAREHOUSE = 'ADD_FINANCE_WAREHOUSE';
export const DELETE_FINANCE_WAREHOUSE = 'DELETE_FINANCE_WAREHOUSE';
export const ARCHIVE_FINANCE_WAREHOUSE = 'ARCHIVE_FINANCE_WAREHOUSE';
export const UNARCHIVE_FINANCE_WAREHOUSE = 'UNARCHIVE_FINANCE_WAREHOUSE';
export const EDIT_FINANCE_WAREHOUSE = 'EDIT_FINANCE_WAREHOUSE';
export const MOVE_FINANCE_WAREHOUSE = 'MOVE_FINANCE_WAREHOUSE';
export const FILTER_FINANCE_WAREHOUSE = 'FILTER_FINANCE_WAREHOUSE';
export const CHANGE_STATUS_FINANCE_WAREHOUSE = 'CHANGE_STATUS_FINANCE_WAREHOUSE';
export const SET_FINANCE_WAREHOUSES_ROLES = 'SET_FINANCE_WAREHOUSES_ROLES';

// Expediton
export const GET_CAN_DELETED_SHIPPING_COMPS = 'GET_CAN_DELETED_SHIPPING_COMPS';
export const SET_SHIPPING_COMPS = 'SET_SHIPPING_COMPS';
export const ADD_SHIPPING_COMP = 'ADD_SHIPPING_COMP';
export const DELETE_SHIPPING_COMP = 'DELETE_SHIPPING_COMP';
export const EDIT_SHIPPING_COMP = 'EDIT_SHIPPING_COMP';
export const MOVE_SHIPPING_COMP = 'MOVE_SHIPPING_COMP';
export const CHANGE_STATUS_SHIPPING_COMP = 'CHANGE_STATUS_SHIPPING_COMP';
export const FILTER_SHIPPING_COMP = 'FILTER_SHIPPING_COMP';

// Account Transaction Report
export const SHOW_ACCOUNTTRANSACTION_REPORT = 'SHOW_ACCOUNTTRANSACTION_REPORT';
export const HIDE_ACCOUNTTRANSACTION_REPORT = 'HIDE_ACCOUNTTRANSACTION_REPORT';

// Audit Modal
export const SHOW_AUDIT_MODAL = 'SHOW_AUDIT_MODAL';
export const HIDE_AUDIT_MODAL = 'HIDE_AUDIT_MODAL';

// Broadcast
export const SET_BROADCAST = 'SET_BROADCAST';
export const CLEAR_BROADCAST = 'CLEAR_BROADCAST';
export const SHOW_BROADCAST = 'SHOW_BROADCAST';
export const HIDE_BROADCAST = 'HIDE_BROADCAST';
export const MARKASREAD_BROADCAST = 'MARKASREAD_BROADCAST';

// NPS
export const HIDE_NPS = 'HIDE_NPS';
export const SET_NPS = 'SET_NPS';

// Tutorials
export const SET_TUTORIALS = 'SET_TUTORIALS';

// Default Message
export const SET_DEFAULT_MESSAGE = 'SET_DEFAULT_MESSAGE';
export const EDIT_DEFAULT_MESSAGE = 'EDIT_DEFAULT_MESSAGE';

// Multi Currencies
export const SET_CURRENCIES = 'SET_CURRENCIES'; // Untuk ngeset yang dari init
export const SET_MULTI_CURRENCIES = 'SET_MULTI_CURRENCIES'; // Untuk ngeset dari yang setting
export const ADD_CURRENCY = 'ADD_CURRENCY';
export const DELETE_CURRENCY = 'DELETE_CURRENCY';
export const EDIT_CURRENCY = 'EDIT_CURRENCY';
export const SET_BASE_CURRENCY = 'SET_BASE_CURRENCY';
export const ACTIVATED_CURRENCY = 'ACTIVATED_CURRENCY';
export const DEACTIVATED_CURRENCY = 'DEACTIVATED_CURRENCY';
// Reviews
export const SET_REVIEWS = 'SET_REVIEWS';
