import React from 'react'
import * as Sentry from '@sentry/react'
import { Result, Button, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

const { Paragraph, Text } = Typography

function SentryErrorBoundary({ children, ...props }) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Result
          status="error"
          title="Maaf, Terjadi masalah pada aplikasi Kledo!"
          subTitle="Silahkan laporkan kejadian yang Anda alami barusan kepada team hebat Kledo,
           untuk feedback selanjutnya, atau klik Reload untuk merefresh halaman atau Reset untuk mencoba lagi."
          extra={[
            <Button
              type="primary"
              key="reload"
              onClick={() => {
                window.location.reload()
              }}
            >
              Reload
            </Button>,
            <Button key="reset" onClick={resetError}>
              Reset
            </Button>,
          ]}
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                Maaf, ditemukan masalah saat Anda menggunakan aplikasi Kledo:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined style={{ color: 'red' }} /> {error.toString()}
            </Paragraph>
            <Paragraph>{componentStack}</Paragraph>
          </div>
        </Result>
      )}
    {...props}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default SentryErrorBoundary
