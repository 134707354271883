import { updateObject } from '../utility'

import * as actionTypes from '../actionTypes'

const initialState = {
  currencies: [],
  multi_currencies: [],
  base_currency_editable: 0,
  multi_currency_deactive_enabled: 0,
}

const setCurrencies = (state, { payload }) => {
  return updateObject(state, {
    currencies: payload,
  })
}

const setTenantCurrencies = (state, { payload }) => {
  return updateObject(state, {
    multi_currencies: payload?.multi_currencies,
    base_currency_editable: payload?.base_currency_editable,
    multi_currency_deactive_enabled: payload?.multi_currency_deactive_enabled,
  })
}

const addTenantCurrency = (state, action) => {
  const multiCurrencies = [...state.multi_currencies, action.payload]
  const currency = action.payload?.currency
  const currencies = [
    ...state.currencies,
    { id: currency?.id, name: currency?.name, code: currency?.code, symbol: currency?.symbol },
  ]
  return updateObject(state, { multi_currencies: multiCurrencies, currencies })
}

const editTenantCurrency = (state, action) => {
  const multiCurrencies = state.multi_currencies.map((item) => {
    if (item.currency_id === action.payload.currency_id) {
      return {
        ...item,
        ...action.payload,
      }
    }
    return item
  })
  return updateObject(state, { multi_currencies: multiCurrencies })
}

const deleteTenantCurrency = (state, { payload }) => {
  const currencies = state.currencies.filter((item) => item.id !== payload.id)
  const multiCurrencies = state.multi_currencies.filter((item) => item.currency_id !== payload.id)
  return updateObject(state, { currencies, multi_currencies: multiCurrencies })
}

const activatedCurrency = (state) => {
  return updateObject(state, { multi_currency_deactive_enabled: 1, base_currency_editable: 1 })
}

const deactivatedCurrency = (state) => {
  return updateObject(state, { ...initialState })
}

const setBaseCurrency = (state, action) => {
  const multiCurrencies = [action.payload]
  const currencies = [action.payload.currency]
  return updateObject(state, { multi_currencies: multiCurrencies, currencies })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENCIES:
      return setCurrencies(state, action)
    case actionTypes.ADD_CURRENCY:
      return addTenantCurrency(state, action)
    case actionTypes.EDIT_CURRENCY:
      return editTenantCurrency(state, action)
    case actionTypes.DELETE_CURRENCY:
      return deleteTenantCurrency(state, action)
    case actionTypes.ACTIVATED_CURRENCY:
      return activatedCurrency(state, action)
    case actionTypes.DEACTIVATED_CURRENCY:
      return deactivatedCurrency(state, action)
    case actionTypes.SET_MULTI_CURRENCIES:
      return setTenantCurrencies(state, action)
    case actionTypes.SET_BASE_CURRENCY:
      return setBaseCurrency(state, action)
    default:
      return state
  }
}

export default reducer
