import axios from 'axios';

export const getCurrencies = (params) => {
  return axios.get('/finance/currencies', { params });
};

export const getTenantCurrencies = (params) => {
  return axios.get('/finance/tenantCurrencies', { params });
};

export const addTenantCurrency = (data) => {
  return axios.post('/finance/tenantCurrencies', data);
};

export const activateTenantCurrency = (data) => {
  return axios.post('/finance/tenantCurrencies/activate', data);
};

export const deactivateTenantCurrency = (data) => {
  return axios.post('/finance/tenantCurrencies/deactivate', data);
};

export const editTenantCurrencySource = (id, data) => {
  return axios.put(`/finance/tenantCurrencies/${id}`, data);
};

export const deleteTenantCurrency = (id) => {
  return axios.delete(`/finance/tenantCurrencies/${id}`);
};

export const getTenantCurrencyRate = (id, params) => {
  return axios.get(`/finance/tenantCurrencies/${id}/rate`, { params });
};

export const editTenantCurrencyRate = (id, data) => {
  return axios.put(`/finance/tenantCurrencies/${id}/rate`, data);
};

export const getCurrencyRateSource = (id, params) => {
  return axios.get(`/finance/tenantCurrencies/${id}/rateAllSource`, { params });
};

export const getTenantCurrencyRates = (params) => {
  return axios.get('/finance/tenantCurrencies/rates', { params });
};

export const editBaseCurrency = (data) => {
  return axios.put('/finance/tenantCurrencies/base', data);
};
