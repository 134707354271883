import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  min-height: 160px;
`

const Wrapper = styled.div`
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: #efefff99;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

function Overlay({ show, children, content }) {
  if (!show) {
    return children;
  }

  return (
    <Container>
      {children}
      <Wrapper>
        {content}
      </Wrapper>
    </Container>
  )
}

export default Overlay
