import store from 'store';

export const storedSettings = payload => {
  const settings = {}
  Object.keys(payload).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : payload[key]
  })
  return settings
}

export const setSettingsToLocalStorage = payload => {
  Object.keys(payload).forEach(setting => {
    store.set(`app.settings.${setting}`, payload[setting])
  });
};