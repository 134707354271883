import axios from 'axios'
import { stringify } from 'query-string'
import { getOnce, cleanBlankValue } from 'utils/helper'

export const getFinanceContacts = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/contacts?${query}`)
}

export const getFinanceContactDetail = (id, params) => {
  return axios.get(`/finance/contacts/${id}`, { params })
}

export const getFinanceContactMoneyInOutStat = (id, params) => {
  return axios.get(`/finance/contacts/${id}/moneyInOutStats`, { params })
}

export const addFinanceContact = (data) => {
  return axios.post('/finance/contacts', data)
}

export const editFinanceContact = (data) => {
  return axios.put(`/finance/contacts/${data.id}`, data)
}

export const archiveFinanceContact = (id) => {
  return axios.patch(`/finance/contacts/${id}/archive`)
}

export const unarchiveFinanceContact = (id) => {
  return axios.patch(`/finance/contacts/${id}/unarchive`)
}

export const deleteFinanceContact = (id) => {
  return axios.delete(`/finance/contacts/${id}`)
}

export const getSuggestionFinanceContact = (params) => {
  const query = stringify(params)

  return getOnce(`/finance/contacts/suggestionPerPage?${query}`)
}

export const getFinanceContact = (id) => {
  return axios.get(`/finance/contacts/${id}/name`)
}

export const getFinanceContactLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/contacts/${id}/logs${query}`)
}

export const getFinanceContactTransactions = (id, params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/finance/contacts/${id}/transactions?${query}`)
}

export const uploadImportFinanceContact = (data) => {
  return axios.post('/finance/contacts/uploadImport', data)
}

export const executeImportFinanceContact = (data) => {
  return axios.post('/finance/contacts/executeImport', data)
}

export const exportFinanceContact = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/finance/contacts/export?${query}`, { responseType: 'arraybuffer' })
}

export const inputMassDeleteFinanceContact = (data) => {
  return axios.post('/finance/contacts/inputMassDelete', data)
}

export const executeMassDeleteFinanceContact = (data) => {
  return axios.delete('/finance/contacts/executeMassDelete', { data })
}
