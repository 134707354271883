import React from 'react'
import { List, Typography, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import _ from 'lodash'
import moment from 'moment'
import { Spinner } from 'components/UI'
import Player from 'components/UI/ModalPlayer/Player'
import style from './style.module.scss'

const Media = ({ videoId, imageUrl, title }) => {
  if (!_.isEmpty(videoId)) {
    return (
      <div className={`${style.itemCover}`}>
        <Player
          containerClassName="video-responsive rounded shadow"
          className="youtube-player"
          videoId={videoId}
          autoplay={0}
        />
      </div>
    )
  }

  if (!_.isEmpty(imageUrl) && _.isEmpty(videoId)) {
    return (
      <div className={`${style.itemCover}`}>
        <img src={imageUrl} alt={title} className="rounded shadow" />
      </div>
    )
  }

  return null
}

const News = ({ loading, data, onLoadMore, hasMore, loadingMore }) => {
  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={1}
      loadMore={onLoadMore}
      hasMore={!loadingMore && hasMore}
      useWindow={false}
    >
      <Skeleton loading={loading} active avatar>
        <List
          className="list-unstyled"
          dataSource={data || []}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              style={{ borderBottom: 'none', paddingTop: 8, paddingBottom: 8 }}
            >
              <div className={style.item}>
                <div className="row">
                  <div className="col">
                    <div className="font-weight-bold">{item.title}</div>
                    <div>
                      <Typography.Text type="secondary">
                        {moment(item.created_at).fromNow()}
                      </Typography.Text>
                    </div>
                  </div>
                </div>
                <div className="row mb-3 mt-2">
                  <div className="col">
                    <Media videoId={item.url} imageUrl={item.image} title={item.title} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">{item.desc}</div>
                </div>
              </div>
            </List.Item>
          )}
        />
      </Skeleton>
      <div style={{ position: 'relative' }}>
        <Spinner spinning={loadingMore && hasMore} />
      </div>
    </InfiniteScroll>
  )
}

export default News
