import React from 'react'
import { setSettingsToLocalStorage } from 'utils/localStorage'

const AppContext = React.createContext()
const initialStates = {
  isMobileMenuOpen: false,
  isMenuCollapsed: false,
};

function AppProvider({value = {settings: {}}, ...props}) {
  const [settings, setSettings] = React.useState({...initialStates, ...value?.settings})

  const defaultValue = React.useMemo(
    () => ({
      settings,
      onChangeSettings: (payload) => {
        setSettings((prevState) => ({ ...prevState, ...payload }))
        setSettingsToLocalStorage(payload)
      },
    }),
    [settings],
  )

  return <AppContext.Provider value={defaultValue} {...props} />
}

function useApp() {
  const context = React.useContext(AppContext)

  if (!context) {
    throw new Error('useApp must be used within a AppProvider')
  }

  return context
}

function AppConsumer({ children }) {
  return (
    <AppContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error('AppConsumer must be used within a AppProvider')
        }
        return children(context)
      }}
    </AppContext.Consumer>
  )
}

const withAppConsumer = (WrappedComponent) => {
  // eslint-disable-next-line
  const HOC = class extends React.Component {
    render() {
      return (
        <AppConsumer>
          {(context) => <WrappedComponent {...this.props} appContext={context} />}
        </AppConsumer>
      )
    }
  }

  return HOC
}

export { AppProvider, useApp, withAppConsumer }
