import axios from 'axios';
import { stringify } from 'query-string';
import { getOnce } from 'utils/helper';

export const getFinanceBankStatements = (params) => {
  const query = stringify(params);
  return getOnce(`/finance/bankStatements?${query}`);
};

export const deleteFinanceBankStatement = (bankAccountId, ids) => {
  return axios.delete('/finance/bankStatements', { data: { bank_account_id: bankAccountId, id: ids } });
};

export const uploadImportFinanceBankStatement = (data) => {
  return axios.post('/finance/bankStatements/uploadImport', data);
};

export const executeImportFinanceBankStatement = (data) => {
  return axios.post('/finance/bankStatements/executeImport', data);
};

export const editFinanceBankStatement = (id, data) => {
  return axios.put(`/finance/bankStatements/${id}`, data);
};

export const getBankStatementDetails = (id) => {
  return getOnce(`/finance/bankStatements/${id}`);
};

export const revertBankStatementDetail = (id, data) => {
  return axios.put(`/finance/bankStatements/${id}/revert`, data);
};
