import React from 'react';
import { injectIntl } from 'react-intl';
import YouTube from "react-youtube";
import './style.css';

const Player = ({ videoId, options, autoplay = 1, ...props }) => {
  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay,
      mute: 0,
    },
    ...options,
  };

  return (
    <YouTube
      containerClassName="video-responsive"
      className="youtube-player"
      videoId={videoId}
      opts={opts}
      {...props}
    />
  )
};

const Component = injectIntl(Player);
export default React.memo(Component);
