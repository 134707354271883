import axios from 'axios';

export const getCountries = (params) => {
  return axios.get('/countries', { params });
};

export const getProvinces = (params) => {
  return axios.get('/provinces', { params });
};

export const getCities = (params) => {
  return axios.get('/cities', { params });
};
