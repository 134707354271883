import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

const AppHelmet = (props) => {
  const locale = useSelector((state) => state.settings.locale)
  const lang = locale === 'en-US' ? 'en' : 'id'
  return <Helmet htmlAttributes={{ lang }} {...props} />
}

export default AppHelmet
