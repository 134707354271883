import { updateObject } from '../utility';
import * as actionTypes from '../actionTypes';

const initialState = {};

const setDefaultMessage = (state, { payload }) => {
  return updateObject(state, { ...payload });
};

const editDefaultMessage = (state, { payload }) => {
  return updateObject(state, { ...payload });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT_MESSAGE: return setDefaultMessage(state, action);
    case actionTypes.EDIT_DEFAULT_MESSAGE: return editDefaultMessage(state, action);
    default: return state;
  }
};

export default reducer;
