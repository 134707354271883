import React, { Component, } from "react";
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import DragableBodyRow from './BodyRow';

const withDragRow = (WrappedComponent) => {
  // eslint-disable-next-line
  const HOC = class extends Component {
    components = {
      body: {
        row: DragableBodyRow,
      },
    };

    render() {
      return (
        <DndProvider key={Math.random()} backend={HTML5Backend}>
          <WrappedComponent
            components={this.components}
            {...this.props}
          />
        </DndProvider>
      )
    }
  };

  return HOC;
};

export default withDragRow;