import { updateObject } from '../utility';
import * as actionTypes from '../actionTypes';

const initialState = {
  custom_column_types: [],
  custom_columns: [],
  custom_column_items: [],
};

const setCustomColumns = (state, {payload}) => {
  return updateObject(state, payload);
};

const addCustomColumn = (state, action) => {
  const custom_columns = [...state.custom_columns, action.payload];
  return updateObject(state, { custom_columns });
};

const editCustomColumn = (state, action) => {
  const items = state.custom_columns.map(item => {
    if (item.id === action.payload.id) {
      return action.payload;
    }
    return item;
  });
  return updateObject(state, { custom_columns: items });
};

const deleteCustomColumn = (state, action) => {
  const custom_columns = state.custom_columns.filter(data => data.id !== action.payload);
  return updateObject(state,
    { custom_columns }
  );
};

const moveCustomColumn = (state, action) => {
  const { dragIndex, hoverIndex } = action.payload;
  const custom_columns = [...state.custom_columns];
  const srcCustomColumn = custom_columns[dragIndex];
  custom_columns.splice(dragIndex, 1);
  custom_columns.splice(hoverIndex, 0, srcCustomColumn);
  return updateObject(state, { custom_columns });
};

const changeStatusCustomColumn = (state, action) => {
  const custom_columns = state.custom_columns.map(item => {
    if (item.id === action.payload.id) {
      return {
        ...item,
        is_active: action.payload.checked ? 1 : 0
      };
    }
    return item;
  });
  return updateObject(state, { custom_columns });
};

const addCustomColumnItem = (state, action) => {
  const custom_column_items = [...state.custom_column_items, action.payload];
  return updateObject(state, { custom_column_items });
};

const editCustomColumnItem = (state, action) => {
  const items = state.custom_column_items.map(item => {
    if (item.id === action.payload.id) {
      return action.payload;
    }
    return item;
  });
  return updateObject(state, { custom_column_items: items });
};

const deleteCustomColumnItem = (state, action) => {
  const custom_column_items = state.custom_column_items.filter(data => data.id !== action.payload);
  return updateObject(state,
    { custom_column_items }
  );
};

const moveCustomColumnItem = (state, action) => {
  const { dragIndex, hoverIndex } = action.payload;
  const custom_column_items = [...state.custom_column_items];
  const srcCustomColumn = custom_column_items[dragIndex];
  custom_column_items.splice(dragIndex, 1);
  custom_column_items.splice(hoverIndex, 0, srcCustomColumn);
  return updateObject(state, { custom_column_items });
};

const changeStatusCustomColumnItem = (state, action) => {
  const custom_column_items = state.custom_column_items.map(item => {
    if (item.id === action.payload.id) {
      return {
        ...item,
        is_active: action.payload.checked ? 1 : 0
      };
    }
    return item;
  });
  return updateObject(state, { custom_column_items });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOM_COLUMNS: return setCustomColumns(state, action);
    case actionTypes.ADD_CUSTOM_COLUMN: return addCustomColumn(state, action);
    case actionTypes.EDIT_CUSTOM_COLUMN: return editCustomColumn(state, action);
    case actionTypes.MOVE_CUSTOM_COLUMN: return moveCustomColumn(state, action);
    case actionTypes.CHANGE_STATUS_CUSTOM_COLUMN: return changeStatusCustomColumn(state, action);
    case actionTypes.DELETE_CUSTOM_COLUMN: return deleteCustomColumn(state, action);
    case actionTypes.ADD_CUSTOM_COLUMN_ITEM: return addCustomColumnItem(state, action);
    case actionTypes.EDIT_CUSTOM_COLUMN_ITEM: return editCustomColumnItem(state, action);
    case actionTypes.MOVE_CUSTOM_COLUMN_ITEM: return moveCustomColumnItem(state, action);
    case actionTypes.CHANGE_STATUS_CUSTOM_COLUMN_ITEM: return changeStatusCustomColumnItem(state, action);
    case actionTypes.DELETE_CUSTOM_COLUMN_ITEM: return deleteCustomColumnItem(state, action);
    default: return state;
  }
};

export default reducer
