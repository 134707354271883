import axios from 'axios';
import { stringify } from 'query-string';

export const getFinanceStockAdjustment = (id, includeStockBefore = 0) => {
  let query = '';
  if (includeStockBefore === 1) {
    query = stringify({ include_stock_before: 1 });
  }
  return axios.get(`/finance/stockAdjustments/${id}?${query}`);
};

export const getFinanceStockAdjustmentLog = (id, params) => {
  const query = stringify(params);
  return axios.get(`/finance/stockAdjustments/${id}/logs?${query}`);
};

export const getFinanceStockAdjustments = (params) => {
  const query = stringify(params);
  return axios.get(`/finance/stockAdjustments?${query}`);
};

export const addFinanceStockAdjustment = (data) => {
  return axios.post('/finance/stockAdjustments', data);
};

export const editFinanceStockAdjustment = (data) => {
  return axios.put(`/finance/stockAdjustments/${data.id}`, data);
};

export const deleteFinanceStockAdjustment = (id) => {
  return axios.delete(`/finance/stockAdjustments/${id}`);
};

export const deleteOnAddFinanceStockAdjustmentAttachment = (url) => {
  return axios.delete('/finance/stockAdjustments/attachments', { data: { url } });
};

export const deleteOnEditFinanceStockAdjustmentAttachment = (id, url) => {
  return axios.delete(`/finance/stockAdjustments/${id}/attachments`, { data: { url } });
};

export const generateImportFinanceStockAdjustment = (params) => {
  const query = stringify(params);
  return axios.get(`/finance/stockAdjustments/generateImport?${query}`, { responseType: 'arraybuffer' });
};

export const uploadImportFinanceStockAdjustment = (data) => {
  return axios.post('/finance/stockAdjustments/uploadImport', data);
};

export const executeImportFinanceStockAdjustment = (data) => {
  return axios.post('/finance/stockAdjustments/executeImport', data);
};
