import axios from 'axios';

export const getCommissions = (params) => {
  return axios.get('/finance/commissions', { params });
};

export const getCommissionTypes = (params) => {
  return axios.get('/finance/commissions/types', { params });
};

export const addCommission = (data) => {
  return axios.post('/finance/commissions', data);
};

export const editCommission = (data) => {
  return axios.put(`/finance/commissions/${data.id}`, data);
};

export const getCommission = (id) => {
  return axios.get(`/finance/commissions/${id}`);
};

export const deleteCommission = (id) => {
  return axios.delete(`/finance/commissions/${id}`);
};