import React from 'react';
import { Button } from 'antd';
import { injectIntl } from 'react-intl';
import { DoubleLeftOutlined } from '@ant-design/icons';

const BackGhost = injectIntl(({ onClick, intl, ...props }) => {
  return (
    <Button
      ghost
      type="primary"
      onClick={onClick}
      {...props}
    >
      <DoubleLeftOutlined /> {intl.formatMessage({ id: 'button.back' })}
    </Button>
  )
});

export default BackGhost;