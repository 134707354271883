import axios from 'axios';
import { stringify } from 'query-string';
import {cleanBlankValue, getOnce} from 'utils/helper';

export const addFinanceProduct = (data) => {
  return axios.post('/finance/products', data);
};

export const addFinanceProductPackage = (data) => {
  return axios.post('/finance/products/bundlePackages', data);
};

export const conversionProduct = (data) => {
  return axios.post('/finance/products/conversions', data);
};

export const editConversionProduct = (id, data) => {
  return axios.put(`/finance/products/conversions/${id}`, data);
};

export const deleteConversionProduct = (id) => {
  return axios.delete(`/finance/products/conversions/${id}`);
};

export const editFinanceProductPackage = (data) => {
  return axios.put(`/finance/products/bundlePackages/${data.id}`, data);
};

export const addFinanceProductManufacture = (data) => {
  return axios.post('/finance/products/bundleManufactures', data);
};

export const editFinanceProductManufacture = (data) => {
  return axios.put(`/finance/products/bundleManufactures/${data.id}`, data);
};

export const editFinanceProduct = (data) => {
  return axios.put(`/finance/products/${data.id}`, data);
};

export const addFinanceCategorie = (data) => {
  return axios.post('/finance/productCategories', data);
};

export const uploadImportFinanceProduct = (data) => {
  return axios.post('/finance/products/uploadImport', data);
};

export const executeImportFinanceProduct = (data) => {
  return axios.post('/finance/products/executeImport', data);
};

export const uploadImportFinanceProductManufacture = (data) => {
  return axios.post('/finance/products/bundleManufactures/uploadImport', data);
};

export const executeImportFinanceProductManufacture = (data) => {
  return axios.post('/finance/products/bundleManufactures/executeImport', data);
};

export const uploadImportFinanceProductPackage = (data) => {
  return axios.post('/finance/products/bundlePackages/uploadImport', data);
};

export const executeImportFinanceProductPackage = (data) => {
  return axios.post('/finance/products/bundlePackages/executeImport', data);
};

export const uploadImportFinanceProductConversion = (data) => {
  return axios.post('/finance/products/conversions/uploadImport', data);
};

export const executeImportFinanceProductConversion = (data) => {
  return axios.post('/finance/products/conversions/executeImport', data);
};

export const getStockProduct = (id, warehouseIds, transDate) => {
  const params = cleanBlankValue({
    warehouse_ids: warehouseIds,
    trans_date: transDate,
  })
  const query = stringify(params);
  return axios.get(`/finance/products/${id}/stocks?${query}`);
};

export const getStockProducts = (params) => {
  return axios.get('/finance/products/stocks', { params });
};

export const getFinanceProducts = (params) => {
  params = cleanBlankValue(params);
  const query = stringify(params);
  return getOnce(`/finance/products?${query}`);
};

export const getFinanceProductsOverview = (params) => {
  params = cleanBlankValue(params);
  const query = stringify(params);
  return axios.get(`/finance/products/overview?${query}`);
};

export const getFinanceProduct = (id, params) => {
  return axios.get(`/finance/products/${id}`, { params });
};

export const getFinanceProductLog = (id, params) => {
  return axios.get(`/finance/products/${id}/logs`, { params });
};

export const getFinanceProductStockMovementChart = (params) => {
  return axios.get('/finance/products/stockMovementStats', { params });
}

export const getFinanceProductMovementByCategoryChart = (params) => {
  return axios.get('/finance/products/movementProductByCategoryStats', { params });
}

export const getFinanceProductValueMovementChart = (params) => {
  return axios.get('/finance/products/movementProductValueStats', { params });
}

export const getFinanceProductSalesPurchaseChartDetail = (id, params) => {
  return axios.get(`/finance/products/${id}/salePurchaseStats`, { params });
};

export const getFinanceProductStockMovementChartDetail = (id, params) => {
  return axios.get(`/finance/products/${id}/stockMovementStats`, { params });
};

export const getConversionProduct = (id) => {
  return axios.get(`/finance/products/conversions/${id}`);
};

export const getFinanceProductStockMovements = (id, params) => {
  params = cleanBlankValue(params);
  const query = stringify(params);
  return axios.get(`/finance/products/${id}/stockMovements?${query}`);
};

export const exportFinanceProductStockMovements = (id, params) => {
  params = cleanBlankValue(params);
  const query = stringify(params);
  return axios.get(`/finance/products/${id}/stockMovements?${query}`, { responseType: 'arraybuffer' });
};

export const getFinanceProductTransactions = (id, params) => {
  params = cleanBlankValue(params);
  const query = stringify(params);
  return axios.get(`/finance/products/${id}/transactions?${query}`);
};

export const archiveFinanceProduct = (id) => {
  return axios.patch(`/finance/products/${id}/archive`);
};

export const unarchiveFinanceProduct = (id) => {
  return axios.patch(`/finance/products/${id}/unarchive`);
};

export const deleteFinanceProduct = (id) => {
  return axios.delete(`/finance/products/${id}`);
};

export const inputMassDeleteProduct = (data) => {
  return axios.post('/finance/products/inputMassDelete', data);
};

export const executeMassDeleteProduct = (data) => {
  return axios.delete('/finance/products/executeMassDelete', { data });
};

export const getAllStockProducts = (requests) => {
  return axios.all(requests).then(axios.spread((...responses) => responses));
};

export const exportProducts = (params) => {
  params = cleanBlankValue(params);
  const query = stringify(params);
  return axios.get(`/finance/products/export?${query}`, { responseType: 'arraybuffer' });
}

export const editUnitConversion = (id, data) => {
  return axios.put(`/finance/products/${id}/units`, data);
};

export const getProductsPriceRule = (params) => {
  return axios.get(`/finance/products/priceRules`, { params });
};
