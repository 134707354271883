import React from 'react'
import { Dropdown } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Notification from 'components/apps/Notification'
import styles from './style.module.scss'

const Actions = () => {
  const [visible, setVisible] = React.useState(false);
  
  const visibleChangeHandler = (flag) => setVisible(flag);
  const hideHandler = () => setVisible(false);

  const menu = (
    <div className="card cui__utils__shadow width-350 border-0">
      <div className="card-body p-0">
        <Notification showParent={visible} onHideParent={hideHandler} />
      </div>
    </div>
  )
  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      placement="bottomLeft"
      visible={visible}
      onVisibleChange={visibleChangeHandler}
    >
      <div className={styles.dropdown}>
        <FontAwesomeIcon icon="bell" className={`${styles.icon}`} />
      </div>
    </Dropdown>
  )
}

export default Actions
