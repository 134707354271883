import * as Sentry from '@sentry/react'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import reducers from 'redux/reducers'

if (process.env.NODE_ENV === 'development' && true) {
  // middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const configureStore = (history) => {
  const routeMiddleware = routerMiddleware(history)
  const middlewares = [thunk, routeMiddleware]
  const store = createStore(
    reducers(history),
    composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer),
  )

  return store
}

export default configureStore
