import React from 'react';
import {ACTIONS, STATUS} from 'react-joyride';
import { injectIntl } from 'react-intl';
import { message, Avatar, Button } from 'antd';
import { useSelector } from 'react-redux';
import { loadedInitSelector } from 'redux/selectors';
import { ProductTour } from 'components/UI';
import { getTutorial } from 'utils/apis';
import { useApp } from 'components/AppProvider';

const WelcomeModal = ({ scrollBarRef, intl }) => {
  const {settings: {isMenuCollapsed}, onChangeSettings} = useApp();
  const loadedInit = useSelector(loadedInitSelector);

  const joyrideRef = React.useRef();
  const [run, setRun] = React.useState(false);
  const [steps, setSteps] = React.useState([]);

  const joyrideCallbackHandler = React.useCallback((data) => {
    const {action, index, status} = data;
    if (index === 2 && action === ACTIONS.PREV) {
      if (scrollBarRef && scrollBarRef.current) {
        scrollBarRef.current.scrollToTop();
      }
    }
    if (action === ACTIONS.CLOSE || ([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
      setRun(false);
    }
    if (action === ACTIONS.START && index === 0 && isMenuCollapsed) {
      onChangeSettings({isMenuCollapsed: false});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollBarRef, ACTIONS, isMenuCollapsed, onChangeSettings]);

  const startTourHandler = React.useCallback(() => {
    if (joyrideRef && joyrideRef.current) {
     joyrideRef.current.helpers.next();
    }
  }, [joyrideRef]);

  const formatSteps = React.useCallback((data) => {
    const newSteps = data.map((row, index) => {
      const newRow = { ...row };
      if (index === 0) {
        newRow.hideFooter = true;
        newRow.content = (
          <>
            <Avatar
              src="https://kledo-live-img.s3-ap-southeast-1.amazonaws.com/welcomephoto.jpg"
              size={100}
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                margin: 'auto',
                transform: 'translateY(-106%)'
              }}
            />
            <div className="mt-2" dangerouslySetInnerHTML={{ __html: newRow.content }} />
            <div className="row mt-5">
              <div className="col text-center">
                <Button
                  style={{backgroundColor: '#4777F3', color: 'white', borderRadius: 10 }}
                  onClick={startTourHandler}
                >
                    Start Tour
                </Button>
              </div>
            </div>
          </>
        );
      } else {
        newRow.content = (<div dangerouslySetInnerHTML={{ __html: row.content }} />);
      }
      return newRow;
    });
    return newSteps;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = React.useCallback(async () => {
    try {
      const response = await getTutorial('welcome');
      if (response.data.success) {
        setSteps(formatSteps(response.data.data));
        setTimeout(() => {
          setRun(true);
        }, 100);
      }
    } catch (error) {
      message.error(error && error.message || intl.formatMessage({ id: 'button.error_data_' }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTutorial]);

  React.useEffect(() => {
    if (loadedInit) {
      getData();
    }
  }, [loadedInit, getData]);

  return (
    <ProductTour
      ref={joyrideRef}
      callback={joyrideCallbackHandler}
      run={run}
      steps={steps}
    />
  );
};

export default injectIntl(WelcomeModal);