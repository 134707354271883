import * as api from 'utils/apis/nps';
import * as actionTypes from '../actionTypes';

export const hideNPSRedux = () => {
  return {
    type: actionTypes.HIDE_NPS,
  };
};

export const ignoreNPS = () => {
  return dispatch => {
    return api.ignoreNPS()
      .then(response => {
        dispatch(hideNPSRedux());
        return response;
      });
  };
};

export const postNPS = (payload) => {
  return dispatch => {
    return api.postNPS(payload)
      .then(response => {
        dispatch(hideNPSRedux());
        return response;
      });
  };
};
