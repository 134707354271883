import axios from 'axios';
import {markAsRead as markAsReadApi} from 'utils/apis';
import * as actionTypes from '../actionTypes';

export const setBroadcast = (payload) => dispatch => {
  dispatch({
    type: actionTypes.SET_BROADCAST,
    payload
  });
};

export const clearBroadcast = () => dispatch => {
  dispatch({
    type: actionTypes.CLEAR_BROADCAST
  });
};

export const showBroadcast = () => dispatch => {
  dispatch({
    type: actionTypes.SHOW_BROADCAST
  });
};

export const hideBroadcast = () => dispatch => {
  dispatch({
    type: actionTypes.HIDE_BROADCAST
  });
};

export const markAsReadBroadcastRedux = (payload) => dispatch => {
  dispatch({
    type: actionTypes.MARKASREAD_BROADCAST,
    payload
  });
};

export const markAsRead = (payload) => {
  return dispatch => {
    dispatch(markAsReadBroadcastRedux(payload));
    return axios.all(payload.data.map(id => markAsReadApi(id)));
  };
};
