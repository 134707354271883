import React from 'react'
import { injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import _ from 'lodash'

import './style.scss'
import { checkPermission } from 'redux/user/selectors'
import { Upgrade } from 'components/UI'
import { isFeatureElite } from 'utils/helper'

// Disable hidden feature
class HideFeature extends React.Component {
  goToUpgradeHandler = () => {
    this.props.push('/settings/billing/upgrade')
  }

  render() {
    const {
      code = '',
      feature = '',
      intl,
      billing,
      currentCount,
      maxCode,
      maxFeature = '',
      hasAccessToBilling,
    } = this.props
    let label = intl.formatMessage({ id: 'billing.feature_available_in_pro_plan' }, { feature })

    if (_.isEmpty(billing)) {
      return this.props.children
    }

    if (maxCode && billing[maxCode] <= currentCount) {
      if (billing?.plan === 'Free' || billing?.plan === 'PRO') {
        label = intl.formatMessage(
          { id: 'billing.marketplace_connect_available_in_elite' },
          { feature: maxFeature },
        )
      } else {
        label = intl.formatMessage({ id: 'billing.upgrade_to_add' }, { feature: maxFeature })
      }
    } else if (
      isFeatureElite({
        billing,
        code,
      })
    ) {
      label = intl.formatMessage({ id: 'billing.feature_available_in_elite_plan' }, { feature })
    } else if (
      billing.hidden_feature &&
      billing.hidden_feature.filter((e) => e === code).length === 0
    ) {
      return this.props.children
    }

    return (
      <div className="hide-feature-container">
        {this.props.children}
        <div className="wrapper">
          <div className="text-center text-wrapper">
            <h5 className="text-center mb-3">{label}</h5>
            {hasAccessToBilling && (
              <Upgrade
                title={intl.formatMessage({ id: 'billing.upgrade_now' })}
                onClick={this.goToUpgradeHandler}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    billing: state.billing,
    hasAccessToBilling: checkPermission(state, 'billing'),
  }
}

export default connect(mapStateToProps, (dispatch) => {
  return {
    push: (path) => dispatch(push(path)),
  }
})(injectIntl(HideFeature))
