import axios from 'axios';

export const getRoles = () => {
	return axios.get(`/roles`);
};

export const getRole = (id) => {
	return axios.get(`/roles/${id}`);
};

export const addRole = (data) => {
	return axios.post(`/roles`, data);
};

export const updateRole = (data, id) => {
	return axios.put(`/roles/${id}`, data);
};

export const deleteRole = (id) => {
	return axios.delete(`/roles/${id}`);
};

export const moveRole = (data) => {
	return axios.patch(`/roles/${data.id}/move`, data);
};
