/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { EditOutlined, PaperClipOutlined } from '@ant-design/icons';
import draftToHtml from 'draftjs-to-html';
import _ from 'lodash';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Button, Modal, Skeleton, Form, Input, message, Result, Space, Checkbox } from 'antd';
import { Cancel, HelpTooltip, Send, MultipleEmailInput } from 'components/UI';
import Editor from 'components/apps/EmailTemplate/Editor';
import ConfirmEmailModal from './ConfirmEmailModal';

const SendEmailModal = ({
  intl,
  sendTitle,
  getEmailTemplate,
  sendEmail,
  show = false,
  data = { contact: {} },
  emailPayment = 0,
  emailSent = false,
  setEmailSent,
  onCloseEmailModal,
  onPrintPdf,
  goToEmailTamplate,
  withRelationAttachment,
  ...props
}) => {
  const formRef = React.createRef();

  const [loadingSendEmail, setLoadingSendEmail] = React.useState(false);
  const [loadingEmailTemplate, setLoadingEmailTemplate] = React.useState(false);
  const [emailTemplate, setEmailTemplate] = React.useState({ body: '', subject: '' });
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [emails, setEmails] = React.useState([]);

  React.useEffect(() => {
    const getTemplate = async () => {
      try {
        setLoadingEmailTemplate(true);
        const response = await getEmailTemplate();
        const resData = response.data.data;
        setLoadingEmailTemplate(false);

        if (!_.isEmpty(resData.mail_to_all)) {
          resData.mail_to = (resData.mail_to_all || []).filter(mail => !!mail);
          setEmails(resData.mail_to);
        } else if (!_.isEmpty(resData.mail_to)) {
          resData.mail_to = [resData.mail_to];
          setEmails(resData.mail_to);
        } else {
          setEmails([]);
        }
        const blocksFromHTML = htmlToDraft(!_.isEmpty(resData.body) ? resData.body.toString() : '');
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        )));
        setEmailTemplate(resData);
      } catch (e) {
        console.log(e);
        message.error(intl.formatMessage({ id: 'email.cannot_send_email' }));
      }
    }

    if (show) {
      getTemplate();
    }
  }, [show, getEmailTemplate, intl]);

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldsValue({
        mail_to: !_.isEmpty(emailTemplate.mail_to) ? emailTemplate.mail_to : [],
        subject: emailTemplate.subject,
        body: emailTemplate.body,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, emailPayment, emailTemplate]);

  const onSendEmail = async (values) => {
    setLoadingSendEmail(true);

    try {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const body = draftToHtml(rawContentState);
      const payload = {
        ...values,
        body,
        mail_to: Array.isArray(values.mail_to) ? values.mail_to.join(',') : ''
      };
      const response = await sendEmail(data.id, payload);
      setLoadingSendEmail(false);

      if (response.data.success) {
        setEmailTemplate({ ...emailTemplate, mail_to: values.mail_to });
        setEmailSent(true);
      } else {
        message.error(response.data.message);
      }
    } catch (e) {
      setLoadingSendEmail(false);
      message.error(intl.formatMessage({ id: 'email.cannot_send_email' }));
    }
  }

  const afterUpdatedOptionHandler = () => {};

  const changeEmailsHandler = (_mails) => setEmails(_mails); 

  if (props.email_confirmed) {
    return (
      <Modal
        title={sendTitle === "button.send_email" || !sendTitle ? intl.formatMessage({ id: 'button.send_email' }) : sendTitle}
        visible={show}
        maskTransitionName=""
        onCancel={onCloseEmailModal}
        destroyOnClose
        footer={(emailSent && props.email_confirmed) ? [
          <Button key={1} onClick={onCloseEmailModal} type="primary">Ok</Button>
        ] : [
            <React.Fragment key="template">
              {goToEmailTamplate && (
                <Button style={{ float: 'left' }} icon={<EditOutlined />} onClick={goToEmailTamplate}>
                  {intl.formatMessage({ id: 'wa.change_template' })}
                </Button>
              )}
            </React.Fragment>,
            <Cancel key={`_${0}`} onClick={onCloseEmailModal} />,
            <Send
              loading={loadingSendEmail}
              form="sendEmailForm"
              key="submit"
              htmlType="submit"
            />
          ]}
      >
        <>
          {loadingEmailTemplate ? <Skeleton active /> : emailSent ? (
            <Result
              status="success"
              title={
                <h5>{`${intl.formatMessage({ id: 'email.email_has_been_sent_to' })} ${(emailTemplate.mail_to || []).join(', ')}`}</h5>
              }
            />
          ) : (
              <Form
                ref={formRef}
                onFinish={onSendEmail}
                layout='vertical'
                id="sendEmailForm"
                initialValues={{
                  mail_to: [],
                  mail_from: '',
                  subject: '',
                  body: '',
                }}
              >
                <Form.Item
                  label={intl.formatMessage({ id: 'email.to' })}
                  name='mail_to'
                  rules={[
                    { required: true, message: intl.formatMessage({ id: 'profile.please_input_the_email/username' }) },
                  ]}
                >
                  <MultipleEmailInput
                    placeholder={intl.formatMessage({ id: 'topBar.profileMenu.email' })}
                    emails={emails}
                    onChange={changeEmailsHandler}
                  />
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: 'email.from' })}>
                  <span className="ant-form-text">{emailTemplate.mail_from}</span>
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'email.subject' })}
                  name='subject'
                  rules={[
                    { required: true, message: intl.formatMessage({ id: 'emails.please_input_the_email_template_subject' }) },
                    { max: 100, message: intl.formatMessage({ id: 'emails.max_100_characters' }) },
                  ]}
                >
                  <Input placeholder={intl.formatMessage({ id: 'email.subject' })} />
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: 'email.message' })}>
                  <Editor
                    editorStateChangeHandler={setEditorState}
                    editorState={editorState}
                  />
                </Form.Item>
                <Space>
                  <PaperClipOutlined />
                  <Button type="link" size="small" onClick={onPrintPdf}>
                    <span className="text-left text-pre-line">
                      {`${data.ref_number || emailTemplate.subject}.pdf`}
                    </span>
                  </Button>
                </Space>
                {withRelationAttachment && (
                  <Form.Item name="include_attachment_relation" valuePropName="checked">
                    <Checkbox>
                      <Space>
                        {intl.formatMessage({ id: 'emails.send_attachment_from_related_transaction' })}
                        <HelpTooltip
                          title={intl.formatMessage({
                            id: 'emails.send_attachment_from_related_transaction_help'
                          })}
                        />
                      </Space>
                    </Checkbox>
                  </Form.Item>
                )}
              </Form>
            )}
        </>
      </Modal>
    );
  }

  return (
    <ConfirmEmailModal
      show={show}
      onClose={onCloseEmailModal}
      afterUpdatedOption={afterUpdatedOptionHandler}
      closeAfterUpdate={false}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    email_confirmed: state.options.options.email_confirmed,
    property_email: state.options.options.property_email,
  };
}

export default connect(mapStateToProps)(SendEmailModal);
