import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 25px;
`;

const Value = styled.h3`
  margin-bottom: 20px;
`;

function Overview({ title, value }) {
  return (
    <Wrapper>
      <Value>{value}</Value>
      <span><strong>{title}</strong></span>
    </Wrapper>

  )
}

Overview.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
}

export default Overview;