import * as actionTypes from '../actionTypes';

const initialState = {
  loadingOutlets: false,
  settingsOutlets: [],
  loadingOutletsDetailForm: false,
  loadingOutletsDetail: false,
  settingsOutletsDetail: {},
  posSales: {
    pos_id: null,
    payment_type_id: null,
    booking_room_id: null,
    payment_id: null,
    table_id: 0,
    gross_sales: 0,
    net_sales: 0,
    tax: 0,
    gratuity: 0,
    collected: 0,
    saved_name: null,
    items: [],
    total_discount: 0,
    service_charge_per: 0,
    tax_per: 0
  },
  posSalesLoading: false,
  posSalesList: {},
  listToRoomLoading: false,
  listToRoom: {},
};

const storePosSettings = (state, action) => ({ ...state, ...action.data })

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POS_SETTINGS_STORE:
      return storePosSettings(state, action);
    default:
      return state;
  }
};

export default reducer;
