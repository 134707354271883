import antdData from 'antd/lib/locale-provider/en_US'
import localeData from 'react-intl/locale-data/en'

const messages = {
  // Dynamic Translation
  'text.choose': 'Choose {title}',
  'text.select': 'Select {title}',
  'text.please_choose': 'Please select {title}',
  'report.name_with_title': 'Name {title}',
  'report.product_name_with_title': '{title} Name',
  'report.product_category_with_title': '{title} Category',
  'report.total_quantity_product_with_title': 'Total Quantity {title}',
  'report.finished_product_with_title': 'Finished {title}',
  'report.search_by_product_with_title': 'Search by {title}',
  'report.product_value_with_title': '{title} Value',

  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Type to search...',
  'topBar.buyNow': 'Buy Now $24',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'topBar.billing.plan': 'Plan',
  'topBar.billing.forever': 'Forever',
  'topBar.billing.active_until': 'Active until',

  'notification.updates': 'Updates',
  'notification.timeline': 'Timeline',
  'notification.server_error': 'Server Error',
  'notification.something_went_wrong': 'Something went wrong, please contact our customer support.',
  'notification.install_kledo': 'Install Kledo App',
  'notification.kledo_is_now_on_google_play': 'Kledo is now available on Google Play',
  'notification.kledo_is_now_on_app_store': 'Kledo is now available on App Store',
  'notification.install_now': 'Install Now',
  'notification.later': 'Later',
  'notification.disable': 'Disable Notification',
  'notification.enable': 'Enable Notification',
  'notification.low_stock': 'Low stock notification',
  'notification.marketplace_sync_done': 'Marketplace sync done notification',
  'notification.bank_sync_done': 'Bank sync done notification',
  'notification.monthly_financial_report_email': 'Monthly financial report email',
  'notification.a_new_version_is_available': 'A new version is available!',
  'notification.please_wait_prepare_to_reload_page':
    'Please wait, prepare to reload page on {countDown} seconds. {errorAutoReloadMessage}',
  'notification.autoreload_failed': 'Auto reload failed.',
  'notification.reload_immediately': 'Reload immediately',

  'date.today': 'Today',
  'date.yesterday': 'Yesterday',
  'date.this_month': 'This Month',
  'date.last_month': 'Last Month',
  'date.last_7_days': 'Last 7 Days',
  'date.last_30_days': 'Last 30 Days',
  'date.this_year': 'This Year',
  'date.last_year': 'Last Year',
  'date.daily': 'Daily',
  'date.monthly': 'Monthly',
  'date.yearly': 'Yearly',

  'button.next': 'Next',
  'button.prev': 'Prev',
  'button.revert': 'Revert',
  'button.renew': 'Renew',
  'button.upgrade': 'Upgrade',
  'button.delete': 'Delete',
  'button.archive': 'Archive',
  'button.unarchive': 'Unarchive',
  'button.add': 'Add',
  'button.edit': 'Edit',
  'button.back': 'Back',
  'button.search': 'Search',
  'button.save': 'Save',
  'button.save_add_new': 'Save & Add New',
  'button.cancel': 'Cancel',
  'button.print': 'Print',
  'button.filter': 'Filter',
  'button.clear_filter': 'Clear Filter',
  'button.year': 'Year',
  'button.month': 'Month',
  'button.close': 'Close',
  'button.return': 'Return',
  'button.hello_can_i_help': 'Hello, can I help you?',
  'button.schedule_now': 'Schedule Now',
  'button.error_data_': 'Error data!',
  'input.placeholder.search': 'Search',
  'popconfirm.title.delete': 'Are you sure to delete?',
  'popconfirm.title.archive': 'Are you sure to archive?',
  'popconfirm.title.unarchive': 'Are you sure to unarchive?',
  'popconfirm.title.reset': 'Are you sure to reset?',
  'suggestion.not_found': 'Data not found',
  'spinner.loading': 'Loading...',
  'button.yes': 'Yes',
  'button.no': 'No',
  'button.pdf': 'PDF',
  'button.print_pdf': 'Print PDF',
  'button.print_label': 'Print Label',
  'button.print_payment': 'Print Payment Recap',
  'button.print_tax': 'Print Tax Invoice',
  'button.print_partial': 'Print Partial',
  'button.copy_link': 'Copy Link',
  'button.send_email': 'Send Email',
  'button.send_sms': 'Send SMS',
  'button.send_wa': 'Send Whatsapp',
  'button.send_email_payment': 'Email Payment Recap',
  'button.send_wa_payment': 'Whatsapp Payment Recap',
  'button.send_sms_payment': 'SMS Payment Recap',
  'button.download_word': 'Download MsWord',
  'button.send': 'Send',
  'button.share': 'Share',
  'button.duplicate': 'Duplicate',
  'button.guide': 'Guide',
  'button.tour': 'Tour',
  'button.video': 'Video',
  'button.article': 'Article',
  'button.import': 'Import',
  'button.export': 'Export',
  'button.view_journal_entries': 'View journal entries',
  'button.add_more': 'Add more',
  'button.change_layout': 'Change Layout',
  'button.reset': 'Reset',
  'button.copy_to_clipboard': 'Copy Link to Clipboard',
  'button.select_all': 'Select All',
  'button.deselect_all': 'Remove All',
  'button.add_with_title': 'Add {title}',
  'button.change_order': 'Change Order',
  'button.sell': 'Sell',
  'button.buy': 'Buy',
  'message.cannot_update_data': 'Cannot update data.',
  'message.cannot_return_data': 'Data is not returnable.',
  'message.link_has_been_copied': 'Link has been copied.',
  'message.on': 'on',
  'link.show_more': 'Show more',
  'link.show_less': 'Show less',
  'link.payment': '{payment} Payment',
  'link.more': 'more',
  'link.add_email': 'Add email',
  'link.add_shipping_address': 'Add shipping address',

  'companies.company_list': 'Company List',
  'companies.add_company': 'Add Company',
  'companies.created_on': 'Created on ',
  'companies.as': 'As',
  'companies.are_you_sure_to_delete_this_company':
    'Are You sure to delete this company?\nAfter deleted, the data cannot be returned',
  'companies.choose_package': 'Choose a Package',
  'companies.full_name': 'Full Name',
  'companies.please_input_full_name': 'Please input full name',
  'companies.company_name': 'Company Name',
  'companies.please_input_company_name': 'Please input company name',
  'companies.role_on_company': 'Role on Company as',
  'companies.please_input_role_on_company': 'Please input role on company',
  'companies.number_of_employee': 'Number of Employee',
  'companies.please_input_number_of_employee': 'Please input number of company',
  'companies.please_input_email': 'Please input email',
  'companies.please_input_phone_number': 'Please input phone number',
  'companies.phone_number_must_08': 'Phone number must begin with 08',
  'companies.next_per_month': 'Next {price}/month',
  'companies.free_forever': 'Free Forever',

  'table.number': 'No',
  'table.show_data': 'Show Data',
  'table.rows_selected': '{count} rows selected',
  'table.min_one_column': 'Minimum show one column',
  'table.data_empty': 'Data Empty',
  'table.data_x_empty': '{name} Data Empty',
  'table.lets_create_your_first_x': 'Lets create your first {name}!',

  'nps.how_likely_you_recommend': 'How likely is that you would recommend Kledo to a friend?',
  'nps.not_likely': 'Not at all likely',
  'nps.very_likely': 'Very likely',
  'nps.please_mention_another':
    'Please mention something else you would like to say through comment box below',
  'nps.cannot_hide_nps': 'Cannot hide NPS',
  'nps.cannot_send_nps': 'Cannot send NPS',
  'nps.thank_you_your_input':
    'Thank you, your input is very meaningful. We analyze every input that comes in.',

  'sms.cannot_send_wa': 'Cannot send Whatsapp.',
  'sms.cannot_send_sms': 'Cannot send SMS.',
  'sms.destination_number': 'Destination Number',
  'sms.sms_has_been_sent_to': 'SMS has been sent to',
  'sms.sms_content': 'SMS Content',
  'sms.wa_content': 'Whatsapp Content',
  'sms.please_input_content': 'Please input SMS content.',
  'sms.please_input_wa_content': 'Please input Whatsapp content.',
  'sms.please_input_phone': 'Please input phone number.',

  'email.to': 'To',
  'email.from': 'From',
  'email.subject': 'Subject',
  'email.message': 'Message',
  'email.please_input_subject': 'Please input subject.',
  'email.please_input_message': 'Please input message.',
  'email.cannot_send_email': 'Cannot send email.',
  'email.email_has_been_sent_to': 'Email has been sent to',
  'email.please_confirm_email': 'Please confirm your email {email} before you can send an email.',
  'email.send_confirmation_email': 'Send Confirmation Email',
  'email.resend_confirmation_email': 'Resend Confirmation Email',
  'email.we_have_sent_confirmation_email':
    'We have sent a confirmation email to {email}. Please check the inbox and confirm your email to proceed.',
  'email.email_confirmation': 'Email Confirmation',
  'email.email_has_been_verified': 'Email {email} has been verified.',
  'email.cannot_verify_email': 'Cannot verify email.',
  'email.email_verification_failed': 'Email {email} verification failed.',
  'email.email_template': 'Email Template',
  'emails.edit_email_template': 'Edit Email Template',
  'emails.are_you_sure_revert_to_default': 'Are you sure to revert template to default?',
  'emails.revert_to_default': 'Revert to Default',
  'emails.template_name': 'Template Name',
  'emails.please_input_the_email_template_name': 'Please input the email template name.',
  'emails.max_100_characters': 'Max 100 characters.',
  'emails.only_alphanumeric_allowed': 'Only alphanumeric allowed',
  'emails.email_template_name': 'Email Template name',
  'emails.subject': 'Subject',
  'emails.please_input_the_email_template_subject': 'Please input the email template subject',
  'emails.max_100_characters': 'Max 100 characters.',
  'emails.subject': 'Subject',
  'emails.email_successfully_updated': 'Email successfully updated.',
  'emails.error_cannot_update_email_template': 'Cannot update email template.',
  'emails.email_successfully_reverted_to_default': 'Email successfully reverted to default.',
  'emails.error_cannot_revert_email_template': 'Cannot revert email template.',
  'emails.reply_email_address_successfully_updated': 'Reply email address successfully updated.',
  'emails.error_cannot_update_reply_email_address': 'Cannot update reply email address.',
  'emails.email_templates': 'Email Templates',
  'emails.reply_email_address': "Sender's Email Address",
  'emails.body': 'Body',
  'emails.rdw_tags_wrapper': 'rdw-tags-wrapper',
  'emails.insert_tagsment': 'Insert Tags',
  'emails.template_name': 'Template Name',
  'emails.when_an_agent_or_guest_replies_to_an_email,_this_is_the_email_their_reply_will_be_sent_to':
    "When you send an email, the following email will be used as sender's email:",
  'emails.display_name': 'Display Name',
  'emails.please_input_the_display_name': 'Please input the display name.',
  'emails.max_20_characters': 'Max 20 characters.',
  'emails.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'emails.display_name': 'Display name',
  'emails.email_address': 'Email Address',
  'emails.please_input_the_email_address': 'Please input the email address.',
  'emails.max_100_characters': 'Max 100 characters.',
  'emails.invalid_email_address_format': 'Invalid email address format.',
  'email.you_can_resend_an_email_after': 'You can resend confirmation email after {countDown}.',
  'emails.send_attachment_from_related_transaction': 'Send attachment from related transaction',
  'emails.send_attachment_from_related_transaction_help':
    'If related transaction has attachment, is it sent on this email too?',

  'wa.wa_template': 'Whatsapp Template',
  'wa.edit_wa_template': 'Edit Whatsapp Template',
  'wa.change_template': 'Change Template',
  'wa.error_cannot_update_template': 'Cannot change Whatsapp template',
  'wa.error_cannot_revert_template': 'Cannot revert Whatsapp template',

  'multiCurrency.multi_currency': 'Multi Currency',
  'multiCurrency.base_currency_cannot_changed_after_enabled':
    'Base currency cannot be changed after enabling multi currency',
  'multiCurrency.activate_multi_currency': 'Activate multi currency',
  'multiCurrency.set_rate_source': 'Set rate source',
  'multiCurrency.change_daily_rate': 'Change daily rate',
  'multiCurrency.add_currency': 'Add currency',
  'multiCurrency.in': 'In',
  'multiCurrency.rate': 'Rate',
  'multiCurrency.source': 'Source',
  'multiCurrency.base_currency': 'Base currency',
  'multiCurrency.currency': 'Currency',
  'multiCurrency.select_currency': 'Select currency',
  'multiCurrency.rate_source': 'Rate source',
  'multiCurrency.select_rate_source': 'Select rate source',
  'multiCurrency.edit_rate_for_date': 'Edit rate for date',
  'multiCurrency.input_date': 'Input date',
  'multiCurrency.rate_value': 'Rate value',
  'multiCurrency.rate_value_must_more_0': 'Rate value must more than 0',
  'multiCurrency.select_rate_value': 'Input rate value',
  'multiCurrency.rate_reference': 'Rate reference',
  'multiCurrency.please_select_base_currency_to_enable_multi':
    'Please select base currency to enable multi currency',
  'multiCurrency.please_select_currency_first': 'Please select currency first',
  'multiCurrency.change_rate_value': 'Change rate value',
  'multiCurrency.transaction_currency': 'Transaction currency',
  'multiCurrency.reset_rate': 'Reset rate',

  'referral.referral': 'Referral',
  'referral.read_more': 'Read more >>',
  'referral.referral_history': 'Referral History',
  'referral.your_referral_code': 'Your Referral Code',
  'referral.company': 'Company',
  'referral.code': 'Code',
  'referral.copy_code': 'Copy Code',
  'referral.add_code': 'Tambah Code',
  'referral.register': 'Register',
  'referral.paid': 'Paid',
  'referral.transfered': 'Transfered',
  'referral.approved': 'Approved',
  'referral.lead': 'Lead',
  'referral.cannot_get_referral': 'Cannot get referral',
  'referral.cannot_add_referral': 'Cannot add referral',
  'referral.cannot_delete_referral': 'Cannot delete referral',
  'referral.invite_your_friends': 'Invite Your Friends to Use Kledo',
  'referral.get_millions_per_month': 'Get millions per month',
  'referral.share_your_referral_code': 'Share your referral code',
  'referral.share_via_sosmed_or_anything':
    'Share via sosmed, email, whatsapp, or anything to your friends',
  'referral.discount_for_your_friends': '15% Discount for your friends',
  'referral.your_friends_will_get_discount':
    'Your friends will get 15% discount if register for 1 year',
  'referral.reward_per_referral': 'Reward Rp 150k per referral',
  'referral.get_reward_per_referral': 'Get reward of Rp 150k per referral',

  'customColumn.you_can_add_custom_column_on_invoice':
    'You can add custom column on invoice transaction data',
  'customColumn.name': 'Name',
  'customColumn.please_input_name': 'Please input name',
  'customColumn.data_type': 'Data Type',
  'customColumn.please_input_data_type': 'Please input data type',
  'customColumn.transaction_field': 'Transaction Field',
  'customColumn.item_field': 'Item Field',
  'customColumn.option': 'Option',
  'customColumn.please_input_option': 'Please input option',
  'customColumn.min_1_option': 'Minimal 1 option',
  'customColumn.add_more': 'Add more',
  'customColumn.what_difference_of_transaction_and_item_field':
    'What is the difference of transaction field and item field? Click here.',
  'customColumn.please_select_custom_column': 'Please select custom field',
  'customColumn.show_custom_column': 'Show Custom Field',
  'customColumn.hide_custom_column': 'Hide Custom Field',
  'customColumn.with_custom_fields_you_can_add_data_to_the_bill':
    "With custom fields, you can add data to the bill, or also add a column to the billing item according to your company's needs.",

  'marketplaceConnect.status': 'Status',
  'marketplaceConnect.marketplace': 'Marketplace',
  'marketplaceConnect.store': 'Shop',
  'marketplaceConnect.store_url': 'Store Url',
  'marketplaceConnect.data_integration_schedule': 'Data Integration Schedule',
  'marketplaceConnect.created_at': 'Created At',
  'marketplaceConnect.last_data_integrated': 'Last Data Sync',
  'marketplaceConnect.action': 'Action',
  'marketplaceConnect.choose_marketplace_to_connect_to_kledo':
    'Choose a marketplace to connect to Kledo',
  'marketplaceConnect.marketplace_not_supported_yet': 'This marketplace is not supported yet',
  'marketplaceConnect.setting': 'Setting',
  'marketplaceConnect.store_name': 'Store Name',
  'marketplaceConnect.delete_connection': 'Delete Connection',
  'marketplaceConnect.sync_now': 'Sync Now',
  'marketplaceConnect.sync_interval': 'Data Sync Interval',
  'marketplaceConnect.please_select_sync_interval': 'Please Select Data Sync Interval',
  'marketplaceConnect.payment_bank_account': 'Payment Bank Account',
  'marketplaceConnect.please_select_payment_bank_account': 'Please Select Payment Bank Account',
  'marketplaceConnect.shipping_cost_account': 'Shipping Cost Account',
  'marketplaceConnect.shipping_cost_account_tooltip':
    'Every transaction that the customer is paid shipping cost to marketplace, expense transaction will be created automatically.',
  'marketplaceConnect.please_select_shipping_cost_account': 'Please Select Shipping Cost Account',
  'marketplaceConnect.admin_cost_account': 'Admin Cost Account',
  'marketplaceConnect.admin_cost_account_tooltip':
    'Every transaction that has admin cost by marketplace, expense transaction will be created automatically.',
  'marketplaceConnect.please_select_admin_cost_account': 'Please Select Admin Cost AccountAdmin',
  'marketplaceConnect.warehouse': 'Warehouse',
  'marketplaceConnect.please_select_warehouse': 'Please Select Warehouse',
  'marketplaceConnect.tag': 'Tag',
  'marketplaceConnect.record_shipping_cost': 'Record Shipping Cost',
  'marketplaceConnect.is_shipping_cost_will_be_recorded_on_kledo':
    'Is shipping cost will be recorded on Kledo?',
  'marketplaceConnect.every_hour': 'Every {hour} hour',
  'marketplaceConnect.please_input_shop_name': 'Please input shop url',
  'marketplaceConnect.yeah_successful': 'Hurray successful!',
  'marketplaceConnect.next_activation_step_will_be_contacted':
    'For the next activation step, You will be contacted by out customer success team on work day and hour, by whatapp and email. Please wait.',
  'marketplaceConnect.if_product_not_track_empty_warehouse':
    'If your product is without stock tracking, please empty this warehouse field',
  'marketplaceConnect.using_marketplace_connect_shop_transaction_automatic_on_kledo':
    'By using marketplace connect, your shop transaction in marketplace will be automatically added to Kledo data, great right? Lets connect your marketplace with Kledo.',
  'marketplaceConnect.tutorial_video': 'Tutorial Video',
  'marketplaceConnect.record_transaction': 'Record Transaction',
  'marketplaceConnect.please_select_record_transaction': 'Please select Record Transaction',
  'marketplaceConnect.on_transaction_done': 'On transaction done',
  'marketplaceConnect.start_on_item_sent': 'Start on item sent',
  'marketplaceConnect.record_transaction_help':
    "If recorded on item sent, then on item's status on sent in kledo order transaction and the delivery will be recorded. And when transaction done, sales invoice transaction and the payment will be created. If recorded on transaction done, then transaction will be inputed only when transaction status in marketplace is done.",

  'posConnect.choose_pos_to_connect_to_kledo': 'Choose a POS to connect to Kledo',
  'posConnect.please_input_client_id': 'Please input Client ID',
  'posConnect.please_input_client_secret': 'Please input Client Secret',
  'posConnect.click_here_to_get_client_id_secret': 'Click {link} to get Client ID and Secret',
  'posConnect.payment_transaction_input_this_coa_account':
    'Payment transaction will be inputed in this COA account',
  'posConnect.default_customer': 'Default Customer',
  'posConnect.please_select_default_customer': 'Please select Default Customer',
  'posConnect.transaction_from_pos_inputed_as_this_customer':
    'Transaction from POS will be inputed as transaction from this customer',
  'posConnect.service_charge_product': 'Service Charge Product',
  'posConnect.please_select_service_charge_product': 'Please select Service Charge Product',
  'posConnect.if_service_charge_inputes_as_this_product':
    'If there is a service charge, will be inputed as a purchase on this product',
  'posConnect.custom_product': 'Custom Product',
  'posConnect.please_select_custom_product': 'Please select Custom Product',
  'posConnect.if_custom_product_inputes_as_this_product':
    'If there is a purchase on custom product, it will be inputed as this product',
  'posConnect.your_pos_has_been_connected':
    'Your POS has been connected to Kledo. Data from POS will automatically sync to Kledo periodically',

  'consolidation.you_must_have_more_than_one_companies':
    'To enable consolidation feature, you must have more than one companies with adimistrator access.',
  'consolidation.if_you_have_more_companies_you_may_activate':
    'If you have more than one companies, you may activate consolidation feature. This feature will automatically compile report from each companies into a consolidation report.',
  'consolidation.you_must_have_admin_each_companies':
    'You must have Administrator access in each companies that will be compiled.',
  'consolidation.please_select_companies_that_will_be_compiled':
    'Please select companies that will be compiled to consolidation report',
  'consolidation.please_select_coa_accounts':
    'Please select COA accounts to be compiled in to consolidation reports',
  'consolidation.please_select_all_companies_that_will_be_compiled':
    'Please select all companies that will be compiled to consolidation report',
  'consolidation.must_enable_2_companies': 'You must enable at least 2 companies',
  'consolidation.cannot_select_company': 'Cannot select company',
  'consolidation.add_company': 'Add Company',
  'consolidation.automatic_compilation': 'Automatic Compilation',
  'consolidation.compile': 'Compile',
  'consolidation.cannot_compile': 'Cannot Compile',
  'consolidation.code': 'Code',
  'consolidation.name': 'Name',
  'consolidation.category': 'Category',
  'consolidation.account': 'Account',
  'consolidation.account_total': 'Account Total',
  'consolidation.activate_consolidation_feature': 'Activate consolidation feature',
  'consolidation.cannot_activate_consolidation': 'Cannot activate consolidation feature',
  'consolidation.compilation_saved':
    'Account compilation saved, click button below to go to consolidation report',
  'consolidation.see_report': 'See Report',
  'consolidation.confirm_auto_compile':
    'Auto compile will automatically compile account with the same code to consolidation account. Are you sure to auto compile?',
  'consolidation.code_already_exist': 'Code already exist, please change to another',

  'translation.translation': 'Translation',
  'translation.title.reset': 'Are you sure to reset?',
  'translation.reset': 'Reset Translation',
  'translation.error_cannot_edit': 'Cannot Edit Translation',

  'audit.audit': 'Audit',
  'audit.show_audit': 'Show Audit',
  'audit.date': 'Date',
  'audit.description': 'Description',
  'audit.ip': 'IP',
  'audit.by': ' by ',
  'audit.user': 'User',
  'audit.item': 'Item',
  'audit.all_item': 'All items',

  'appSetting.apps': 'Apps',
  'appSetting.date_created': 'Date Created',
  'appSetting.app_name': 'App Name',
  'appSetting.client_id': 'Client ID',
  'appSetting.client_id_has_been_copied': 'Client ID has been copied',
  'appSetting.secret_has_been_copied': 'Secret has been copied',
  'appSetting.endpoint_has_been_copied': 'API Endpoint URL has been copied',
  'appSetting.documentation_has_been_copied': 'API Documentation has been copied',
  'appSetting.api_documentation_endpoint': 'API Documentation Endpoint',
  'appSetting.please_input_name': 'Please input app name',
  'appSetting.please_redirect_uri': 'Please input redirect URI',
  'appSetting.please_valid_uri': 'Please input valid URL',
  'appSetting.error_cannot_get': 'Cannot get apps',
  'appSetting.error_cannot_create': 'Cannot create apps',
  'appSetting.error_cannot_edit': 'Cannot edit apps',
  'appSetting.error_cannot_delete': 'Cannot delete apps',
  'appSetting.app_has_been_created_make_sure_save_secret':
    'New app has been created, please please save secret code below before going to another page.',
  'appSetting.secret_code_just_shown_once':
    'Secret code just shown once. Save this code, because after this you cannot see this secret code again.',
  'appSetting.integrate_your_app_with_kledo': 'Integrate your application with Kledo',
  'appSetting.now_app_can_be_integrated_with_kledo':
    'Now your application, website, or software can be integrated with Kledo.',
  'appSetting.for_more_details_lets_see_the_video': 'For more details, lets watch the video',
  'appSetting.watch_video': 'Watch video',

  'settings.shipping_comps': 'Manage shipping companies',
  'settings.businessflow': 'Manage your business flow',
  'settings.company': "Company's data and logo",
  'settings.taxes': 'Manage taxes',
  'settings.terms': 'Manage payment terms',
  'settings.billing': 'Upgrade and renew Kledo plan',
  'settings.automatic_sequencing': 'Manage invoice automatic sequencing',
  'settings.lock_date': 'Lock transaction data on specific date',
  'settings.layout_invoice': 'Manage invoice layout',
  'settings.layout_reporting': 'Manage finance report layout',
  'settings.users': 'Invite staff and colleagues to use Kledo',
  'settings.roles': 'Manage users role',
  'settings.my_profile': 'Manage profile and change password',
  'settings.setup': 'Remove demo data and start from scratch',
  'settings.translation': "Change language on Kledo's menu",
  'settings.audits': 'Watch data log',
  'settings.units': 'Manage product units',
  'settings.consolidation': 'Manage companies and account for consolidation reportings',
  'settings.tags': 'Manage tag to separate branch/department/division reporting',
  'settings.email_template': 'Manage email layouts',
  'settings.apps': 'Connect your Apps with Kledo API',
  'settings.account_mappings': 'CoA Account mapping for transactions on Kledo',
  'settings.marketplace_connect': 'Automatically sync Shopee, Tokopedia, and Lazada to Kledo',
  'settings.whatsapp_template': 'Manage default template invoice delivery via Whatsapp',
  'settings.notification': 'Manage notification that will be sent to your email',
  'settings.notification_title': 'Notification',
  'settings.custom_columns': 'Custom data on your invoice',
  'settings.woocommerce': 'Automatically sync Wordpress & Woocommerce to Kledo',
  'settings.pos_connect': 'Automatically sync POS to Kledo',
  'settings.multi_currency': 'Manage multi currency usage',
  'settings.sales_commission': 'Manage sales commission each sales team',

  'billing.error_cannot_upgrade': 'Cannot upgrade',
  'billing.error_cannot_renew': 'Cannot renew',
  'billing.error_cannot_delete': 'Cannot delete',
  'billing.error_cannot_get_addon': 'Cannot get addon',
  'billing.error_cannot_add_addon': 'Cannot add addon',
  'billing.account_expired': 'Your account has expired, please renew now',
  'billing.expired': 'Your account has expired, {setup} to renew',
  'billing.expired_link': 'click here',
  'billing.billing': 'Billing',
  'billing.thankyou': 'Thankyou',
  'billing.upgrade': 'Upgrade',
  'billing.renew': 'Renew',
  'billing.addon': 'Addon',
  'billing.show_addon': 'Show Addon',
  'billing.hide_addon': 'Hide Addon',
  'billing.upgrade_now': 'Upgrade Now',
  'billing.renew_now': 'Renew Now',
  'billing.order_now': 'Order Now',
  'billing.upgrade_plan': 'Upgrade Plan',
  'billing.renew_plan': 'Renew Plan',
  'billing.subscription_plan': 'Subscription Plan',
  'billing.plan': 'Plan',
  'billing.forever': 'Forever',
  'billing.payment_history': 'Payment History',
  'billing.active_until': 'Active until',
  'billing.order_date': 'Order Date',
  'billing.description': 'Description',
  'billing.period': 'Period',
  'billing.payment_date': 'Payment Date',
  'billing.total': 'Total',
  'billing.status': 'Status',
  'billing.select_plan': 'Select a plan',
  'billing.select_contract_duration': 'Select the contract duration',
  'billing.total_bill': 'Total bill',
  'billing.please_add_an_addon': 'Please add one of the addon',
  'billing.please_select_a_plan': 'Please select a plan',
  'billing.please_select_the_contract_duration': 'Please select the contract duration',
  'billing.get_10_discount_for_1_year_payment': 'Get 10% discount for 1 year payment',
  'billing.bill_email_has_been_sent_to_your_email':
    'Bill email has been sent to your email address.',
  'billing.please_make_a_payment_of': 'Please make a payment of',
  'billing.by_click_button_below': 'by clicking button below',
  'billing.pay_now': 'Pay Now',
  'billing.pay': 'Pay',
  'billing.payment_failed': 'Payment Failed',
  'billing.please_contact_kledo_if_difficulity_on_payment':
    'Please contact tim Hebat Kledo if you have a difficulity on payment.',
  'billing.payment_success': 'Payment Succeed',
  'billing.your_account_activated_thanks': 'Your account has been activated. Thank You.',
  'billing.payment_pending': 'Waiting for Payment',
  'billing.your_account_wiil_be_active_after_payment':
    'Your account will be active after payment. If you have made a payment, please click the following button. Thank You.',
  'billing.to': 'to',
  'billing.bank': 'Bank',
  'billing.account_number': 'Account Number',
  'billing.after_make_a_payment_please_confirm_via_whatsapp':
    'After make a payment, please confirm via whatsapp at',
  'billing.back_to_billing': 'Back to Billing',
  'billing.feature_available_in_pro_plan': '{feature} feature available in PRO plan',
  'billing.feature_available_in_elite_plan': '{feature} feature available in Elite plan',
  'billing.upgrade_to_add': 'Your {feature} quota is exhausted, please add an addon',
  'billing.marketplace_connect_available_in_elite':
    '{feature} Available in Elite Package, Please Upgrade',
  'billing.warehouse_and_inventory': 'Warehouse & Inventory',
  'billing.track_product': 'Track product',
  'billing.attachment': 'Attachment',
  'billing.dashboard_layout_setting': 'Dashboard layout setting',
  'billing.feature_locked': 'Feature Locked',
  'billing.your_free_trial_active': 'Your Free Trial account has been activated.',
  'billing.account_will_active_until': 'The account will be active until',
  'billing.token_required_to_make_payment': 'Token is required to make a payment',

  'dashboard.hello_name': 'Hello {name},',
  'dashboard.schedule_a_demo_and_consult':
    "Let's schedule a free online demo and consultation with tim hebat Kledo.",
  'dashboard.demo_online_with_zoom':
    'The demo will be conducted online using Zoom 1 on 1 for 45 minutes.',
  'dashboards.dashboard': 'Dashboard',
  'dashboards.select_company': 'Select a Company: ',
  'dashboards.gross_profit': 'Gross profit',
  'dashboards.net_profit': 'Net Profit',
  'dashboards.net_profit_for_this_year': 'Net profit for this year',
  'dashboards.sales_value_this_year': 'Sales value this year',
  'dashboards.sales': 'Sales',
  'dashboards.awaiting_payment': 'Awaiting payment',
  'dashboards.overdue': 'Overdue',
  'dashboards.sales_this_day': 'Sales this day',
  'dashboards.sales_this_month': 'Sales this month',
  'dashboards.sales_this_year': 'Sales this year',
  'dashboards.this_month': 'This month',
  'dashboards.ytd': 'YTD',
  'dashboards.total': 'Total',
  'dashboards.are_you_sure_delete': 'Are you sure delete?',
  'dashboards.modif': 'Modif',
  'dashboards.exit': 'Exit',
  'dashboards.select_account': 'select account',
  'dashboards.please_select_account': 'Please select account',
  'dashboards.in': 'In',
  'dashboards.out': 'Out',
  'dashboards.net': 'Net',
  'dashboards.edit': 'Edit',
  'dashboards.other': 'Other',
  'breadcrumb.sales': 'Sales',
  'breadcrumb.purchases': 'Purchase',
  'breadcrumb.conversionbalances': 'Conversion Balances',
  'breadcrumb.conversiondate': 'Conversion Date',
  'breadcrumb.add': 'Add',
  'breadcrumb.edit': 'Edit',
  'breadcrumb.detail': 'Detail',

  'payments.add_a_payment_method': 'Add A Payment Method',
  'payments.delete_a_payment_method': 'Delete A Payment Method',
  'payments.are_you_sure_delete': 'Are you sure to delete?',
  'payments.payment_metod': 'Payment Metod',
  'payments.please_select_a_payment_to_delete': 'Please select a payment to delete',
  'payments.select_a_payment_method': 'Select a payment method',
  'payments.edit_payment_method': 'Edit Payment Method',
  'payments.payment_method': 'Payment Method',
  'payments.please_input_the_payment_name': 'Please input the payment name.',
  'payments.max_20_characters': 'Max 20 characters.',
  'payments.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'payments.name': 'Name',
  'payments.payment': 'Payment',
  'payments.payment_methods': 'Payment Methods',
  'payments.successfully_added': 'successfully added.',
  'payments.error_cannot_add': 'Cannot add payment',
  'payments.successfully_deleted': 'successfully deleted.',
  'payments.error_cannot_delete': 'Cannot delete payment.',
  'payments.payment_successfully_updated': 'Payment successfully updated.',
  'payments.error_cannot_update_payment': 'Cannot update payment.',
  'payments.payment_moved_successfully': 'Payment moved successfully.',
  'payments.error_cannot_move_the_payment': 'Cannot move the payment.',
  'payments.change_status_payment_successfully': 'Change status payment successfully.',
  'payments.error_cannot_change_status_the_payment': 'Cannot change status the payment.',
  'payments.must_have_active_payment': 'Must have at least one active payment.',

  'profile.profile': 'Profile',
  'profile.image_profile': 'Profile Image',
  'profile.my_profile': 'My Profile',
  'profile.old_password': 'Old Password',
  'profile.please_input_the_old_password': 'Please input the old password.',
  'profile.new_password': 'New Password',
  'profile.please_input_the_new_password': 'Please input the new password.',
  'profile.max_100_characters': 'Max 100 characters.',
  'profile.confirm_new_password': 'Confirm New Password',
  'profile.please_input_the_confirm_new_password': 'Please input the confirm new password.',
  'profile.email_or_username': 'Email / Username',
  'profile.please_input_the_email/username': 'Please input the email/username.',
  'profile.the_input_is_not_valid-e-mail': 'The input is not valid E-mail.',
  'profile.full_name': 'Full Name',
  'profile.please_input_the_full_name': 'Please input the full name.',
  'profile.0nly_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'profile.full_name': 'Full Name',
  'profile.phone_number': 'Phone Number',
  'profile.please_input_the_phone_number': 'Please input the phone number.',
  'profile.max_20_characters': 'Max 20 characters.',
  'profile.only_number_allowed': 'Only number allowed.',
  'profile.phone_number': 'Phone Number',
  'profile.user_profile_successfully_updated': 'User profile successfully updated.',
  'profile.error_cannot_update_user_profile': 'Cannot update user profile.',
  'profile.user_password_successfully_updated': 'User password successfully updated.',
  'profile.error_cannot_update_user_password': 'Cannot update user password.',
  'profile.change_password': 'Change Password',
  'profile.two_passwords_that_you_enter_is_inconsistent_':
    'Two passwords that you enter is inconsistent.',
  'profile.password_atleast_8_characters_atleast_1_uppercase_and_atleast_1_digit':
    'Password atleast 8 characters, atleast 1 uppercase and atleast 1 digit.',
  'profile.password_atleast_6_characters': 'Password atleast 6 characters.',
  'profile.logout_from_all_device': 'Logout from all device',
  'profile.cannot_logout_from_all_device': 'Cannot logout from all device',

  'roles.add_role': 'Add Role',
  'roles.delete_role': 'Delete Role',
  'roles.select_role_to_delete': 'Select Role to Delete',
  'roles.please_select_a_role': 'Please select a role.',
  'roles.select_role': 'Select role',
  'roles.edit_role': 'Edit Role',
  'roles.role': 'Role',
  'roles.roles': 'Roles',
  'roles.please_input_the_role_name': 'Please input the role name.',
  'roles.max_100_characters': 'Max 100 characters.',
  'roles.superadmin': 'Superadmin',
  'roles.error_cannot_get_roles': 'Cannot get Roles.',
  'roles.successfully_added': 'successfully added.',
  'roles.error_cannot_add': 'Cannot add',
  'roles.role_successfully_updated': 'Role successfully updated.',
  'roles.error_cannot_update_role': 'Cannot update role.',
  'roles.successfully_deleted': 'successfully deleted.',
  'roles.error_cannot_delete': 'Cannot delete',
  'roles.role_moved_successfully': 'Role moved successfully.',
  'roles.error_cannot_move_the_channel': 'Cannot move the channel.',
  'roles.search_by_role_name': 'Search by role name',
  'roles.name': 'Role name',
  'roles.invoices_payment': 'Payment',
  'roles.orders_payment': 'Payment',
  'roles.purchase_invoices_payment': 'Payment',
  'roles.purchase_orders_payment': 'Payment',
  'roles.product_show_purchase_price': 'Show Purchase Price',
  'roles.product_show_sale_price': 'Show Sale Price',
  'roles.product_show_hpp': 'Show COGS',
  'roles.inventory_show_hpp': 'Show Price',
  'roles.reports_finance': 'Finance',
  'roles.reports_accounting': 'Accounting',
  'roles.reports_sales': 'Sales',
  'roles.reports_purchases': 'Purchases',
  'roles.reports_expenses': 'Expenses',
  'roles.reports_taxes': 'Taxes',
  'roles.reports_inventory': 'Inventory',
  'roles.reports_fixed_assets': 'Fixed Assets',
  'roles.reports_budget': 'Budget',
  'roles.reports_consolidation': 'Consolidation',
  'roles.apikeys': 'API Keys',
  'roles.sales_other_user_access': 'Other User Access',
  'roles.purchase_other_user_access': 'Other User Access',
  'roles.expenses_other_user_access': 'Other User Access',
  'roles.product_show_manufacture_raw': 'Show Manufacture Raw Materials',
  'roles.show_purchase_price': 'Show Purchase Price',
  'roles.show_sales_price': 'Show Sales Price',
  'roles.show_cogs': 'Show COGS',
  'roles.show_manufaktur_materials': 'Show Manufaktur Materials',

  'shipComp.shipping_company': 'Shipping Companies',
  'shipComp.name': 'Name',
  'shipComp.status': 'Status',
  'shipComp.error_cannot_move': 'Cannot add shipping company.',
  'shipComp.error_cannot_add': 'Cannot add shipping company.',
  'shipComp.error_cannot_update': 'Cannot update shipping company.',
  'shipComp.error_cannot_delete': 'Cannot delete shipping company.',
  'shipComp.error_cannot_change_status': 'Cannot change status shipping company.',
  'shipComp.please_select_to_delete': 'Please select a Shipping Company to delete',
  'shipComp.select': 'Select a Shipping Company',
  'shipComp.please_input_the_name': 'Please input the shipping company name.',
  'shipComp.must_have_active_shipcomp': 'Must have at least one active shipping company.',

  'report.access_denied': 'Access Denied',
  'report.profitability_per_invoice': 'Profitability per Invoice',
  'report.show_account': 'Show Account',
  'report.show_zero_account_balance': 'Show zero account balance',
  'report.account_not_compiled':
    'Some accounts are not compiled yet, please {link} to compile the accounts',
  'report.month': 'Month',
  'report.date': 'Date',
  'report.general_ledger_reporting': 'General Ledger',
  'report.account': 'Account',
  'report.general_ledger': 'General Ledger',
  'report.source': 'Source',
  'report.description': 'Description',
  'report.reference': 'Reference',
  'report.debit': 'Debit',
  'report.credit': 'Credit',
  'report.ytd_debit': 'YTD Debit',
  'report.ytd_credit': 'YTD Credit',
  'report.running_balance': 'Running Balance',
  'report.opening_balance': 'Opening Balance',
  'report.closing_balance': 'Closing Balance',
  'report.total': 'Total',
  'report.total_this_page': 'Total This Page',
  'report.total_all_page': 'Total All Page',
  'report.posted_on': 'Posted on',
  'report.no_data': 'No Data',
  'report.journal_reporting': 'Journal',
  'report.journal_report': 'Journal',
  'report.journal': 'Journal',
  'report.account_transaction_reporting': 'Account Transaction',
  'report.bank_summary': 'Bank Summary',
  'report.bank_account': 'Bank Account',
  'report.cash_received': 'Cash Received',
  'report.cash_spent': 'Cash Spent',
  'report.sales_tax': 'Sales Tax',
  'report.net': 'Net',
  'report.tax': 'Tax',
  'report.customer_invoice': 'Customer Invoice',
  'report.invoice_number': 'Invoice Number',
  'report.to': 'To',
  'report.from': 'From',
  'report.due_date': 'Due Date',
  'report.date_and_time': 'Date & time',
  'report.invoice_date': 'Invoice Date',
  'report.invoice_total': 'Invoice Total',
  'report.paid': 'Paid',
  'report.due': 'Due',
  'report.all': 'All',
  'report.status': 'Status',
  'report.income_per_customer': 'Income Per Customer',
  'report.customer': 'Customer',
  'report.income': 'Income',
  'report.sales_per_product': 'Sales Per Product',
  'report.product_name': 'Product Name',
  'report.current_unit_price': 'Current Unit Price',
  'report.quantity_sold': 'Quantity Sold',
  'report.quantity_bought': 'Quantity Bought',
  'report.average': 'Average',
  'report.purchases': 'Purchases',
  'report.purchases_per_vendor': 'Purchases Per Vendor',
  'report.purchases_per_product': 'Purchases Per Product',
  'report.expense_per_contact': 'Expense Per Contact',
  'report.supplier': 'Supplier',
  'report.supplier_invoice': 'Supplier Invoice',
  'report.purchase_value': 'Purchase Value',
  'report.aged_receivable': 'Aged Receivable',
  'report.aged_receivable_not_yet_due': 'Not Yet Due',
  'report.aged_receivable_detail': 'Aged Receivable Detail',
  'report.aged_payable': 'Aged Payable',
  'report.aged_payable_detail': 'Aged Payable Detail',
  'report.expense_claim': 'Expense Claim',
  'report.expense_claim_detail': 'Expense Claim Detail',
  'report.trial_balance': 'Trial Balance',
  'report.profit_and_loss': 'Profit and Loss',
  'report.sales': 'Sales',
  'report.other_revenue': 'Other Revenue',
  'report.cost_of_sales': 'Cost of Sales',
  'report.total_cost_of_sales': 'Total Cost of Sales',
  'report.expenses': 'Expenses',
  'report.operating_expenses': 'Operating Expenses',
  'report.total_operating_expenses': 'Total Operating Expenses',
  'report.other_expenses': 'Other Expenses',
  'report.gross_profit': 'Gross Profit',
  'report.trading_income': 'Trading Income',
  'report.total_trading_income': 'Total Trading Income',
  'report.net_profit': 'Net Profit',
  'report.profitloss_comprehensive_income': 'Profit/Loss Comprehensive Income',
  'report.total_comprehensive_income_this_period': 'Total Comprehensive Income This Period',
  'report.reports': 'Reports',
  'report.financial': 'Financial',
  'report.balance_sheet': 'Balance Sheet',
  'report.compare': 'Compare',
  'report.period': 'Period',
  'report.cash_summary': 'Cash Summary',
  'report.executive_summary': 'Executive Summary',
  'report.sales_tax_report': 'Sales Tax',
  'report.accounting': 'Accounting',
  'report.aged_receivables': 'Aged Receivables',
  'report.customer_invoice_report': 'Customer Invoice',
  'report.aged_payables': 'Aged Payables',
  'report.supplier_invoice_report': 'Supplier Invoice',
  'report.purchases_per_supplier': 'Purchases per Supplier',
  'report.cash_flow': 'Cash Flow',
  'report.net_cash_flows_from_': 'Net cash flows from ',
  'report.direct_method': 'Direct Method',
  'report.indirect_method': 'Indirect Method',
  'report.please_select': 'Please select',
  'report.cash_and_cash_equivalent': 'Cash and Cash Equivalent',
  'report.cash_and_cash_equivalent_at_beginning_period':
    'Cash and cash equivalent at beginning of period',
  'report.cash_and_cash_equivalent_at_end_period': 'Cash and cash equivalent at end of period',
  'report.net_change_in_cash_for_period': 'Net change in cash for period',
  'report.net_cash_flows': 'Net cash flows',
  'report.equity_movement': 'Movement in Equity',
  'report.beginning': 'Beginning',
  'report.ending': 'Ending',
  'report.net_movement': 'Net Movement',
  'report.expense_claim_customer': 'Customer',
  'report.spend': 'Expenses',
  'report.to_customer': 'To',
  'report.account_transactions': 'Account Transactions',
  'report.account_transactions_title': '{account} Transactions',
  'report.fixed_asset': 'Fixed Asset',
  'report.fixed_asset_detail': 'Fixed Asset Detail',
  'report.fixed_asset_summary': 'Fixed Asset Summary',
  'report.asset_name': 'Asset Name',
  'report.number': 'Number',
  'report.purchase_date': 'Purchase Date',
  'report.purchase_price': 'Purchase Price',
  'report.depreciation': 'Depreciation',
  'report.book_value': 'Book Value',
  'report.witholding_tax': 'Witholding Tax',
  'report.inventory': 'Inventory',
  'report.inventory_summary': 'Inventory Summary',
  'report.inventory_stock_movement': 'Inventory Stock Movement',
  'report.warehouse_stock_summary': 'Warehouse Stock Summary',
  'report.warehouse_stock_movement': 'Warehouse Stock Movement',
  'report.warehouse': 'Warehouse',
  'report.category': 'Category',
  'report.select_category': 'Select Category',
  'report.code': 'Code',
  'report.qty': 'Quantity',
  'report.opening_qty': 'Opening Quantity',
  'report.qty_movement': 'Quantity Movement',
  'report.closing_qty': 'Closing Quantity',
  'report.opening_value': 'Opening Value',
  'report.value_movement': 'Value Movement',
  'report.value': 'Value',
  'report.closing_value': 'Closing Value',
  'report.avg_cost': 'Average Cost',
  'report.unit_price': 'Unit Price',
  'report.product_value': 'Product Value',
  'report.product': 'Product',
  'report.until': 'until',
  'report.budget': 'Budget',
  'report.actual': 'Actual',
  'report.movement': 'Movement',
  'report.price': 'Price',
  'report.unpaid': 'Unpaid',
  'report.grand_total': 'Grand Total',
  'report.delivery': 'Delivery',
  'report.please_input_name': 'Please input name',
  'report.start_period': 'Start Period',
  'report.please_input_start_period': 'Please input start period',
  'report.no_of_period': 'No. of Period',
  'report.last_period': 'Last Period',
  'report.last_n_period': 'Last {count} Period',
  'report.cannot_save_budget': 'Cannot save budget',
  'report.cannot_delete_budget': 'Cannot delete budget',
  'report.interval': 'Interval',
  'report.apply': 'Apply',
  'report.delete_figures_this_column': 'Delete all figures from this column',
  'report.apply_figures_to_following_month':
    'Apply the figures from this month to the following months',
  'report.adjust_figures_each_month_amount':
    'Adjust the figures for each subsequent month to this amount',
  'report.adjust_figures_each_month_percent':
    'Adjust the figures for each subsequent month with this percentage',
  'report.max_n_period_to_print': 'Maximum {count} period to print',
  'report.total_income': 'Total Income',
  'report.gross_profit': 'Gross Profit',
  'report.operating_costs': 'Operating Costs',
  'report.net_profit': 'Net Profit',
  'report.search_by_product': 'Search by product',
  'report.shipping_amount': 'Shipping Amount',
  'report.invoice_total': 'Invoice Total',
  'report.shipping_cost_total': 'Shipping Cost Total',
  'report.shipping_cost_per_expedition': 'Shipping Cost per Expedition',
  'report.sales_per_sales_person': 'Sales per Sales Person',
  'report.production_report': 'Production Report',
  'report.finished_product': 'Finished Product',
  'report.percentage': 'Percentage',
  'report.transaction_total': 'Transaction Total',
  'report.receivable_payable_contact': 'Receivable Payable per Contact',
  'report.sort_by': 'Sort by',
  'report.invoice_payment': 'Invoice Payment',
  'report.purchase_invoice_payment': 'Purchase Invoice Payment',
  'report.first_payment_date': 'First Payment Date',
  'report.paid_date': 'Paid Date',
  'report.product_profitability': 'Product Profitability',
  'report.total_profit': 'Total Profit',
  'report.profit_margin': 'Profit Margin',
  'report.avg_sell_price': 'Avg Sell Price',
  'report.hpp_per_unit': 'COGS Per Unit',
  'report.stock_in': 'Stock In',
  'report.stock_out': 'Stock Out',
  'report.product_sales_per_customer': 'Product Sales Per Customer',
  'report.product_purchase_per_supplier': 'Product Purchase Per Supplier',
  'report.total_quantity_product': 'Total Quantity Product',
  'report.product_purchases_per_vendor': 'Product Purchases per Vendor',
  'report.vendor': 'Vendor',
  'report.sales_per_product_category': 'Sales Per Product Category',

  'report.total_price': 'Total Price',

  'report.view_price': 'View Price',
  'report.include_unit_conversion': 'Show unit conversions',
  'trialBalance.show_balance': 'Show Balance',

  'attachment.file_name': 'File Name',
  'attachment.search_by_file_name': 'Search by file name',

  'setup.demo_data_successfully_cleared': 'Demo data successfully cleared.',
  'setup.error_demo_data_failure_to_cleared_': 'Failed to clear demo data.',
  'setup.demo_data_successfully_finished': 'Setup finished successfully.',
  'setup.error_demo_data_failure_to_finished': 'Failed to finish setup.',
  'setup.setup_clear_demo_data': 'Setup Clear Demo Data',
  'setup.you_are_using_a_demo_of_kledo_at_the_moment':
    'You are using a demo of kledo at the moment.',
  'setup.click_button_below_to_clear_the_test_data_and_start_setting_up_your_property':
    'Click button below to clear the test data and start setting up your company',
  'setup.are_you_sure_that_you_want_to_clear_demo_': 'Are you sure to clear demo data?',
  'setup.this_will_remove_all_rooms_room_types_rates_and_bookings':
    'This will remove all data, and cannot be undone.',
  'setup.clear_test_data_and_setup': 'Clear test data and setup',
  'setup.clear_demo_data': 'Clear demo data',
  'setup.finish': 'Finish',
  'setup.finish_setup': 'Finish setup',
  'setup.demo': 'The data presented here is dummy data. When ready, {setup} to reset the data.',
  'setup.demo_link': 'click here',
  'setup.trial': 'Your free trial access will be ended in {days} days. {setup}.',
  'setup.go_to_dashboard': 'Go to dashboard',
  'setup.setup_complete':
    'Demo data has been deleted and setup is complete, now you can start using Kledo.',
  'setup.input_your_cash_and_account_bank_name': 'Enter your company cash & bank account name',
  'setup.input_your_company_name': 'Enter your company data',
  'setup.company_logo': 'Company Logo',
  'setup.cash': 'Cash',
  'setup.bca_account': 'BCA Account',
  'setup.mandiri_account': 'Mandiri Account',
  'setup.please_input_bank_account_name_or_delete_this_field':
    'Input bank account name or delete this field.',

  'accountMapping.account_mappinng': 'Account Mapping',

  'users.hello_please_select_company_to_proceed':
    'Hello {name}, please select a company to proceed',
  'users.please_select_company_to_proceed': 'please select a company to proceed',
  'users.hello': 'Hello',
  'users.you_are_invited_to_join': 'You are invited to join',
  'users.do_you_agree_to_join': 'Do You agree to join',
  'users.all_users': 'All users',
  'users.select_user': 'Select user',
  'users.yes': 'Yes',
  'users.no': 'No',
  'users.add_user': 'Add User',
  'users.delete_user': 'Delete User',
  'users.only_displays_users_other_than_you': 'Only displays users other than you',
  'users.please_select_a_user': 'Please select a user.',
  'users.select_user': 'Select user',
  'users.select_user_to_delete': 'Select User to Delete',
  'users.edit_user': 'Edit User',
  'users.name': 'Name',
  'users.email': 'Email',
  'users.role': 'Role',
  'users.roles': 'Roles',
  'users.please_input_the_email_address': 'Please input the email address.',
  'users.max_100_characters': 'Max 100 characters.',
  'users.invalid_email_address_format': 'Invalid email address format.',
  'users.please_select_a_role': 'Please select a role.',
  'users.select_roles': 'Select roles',
  'users.pending_invitation': '(Pending invitation)',
  'users.user': 'User',
  'users.successfully_added': 'successfully added.',
  'users.error_cannot_add': 'Cannot add',
  'users.user_successfully_updated': 'User successfully updated.',
  'users.error_cannot_update_user': 'Cannot update user.',
  'users.successfully_deleted': 'successfully deleted.',
  'users.error_cannot_delete': 'Cannot delete',
  'users.search_by_name_or_email': 'Search by name or email',
  'users.restore_password': 'Restore Password',
  'users.enter_the_email_address_associated_with_your_account_and_well_email_you_a_link_to_reset_your_password':
    "Enter the email address associated with your account, and we'll email you a link to reset your password",
  'users.back_to_login': 'Back to login',
  'users.send_reset_link': 'Send Reset Link',
  'users.please_check_your_email_inbox_and_follow_the_instruction_to_reset_your_password_if_email_hasnt_received_please_check_the_bulk_and_spam_folder':
    "Please check your email inbox and follow the instruction to reset your password. If email hasn't received please check the bulk and spam folder",
  'users.please_input_your_email': 'Please input your email',
  'users.the_input_is_not_valid_email_': 'The input is not valid E-mail.',
  'users.welcome_to_kledo': 'WELCOME TO KLEDO',
  'users.welcome_to2': 'Welcome to ',
  'users.phone': 'Phone',
  'users.password': 'Password',
  'users.confirm_new_password': 'Confirm New Password',
  'users.activate': 'Activate',
  'users.invalid_invitation_token': 'Invalid invitation token',
  'users.your_invitation_token_is_invalid_please_check_it_again_and_reload_the_page':
    'Please check it again and reload the page',
  'users.please_fill_the_register_form': 'Please fill the register form',
  'users.please_input_your_email_address': 'Please input your e-mail address',
  'users.please_input_your_name': 'Please input your name',
  'users.please_input_your_phone': 'Please input your phone',
  'users.only_number_allowed': 'Only number allowed.',
  'users.please_input_new_password': 'Please input New Password',
  'users.min_8_characters': 'Min 8 characters.',
  'users.password_atleast_8_characters_atleast_1_uppercase_and_atleast_1_digit':
    'Password atleast 8 characters, atleast 1 uppercase and atleast 1 digit.',
  'users.two_passwords_that_you_enter_is_inconsistent_':
    'Two passwords that you enter is inconsistent.',
  'users.welcome_to': 'WELCOME TO',
  'users.please_log_in': 'Please log in',
  'users.you_have_logged_out_automatically':
    'You have been logged out automatically, please continue to proceed.',
  'users.remember_me': 'Remember me',
  'users.forgot_password_': 'Forgot password?',
  'users.login': 'Login',
  'users.login_with_google': 'Sign in with Google',
  'users.login_failed': 'Login Failed',
  'users.go_back_to_home_page': 'Go back to the home page',
  'users.failed_to_connect_to_kledo': 'Failed to connect to Kledo server',
  'users.please_check_your_internet_connection_please_contact_wa':
    'Please check your internet connection. If you still have a problem, please contact via Whatsapp on 62-8238-333-4000',
  'users.please_input_your_password': 'Please input your password',
  'users.profile': 'Profile',
  'users.reset_password': 'Reset Password',
  'users.request_new_password_reset': 'Request new password reset',
  'users.new_password': 'New Password',
  'users.invalid_password_reset_token': 'Invalid password reset token',
  'users.your_password_reset_token_is_invalid_please_check_it_again_and_reload_the_page':
    'Your password reset token is invalid, please check it again and reload the page',
  'users.users': 'Users',
  'users.information': 'Information',
  'users.information_invitation_email':
    "A invitation email already sent to {email} . Please ask your colleague to follow the instruction in the email to activate the account. If email hasn't received please check the bulk and spam folder.",
  'users.create_kledo_accounts': 'Create Kledo Accounts',
  'users.congratulation_': 'Congratulation!',
  'users.registration_failed': 'Registration Failed',
  'users.your_kledo_account_successfully_created': 'Your Kledo account successfully created.',
  'users.your_username_and_password_already_sent_to_your_email':
    'Your username and password already sent to your email',
  'users.kledo_app_url': 'Kledo App Url',
  'users.kledo_app_url_is_required': 'Kledo App Url is required',
  'users.kledo_app_arl_must_be_at_least_3_characters':
    'Kledo App Url must be at least 3 characters',
  'users.kledo_app_url_cannot_be_longer_than_20_characters':
    'Kledo App Url cannot be longer than 20 characters',
  'users.name_is_required': 'Name is required',
  'users.name_must_be_at_least_5_characters': 'Name must be at least 5 characters',
  'users.name_cannot_be_longer_than_20_characters': 'Name cannot be longer than 20 characters',
  'users.property_name': 'Company Name',
  'users.property_name_is_required': 'Company Name is required',
  'users.property_name_must_be_at_least_5_characters': 'Company Name must be at least 5 characters',
  'users.property_name_cannot_be_longer_than_20_characters':
    'Company Name cannot be longer than 20 characters',
  'users.role_is_required': 'Role is required',
  'users.role_must_be_at_least_3_characters': 'Role must be at least 3 characters',
  'users.role_cannot_be_longer_than_20_characters': 'Role cannot be longer than 20 characters',
  'users.phone_number': 'Phone Number',
  'users.phone_number_is_required': 'Phone Number is required',
  'users.no_phone_number_worng': 'Phone Number worng',
  'users.phone_number_must_be_at_least_5_characters': 'Phone Number must be at least 5 characters',
  'users.phone_number_cannot_be_longer_than_20_characters':
    'Phone Number cannot be longer than 20 characters',
  'users.email_is_required': 'Email is required',
  'users.email_is_not_a_valid_email': 'Email is not a valid email',
  'users.register_now': 'Register Now',
  'users.login_now': 'Login Now',
  'users.domain_value_app_kledo_com_already_used_please_use_another_name':
    'Domain {value}.app.kledo.com already used, please use another name',
  'users.please_contact_hello_kledo_com_for_more_info':
    'Please contact hello@kledo.com for more info.',
  'users.registration_completed': 'Regsitration completed!',
  'users.password_has_been_sent_to_email_sms':
    'Password has been sent to your email and sms, please check it out',

  'report.point_of_sale': 'Point Of Sale',
  'report.net_sales_per_product_category': 'Net Sales per Product Category',
  'report.volume_per_product_category': 'Volume per Product Category',
  'report.product_category': 'Product Category',
  'report.gross_sales': 'Gross Sales',
  'report.net_sales': 'Net Sales',
  'report.discount': 'Discount',
  'report.gratuity': 'Gratuity',
  'report.collected': 'collected',
  'report.total_collected': 'Total Collected',
  'report.all_outlets': 'All',
  'report.date': 'Date',
  'report.payment': 'Payment',
  'report.day_of_the_week_sales': 'Day Of The Week Sales',
  'report.sales': 'Sales',
  'report.sales_summary': 'Sales Summary',
  'report.invoice': 'Invoice',

  'report.transactions': 'Transactions',
  'report.buy_sell_price': 'Buy/Sell Price',
  'report.avg_per_transaction': 'Avg. Net Sale Per Transactions',
  'report.name': 'Name',
  'report.volume': 'Volume',
  'report.top_products': 'Top Products',
  'report.pos': 'Pos',
  'report.pos_reports': 'Pos Reports',
  'report.outlets': 'Outlets',

  'financeAccounts.add_account': 'Add Account',
  'financeAccounts.financeAccounts': 'Account',
  'financeAccounts.all_accounts': 'All accounts',
  'financeAccounts.category': 'Category',
  'financeAccounts.select_category': 'Select category',
  'financeAccounts.successfully_added': 'successfully added.',
  'financeAccounts.error_cannot_add': 'Cannot add account.',
  'financeAccounts.error_cannot_update': 'Cannot update account.',
  'financeAccounts.error_cannot_move': 'Cannot move the account.',
  'financeAccounts.error_cannot_change_status': 'Cannot change status the account.',
  'financeAccounts.edit_account': 'Edit Account',
  'financeAccounts.please_input_the_account_name': 'Please input the account name.',
  'financeAccounts.please_input_the_code': 'Please input the code.',
  'financeAccounts.account_name': 'Name',
  'financeAccounts.code': 'Code',
  'financeAccounts.desc': 'Description',
  'financeAccounts.please_select_a_category': 'Please select a category.',
  'financeAccounts.max_20_characters': 'Max 20 characters.',
  'financeAccounts.max_100_characters': 'Max 100 characters.',
  'financeAccounts.min_2_characters': 'Min 2 characters.',
  'financeAccounts.balance': 'Balance',
  'financeAccounts.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'financeAccounts.select_account': 'Select account',
  'financeAccounts.set_conversion_balances': 'Conversion Balances',
  'financeAccounts.error_get_conversion_balances': 'Error check conversion balances',
  'financeAccounts.set_manual_journal': 'Manual Journal',
  'financeAccounts.error_cannot_load_data': 'Cannot load data account.',
  'financeAccounts.all_category': 'All category',
  'financeAccounts.error_cannot_delete': 'Cannot delete account.',
  'financeAccounts.error_cannot_search': 'Cannot search account.',
  'financeAccounts.follow_these_steps_to_import_your_account':
    'Follow these steps to import your account',
  'financeAccounts.1_download_your_account_template_file': '1. Download your account template file',
  'financeAccounts.start_by_downloading_our_account_csv':
    'Start by downloading our accounts Excel template file. This file has the correct column headings Kledo needs to import details of your accounts.',
  'financeAccounts.download_template_file': 'Download Template File',
  'financeAccounts.download_template_data_example_file': 'Download template data example file',
  'financeAccounts.import': 'Import Account',
  'financeAccounts.2_copy_your_account_into_the_template':
    '2. Copy your accounts into the template',
  'financeAccounts.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your accounts. Make sure the product details match the column headings provided in the template.',
  'financeAccounts.important': 'IMPORTANT',
  'financeAccounts.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeAccounts.upload': 'Upload',
  'financeAccounts.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeAccounts.a_maximum_of_500_account_can_be_imported_at_a_time':
    'A maximum of 1.000 accounts can be imported at a time.',
  'financeAccounts.import_actions_only_insert_new_account_cannot_update_or_delete':
    'Import actions only insert new accounts, cannot update nor delete.',
  'financeAccounts.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeAccounts.account_successfully_imported': '{rowCount} account imported successfully.',
  'financeAccounts.go_to_account_list': 'Go to account list',
  'financeAccounts.sub_account_from': 'Sub Account from',

  'financeClosing.closing': 'Closing',
  'financeClosing.period': 'Period',
  'financeClosing.note': 'Note',
  'financeClosing.profit_loss': 'Profit/Loss',
  'financeClosing.choose_period': 'Choose Period',
  'financeClosing.set_worksheet': 'Set Worksheet',
  'financeClosing.worksheet': 'Worksheet',
  'financeClosing.done': 'Done',
  'financeClosing.please_select_period': 'Please select closing period',
  'financeClosing.please_select_date': 'Please select date',
  'financeClosing.please_select_account': 'Please select account',
  'financeClosing.closing_report': 'Closing Report',
  'financeClosing.closing_period_to_has_been_saved': 'Closing period {from} to {to} has been saved',
  'financeClosing.period_from_to': 'Period: {from} - {to}',
  'financeClosing.see_closing_report': 'See Closing Report',
  'financeClosing.error_get_start_date': 'Cannot get start date',
  'financeClosing.error_get_closing_data': 'Cannot get closing data',
  'financeClosing.error_create_closing_data': 'Cannot create closing data',
  'financeClosing.error_delete_closing_data': 'Cannot delete closing data',
  'financeClosing.debit': 'Debit',
  'financeClosing.credit': 'Credit',
  'financeClosing.net_profit_loss': 'Net Profit/Loss',
  'financeClosing.tax_expense': 'Tax Expense',
  'financeClosing.tax_expense_account': 'Tax Expense Account',
  'financeClosing.tax_payable_account': 'Tax Payable Account',
  'financeClosing.tax_amount': 'Tax Amount',
  'financeClosing.tax_percent': 'Tax Percentage',
  'financeClosing.net_profit_loss_after_tax': 'Net Profit/Loss After Tax',
  'financeClosing.retained_earnings_account': 'Retained Earnings Account',
  'financeClosing.early_period': 'Early Period',

  'financeContacts.add_contact': 'Add Contact',
  'financeContacts.financeContacts': 'Contact',
  'financeContacts.contact_type': 'Contact Type',
  'financeContacts.select_contact_type': 'Select contact type',
  'financeContacts.successfully_added': 'successfully added.',
  'financeContacts.error_cannot_add': 'Cannot add contact.',
  'financeContacts.error_cannot_update': 'Cannot update contact.',
  'financeContacts.edit_contact': 'Edit Contact',
  'financeContacts.please_input_the_contact_name': 'Please input the contact name.',
  'financeContacts.please_select_a_contact_type': 'Please select a contact type.',
  'financeContacts.contact_group': 'Contact Group',
  'financeContacts.please_select_a_contact_group': 'Please select a contact group.',
  'financeContacts.contact_name': 'Name',
  'financeContacts.all_contact_type': 'All contact type',
  'financeContacts.max_characters': 'Max {value} characters.',
  'financeContacts.min_characters': 'Min {value} characters.',
  'financeContacts.balance': 'Balance',
  'financeContacts.company': 'Company',
  'financeContacts.address': 'Address',
  'financeContacts.email': 'Email',
  'financeContacts.add_email': 'Add Email',
  'financeContacts.alternative_email': 'Alternative Email',
  'financeContacts.email_cannot_be_the_same': 'Email cannot be the same',
  'financeContacts.phone': 'Phone',
  'financeContacts.secondary_phone': 'Secondary phone',
  'financeContacts.npwp': 'Tax Number',
  'financeContacts.only_number_allowed': 'Only number allowed.',
  'financeContacts.invalid_email_address_format': 'Invalid email address format.',
  'financeContacts.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'financeContacts.all_contact_type': 'All contact type',
  'financeContacts.error_cannot_search': 'Cannot search contact.',
  'financeContacts.group': 'Group',
  'financeContacts.all_group': 'All Group',
  'financeContacts.add_group': 'Add Group',
  'financeContacts.edit_group': 'Edit Group',
  'financeContacts.delete_group': 'Delete Group',
  'financeContacts.only_group_that_not_assigned_in_contact_could_be_deleted':
    'Note: Only group that not assigned in contact could be deleted.',
  'financeContacts.billing_address': 'Billing Address',
  'financeContacts.shipping_address': 'Shipping Address',
  'financeContacts.account_mapping': 'Account Mapping',
  'financeContacts.show_phone_detail': 'Show phone details',
  'financeContacts.hide_phone_detail': 'Hide phone details',
  'financeContacts.show_account_mapping': 'Show account mapping',
  'financeContacts.hide_account_mapping': 'Hide account mapping',
  'financeContacts.payable_account': 'Payable Account',
  'financeContacts.receivable_account': 'Receivable Account',
  'financeContacts.transactions': 'Transactions',
  'financeContacts.successfully_deleted': 'successfully deleted.',
  'financeContacts.successfully_added': 'successfully added.',
  'financeContacts.error_cannot_delete': 'Cannot delete contact group.',
  'financeContacts.error_cannot_add_group': 'Cannot add contact group.',
  'financeContacts.error_cannot_update': 'Cannot update contact group.',
  'financeContacts.error_cannot_move': 'Cannot move the contact group.',
  'financeContacts.error_cannot_change_status': 'Cannot change status the contact group.',
  'financeContacts.error_cannot_load_can_be_deleted_group': 'Cannot load contact group.',
  'financeContacts.follow_these_steps_to_import_your_contact':
    'Follow these steps to import your contact',
  'financeContacts.1_download_your_contact_template_file': '1. Download your contact template file',
  'financeContacts.start_by_downloading_our_contact_csv':
    'Start by downloading our contacts Excel template file. This file has the correct column headings Kledo needs to import details of your contacts.',
  'financeContacts.download_template_file': 'Download Template File',
  'financeContacts.download_template_data_example_file': 'Download template data example file',
  'financeContacts.import': 'Import Contact',
  'financeContacts.2_copy_your_contact_into_the_template':
    '2. Copy your contacts into the template',
  'financeContacts.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your contacts. Make sure the product details match the column headings provided in the template.',
  'financeContacts.important': 'IMPORTANT',
  'financeContacts.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeContacts.upload': 'Upload',
  'financeContacts.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeContacts.a_maximum_of_500_contact_can_be_imported_at_a_time':
    'A maximum of 1.000 contacts can be imported at a time.',
  'financeContacts.import_actions_only_insert_new_contact_cannot_update_or_delete':
    'Import actions only insert new contacts, cannot update nor delete.',
  'financeContacts.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeContacts.contact_successfully_imported': '{rowCount} contact imported successfully.',
  'financeContacts.go_to_contact_list': 'Go to contact list',
  'financeContacts.mass_delete_info':
    '**Contact that have a transaction cannot be deleted, can only be archived',
  'financeContacts.want_to_use_this_contact': 'Would like to use this contact ?',
  'financeContacts.are_you_sure_to_archive': 'Are you sure to archive this contact?',
  'financeContacts.are_you_sure_to_unarchive': 'Are you sure to unarchive this contact?',
  'financeContacts.your_net_debt': 'Your net debt',
  'financeContacts.you_owe': 'You owe',
  'financeContacts.they_owe': 'They owe',
  'financeContacts.payment_received': 'Payment received',
  'financeContacts.your_due_debt': 'Your due debt',
  'financeContacts.their_due_debt': 'Their due debt',
  'financeContacts.payment_sent': 'Payment sent',
  'financeContacts.awaiting_payment': 'Awaiting payments',
  'financeContacts.debt_you_need_to_pay': 'Debt you need to pay',
  'financeContacts.money_in_out': 'Money In Out',
  'financeContacts.money_in': 'Money In',
  'financeContacts.money_out': 'Money Out',
  'financeContacts.bank_accounts': 'Bank Accounts',
  'financeContacts.bank': 'Bank',
  'financeContacts.branch': 'Branch',
  'financeContacts.holder_name': 'Holder Name',
  'financeContacts.account_number': 'Account Number',
  'financeContacts.required': 'Required',
  'financeContacts.select_shipping_address': 'Select Shipping Address',
  'financeContacts.revert_to_default_address': 'Revert to default address',
  'financeContacts.max_payable': 'Max Payable',
  'financeContacts.total_payable_is_calculated_from_purchase_invoices':
    'Total payable is calculated from purchase invoices, transaction costs and accounts payable. If the total debt exceeds the maximum, then when creating a new purchase invoice it will be rejected.',
  'financeContacts.max_receivable': 'Max Receivable',
  'financeContacts.total_accounts_receivable_is_calculated_from_sales_invoices':
    'Total accounts receivable is calculated from sales invoices and accounts receivable contacts. If the total receivable exceeds the maximum, then when creating a new sales invoice it will be rejected.',
  'financeContacts.country': 'Country',
  'financeContacts.province': 'Province',
  'financeContacts.city': 'City',

  'financeExpenseTerms.delete_expenseTerm': 'Delete Expense Term',
  'financeExpenseTerms.are_you_sure_delete': 'Are you sure to delete?',
  'financeExpenseTerms.select_expenseTerm': 'Select an Expense Term',
  'financeExpenseTerms.please_select_expenseTerm_to_delete':
    'Please select an Expense Term to delete',
  'financeExpenseTerms.add_expenseTerm': 'Add Expense Term',
  'financeExpenseTerms.financeExpenseTerms': 'Expense Term',
  'financeExpenseTerms.successfully_deleted': 'successfully deleted.',
  'financeExpenseTerms.successfully_added': 'successfully added.',
  'financeExpenseTerms.error_cannot_delete': 'Cannot delete expense term.',
  'financeExpenseTerms.error_cannot_add': 'Cannot add expense term.',
  'financeExpenseTerms.error_cannot_update': 'Cannot update expense term.',
  'financeExpenseTerms.error_cannot_move': 'Cannot move the expense term.',
  'financeExpenseTerms.error_cannot_change_status': 'Cannot change status the expense term.',
  'financeExpenseTerms.edit_expenseTerm': 'Edit Expense Term',
  'financeExpenseTerms.please_input_the_expenseTerm_name': 'Please input the expense term name.',
  'financeExpenseTerms.please_input_the_days': 'Please input the days.',
  'financeExpenseTerms.expenseTerm_name': 'Name',
  'financeExpenseTerms.length': 'Length',
  'financeExpenseTerms.days': 'Days',
  'financeExpenseTerms.max_characters': 'Max {value} characters.',
  'financeExpenseTerms.min_characters': 'Min {value} characters.',
  'financeExpenseTerms.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'financeExpenseTerms.only_number_allowed': 'Only number allowed.',
  'financeExpenseTerms.only_0_-_1000_allowed': 'Only 0 - 1000 allowed',
  'financeExpenseTerms.only_0_-_10000_allowed': 'Only 0 - 10.000 allowed',
  'financeExpenseTerms.must_have_active_termin': 'Must have at least one active term.',

  'financeTaxes.add_tax': 'Add Tax',
  'financeTaxes.financeTaxes': 'Tax',
  'financeTaxes.successfully_added': 'successfully added.',
  'financeTaxes.error_cannot_add': 'Cannot add tax.',
  'financeTaxes.error_cannot_update': 'Cannot update tax.',
  'financeTaxes.error_cannot_move': 'Cannot move the tax.',
  'financeTaxes.error_cannot_change_status': 'Cannot change status the tax.',
  'financeTaxes.edit_tax': 'Edit Tax Term',
  'financeTaxes.please_input_the_tax_name': 'Please input the tax name.',
  'financeTaxes.please_input_the_effective_rates': 'Please input the effective rate.',
  'financeTaxes.please_select_a_sell_tax_account': 'Please select a sell tax account.',
  'financeTaxes.please_select_a_buy_tax_account': 'Please select a buy tax account.',
  'financeTaxes.select_buy_tax_account': 'Select buy tax account',
  'financeTaxes.select_sell_tax_account': 'Select sell tax account',
  'financeTaxes.tax_name': 'Name',
  'financeTaxes.effective_rates': 'Effective Rate',
  'financeTaxes.is_withholding': 'With Holding',
  'financeTaxes.buy_tax_account': 'Buy Tax Account',
  'financeTaxes.sell_tax_account': 'Sell Tax Account',
  'financeTaxes.max_characters': 'Max {value} characters.',
  'financeTaxes.min_characters': 'Min {value} characters.',
  'financeTaxes.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'financeTaxes.only_number_allowed': 'Only number allowed.',
  'financeTaxes.effective_rate_%': 'Effective Rate %',
  'financeTaxes.must_have_active_tax': 'Must have at least one active tax.',
  'financeTaxes.witholding': 'Witholding',
  'financeTaxes.error_cannot_load_data_detail': 'Cannot load data detail tax.',
  'financeTaxes.delete_tax': 'Delete Tax',
  'financeTaxes.are_you_sure_delete': 'Are you sure to delete?',
  'financeTaxes.select_tax': 'Select a Tax',
  'financeTaxes.please_select_tax_to_delete': 'Please select a Tax to delete',
  'financeTaxes.successfully_deleted': 'successfully deleted.',
  'financeTaxes.error_cannot_delete': 'Cannot delete tax.',
  'financeTaxes.manually_edit_tax_amount_allowed': 'Manually edit tax amount allowed',
  'financeTaxes.allow_manual': 'Allow manual',

  'financeUnits.error_cannot_move': 'Cannot move unit',
  'financeUnits.name': 'Name',
  'financeUnits.max_characters': 'Max {value} characters.',
  'financeUnits.min_characters': 'Min {value} characters.',
  'financeUnits.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'financeUnits.please_input_the_unit_name': 'Please input the tax name.',
  'financeUnits.error_cannot_add': 'Cannot add unit.',
  'financeUnits.error_cannot_update': 'Cannot update unit.',
  'financeUnits.error_cannot_delete': 'Cannot delete unit.',
  'financeUnits.please_select_unit_to_delete': 'Please select a Unit to delete',
  'financeUnits.select_unit': 'Select a Unit',
  'financeUnits.unit': 'Unit',

  'financeExpenses.add_expense': 'Add Expense',
  'financeExpenses.financeExpenses': 'Expense',
  'financeExpenses.successfully_added': 'successfully added.',
  'financeExpenses.error_cannot_add': 'Cannot add expense.',
  'financeExpenses.error_cannot_update': 'Cannot update expense.',
  'financeExpenses.error_cannot_move': 'Cannot move the expense.',
  'financeExpenses.error_cannot_change_status': 'Cannot change status the expense.',
  'financeExpenses.edit_expense': 'Edit Expense',
  'financeExpenses.please_input_the_expense_name': 'Please input the expense name.',
  'financeExpenses.please_input_the_effective_rates': 'Please input the effective rate.',
  'financeExpenses.please_select_a_sell_expense_account': 'Please select a sell expense account.',
  'financeExpenses.please_select_a_buy_expense_account': 'Please select a buy expense account.',
  'financeExpenses.select_buy_expense_account': 'Select buy expense account',
  'financeExpenses.select_sell_expense_account': 'Select sell expense account',
  'financeExpenses.expense_name': 'Name',
  'financeExpenses.effective_rates': 'Effective Rate',
  'financeExpenses.is_withholding': 'With Holding',
  'financeExpenses.buy_expense_account': 'Buy expense account',
  'financeExpenses.sell_expense_account': 'Sell expense account',
  'financeExpenses.max_characters': 'Max {value} characters.',
  'financeExpenses.min_characters': 'Min {value} characters.',
  'financeExpenses.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'financeExpenses.only_number_allowed': 'Only number allowed.',
  'financeExpenses.expense_account': 'Expense Account',
  'financeExpenses.select_expense_account': 'Select account',
  'financeExpenses.description': 'Description',
  'financeExpenses.tax': 'Tax',
  'financeExpenses.select_tax': 'Select tax',
  'financeExpenses.amount': 'Amount',
  'financeExpenses.pay_from': 'Pay From',
  'financeExpenses.please_select_a_pay_from': 'Please select a pay from',
  'financeExpenses.select_pay_from': 'Select pay from',
  'financeExpenses.pay_later': 'Pay Later',
  'financeExpenses.benefeciary': 'Benefeciary',
  'financeExpenses.please_select_a_benefeciary': 'Please select a benefeciary',
  'financeExpenses.select_benefeciary': 'Select benefeciary',
  'financeExpenses.add_contact': 'Add contact',
  'financeExpenses.all_contacts': 'All contact',
  'financeExpenses.transaction_date': 'Transaction Date',
  'financeExpenses.please_select_transaction_date': 'Please select transaction date',
  'financeExpenses.due_date': 'Due Date',
  'financeExpenses.please_select_due_date': 'Please select due date',
  'financeExpenses.term': 'Term',
  'financeExpenses.select_term': 'Select term',
  'financeExpenses.add_term': 'Add term',
  'financeExpenses.price_include_tax': 'Price include tax',
  'financeExpenses.add_more': 'Add more',
  'financeExpenses.payment_method': 'Payment Method',
  'financeExpenses.please_select_a_payment_method': 'Please select a payment method',
  'financeExpenses.select_payment_method': 'Select payment method',
  'financeExpenses.number': 'Number',
  'financeExpenses.please_input_the_number': 'Please input the number.',
  'financeExpenses.memo': 'Memo',
  'financeExpenses.date': 'Date',
  'financeExpenses.category': 'Category',
  'financeExpenses.status': 'Status',
  'financeExpenses.balance_due': 'Balance Due',
  'financeExpenses.total': 'Total',
  'financeExpenses.please_select_a_term': 'Please select a term.',
  'financeExpenses.search_by_memo': 'Search',
  'financeExpenses.the_due_date_max_higger_than_transaction_date':
    'The due date must same or after transaction date',
  'financeExpenses.detail_title': '{status} Expense',
  'financeExpenses.pay_bill': 'Pay Bill',
  'financeExpenses.detail_expense': 'Detail Expense',
  'financeExpenses.total_amount_must_greater_than_total_payment':
    'Total amount must greater than or equal to total payment.',
  'financeExpenses.all': 'All',
  'financeExpenses.paid': 'Paid',
  'financeExpenses.unpaid': 'Unpaid',
  'financeExpenses.partial': 'Partial',
  'financeExpenses.closed': 'Closed',
  'financeExpenses.follow_these_steps_to_import_your_expenses':
    'Follow these steps to import your expenses',
  'financeExpenses.1_download_your_expenses_template_file':
    '1. Download your expenses template file',
  'financeExpenses.start_by_downloading_our_expenses_csv':
    'Start by downloading our expenses Excel template file. This file has the correct column headings Kledo needs to import details of your expenses.',
  'financeExpenses.download_template_file': 'Download Template File',
  'financeExpenses.download_template_data_example_file': 'Download template data example file',
  'financeExpenses.import': 'Import Expenses',
  'financeExpenses.2_copy_your_expenses_into_the_template':
    '2. Copy your expenses into the template',
  'financeExpenses.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your expenses. Make sure the product details match the column headings provided in the template.',
  'financeExpenses.important': 'IMPORTANT',
  'financeExpenses.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeExpenses.upload': 'Upload',
  'financeExpenses.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeExpenses.a_maximum_of_500_expenses_can_be_imported_at_a_time':
    'A maximum of 1.000 expenses can be imported at a time.',
  'financeExpenses.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Contact name that not exist will be inserted as a new contact.',
  'financeExpenses.import_actions_only_insert_new_expenses_cannot_update_or_delete':
    'Import actions only insert new data, cannot update nor delete.',
  'financeExpenses.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeExpenses.you_can_only_upload_csv_file': 'You can only upload csv or excel file.',
  'financeExpenses.upload_file': 'Upload File',
  'financeExpenses.check_the_imported_data': 'Check Data',
  'financeExpenses.please_check_the_imported_data': 'Please check the imported data',
  'financeExpenses.import_expenses_done': 'Import Success',
  'financeExpenses.error_upload_file': 'Upload failed.',
  'financeExpenses.file_not_found': 'File not found',
  'financeExpenses.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `If you are happy to continue with the import please click the "Import Now" button.`,
  'financeExpenses.contact_name': 'Contact name',
  'financeExpenses.email': 'Email',
  'financeExpenses.address': 'Address',
  'financeExpenses.phone': 'Phone',
  'financeExpenses.expense_date': 'Expense Date',
  'financeExpenses.expense_number': 'Expense Number',
  'financeExpenses.reference': 'Reference',
  'financeExpenses.account_code': 'Account Code',
  'financeExpenses.tax_inclusive': 'Tax Inclusive',
  'financeExpenses.paid': 'Paid',
  'financeExpenses.paid_account': 'Paid To Account',
  'financeExpenses.row_imported': '{imported} of {total} rows imported',
  'financeExpenses.expenses_successfully_imported': '{rowCount} expense imported successfully.',
  'financeExpenses.view_detail': 'View detail',
  'financeExpenses.import_another_file': 'Import another file',
  'financeExpenses.go_to_expenses_list': 'Go to expense list',
  'financeExpenses.success': 'Success',
  'financeExpenses.failed': 'Failed',
  'financeExpenses.error_cannot_import': 'Import failed',
  'financeExpenses.error': 'Error',
  'financeExpenses.import_expenses_in_progress': 'Import in progress',
  'financeExpenses.import_expenses_is_still_in_progress': 'Import is still in progress',
  'financeExpenses.its_ok_to_close_this_page': `It's OK to close this page or import another file. When import done, you will be notified via email.`,

  'financePurchaseOrders.message': 'Message',
  'financePurchaseOrders.show_message': 'Show Message',
  'financePurchaseOrders.hide_message': 'Hide Message',
  'financePurchaseOrders.show_attachment': 'Show Attachment',
  'financePurchaseOrders.hide_attachment': 'Hide Attachment',
  'financePurchaseOrders.account': 'Account',
  'financePurchaseOrders.debit': 'Debit',
  'financePurchaseOrders.credit': 'Credit',
  'financePurchaseOrders.journal_report': 'Journal Report',
  'financePurchaseOrders.add_purchaseorder': 'Add Purchase Order',
  'financePurchaseOrders.financePurchaseOrders': 'Purchase Order',
  'financePurchaseOrders.successfully_added': 'successfully added.',
  'financePurchaseOrders.error_cannot_add': 'Cannot add purchase order.',
  'financePurchaseOrders.error_cannot_print_pdf': 'Cannot print pdf purchase order.',
  'financePurchaseOrders.error_cannot_update': 'Cannot update purchase order.',
  'financePurchaseOrders.error_cannot_move': 'Cannot move the purchase order.',
  'financePurchaseOrders.error_cannot_change_status': 'Cannot change status the purchase order.',
  'financePurchaseOrders.edit_purchaseorder': 'Edit Purchase Order',
  'financePurchaseOrders.max_characters': 'Max {value} characters.',
  'financePurchaseOrders.min_characters': 'Min {value} characters.',
  'financePurchaseOrders.only_number_allowed': 'Only number allowed.',
  'financePurchaseOrders.description': 'Description',
  'financePurchaseOrders.tax': 'Tax',
  'financePurchaseOrders.select_tax': 'Select tax',
  'financePurchaseOrders.amount': 'Amount',
  'financePurchaseOrders.please_select_a_contact': 'Please select a contact',
  'financePurchaseOrders.select_contact': 'Select contact',
  'financePurchaseOrders.add_contact': 'Add contact',
  'financePurchaseOrders.all_contacts': 'All contact',
  'financePurchaseOrders.transaction_date': 'Transaction Date',
  'financePurchaseOrders.please_select_transaction_date': 'Please select transaction date',
  'financePurchaseOrders.due_date': 'Due Date',
  'financePurchaseOrders.please_select_due_date': 'Please select due date',
  'financePurchaseOrders.term': 'Term',
  'financePurchaseOrders.select_term': 'Select term',
  'financePurchaseOrders.add_term': 'Add term',
  'financePurchaseOrders.price_include_tax': 'Price include tax',
  'financePurchaseOrders.add_more': 'Add more',
  'financePurchaseOrders.number': 'Number',
  'financePurchaseOrders.please_input_the_number': 'Please input the number.',
  'financePurchaseOrders.memo': 'Memo',
  'financePurchaseOrders.date': 'Date',
  'financePurchaseOrders.status': 'Status',
  'financePurchaseOrders.balance_due': 'Balance Due',
  'financePurchaseOrders.total': 'Total',
  'financePurchaseOrders.please_select_a_term': 'Please select a term.',
  'financePurchaseOrders.search_by_memo': 'Search',
  'financePurchaseOrders.the_due_date_max_higger_than_transaction_date':
    'The due date must same or after transaction date',
  'financePurchaseOrders.detail_title': 'Purchase Order {number}',
  'financePurchaseOrders.vendor': 'Vendor',
  'financePurchaseOrders.reference': 'Reference',
  'financePurchaseOrders.product': 'Product',
  'financePurchaseOrders.qty': 'Qty',
  'financePurchaseOrders.unit': 'Unit',
  'financePurchaseOrders.price': 'Price',
  'financePurchaseOrders.add_product': 'Add Product',
  'financePurchaseOrders.select_product': 'Select product',
  'financePurchaseOrders.attachments': 'Attachments',
  'financePurchaseOrders.save_&_approve': 'Save & approve',
  'financePurchaseOrders.save_&_approve_add_new': 'Save & approve & Add New',
  'financePurchaseOrders.save_as_draft': 'Save as draft',
  'financePurchaseOrders.save_as_draft_add_new': 'Save as draft & Add New',
  'financePurchaseOrders.save_&_submit_for_approval': 'Save & submit for approval',
  'financePurchaseOrders.save_&_submit_for_approval_add_new':
    'Save & submit for approval & Add New',
  'financePurchaseOrders.please_select_a_product': 'Please select a product.',
  'financePurchaseOrders.please_input_the_qty': 'Please input the qty.',
  'financePurchaseOrders.please_input_the_price': 'Please input the price.',
  'financePurchaseOrders.please_input_the_amount': 'Please input the amount.',
  'financePurchaseOrders.approve': 'Approve',
  'financePurchaseOrders.are_you_sure_to_approve': 'Are you sure to approve ?',
  'financePurchaseOrders.submit_for_approval': 'Submit for approval',
  'financePurchaseOrders.are_you_sure_to_submit_for_approval':
    'Are you sure to submit for approval ?',
  'financePurchaseOrders.create_invoice': 'Create Invoice',
  'financePurchaseOrders.must_greater_than_0': 'Must greater than 0.',
  'financePurchaseOrders.must_be_smaller_than_bill': 'Must be smaller than bill.',

  'financePurchaseOrders.follow_these_steps_to_import_your_purchase_orders':
    'Follow these steps to import your purchase orders',
  'financePurchaseOrders.1_download_your_purchase_orders_template_file':
    '1. Download your purchase orders template file',
  'financePurchaseOrders.start_by_downloading_our_purchase_orders_csv':
    'Start by downloading our purchase orders Excel template file. This file has the correct column headings Kledo needs to import details of your orders.',
  'financePurchaseOrders.download_template_file': 'Download Template File',
  'financePurchaseOrders.download_template_data_example_file':
    'Download template data example file',
  'financePurchaseOrders.import': 'Import Purchase Orders',
  'financePurchaseOrders.2_copy_your_purchase_orders_into_the_template':
    '2. Copy your purchase orders into the template',
  'financePurchaseOrders.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your purchase orders. Make sure the product details match the column headings provided in the template.',
  'financePurchaseOrders.important': 'IMPORTANT',
  'financePurchaseOrders.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financePurchaseOrders.upload': 'Upload',
  'financePurchaseOrders.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financePurchaseOrders.a_maximum_of_500_purchase_orders_can_be_imported_at_a_time':
    'A maximum of 1.000 purchase orders can be imported at a time.',
  'financePurchaseOrders.product_code_that_not_exist_will_be_inserted_as_a_new_product':
    'Product code that not exist will be inserted as a new product.',
  'financePurchaseOrders.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Contact name that not exist will be inserted as a new contact.',
  'financePurchaseOrders.import_actions_only_insert_new_purchase_orders_cannot_update_or_delete':
    'Import actions only insert new purchase orders, cannot update nor delete.',
  'financePurchaseOrders.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financePurchaseOrders.you_can_only_upload_csv_file': 'You can only upload csv or excel file.',
  'financePurchaseOrders.upload_file': 'Upload File',
  'financePurchaseOrders.check_the_imported_data': 'Check Data',
  'financePurchaseOrders.please_check_the_imported_data': 'Please check the imported data',
  'financePurchaseOrders.import_purchase_orders_done': 'Import Success',
  'financePurchaseOrders.error_upload_file': 'Upload failed.',
  'financePurchaseOrders.file_not_found': 'File not found',
  'financePurchaseOrders.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `If you are happy to continue with the import please click the "Import Now" button.`,
  'financePurchaseOrders.valid': 'Valid',
  'financePurchaseOrders.invalid': 'Invalid',
  'financePurchaseOrders.total': 'Total',
  'financePurchaseOrders.import_now': 'Import Now',
  'financePurchaseOrders.contact_name': 'Contact name',
  'financePurchaseOrders.email': 'Email',
  'financePurchaseOrders.address': 'Address',
  'financePurchaseOrders.phone': 'Phone',
  'financePurchaseOrders.purchase_order_date': 'Purchase Order Date',
  'financePurchaseOrders.purchase_order_number': 'Purchase Order Number',
  'financePurchaseOrders.product_name': 'Product name',
  'financePurchaseOrders.code_sku': 'Kode/SKU',
  'financePurchaseOrders.tax_inclusive': 'Tax Inclusive',

  'financePurchaseOrders.inventory_account': 'Inventory Account',
  'financePurchaseOrders.purchase_orders_successfully_imported':
    '{rowCount} pesanan pembelian imported successfully.',
  'financePurchaseOrders.view_detail': 'View detail',
  'financePurchaseOrders.import_another_file': 'Import another file',
  'financePurchaseOrders.go_to_purchase_order_list': 'Go to purchase order list',
  'financePurchaseOrders.success': 'Success',
  'financePurchaseOrders.failed': 'Failed',
  'financePurchaseOrders.error_cannot_import': 'Import failed',
  'financePurchaseOrders.error': 'Error',
  'financePurchaseOrders.tax_manual': 'Manual Tax',
  'financePurchaseOrders.tax_manual_must_less_than_amount': 'Manual tax must less than amount.',
  'financePurchaseOrders.discount': 'Discount',
  'financePurchaseOrders.additional_discount': 'Additional Discount',
  'financePurchaseOrders.discount_per_item': 'Discount per item',
  'financePurchaseOrders.total_discount': 'Total Discount',
  'financePurchaseOrders.total_cannot_be_negative': 'Due cannot be negative.',
  'financePurchaseOrders.product_not_found': 'Product not found',
  'financePurchaseOrders.product_has_been_added': 'Product has been added',
  'financePurchaseOrders.discount_per_quantity': 'Discount per quantity',

  'salesCommission.sales_commission': 'Sales Commission',
  'salesCommission.commission': 'Commission',
  'salesCommission.commission_required': 'Commission is required',
  'salesCommission.commission_name': 'Commission Name',
  'salesCommission.commission_name_required': 'Commission Name is required',
  'salesCommission.commission_amount_by': 'Commission Amount By',
  'salesCommission.commission_amount': 'Commission Amount',
  'salesCommission.min_transaction_per_month': 'Minimum transaction per month',
  'salesCommission.min_transaction_per_month_required': 'Minimum transaction is required',
  'salesCommission.cannot_get_commission_type': 'Cannot get commission type',
  'salesCommission.cannot_get_commission': 'Cannot get commission',
  'salesCommission.applies_to_the_following_sales_person': 'Applies to the Following Sales Person',
  'salesCommission.sales_person_required': 'Sales person is required',
  'salesCommission.all_sales': 'All Sales',
  'salesCommission.you_can_manage_sales_person_in_this_page':
    'You can manage sales commission for each sales person in this page. If done, {link} to see the sales commission report.',
  'salesCommission.not_due_only':
    'Commission is only for transactions whose payment is not past due',

  'priceRule.price_rule': 'Price Rule',
  'priceRule.rule_name': 'Rule Name',
  'priceRule.rule_name_required': 'Rule name is required',
  'priceRule.dicsount': 'Discount',
  'priceRule.discount_required': 'Discount is required',
  'priceRule.min_qty': 'Minimal Qty',
  'priceRule.min_qty_required': 'Minimal qty is required',
  'priceRule.effective_date': 'Effective Date',
  'priceRule.forever': 'Forever',
  'priceRule.date': 'Date',
  'priceRule.date_start': 'Start Date',
  'priceRule.date_end': 'End Date',
  'priceRule.date_is_required': 'Date is required',
  'priceRule.until': 'until',
  'priceRule.applies_to_the_following_customers': 'Applies to the Following Customers',
  'priceRule.customer': 'Customer',
  'priceRule.all_customer': 'All Customer',
  'priceRule.customer_group': 'Customer Group',
  'priceRule.customer_groups_that_will_get_discount': 'Customer groups that will get discount',
  'priceRule.add_group': 'Add group',
  'priceRule.select_group': 'Select group',
  'priceRule.per_customer': 'Per Customer',
  'priceRule.customers_that_will_get_discount': 'Customers that will get discount',
  'priceRule.add_customer': 'Add customer',
  'priceRule.name': 'Name',
  'priceRule.company': 'Company',
  'priceRule.applies_to_the_following_products': 'Applies to the Following Products',
  'priceRule.product': 'Product',
  'priceRule.all_product': 'All Product',
  'priceRule.product_category': 'Product Category',
  'priceRule.discounted_product_category': 'Discounted product category',
  'priceRule.add_category': 'Add category',
  'priceRule.per_product': 'Per Product',
  'priceRule.discounted_product': 'Discounted Product',
  'priceRule.add_product': 'Add product',
  'priceRule.status': 'Status',
  'priceRule.active': 'Active',
  'priceRule.inactive': 'Inactive',
  'priceRule.select_price_rule': 'Select price rule',
  'priceRule.to_use_the_pricing_rules_please_first_activate_the_sales_discount_option':
    'To use the pricing rules, please first activate the Sales Discount option from the Settings Business Flow menu.',

  'financeProduct.search_in': 'Search in',
  'financeProduct.available': 'Available',
  'financeProduct.almost_empty': 'Almost Empty',
  'financeProduct.empty': 'Empty',
  'financeProduct.product_availability': 'Product Availability',
  'financeProduct.product_type_per_category': 'Product Type per Category',
  'financeProduct.stock_per_category': 'Stock per Category',
  'financeProduct.warehouse_location': 'Warehouse Location',
  'financeProduct.available_stock_product': 'Available Stock Product',
  'financeProduct.almost_empty_stock_product': 'Almost Empty Stock Product',
  'financeProduct.empty_stock_product': 'Empty Stock Product',
  'financeProduct.total_stock': 'Stock Total',
  'financeProduct.total_product_value': 'Product Value Total',
  'financeProduct.total_avg_base_price': 'COGS Total',
  'financeProduct.total_sales': 'Sales Total',
  'financeProduct.total_purchase': 'Purchases Total',
  'financeProduct.total_product_type': 'Product Type Total',
  'financeProduct.product': 'Product',
  'financeProduct.code_sku_tooltip':
    'SKU (Stock Keeping Unit) code is a unique code for each product. The code will be generated automatically, or you can change it.',
  'financeProduct.conversion_detail_title': 'Product Conversion {number}',
  'financeProduct.row': 'Row',
  'financeProduct.avg_base_price': 'COGs',
  'financeProduct.all_stock': 'All stock',
  'financeProduct.all_products': 'All products',
  'financeProduct.detail_product': 'Detail Product',
  'financeProduct.category': 'Category',
  'financeProduct.product_name': 'Product Name',
  'financeProduct.code_sku': 'Code/SKU',
  'financeProduct.cost_price': 'Cost Price',
  'financeProduct.sale_price': 'Sale Price',
  'financeProduct.quantity': 'Quantity',
  'financeProduct.products_and_services': 'Products & Services',
  'financeProduct.add_category': 'Add Category',
  'financeProduct.add_product': 'Add Product',
  'financeProduct.name_category_required': 'Name category required',
  'financeProduct.name_category_must_be_at_least_2_characters':
    'name category must be at least 2 characters',
  'financeProduct.edit_category': 'Edit Category',
  'financeProduct.edit_product': 'Edit Product',
  'financeProduct.image': 'Image',
  'financeProduct.product_image': 'Product Image',
  'financeProduct.show_product_image': 'Show Product Image',
  'financeProduct.hide_product_image': 'Hide Product Image',
  'financeProduct.choose_category': 'Choose Category',
  'financeProduct.choose_category_required': 'choose category required',
  'financeProduct.product_name_required': 'product name required',
  'financeProduct.product_name_must_be_at_least_2_characters':
    'product name must be at least 2 characters',
  'financeProduct.product_name_max_150_characters': 'product name maximal 150 characters',
  'financeProduct.code_sku_must_be_at_least_2_characters': 'Code/SKU must be at least 2 characters',
  'financeProduct.i_purchase_this_item': 'I purchase this item',
  'financeProduct.price': 'Price',
  'financeProduct.price_required': 'Price required',
  'financeProduct.extra_price_must_greather_than_ziro': 'Extra price must greather than 0.',
  'financeProduct.account': 'Account',
  'financeProduct.select_account': 'Select Account',
  'financeProduct.tax': 'Tax',
  'financeProduct.choose_tax_required': 'choose tax required',
  'financeProduct.choose_account_required': 'choose account required',
  'financeProduct.select_tax_rate': 'Select Tax Rate',
  'financeProduct.i_sell_this_item': 'I sell this item',
  'financeProduct.i_track_this_item': 'I track this item',
  'financeProduct.this_treats_your_item_as_a_tracked_inventory':
    'This treats your item as a tracked inventory asset.',
  'financeProduct.add_product': 'Add Product',
  'financeProduct.date': 'Date',
  'financeProduct.type': 'Type',
  'financeProduct.add_adjustment': 'Add Adjustment',
  'financeProduct.quantity_on_hand': 'Quantity On Hand',
  'financeProduct.total_value': 'Total Value',
  'financeProduct.purchase': 'Purchase',
  'financeProduct.tax_rate': 'Tax Rate',
  'financeProduct.sales': 'Sales',
  'financeProduct.inventory': 'Inventory',
  'financeProduct.inventory_asset_account': 'Inventory Asset Account',
  'financeProduct.total': 'Total',
  'financeProduct.decrease_quantity': 'Decrease quantity',
  'financeProduct.revolution': 'Revolution',
  'financeProduct.new_adjustment': 'New Adjustment',
  'financeProduct.adjustment_type': 'Adjustment Type',
  'financeProduct.adjustment_type_required': 'adjustment type required',
  'financeProduct.select_adjustment_type': 'Select Adjustment Type',
  'financeProduct.date_required': 'date required',
  'financeProduct.current_qty': 'Current qty',
  'financeProduct.qty_required': 'qty required',
  'financeProduct.avg_cost': 'Avg cost',
  'financeProduct.avg_cost_required': 'avg cost required',
  'financeProduct.total_value_required': 'total value required',
  'financeProduct.adjustment': 'Adjustment',
  'financeProduct.adjustment_required': 'adjustment required',
  'financeProduct.cost_price_required': 'cost price required',
  'financeProduct.new_qty_on_hand': 'New qty on hand',
  'financeProduct.new_qty_on_hand_required': 'new qty on hand required',
  'financeProduct.account': 'Account',
  'financeProduct.choose_account_required': 'choose account required',
  'financeProduct.select_account': 'Select Account',
  'financeProduct.note': 'Note',
  'financeProduct.note_required': 'note required',
  'financePurchaseOrders.detail_purchaseorder': 'Detail Purchase Order',
  'financeProduct.error_cannot_add': 'Cannot add product',
  'financeProduct.error_cannot_add_categorie': 'Cannot add product categorie',
  'financeProduct.recent_transactions': 'Recent Transactions',
  'financeProduct.manufacture': 'Manufacture',
  'financeProduct.package': 'Package',
  'financeProduct.conversion': 'Conversion',
  'financeProduct.follow_these_steps_to_import_your_products':
    'Follow these steps to import your products',
  'financeProduct.1_download_your_products_template_file':
    '1. Download your products template file',
  'financeProduct.start_by_downloading_our_products_csv':
    'Start by downloading our products Excel template file. This file has the correct column headings Kledo needs to import details of your products.',
  'financeProduct.download_template_file': 'Download Template File',
  'financeProduct.download_template_data_example_file': 'Download template data example file',
  'financeProduct.import': 'Import Products',
  'financeProduct.2_copy_your_products_into_the_template':
    '2. Copy your products into the template',
  'financeProduct.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your products. Make sure the product details match the column headings provided in the template.',
  'financeProduct.important': 'IMPORTANT',
  'financeProduct.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeProduct.upload': 'Upload',
  'financeProduct.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeProduct.a_maximum_of_1000_products_can_be_imported_at_a_time':
    'A maximum of 1.000 products can be imported at a time.',
  'financeProduct.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step. You can also use a previously exported CSV of your products to update all your products in one go, saving you the effort of having to individually update all your products through the browser. The import will use the item code to determine whether a product exist and should be updated, or does not exist and should be created. Products cannot and will not be deleted using this import so you can safely update a subset of your products without fear of losing the others.',
  'financeProduct.you_can_only_upload_csv_file': 'You can only upload csv or excel file.',
  'financeProduct.upload_file': 'Upload File',
  'financeProduct.check_the_imported_data': 'Check Data',
  'financeProduct.please_check_the_imported_data': 'Please check the imported data',
  'financeProduct.import_product_done': 'Import Success',
  'financeProduct.error_upload_file': 'Upload failed.',
  'financeProduct.file_not_found': 'File not found',
  'financeProduct.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `If you are happy to continue with the import please click the "Import Now" button.`,
  'financeProduct.valid': 'Valid',
  'financeProduct.invalid': 'Invalid',
  'financeProduct.total': 'Total',
  'financeProduct.import_now': 'Import Now',
  'financeProduct.status': 'Status',
  'financeProduct.name': 'Name',
  'financeProduct.purchase_price': 'Purchase Price',
  'financeProduct.purchase_account': 'Purchase Account',
  'financeProduct.purchase_tax': 'Purchase Tax',
  'financeProduct.sell_price': 'Sell Price',
  'financeProduct.sell_account': 'Sell Account',
  'financeProduct.sell_tax': 'Sell Tax',
  'financeProduct.track_account': 'Tracking Account',
  'financeProduct.product_bundle': 'Product Bundle',
  'financeProduct.product_bundle_qty': 'Product Bundle Qty',
  'financeProduct.product_bundle_account': 'Product Bundle Account',
  'financeProduct.product_bundle_account_cost': 'Product Bundle Account Cost',
  'financeProduct.account_cost': 'Account Cost',
  'financeProduct.inventory_account': 'Inventory Account',
  'financeProduct.products_successfully_imported': '{rowCount} products imported successfully.',
  'financeProduct.view_detail': 'View detail',
  'financeProduct.import_another_file': 'Import another file',
  'financeProduct.go_to_product_list': 'Go to product list',
  'financeProduct.success': 'Success',
  'financeProduct.failed': 'Failed',
  'financeProduct.error_cannot_import': 'Import failed',
  'financeProduct.error_cannot_export': 'Export failed',
  'financeProduct.error': 'Error',
  'financeProduct.please_input_the_code_sku': 'Please input the code/SKU.',
  'financeProduct.code_sku_maximal_150_characters': 'Code/SKU maximal 150 characters.',
  'financeProduct.please_choose_at_least_a_product_action':
    'Please choose at least a product action (Sell/Purchase/Inventory).',
  'financeProduct.name_category_already_exist':
    'Nama kategori sudah digunakan, silakan pilih yang lain',
  'financeProduct.please_choose_this_action':
    'You must activate purchase and sell while you track the product inventory!',
  'financeProduct.please_choose_the_unit': 'Please choose the unit.',
  'financeProduct.qty': 'Qty',
  'financeProduct.show_qty_per_warehouse': 'Show qty per warehouse',
  'financeProduct.select_category': 'Select category',
  'financeProduct.qty': 'Qty',
  'financeProduct.min_qty': 'Minimum Qty',
  'financeProduct.min_qty_must_less': 'Qty must less than next qty',
  'financeProduct.min_qty_must_greater': 'Qty must greater than previous qty',
  'financeProduct.wholesale_price': 'Wholesale Price',
  'financeProduct.wholesale_qty': 'Wholesale Qty',
  'financeProduct.show_wholesale_price': 'Show Wholesale Price',
  'financeProduct.hide_wholesale_price': 'Hide Wholesale Price',
  'financeProduct.warehouses': 'Warehouses',
  'financeProduct.warehouse': 'Warehouse',
  'financeProduct.transaction': 'Transaction',
  'financeProduct.description': 'Description',
  'financeProduct.type': 'Type',
  'financeProduct.stock_movement': 'Stock Movement',
  'financeProduct.stock': 'Stock',
  'financeProduct.select_stock': 'Select Stock',
  'financeProduct.error_load_stock': 'Error cannot load stock',
  'financeProduct.show_accounts_and_taxes_setting': 'Show account and taxes setting',
  'financeProduct.hide_accounts_and_taxes_setting': 'Hide account and taxes setting',
  'financeProduct.categories': 'Manage Categories',
  'financeProduct.error_cannot_move': 'Cannot move the category.',
  'financeProduct.please_select_a_category_to_delete': 'Please select a category to delete.',
  'financeProduct.delete_a_product_category': 'Delete A Product Category',
  'financeProduct.error_cannot_delete_category': 'Cannot delete product category.',
  'financeProduct.error_cannot_update_category': 'Cannot update product category.',
  'financeProduct.error_cannot_update': 'Cannot update product.',
  'financeProduct.error_cannot_load_can_be_deleted_category': 'Cannot load product category.',
  'financeProduct.only_category_that_not_assigned_in_product_could_be_deleted':
    'Note: Only category that not assigned in product could be deleted.',
  'financeProduct.reference': 'Reference',
  'financeProduct.error_load_product': 'Error cannot load product.',
  'financeProduct.add_product': 'Add {title}',
  'financeProduct.add_product_package': 'Add {title} Package',
  'financeProduct.duplicate_product_package': 'Duplicate {title} Package',
  'financeProduct.duplicate_product_manufacture': 'Duplicate {title} Manufacture',
  'financeProduct.add_product_manufacture': 'Add {title} Manufacture',
  'financeProduct.edit_product_package': 'Edit {title} Package',
  'financeProduct.edit_product_manufacture': 'Edit {title} Manufacture',
  'financeProduct.package_product_items': 'The product package consists of :',
  'financeProduct.manufacture_product_items': 'The product manufacture consists of :',
  'financeProduct.error_load_product_detail': 'Error load product detail.',
  'financeProduct.cost_of_production': 'The production costs consist of :',
  'financeProduct.product_conversion': 'Product Conversion',
  'financeProduct.total_cost': 'Total cost',
  'financeProduct.total_cost_conversion': 'Total cost of conversion',
  'financeProduct.cost_per_unit': 'Cost per unit',
  'financeProduct.error_cannot_conversion_product': 'Error cannot conversion product',
  'financeProduct.conversion_title': '{title} Conversion {name}',
  'financeProduct.amount': 'Amount',
  'financeProduct.conversion_date': 'Conversion Date',
  'financeProduct.error_load_conversion_product_detail': 'Error load conversion product detail',
  'financeProduct.error_cannot_delete_conversion_product': 'Error cannot delete conversion product',
  'financeProduct.multiplier': 'Multiplier',
  'financeProduct.please_input_the_multiplier': 'Please input the multiplier',
  'financeProduct.per_pcs': 'Per Pcs',
  'financeProduct.unit_conversion': 'Unit Conversion',
  'financeProduct.unit': 'Unit',
  'financeProduct.base_unit': 'Base Unit',
  'financeProduct.add_unit_conversion': 'Add unit conversion',
  'financeProduct.edit_unit_conversion': 'Edit unit conversion',
  'financeProduct.please_input_unit': 'Please input unit',
  'financeProduct.please_input_unit_conv': 'Please input unit conversion',
  'financeProduct.unit_cannot_same': 'Unit cannot be the same',
  'financeProduct.unit_cannot_same_as_conversion': 'Unit cannot be the same as unit conversion',
  'financeProduct.cannot_edit_unit_conversion': 'Cannot edit unit conversion',
  'financeProduct.mass_delete_info':
    '**Product that have a transaction cannot be deleted, can only be archived',
  'financeProduct.archive': 'Archive',
  'financeProduct.show_archive': 'Show Archive',
  'financeProduct.unarchive': 'Unarchive',
  'financeProduct.archived': 'Archived',
  'financeProduct.without_archive': 'Without archive',
  'financeProduct.with_archive': 'with archive',
  'financeProduct.archive_only': 'Archive only',
  'financeProduct.are_you_sure_to_archive': 'Are you sure to archive this product?',
  'financeProduct.are_you_sure_to_unarchive': 'Are you sure to unarchive this product?',
  'financeProduct.future_transactions': 'Future transactions',
  'financeProduct.all_transactions': 'All transactions',
  'financeProduct.current_transaction_will_not_be_modified':
    'Current transactions will not be modified, Only affect future transactions.',
  'financeProduct.current_transaction_and_future_will_be_modified':
    'Current transactions and future transactions will be modified.',
  'financeProduct.purchase_account_cannot_be_modified_for_future_transactions':
    'Purchase account cannot be modified for future transactions, because this product already has transactions.',
  'financeProduct.purchase_account_cannot_be_modified_for_all_transactions':
    'Purchase account cannot be modified for all transactions, because this product already has transactions before lock date or before book closing.',
  'financeProduct.sell_account_cannot_be_modified': 'Sell account cannot be modified.',
  'financeProduct.sell_account_cannot_be_modified_for_all_transactions':
    'Sell account cannot be modified for all transactions, because this product already has transactions before lock date or before book closing.',
  'financeProduct.track_account_cannot_be_modified':
    'Track account cannot be modified because this product already has transactions before lock date or before book closing.',
  'financeProduct.please_choose_account_update_past': 'Please choose account update past setting!',
  'financeProduct.same_as_business_flow': 'Same as business flow setting',
  'financeProduct.please_input_buffer_qty': 'Please input minimum stock',

  'financePurchaseInvoices.import_purchase_invoice_status': 'Import Purchase Invoice Status',
  'financePurchaseInvoices.message': 'Message',
  'financePurchaseInvoices.add_purchaseInvoice': 'Add Purchase Invoice',
  'financePurchaseInvoices.financePurchaseInvoices': 'Purchase Invoice',
  'financePurchaseInvoices.successfully_added': 'successfully added.',
  'financePurchaseInvoices.error_cannot_add': 'Cannot add purchase invoice.',
  'financePurchaseInvoices.error_cannot_print_pdf': 'Cannot print pdf purchase invoice.',
  'financePurchaseInvoices.error_cannot_update': 'Cannot update purchase invoice.',
  'financePurchaseInvoices.error_cannot_move': 'Cannot move the purchase invoice.',
  'financePurchaseInvoices.error_cannot_change_status':
    'Cannot change status the purchase invoice.',
  'financePurchaseInvoices.edit_purchaseInvoice': 'Edit Purchase invoice',
  'financePurchaseInvoices.max_characters': 'Max {value} characters.',
  'financePurchaseInvoices.min_characters': 'Min {value} characters.',
  'financePurchaseInvoices.only_number_allowed': 'Only number allowed.',
  'financePurchaseInvoices.description': 'Description',
  'financePurchaseInvoices.tax': 'Tax',
  'financePurchaseInvoices.select_tax': 'Select tax',
  'financePurchaseInvoices.amount': 'Amount',
  'financePurchaseInvoices.please_select_a_contact': 'Please select a contact',
  'financePurchaseInvoices.select_contact': 'Select contact',
  'financePurchaseInvoices.add_contact': 'Add contact',
  'financePurchaseInvoices.all_contacts': 'All contact',
  'financePurchaseInvoices.transaction_date': 'Transaction Date',
  'financePurchaseInvoices.please_select_transaction_date': 'Please select transaction date',
  'financePurchaseInvoices.due_date': 'Due Date',
  'financePurchaseInvoices.please_select_due_date': 'Please select due date',
  'financePurchaseInvoices.term': 'Term',
  'financePurchaseInvoices.select_term': 'Select term',
  'financePurchaseInvoices.add_term': 'Add term',
  'financePurchaseInvoices.price_include_tax': 'Price include tax',
  'financePurchaseInvoices.add_more': 'Add more',
  'financePurchaseInvoices.number': 'Number',
  'financePurchaseInvoices.please_input_the_number': 'Please input the number.',
  'financePurchaseInvoices.memo': 'Memo',
  'financePurchaseInvoices.date': 'Date',
  'financePurchaseInvoices.status': 'Status',
  'financePurchaseInvoices.balance_due': 'Balance Due',
  'financePurchaseInvoices.total': 'Total',
  'financePurchaseInvoices.please_select_a_term': 'Please select a term.',
  'financePurchaseInvoices.search_by_memo': 'Search',
  'financePurchaseInvoices.the_due_date_max_higger_than_transaction_date':
    'The due date must same or after transaction date',
  'financePurchaseInvoices.the_payment_date_max_higger_than_transaction_date':
    'The payment date must same or after transaction date',
  'financePurchaseInvoices.detail_title': 'Purchase Invoice {number}',
  'financePurchaseInvoices.vendor': 'Vendor',
  'financePurchaseInvoices.reference': 'Reference',
  'financePurchaseInvoices.product': 'Product',
  'financePurchaseInvoices.qty': 'Qty',
  'financePurchaseInvoices.price': 'Price',
  'financePurchaseInvoices.add_product': 'Add Product',
  'financePurchaseInvoices.select_product': 'Select product',
  'financePurchaseInvoices.attachments': 'Attachments',
  'financePurchaseInvoices.save_&_approve': 'Save & approve',
  'financePurchaseInvoices.save_as_draft': 'Save as draft',
  'financePurchaseInvoices.save_&_submit_for_approval': 'Save & submit for approval',
  'financePurchaseInvoices.please_select_a_product': 'Please select a product.',
  'financePurchaseInvoices.please_input_the_qty': 'Please input the qty.',
  'financePurchaseInvoices.please_input_the_price': 'Please input the price.',
  'financePurchaseInvoices.please_input_the_amount': 'Please input the amount.',
  'financePurchaseInvoices.approve': 'Approve',
  'financePurchaseInvoices.are_you_sure_to_approve': 'Are you sure to approve ?',
  'financePurchaseInvoices.submit_for_approval': 'Submit for approval',
  'financePurchaseInvoices.are_you_sure_to_submit_for_approval':
    'Are you sure to submit for approval ?',
  'financePurchaseInvoices.create_invoice': 'Create Invoice',
  'financePurchaseInvoices.amount_paid': 'Amount Paid',
  'financePurchaseInvoices.paid_from': 'Paid From',
  'financePurchaseInvoices.add_payment': 'Add Payment',
  'financePurchaseInvoices.make_a_payment': 'Make a payment',
  'financePurchaseInvoices.select_paid_from': 'Select paid from',
  'financePurchaseInvoices.please_select_a_paid_from': 'Please select a paid from.',
  'financePurchaseInvoices.paid_to': 'Paid To',
  'financePurchaseInvoices.select_paid_to': 'Select paid to',
  'financePurchaseInvoices.please_select_a_paid_to': 'Please select a paid to.',
  'financePurchaseInvoices.error_cannot_add_payment': 'Cannot add payment.',
  'financePurchaseInvoices.payment': 'Payment',
  'financePurchaseInvoices.detail_purchaseInvoice': 'Detail Purchase Invoice',
  'financePurchaseInvoices.number_po': 'PO Number',
  'financePurchaseInvoices.amount': 'Amount',
  'financePurchaseInvoices.amount': 'Amount',

  'financePurchaseInvoices.follow_these_steps_to_import_your_purchase_invoices':
    'Follow these steps to import your purchase invoices',
  'financePurchaseInvoices.1_download_your_purchase_invoices_template_file':
    '1. Download your purchase invoices template file',
  'financePurchaseInvoices.start_by_downloading_our_purchase_invoices_csv':
    'Start by downloading our purchase invoices Excel template file. This file has the correct column headings Kledo needs to import details of your invoices.',
  'financePurchaseInvoices.download_template_file': 'Download Template File',
  'financePurchaseInvoices.download_template_data_example_file':
    'Download template data example file',
  'financePurchaseInvoices.import': 'Import Purchase Invoices',
  'financePurchaseInvoices.2_copy_your_purchase_invoices_into_the_template':
    '2. Copy your purchase invoices into the template',
  'financePurchaseInvoices.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your invoices. Make sure the product details match the column headings provided in the template.',
  'financePurchaseInvoices.important': 'IMPORTANT',
  'financePurchaseInvoices.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financePurchaseInvoices.upload': 'Upload',
  'financePurchaseInvoices.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financePurchaseInvoices.a_maximum_of_500_purchase_invoices_can_be_imported_at_a_time':
    'A maximum of 1.000 purchase invoices can be imported at a time.',
  'financePurchaseInvoices.product_code_that_not_exist_will_be_inserted_as_a_new_product':
    'Product code that not exist will be inserted as a new product.',
  'financePurchaseInvoices.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Contact name that not exist will be inserted as a new contact.',
  'financePurchaseInvoices.import_actions_only_insert_new_purchase_invoices_cannot_update_or_delete':
    'Import actions only insert new purchase invoices, cannot update nor delete.',
  'financePurchaseInvoices.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financePurchaseInvoices.you_can_only_upload_csv_file': 'You can only upload csv or excel file.',
  'financePurchaseInvoices.upload_file': 'Upload File',
  'financePurchaseInvoices.check_the_imported_data': 'Check Data',
  'financePurchaseInvoices.please_check_the_imported_data': 'Please check the imported data',
  'financePurchaseInvoices.import_purchase_invoices_done': 'Import Success',
  'financePurchaseInvoices.error_upload_file': 'Upload failed.',
  'financePurchaseInvoices.file_not_found': 'File not found',
  'financePurchaseInvoices.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `If you are happy to continue with the import please click the "Import Now" button.`,
  'financePurchaseInvoices.valid': 'Valid',
  'financePurchaseInvoices.invalid': 'Invalid',
  'financePurchaseInvoices.total': 'Total',
  'financePurchaseInvoices.import_now': 'Import Now',
  'financePurchaseInvoices.contact_name': 'Contact name',
  'financePurchaseInvoices.email': 'Email',
  'financePurchaseInvoices.address': 'Address',
  'financePurchaseInvoices.phone': 'Phone',
  'financePurchaseInvoices.invoice_date': 'Purchase Invoice Date',
  'financePurchaseInvoices.invoice_number': 'Purchase Invoice Number',
  'financePurchaseInvoices.product_name': 'Product name',
  'financePurchaseInvoices.code_sku': 'Kode/SKU',
  'financePurchaseInvoices.tax_inclusive': 'Tax Inclusive',
  'financePurchaseInvoices.paid': 'Paid',
  'financePurchaseInvoices.paid_account': 'Paid Account',
  'financePurchaseInvoices.copy_link': 'Copy Purchase Invoice Link',
  'financePurchaseInvoices.send_email': 'Send Purchase Invoice Email',
  'financePurchaseInvoices.send_sms': 'Send Purchase Invoice SMS',
  'financePurchaseInvoices.pay_with_credit_memo': 'Pay with credit memo',
  'financePurchaseInvoices.please_select_with_credit_memo': 'Please select with credit memo',
  'financePurchaseInvoices.pay_with_debit_memo': 'Pay with debit memo',
  'financePurchaseInvoices.please_select_with_debit_memo': 'Please select with debit memo',
  'financeInvoices.sales_person': 'Sales Person',
  'financeInvoices.set_paid': 'Set Paid',
  'financeInvoices.mass_payment': 'Mass Payment',
  'financeInvoices.cannot_mass_payment': 'Cannot mass payment',
  'financeInvoices.can_be_paid': 'Can be paid',
  'financeInvoices.cannot_be_paid': 'Cannot be paid',
  'financeInvoices.data_progress_paid': '{count} out of {total} data has been paid',
  'financeInvoices.data_has_been_paid': 'Total {count} data has been paid',
  'financeInvoices.create_mass_trans': 'Create Mass {title}',
  'financeInvoices.can_be_created_to': 'Can be created to {title}',
  'financeInvoices.cannot_be_created_to': 'Cannot created to {title}',
  'financeInvoices.data_progress_create_trans': '{count} out of {total} {title} has been created',
  'financeInvoices.data_has_been_create_trans': 'Total {count} {title} has been created',
  'financeInvoices.please_select_tag': 'Please select tag',

  'financePurchaseInvoices.inventory_account': 'Inventory Account',
  'financePurchaseInvoices.purchase_invoices_successfully_imported':
    '{rowCount} purchase invoice imported successfully.',
  'financePurchaseInvoices.view_detail': 'View detail',
  'financePurchaseInvoices.import_another_file': 'Import another file',
  'financePurchaseInvoices.go_to_purchase_invoice_list': 'Go to Purchase invoice list',
  'financePurchaseInvoices.success': 'Success',
  'financePurchaseInvoices.failed': 'Failed',
  'financePurchaseInvoices.error_cannot_import': 'Import failed',
  'financePurchaseInvoices.error': 'Error',
  'financePurchaseInvoices.make_a_payment': 'Send a payment',

  'financeQuotes.message': 'Message',
  'financeQuotes.add_quote': 'Add Quote',
  'financeQuotes.financeQuotes': 'Quote',
  'financeQuotes.quote_accepted': 'Accepted quote',
  'financeQuotes.quote_open': 'Open quote',
  'financeQuotes.successfully_added': 'successfully added.',
  'financeQuotes.error_cannot_add': 'Cannot add quote.',
  'financeQuotes.error_cannot_print_pdf': 'Cannot print pdf quote.',
  'financeQuotes.error_cannot_update': 'Cannot update quote.',
  'financeQuotes.error_cannot_move': 'Cannot move the quote.',
  'financeQuotes.error_cannot_change_status': 'Cannot change status the quote.',
  'financeQuotes.edit_quote': 'Edit Quote',
  'financeQuotes.max_characters': 'Max {value} characters.',
  'financeQuotes.min_characters': 'Min {value} characters.',
  'financeQuotes.only_number_allowed': 'Only number allowed.',
  'financeQuotes.description': 'Description',
  'financeQuotes.tax': 'Tax',
  'financeQuotes.select_tax': 'Select tax',
  'financeQuotes.amount': 'Amount',
  'financeQuotes.please_select_a_contact': 'Please select a contact',
  'financeQuotes.select_contact': 'Select contact',
  'financeQuotes.add_contact': 'Add contact',
  'financeQuotes.all_contacts': 'All contact',
  'financeQuotes.transaction_date': 'Transaction Date',
  'financeQuotes.please_select_transaction_date': 'Please select transaction date',
  'financeQuotes.due_date': 'Expiry',
  'financeQuotes.please_select_due_date': 'Please select expiry',
  'financeQuotes.term': 'Term',
  'financeQuotes.select_term': 'Select term',
  'financeQuotes.add_term': 'Add term',
  'financeQuotes.price_include_tax': 'Price include tax',
  'financeQuotes.add_more': 'Add more',
  'financeQuotes.number': 'Number',
  'financeQuotes.please_input_the_number': 'Please input the number.',
  'financeQuotes.memo': 'Memo',
  'financeQuotes.date': 'Date',
  'financeQuotes.status': 'Status',
  'financeQuotes.balance_due': 'Balance Due',
  'financeQuotes.total': 'Total',
  'financeQuotes.please_select_a_term': 'Please select a term.',
  'financeQuotes.search_by_memo': 'Search',
  'financeQuotes.the_due_date_max_higger_than_transaction_date':
    'The expiry date must same or after transaction date',
  'financeQuotes.detail_title': 'Quote {number}',
  'financeQuotes.customer': 'Customer',
  'financeQuotes.reference': 'Reference',
  'financeQuotes.product': 'Product',
  'financeQuotes.qty': 'Qty',
  'financeQuotes.price': 'Price',
  'financeQuotes.add_product': 'Add Product',
  'financeQuotes.select_product': 'Select product',
  'financeQuotes.attachments': 'Attachments',
  'financeQuotes.save_as_draft': 'Save as draft',
  'financeQuotes.save_as_draft_add_new': 'Save as draft & Add New',
  'financeQuotes.save_&_submit_for_approval': 'Save & submit for approval',
  'financeQuotes.please_select_a_product': 'Please select a product.',
  'financeQuotes.please_input_the_qty': 'Please input the qty.',
  'financeQuotes.please_input_the_price': 'Please input the price.',
  'financeQuotes.please_input_the_amount': 'Please input the amount.',
  'financeQuotes.mark_as_sent': 'Mark as sent',
  'financeQuotes.are_you_sure_to_mark_as_sent': 'Are you sure to mark as sent ?',
  'financeQuotes.mark_as_accepted': 'Mark as accepted',
  'financeQuotes.are_you_sure_to_mark_as_accepted': 'Are you sure to mark as accepted ?',
  'financeQuotes.mark_as_declined': 'Mark as declined',
  'financeQuotes.are_you_sure_to_mark_as_declined': 'Are you sure to mark as declined ?',
  'financeQuotes.create_invoice': 'Create Invoice',
  'financeQuotes.send': 'Send',
  'financeQuotes.send_add_new': 'Send & Add New',
  'financeQuotes.detail_quote': 'Detail Quote',
  'financeQuotes.create_order': 'Create Order',

  'financeQuotes.follow_these_steps_to_import_your_quotes':
    'Follow these steps to import your quotes',
  'financeQuotes.1_download_your_quotes_template_file': '1. Download your quotes template file',
  'financeQuotes.start_by_downloading_our_quotes_csv':
    'Start by downloading our quotes Excel template file. This file has the correct column headings Kledo needs to import details of your quotes.',
  'financeQuotes.download_template_file': 'Download Template File',
  'financeQuotes.download_template_data_example_file': 'Download template data example file',
  'financeQuotes.import': 'Import Quotes',
  'financeQuotes.2_copy_your_quotes_into_the_template': '2. Copy your quotes into the template',
  'financeQuotes.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your quotes. Make sure the product details match the column headings provided in the template.',
  'financeQuotes.important': 'IMPORTANT',
  'financeQuotes.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeQuotes.upload': 'Upload',
  'financeQuotes.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeQuotes.a_maximum_of_500_quotes_can_be_imported_at_a_time':
    'A maximum of 1.000 quotes can be imported at a time.',
  'financeQuotes.product_code_that_not_exist_will_be_inserted_as_a_new_product':
    'Product code that not exist will be inserted as a new product.',
  'financeQuotes.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Contact name that not exist will be inserted as a new contact.',
  'financeQuotes.import_actions_only_insert_new_quotes_cannot_update_or_delete':
    'Import actions only insert new quotes, cannot update nor delete.',
  'financeQuotes.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeQuotes.you_can_only_upload_csv_file': 'You can only upload csv or excel file.',
  'financeQuotes.upload_file': 'Upload File',
  'financeQuotes.check_the_imported_data': 'Check Data',
  'financeQuotes.please_check_the_imported_data': 'Please check the imported data',
  'financeQuotes.import_quotes_done': 'Import Success',
  'financeQuotes.error_upload_file': 'Upload failed.',
  'financeQuotes.file_not_found': 'File not found',
  'financeQuotes.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `If you are happy to continue with the import please click the "Import Now" button.`,
  'financeQuotes.valid': 'Valid',
  'financeQuotes.invalid': 'Invalid',
  'financeQuotes.total': 'Total',
  'financeQuotes.import_now': 'Import Now',
  'financeQuotes.contact_name': 'Contact name',
  'financeQuotes.email': 'Email',
  'financeQuotes.address': 'Address',
  'financeQuotes.phone': 'Phone',
  'financeQuotes.quote_date': 'Quote Date',
  'financeQuotes.quote_number': 'Quote Number',
  'financeQuotes.product_name': 'Product name',
  'financeQuotes.code_sku': 'Kode/SKU',
  'financeQuotes.tax_inclusive': 'Tax Inclusive',
  'financeQuotes.paid': 'Paid',
  'financeQuotes.paid_account': 'Paid To Account',
  'financeQuotes.copy_link': 'Copy Quote Link',
  'financeQuotes.send_email': 'Send Quote Email',
  'financeQuotes.send_sms': 'Send Quote SMS',
  'financeQuotes.send_wa': 'Send Quote Whatsapp',

  'financeQuotes.inventory_account': 'Inventory Account',
  'financeQuotes.quotes_successfully_imported': '{rowCount} quote imported successfully.',
  'financeQuotes.view_detail': 'View detail',
  'financeQuotes.import_another_file': 'Import another file',
  'financeQuotes.go_to_quote_list': 'Go to quote list',
  'financeQuotes.success': 'Success',
  'financeQuotes.failed': 'Failed',
  'financeQuotes.error_cannot_import': 'Import failed',
  'financeQuotes.error': 'Error',
  'financeQuotes.print_quote': 'Print Quotation',

  'pos.pos': 'POS',
  'pos.pos_activity': 'POS Activity',
  'pos.transfer_to_room': 'Transfer to Room',
  'pos.please_choose_a_table': 'please choose a table!',
  'pos.failed_list_item_null': 'failed list item null!',
  'pos.harga_item': 'Harga item',
  'pos.activity': 'Activity',
  'pos.saved_bill': 'Saved Bill',
  'pos.items': 'Items',
  'pos.total_harga_item': 'Total harga item',
  'pos.discount': 'Discount',
  'pos.service_charge': 'Service Charge',
  'pos.tax': 'Tax',
  'pos.total': 'Total',
  'pos.pay': 'Pay',
  'pos.save_bill': 'Save Bill',
  'pos.list_save_bill': 'List Save Bill',
  'pos.table': 'Table',
  'pos.select_table': 'select table',
  'pos.receipt_number': 'Receipt Number',
  'pos.details': 'Details',
  'pos.payment': 'Payment',
  'pos.date_range': 'Date Range',
  'pos.unpaid': 'unpaid',
  'pos.paid_cashier': 'paid cashier',
  'pos.transfer_to_room_': 'transfer to room',
  'pos.please_select_date_range': 'please select date range',
  'pos.select': 'Select',
  'pos.room': 'Room',
  'pos.name': 'Name',
  'pos.checking': 'Checking',
  'pos.transfer': 'Transfer',
  'pos.new_sale': 'New Sale',
  'pos.transfer_to': 'Transfer To',
  'pos.guest_name': 'Guest Name',
  'pos.pos_outlets': 'POS Outlets',
  'pos.category': 'Category',
  'pos.variant_name': 'Variant Name',
  'pos.price': 'Price',
  'pos.modify_variant': 'Modify Variant',
  'pos.variant_of': 'Variant of',
  'pos.add_variant_category': 'Add Variant Category',
  'pos.add_variant': 'Add Variant',
  'pos.product_name': 'Product Name',
  'pos.add_table': 'Add Table',
  'pos.enable_table_for_this_outlet': 'Enable table for this outlet',
  'pos.add_product': 'Add Product',
  'pos.add_category': 'Add Category',
  'pos.product': 'Product',
  'pos.settings': 'Settings',
  'pos.name_category_required': 'Name category required',
  'pos.edit_category': 'Edit Category',
  'pos.category_name': 'Category Name',
  'pos.change_multi_required': 'change multi required',
  'pos.one_variant_per_product_sale': 'One Variant per product sale',
  'pos.multiple_variant_per_product_sale': 'Multiple Variant per product sale',
  'pos.edit_variant_category': 'Edit Variant Category',
  'pos.add_tables': 'Add Tables',
  'pos.name_table_required': 'name table required',
  'pos.tables': 'Tables',
  'pos.edit_tables': 'Edit Tables',
  'pos.name_required': 'name required',
  'pos.tax_required': 'tax required',
  'pos.service_charge': 'Service Charge',
  'pos.service_charge_required': 'service charge required',
  'pos.product_image': 'Product Image',
  'pos.product_image_required': 'Product Image required',
  'pos.choose_category_required': 'choose category required',
  'pos.choose_category': 'Choose Category',
  'pos.product_name_required': 'product name required',
  'pos.price': 'Price',
  'pos.price_required': 'price required',
  'pos.extra_price_must_greather_than_0': 'Extra price must greather than 0.',
  'pos.base_price': 'Base Price',
  'pos.base_price_required': 'Base Price required',
  'pos.description': 'Description',
  'pos.description_required': 'Description required',
  'pos.edit_product': 'Edit Product',
  'pos.variant': 'Variant',
  'pos.variant_name_required': 'variant name required',
  'pos.edit_variant': 'Edit Variant',

  'financeInvoices.order_payment': 'Order Payment',
  'financeInvoices.builtin_notes_for_easy_searching': 'Built-in notes for easy searching.',
  'financeInvoices.the_number_will_be_automatically_generated_by_the_system':
    'The number will be automatically generated by the system, but you can also change it here. You can set the format of this number {link}.',
  'financeInvoices.used_to_create_transaction_categories':
    'Used to create transaction categories. You can also generate reports based on this tag.',
  'financeInvoices.message': 'Message',
  'financeInvoices.here': 'here',
  'financeInvoices.add_invoice': 'Add Invoice',
  'financeInvoices.financeInvoices': 'Invoice',
  'financeInvoices.witholding': 'Witholding',
  'financeInvoices.select_holding_from': 'Select holding from',
  'financeInvoices.please_select_a_holding_from': 'Please select a holding from',
  'financeInvoices.successfully_added': 'successfully added.',
  'financeInvoices.error_cannot_add': 'Cannot add invoice.',
  'financeInvoices.error_cannot_print_pdf': 'Cannot print pdf invoice.',
  'financeInvoices.error_cannot_export_receipt': 'Export receipt failed',
  'financeInvoices.error_cannot_update': 'Cannot update invoice.',
  'financeInvoices.error_cannot_move': 'Cannot move the invoice.',
  'financeInvoices.error_cannot_change_status': 'Cannot change status the invoice.',
  'financeInvoices.edit_invoice': 'Edit Invoice',
  'financeInvoices.max_characters': 'Max {value} characters.',
  'financeInvoices.min_characters': 'Min {value} characters.',
  'financeInvoices.only_number_allowed': 'Only number allowed.',
  'financeInvoices.description': 'Description',
  'financeInvoices.tax': 'Tax',
  'financeInvoices.select_tax': 'Select tax',
  'financeInvoices.amount': 'Amount',
  'financeInvoices.please_select_a_contact': 'Please select a contact',
  'financeInvoices.select_contact': 'Select contact',
  'financeInvoices.add_contact': 'Add contact',
  'financeInvoices.all_contacts': 'All contact',
  'financeInvoices.transaction_date': 'Transaction Date',
  'financeInvoices.transaction': 'Transaction',
  'financeInvoices.please_select_transaction_date': 'Please select transaction date',
  'financeInvoices.due_date': 'Due Date',
  'financeInvoices.please_select_due_date': 'Please select due date',
  'financeInvoices.term': 'Term',
  'financeInvoices.select_term': 'Select term',
  'financeInvoices.add_term': 'Add term',
  'financeInvoices.price_include_tax': 'Price include tax',
  'financeInvoices.add_more': 'Add more',
  'financeInvoices.number': 'Number',
  'financeInvoices.please_input_the_number': 'Please input the number.',
  'financeInvoices.memo': 'Memo',
  'financeInvoices.date': 'Date',
  'financeInvoices.payment_date': 'Payment Date',
  'financeInvoices.paid_date': 'Paid Date',
  'financeInvoices.show_payment_amount': 'Show payment amount',
  'financeInvoices.hide_payment_amount': 'Hide payment amount',
  'financeInvoices.status': 'Status',
  'financeInvoices.balance_due': 'Balance Due',
  'financeInvoices.total': 'Total',
  'financeInvoices.please_select_a_term': 'Please select a term.',
  'financeInvoices.search_by_memo': 'Search',
  'financeInvoices.the_due_date_max_higger_than_transaction_date':
    'The due date must same or after transaction date',
  'financeInvoices.detail_title': ' Invoice {number}',
  'financeInvoices.customer': 'Customer',
  'financeInvoices.reference': 'Reference',
  'financeInvoices.product': 'Product',
  'financeInvoices.product_type': 'Product Type',
  'financeInvoices.qty': 'Qty',
  'financeInvoices.price': 'Price',
  'financeInvoices.add_product': 'Add Product',
  'financeInvoices.select_product': 'Select product',
  'financeInvoices.attachments': 'Attachments',
  'financeInvoices.save_&_approve': 'Save & approve',
  'financeInvoices.save_as_draft': 'Save as draft',
  'financeInvoices.save_&_submit_for_approval': 'Save & submit for approval',
  'financeInvoices.please_select_a_product': 'Please select a product.',
  'financeInvoices.please_input_the_qty': 'Please input the qty.',
  'financeInvoices.please_input_the_price': 'Please input the price.',
  'financeInvoices.please_input_the_amount': 'Please input the amount.',
  'financeInvoices.approve': 'Approve',
  'financeInvoices.are_you_sure_to_approve': 'Are you sure to approve ?',
  'financeInvoices.submit_for_approval': 'Submit for approval',
  'financeInvoices.are_you_sure_to_submit_for_approval': 'Are you sure to submit for approval ?',
  'financeInvoices.create_invoice': 'Create Invoice',
  'financeInvoices.amount_paid': 'Amount Paid',
  'financeInvoices.add_payment': 'Receive Payment',
  'financeInvoices.receive_a_payment': 'Receive a payment',
  'financeInvoices.error_cannot_add_payment': 'Cannot add payment.',
  'financeInvoices.payment': 'Payment',
  'financeInvoices.detail_invoice': 'Detail  Invoice',
  'financeInvoices.number_quotes': 'Quotes Number',
  'financeInvoices.amount': 'Amount',
  'financeInvoices.tax_code': 'Tax Code',
  'financeInvoices.please_input_the_tax_code': 'Please input the tax code.',
  'financeInvoices.error_cannot_change_tax_code': 'Cannot change tax code.',
  'financeInvoices.order': 'Order Number',
  'financeInvoices.quote': 'Quote Number',
  'financeInvoices.delivery': 'Delivery Number',
  'financeInvoices.please_select_deliveries': 'Please select at least one delivery',
  'financeInvoices.cannot_greather_than_order': 'Cannot greather than order.',
  'financeInvoices.down_payment': 'Down payment',
  'financeInvoices.already_paid': 'Paid',
  'financeInvoices.print_invoice': 'Invoice',
  'financeInvoices.whoever_have_this_link_can_see_pdf':
    'Whoever has this link can see the invoice as PDF format.',
  'financeInvoices.copy_link': 'Copy Invoice Link',
  'financeInvoices.send_email': 'Send Invoice Email',
  'financeInvoices.send_sms': 'Send Invoice SMS',
  'financeInvoices.send_wa': 'Send Invoice Whatsapp',
  'financeInvoices.on': 'on',
  'financeInvoices.last_printed_by': 'Last printed by',
  'financeInvoices.last_modified_by': 'Last modified by',
  'financeInvoices.preorder_number': 'PO Number',
  'financeInvoices.total_before_tax': 'Total Before Tax',
  'financeInvoices.printStatus': 'Print Status',
  'financeInvoices.import_new_invoice': 'Import New Invoice',
  'financeInvoices.import_invoice_status': 'Import Invoice Status',
  'financeInvoices.print_partial': 'Partial Invoice',
  'financeInvoices.print_delivery_label': 'Delivery Label',
  'financeInvoices.print_delivery': 'Delivery Order',
  'financeInvoices.print_payment': 'Payment Recap',
  'financeInvoices.print_tax': 'Tax Invoice',
  'financeInvoices.download_word': 'Invoice MsWord',
  'financeInvoices.default_label_term': 'Term Invoice I',
  'financeInvoices.please_input_label': 'Please input the label',
  'financeInvoices.insufficient_stock': 'Insufficient Stock',
  'financeInvoices.insufficient_stock_confirm':
    'There is a product that has insufficient stock, Will you still make this bill ?',
  'financeInvoices.insufficient_stock_warning':
    'Cannot add to the bill, because there are products that have insufficient stock.',

  'financeInvoices.sales': 'Sales',
  'financeInvoices.print_receipt': 'Print Invoices Receipt',
  'financeInvoices.download': 'Download',
  'financeInvoices.download_sales_report': 'Download Sales Report',
  'financeInvoices.my_order': 'My Order',
  'financeInvoices.all': 'All',
  'financeInvoices.export': 'Export',
  'financeInvoices.orders': 'Orders',
  'financeInvoices.order_review': 'Order Review',
  'financeInvoices.manage_order': 'Manage Order',
  'financeInvoices.download_order': 'Download Order',
  'financeInvoices.export_all_order': 'Export All Order',
  'financeInvoices.delivery_list': 'Delivery List',
  'financeInvoices.done': 'Done',
  'financeInvoices.export_invoice': 'Export E-Invoice',
  'financeInvoices.choose_template': 'Choose Template',
  'financeInvoices.import_with_kledo_template': 'Import with Excel template format from Kledo',
  'financeInvoices.marketplace_connect_sync_automatic_from_marketplace_without_import':
    'Marketplace Connect: Sync data automatically from marketplace to Kledo, without import',
  'financeInvoices.import_with_marketplace_template':
    'Import with Excel template format from Marketplace',
  'financeInvoices.export_sales_data_from_tokopedia': '1. Export Sales Data from Tokopedia',
  'financeInvoices.export_sales_data_from_shopee': '1. Export Sales Data from Shopee',
  'financeInvoices.export_sales_data_from_bukalapak': '1. Export Sales Data from Bukalapak',
  'financeInvoices.export_sales_data_from_lazada': '1. Export Sales Data from Lazada',
  'financeInvoices.export_sales_data_from_tiktok': '1. Export Sales Data from Tiktok Shop',
  'financeInvoices.export_sales_data_record_shipping_cost_to_bill': 'Record Shipping Cost To Bill',
  'financeInvoices.upload_excel_from_tokopedia': '2. Upload Excel File from Tokopedia Here',
  'financeInvoices.upload_excel_from_shopee': '2. Upload Excel File from Shopee Here',
  'financeInvoices.upload_excel_from_bukalapak': '2. Upload Excel File from Bukalapak Here',
  'financeInvoices.upload_excel_from_lazada': '2. Upload Excel File from Lazada Here',
  'financeInvoices.upload_excel_from_tiktok': '2. Upload Excel File from Tiktok Shop Here',
  'financeInvoices.set_import_option': '3. Set Import Option',
  'financeInvoices.1a_tokopedia': 'a. On Home page, click tab {sales}',
  'financeInvoices.1b_tokopedia': 'b. Click {download} button',
  'financeInvoices.1c_tokopedia':
    'c. Filter sales report time range that you want to download, then click {download} button. Save the recently downloaded Excel file',
  'financeInvoices.1a_shopee':
    'a. Login on shopee seller center application, then click {myOrder} menu',
  'financeInvoices.1b_shopee': 'b. Click {all} tab',
  'financeInvoices.1c_shopee':
    'c. Filter sales report time range that you want to download on Data Period column, then click {export} button. Save the recently downloaded Excel file',
  'financeInvoices.1a_bukalapak': 'a. On Home page, click {transaction} tab',
  'financeInvoices.1b_bukalapak': 'b. Click {downloadTransaction} button',
  'financeInvoices.1c_bukalapak':
    'c. Filter time range and sales report transaction that you want to download, then click {downloadTransaction} button. Save the recently downloaded Excel file',
  'financeInvoices.1a_lazada': 'a. On Home page, click {orders} tab, then click {orderReview} tab',
  'financeInvoices.1b_lazada': 'b. Click {export} button, then click {exportAllOrder}',
  'financeInvoices.1c_lazada':
    'c. For sales report that you want to download, click {download} button, then click {done}. Save the recently downloaded Excel file',
  'financeInvoices.1a_tiktok': 'a. Click {order} - {manageOrder} menu',
  'financeInvoices.1b_tiktok': 'b. Click {done} tab',
  'financeInvoices.1c_tiktok': 'c. Click {downloadOrder} menu',
  'financeInvoices.1d_tiktok': 'd. Select Excel download format',
  'financeInvoices.1e_tiktok': 'e. Check {deliveryList} on {chooseTemplate}',
  'financeInvoices.1f_tiktok': 'f. Click {download} button',
  'financeInvoices.this_import_will_change_status':
    'This import feature will change invoice status that has not paid to paid.',
  'financeInvoices.data_will_not_change_even_if_different':
    'Invoice data will not be changed on this import, even if the data on the kledo system with the data on the excel file is different.',
  'financeInvoices.follow_these_steps_to_import_your_invoices':
    'Follow these steps to import your invoices',
  'financeInvoices.1_download_your_invoices_template_file':
    '1. Download your invoices template file',
  'financeInvoices.start_by_downloading_our_invoices_csv':
    'Start by downloading our invoices Excel template file. This file has the correct column headings Kledo needs to import details of your invoices.',
  'financeInvoices.download_template_file': 'Download Template File',
  'financeInvoices.download_template_data_example_file': 'Download template data example file',
  'financeInvoices.import': 'Import Invoices',
  'financeInvoices.2_copy_your_invoices_into_the_template':
    '2. Copy your invoices into the template',
  'financeInvoices.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your invoices. Make sure the product details match the column headings provided in the template.',
  'financeInvoices.important': 'IMPORTANT',
  'financeInvoices.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeInvoices.upload': 'Upload',
  'financeInvoices.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeInvoices.a_maximum_of_500_invoices_can_be_imported_at_a_time':
    'A maximum of 1.000 invoices can be imported at a time.',
  'financeInvoices.product_code_that_not_exist_will_be_inserted_as_a_new_product':
    'Product code that not exist will be inserted as a new product.',
  'financeInvoices.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Contact name that not exist will be inserted as a new contact.',
  'financeInvoices.import_actions_only_insert_new_invoices_cannot_update_or_delete':
    'Import actions only insert new invoices, cannot update nor delete.',
  'financeInvoices.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeInvoices.you_can_only_upload_csv_file': 'You can only upload csv or excel file.',
  'financeInvoices.upload_file': 'Upload File',
  'financeInvoices.check_the_imported_data': 'Check Data',
  'financeInvoices.please_check_the_imported_data': 'Please check the imported data',
  'financeInvoices.import_invoices_done': 'Import Success',
  'financeInvoices.error_upload_file': 'Upload failed.',
  'financeInvoices.file_not_found': 'File not found',
  'financeInvoices.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `If you are happy to continue with the import please click the "Import Now" button.`,
  'financeInvoices.valid': 'Valid',
  'financeInvoices.invalid': 'Invalid',
  'financeInvoices.total': 'Total',
  'financeInvoices.import_now': 'Import Now',
  'financeInvoices.contact_name': 'Contact name',
  'financeInvoices.email': 'Email',
  'financeInvoices.address': 'Address',
  'financeInvoices.phone': 'Phone',
  'financeInvoices.invoice_date': 'Invoice Date',
  'financeInvoices.invoice_number': 'Invoice Number',
  'financeInvoices.product_name': 'Product name',
  'financeInvoices.code_sku': 'Kode/SKU',
  'financeInvoices.tax_inclusive': 'Tax Inclusive',
  'financeInvoices.paid': 'Paid',
  'financeInvoices.paid_account': 'Paid To Account',
  'financeInvoices.row_imported': '{imported} of {total} rows imported',

  'financeInvoices.inventory_account': 'Inventory Account',
  'financeInvoices.invoices_successfully_imported': '{rowCount} invoice imported successfully.',
  'financeInvoices.view_detail': 'View detail',
  'financeInvoices.import_another_file': 'Import another file',
  'financeInvoices.go_to_invoice_list': 'Go to invoice list',
  'financeInvoices.success': 'Success',
  'financeInvoices.failed': 'Failed',
  'financeInvoices.error_cannot_import': 'Import failed',
  'financeInvoices.error': 'Error',
  'financeInvoices.import_invoices_in_progress': 'Import in progress',
  'financeInvoices.import_invoices_is_still_in_progress': 'Import is still in progress',
  'financeInvoices.its_ok_to_close_this_page': `It's ok OK to close this page or import another file. When import done, you will be notified via email.`,
  'financeInvoices.duplicate_to_trans': 'Duplicate to transaction',

  'financeInvoices.you_can_only_upload_up_to': `You can only upload up to {count} files`,
  'financeInvoices.cash_payment': 'Cash Payment',
  'financeInvoices.with_credit_payment': 'With Credit Memo',
  'financeInvoices.with_debit_payment': 'With Debit Memo',
  'financeInvoices.payment_with_credit_memo': 'Payment using Credit Memo',
  'financeInvoices.payment_with_debit_memo': 'Payment using Debit Memo',

  'financeBanks.edit_bank_statement': 'Edit Bank Statement',
  'financeBanks.are_you_sure_to_revert': 'Are you sure to revert ?',
  'financeBanks.error_cannot_revert': 'Cannot revert',
  'financeBanks.add_bank': 'Add Bank',
  'financeBanks.financeBanks': 'Cash & Bank Accounts',
  'financeBanks.manage_account': 'Manage Account',
  'financeBanks.statement_balance': 'Statement Balance',
  'financeBanks.balance_in_kledo': 'Balance in kledo',
  'financeBanks.successfully_added': 'successfully added.',
  'financeBanks.error_cannot_add': 'Cannot add bank.',
  'financeBanks.error_cannot_update': 'Cannot update bank.',
  'financeBanks.error_cannot_move': 'Cannot move the bank.',
  'financeBanks.error_cannot_change_status': 'Cannot change status the bank.',
  'financeBanks.edit_bank': 'Edit  bank',
  'financeBanks.max_characters': 'Max {value} characters.',
  'financeBanks.min_characters': 'Min {value} characters.',
  'financeBanks.only_number_allowed': 'Only number allowed.',
  'financeBanks.description': 'Description',
  'financeBanks.number': 'Number',
  'financeBanks.max_characters': 'Max {value} characters.',
  'financeBanks.min_characters': 'Min {value} characters.',
  'financeBanks.tax': 'Tax',
  'financeBanks.select_tax': 'Select tax',
  'financeBanks.amount': 'Amount',
  'financeBanks.please_select_a_contact': 'Please select a contact',
  'financeBanks.select_contact': 'Select contact',
  'financeBanks.add_contact': 'Add contact',
  'financeBanks.all_contacts': 'All contact',
  'financeBanks.contact': 'Contact',
  'financeBanks.transaction_date': 'Transaction Date',
  'financeBanks.please_select_transaction_date': 'Please select transaction date',
  'financeBanks.due_date': 'Due Date',
  'financeBanks.please_select_due_date': 'Please select due date',
  'financeBanks.term': 'Term',
  'financeBanks.select_term': 'Select term',
  'financeBanks.add_term': 'Add term',
  'financeBanks.price_include_tax': 'Price include tax',
  'financeBanks.add_more': 'Add more',
  'financeBanks.number': 'Number',
  'financeBanks.please_input_the_number': 'Please input the number.',
  'financeBanks.memo': 'Memo',
  'financeBanks.date': 'Date',
  'financeBanks.status': 'Status',
  'financeBanks.balance_due': 'Balance Due',
  'financeBanks.total': 'Total',
  'financeBanks.please_select_a_account': 'Please select a account.',
  'financeBanks.search_by_memo': 'Search',
  'financeBanks.the_due_date_max_higger_than_transaction_date':
    'The due date must same or after transaction date',
  'financeBanks.detail_title': ' Transaction: {transTypeName}',
  'financeBanks.customer': 'Customer',
  'financeBanks.reference': 'Reference',
  'financeBanks.attachments': 'Attachments',
  'financeBanks.save_&_approve': 'Save & approve',
  'financeBanks.save_as_draft': 'Save as draft',
  'financeBanks.save_&_submit_for_approval': 'Save & submit for approval',
  'financeBanks.please_input_the_amount': 'Please input the amount.',
  'financeBanks.approve': 'Approve',
  'financeBanks.are_you_sure_to_approve': 'Are you sure to approve ?',
  'financeBanks.submit_for_approval': 'Submit for approval',
  'financeBanks.are_you_sure_to_submit_for_approval': 'Are you sure to submit for approval ?',
  'financeBanks.create_bank': 'Create Bank',
  'financeBanks.amount_paid': 'Amount Paid',
  'financeBanks.paid_from': 'Paid From',
  'financeBanks.add_payment': 'Add Payment',
  'financeBanks.make_a_payment': 'Make a payment',
  'financeBanks.select_paid_from': 'Select paid from',
  'financeBanks.please_select_a_paid_from': 'Please select a paid from.',
  'financeBanks.error_cannot_add_payment': 'Cannot add payment.',
  'financeBanks.payment': 'Payment',
  'financeBanks.detail_bank': 'Detail  Bank',
  'financeBanks.spent': 'Spent',
  'financeBanks.received': 'Received',
  'financeBanks.balance': 'Balance',
  'financeBanks.transfer_money': 'Transfer Money',
  'financeBanks.spend_money': 'Spend Money',
  'financeBanks.receive_money': 'Receive Money',
  'financeBanks.auto_import_bank_statement': 'Auto Import Bank Statement',
  'financeBanks.manually_import_bank_statement': 'Manually Import Bank Statement',
  'financeBanks.from': 'From',
  'financeBanks.to': 'To',
  'financeBanks.account': 'Account',
  'financeBanks.bank_account': 'Bank Account',
  'financeBanks.account_transactions': 'Account Transactions',
  'financeBanks.bank_statements': 'Bank Statements',
  'financeBanks.reconcile': 'Reconcile',
  'financeBanks.reconcile_mass_reconcile_title': 'Mass Reconciliation',
  'financeBanks.reconcile_mass_can_be_reconcile': 'Can be reconciled',
  'financeBanks.reconcile_mass_cannot_be_reconcile': 'Cannot be reconciled',
  'financeBanks.reconcile_mass_reconcile_are_you_sure':
    'Are you sure you want to reconcile {count} transaction data?',
  'financeBanks.reconcile_data_progress_deleted':
    '{count} data from total {total} has been reconciled',
  'massDelete.data_has_been_deleted': 'Total {count} data has been successfully reconciled',
  'financeBanks.search_by_reference': 'Search',
  'financeBanks.please_select_a_from_account': 'Please select a from account',
  'financeBanks.please_select_a_to_account': 'Please select a to account',
  'financeBanks.transfer': 'Transfer',
  'financeBanks.edit_account_transaction': 'Edit Account Transaction',
  'financeBanks.error_cannot_transfer_money': 'Error cannot transfer money',
  'financeBanks.the_to_account_cannot_be_same_with_from_account':
    'The to account cannot be same with from account.',
  'financeBanks.the_from_account_cannot_be_same_with_to_account':
    'The from account cannot be same with to account.',
  'financeBanks.new_transaction': 'New Transaction',
  'financeBanks.source': 'Source',
  'financeBanks.confirm_delete_bank_statement_p1': 'This will change your bank statement balance.',
  'financeBanks.confirm_delete_bank_statement_p2':
    'Usually this action only taken to delete duplicate transaction.',
  'financeBanks.follow_these_steps_to_import_your_transactions':
    'Follow these steps to import your transactions',
  'financeBanks.import_bank_transactions': 'Import Bank Transactions',
  'financeBanks.you_can_only_upload_csv_file': 'You can only upload csv or excel file.',
  'financeBanks.upload_file': 'Upload',
  'financeBanks.set_import_options': 'Set Options',
  'financeBanks.import_statement_done': 'Done',
  'financeBanks.error_upload_file': 'Error upload file.',
  'financeBanks.file_not_found': 'File not found.',
  'financeBanks.statement_heading': 'Statement Heading',
  'financeBanks.statement_value': 'Statement Value',
  'financeBanks.assign_to': 'Assign To',
  'financeBanks.please_select_a_assign_to': 'Please select a assign to.',
  'financeBanks.select_assign_to': 'Select assign to',
  'financeBanks.rows_uploaded_set_the_import_options':
    '{rowCount} rows uploaded. Set the import options below and click Import Now button to continue.',
  'financeBanks.please_select_a_date_format': 'Please select a date format.',
  'financeBanks.select_date_format': 'Select date format',
  'financeBanks.dont_import_the_first_line':
    "Don't import the first line because they are column headings.",
  'financeBanks.import_now': 'Import Now',
  'financeBanks.import': 'Import',
  'financeBanks.date_format': 'Date Format',
  'financeBanks.error_cannot_import': 'Error cannot import.',
  'financeBanks.1_in_a_new_window': '1. In a new window, go to your bank web site.',
  'financeBanks.2_download_your_bank_statement': '2. Download your bank statement.',
  'financeBanks.file_type_must_be_csv': 'File type must be CSV',
  'financeBanks.3_upload_the_bank_statement_here': '3. Upload the bank statement here.',
  'financeBanks.download_our_csv_template': 'Download our CSV template',
  'financeBanks.to_create_your_own_bank_statement_file': ' to create your own bank statement file.',
  'financeBanks.import_maximum_of_1000_bank_statement_line_at_a_time':
    'Import a maximum of 1000 bank statement line at a time.',
  'financeBanks.drop_the_file_to_upload': 'Drop the file to upload',
  'financeBanks.transaction_lines_successfully_imported':
    '{rowCount} transaction lines successfully imported.',
  'financeBanks.view_detail': 'View detail',
  'financeBanks.import_another_file': 'Import another file',
  'financeBanks.go_to_reconciliation': 'Go to reconciliation',
  'financeBanks.success': 'Success',
  'financeBanks.failed': 'Failed',
  'financeBanks.invalid_date': 'Invalid Date',
  'financeBanks.invalid_transaction_type': 'Invalid Transaction Type',
  'financeBanks.invalid_amount': 'Invalid Amount',
  'financeBanks.total': 'Total',
  'financeBanks.review_your_bank_statement': 'Review your bank statement...',
  'financeBanks.then_match_with_your_transactions_in_kledo':
    '...then match with your transactions in Kledo',
  'financeBanks.bank_statement': 'Bank Statement',
  'financeBanks.go': 'Go',
  'financeBanks.show_received_item': 'Show Received Items',
  'financeBanks.find_&_match': 'Find & Match',
  'financeBanks.kledo_transaction': 'Kledo Transaction',
  'financeBanks.subtotal': 'Subtotal',
  'financeBanks.must_match': 'Must Match',
  'financeBanks.difference': 'Difference',
  'financeBanks.find_&_select_matching_transactions': '1. Find & select matching transactions',
  'financeBanks.error_cannot_reconcile': 'Error cannot reconcile.',
  'financeBanks.are_you_sure_to_reconcile': 'Are you sure to reconcile ?',
  'financeBanks.difference_must_be_zero': 'Difference must be 0.',
  'financeBanks.view_your_selected_transactions':
    '2. View your selected transactions, Add new transactions, as needed.',
  'financeBanks.the_sum_of_your_selected_transactions_must_match_the_money_spent':
    '3. The sum of your selected transactions must match the money spent. Make adjustments, as needed.',
  'financeBanks.show_spent_item': 'Show Spent Items',
  'financeBanks.to_reconcile': 'To',
  'financeBanks.transactions': 'Transactions',
  'financeBanks.minimal_value': 'Minimal value is {value}',
  'financeBanks.maximal_value': 'Maximal value is {value}',
  'financeBanks.error_cannot_print_pdf': 'Cannot print pdf bank.',
  'financeBanks.print_receipt': 'Print Receipt',
  'financeBanks.print_remittance_advice': 'Print Remittance Advice',
  'financeBanks.print_cash_receipt': 'Print Cash Receipt',
  'financeBanks.print_expense_slip': 'Print Expense Slip',
  'financeBanks.print_purchase_payment': 'Print Remittance Advice',
  'financeBanks.print_transfer': 'Print Transfer',
  'financeBanks.copy_link_receipt': 'Copy Receipt Link',
  'financeBanks.copy_link_remittance_advice': 'Copy Remittance Advice Link',
  'financeBanks.copy_link_cash_receipt': 'Copy Cash Receipt Link',
  'financeBanks.copy_link_expense_slip': 'Copy Expense Slip Link',
  'financeBanks.copy_link_purchase_payment': 'Copy Remittance Advice Link',
  'financeBanks.copy_link_transfer': 'Copy Transfer Link',
  'financeBanks.send_email_receipt': 'Send Receipt Email',
  'financeBanks.send_email_remittance_advice': 'Send Remittance Advice Email',
  'financeBanks.send_email_cash_receipt': 'Send Cash Receipt Email',
  'financeBanks.send_email_expense_slip': 'Send Expense Slip Email',
  'financeBanks.send_email_purchase_payment': 'Send Remittance Advice Email',
  'financeBanks.send_sms_receipt': 'Send Receipt SMS',
  'financeBanks.send_sms_remittance_advice': 'Send Remittance Advice SMS',
  'financeBanks.send_sms_cash_receipt': 'Send Cash Receipt SMS',
  'financeBanks.send_sms_expense_slip': 'Send Expense Slip SMS',
  'financeBanks.send_sms_purchase_payment': 'Send Remittance Advice SMS',
  'financeBanks.send_wa_receipt': 'Send Receipt Whatsapp',
  'financeBanks.send_wa_remittance_advice': 'Send Remittance Advice Whatsapp',
  'financeBanks.send_wa_cash_receipt': 'Send Cash Receipt Whatsapp',
  'financeBanks.send_wa_expense_slip': 'Send Expense Slip Whatsapp',
  'financeBanks.send_wa_purchase_payment': 'Send Remittance Advice Whatsapp',
  'financeBanks.1_download_your_bankStatement_template_file':
    '1. Download your bank statement template file',
  'financeBanks.start_by_downloading_our_bankStatement_csv':
    'Start by downloading our bank statements Excel template file. This file has the correct column headings Kledo needs to import details of your bank statements.',
  'financeBanks.download_template_file': 'Download Template File',
  'financeBanks.download_template_data_example_file': 'Download template data example file',
  'financeBanks.import': 'Import Bank Statement',
  'financeBanks.2_copy_your_bankStatement_into_the_template':
    '2. Copy your bank statements into the template',
  'financeBanks.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your bank statements. Make sure the product details match the column headings provided in the template.',
  'financeBanks.important': 'IMPORTANT',
  'financeBanks.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeBanks.upload': 'Upload',
  'financeBanks.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeBanks.a_maximum_of_500_bankStatement_can_be_imported_at_a_time':
    'A maximum of 1.000 bank statements can be imported at a time.',
  'financeBanks.import_actions_only_insert_new_bankStatement_cannot_update_or_delete':
    'Import actions only insert new bank statements, cannot update nor delete.',
  'financeBanks.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeBanks.bankStatement_successfully_imported':
    '{rowCount} bank statement imported successfully.',
  'financeBanks.reconciliation_detail': 'Reconciliation Detail',
  'financeBanks.detail': 'Detail',
  'financeBanks.mutasi_bank': 'Bank Mutation',
  'financeBanks.transactions_on_kledo': 'Transaction on Kledo',
  'financeBanks.error_load_data': 'Error on get list bank statement',
  'financeBanks.bank_connect': 'Bank Connect',
  'financeBanks.add_bank_connect': 'Add Bank Connect',
  'financeBanks.edit_bank_connect': 'Edit Bank Connect',
  'financeBanks.choose_bank': 'Choose bank that will be connected to Kledo',
  'financeBanks.bank_connected': 'Bank connected to Kledo',
  'financeBanks.account': 'Account',
  'financeBanks.fill_out_the_form': 'Fill Out the Form',
  'financeBanks.under_review': 'Under Review',
  'financeBanks.signature_and_send': 'Signature & Send',
  'financeBanks.activation': 'Activation',
  'financeBanks.submission': 'Submission',
  'financeBanks.review': 'Review',
  'financeBanks.active': 'Active',
  'financeBanks.cannot_add_bank_connect': 'Cannot add bank connect',
  'financeBanks.cannot_delete_bank_connect': 'Cannot delete bank connect',
  'financeBanks.please_select_account': 'Please select account',
  'financeBanks.send_submission': 'Send Submission',
  'financeBanks.connect_to_account': 'Connect to Account',
  'financeBanks.account_information': 'Bank Account Information',
  'financeBanks.bank_account_type': 'Bank Account Type',
  'financeBanks.internet_banking_username': 'Internet Banking Username',
  'financeBanks.internet_banking_password': 'Internet Banking Password',
  'financeBanks.repeat_internet_banking_password': 'Repeat Internet Banking Password',
  'financeBanks.please_input_ib_username': 'Please input internet banking username',
  'financeBanks.please_input_ib_password': 'Please input internet banking password',
  'financeBanks.last_get_data': 'Last Get Data',
  'financeBanks.account_owner_name': 'Bank Account Owner Name',
  'financeBanks.account_number': 'Bank Account Number',
  'financeBanks.please_input_account_owner_name': 'Please input account owner name',
  'financeBanks.please_input_account_number': 'Please input account number',
  'financeBanks.scan_bank_book': 'Scan/Photo of Bank Book',
  'financeBanks.please_input_scan_bank_book': 'Please input scan/photo of bank book',
  'financeBanks.identity_card_information': 'Identity Card Information',
  'financeBanks.name_on_bank_account_owner_card': 'Name on Bank Account Owner Identity Card',
  'financeBanks.identity_card_number': 'Identity Card Number',
  'financeBanks.scan_identity_card': 'Scan/Photo of Identity Card',
  'financeBanks.please_input_name_on_bank_account_owner_card': 'Please input name',
  'financeBanks.please_input_identity_card_number': 'Please input identity card number',
  'financeBanks.please_input_scan_identity_card': 'Please input scan/photo of identity card',
  'financeBanks.note': 'Note',
  'financeBanks.follow_these_steps_to_import_your_bankTrans':
    'Follow these steps to import your bank transaction',
  'financeBanks.1_download_your_bankTrans_template_file':
    '1. Download your bank transaction template file',
  'financeBanks.start_by_downloading_our_bankTrans_csv':
    'Start by downloading our bank transactions Excel template file. This file has the correct column headings Kledo needs to import details of your bank transactions.',
  'financeBanks.download_template_file': 'Download Template File',
  'financeBanks.download_template_data_example_file': 'Download template data example file',
  'financeBanks.import_bankTrans': 'Import Bank Transaction',
  'financeBanks.2_copy_your_bankTrans_into_the_template':
    '2. Copy your bank transactions into the template',
  'financeBanks.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your bank transactions. Make sure the product details match the column headings provided in the template.',
  'financeBanks.important': 'IMPORTANT',
  'financeBanks.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeBanks.upload': 'Upload',
  'financeBanks.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeBanks.a_maximum_of_500_bankTrans_can_be_imported_at_a_time':
    'A maximum of 1.000 bank transactions can be imported at a time.',
  'financeBanks.import_actions_only_insert_new_bankTrans_cannot_update_or_delete':
    'Import actions only insert new bank transactions, cannot update nor delete.',
  'financeBanks.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeBanks.bankTrans_successfully_imported': '{rowCount} bankTrans imported successfully.',
  'financeBanks.go_to_bankTrans_list': 'Go to bankTrans list',
  'financeBanks.revert_reconciliation': 'Revert Reconciliation',
  'financeBanks.view_reconciliation': 'View Reconciliation',
  'financeBanks.import_with_bank_template': 'Import with template format from Bank',
  'financeBanks.login_bca_business': '1. Login to your BCA Business internet banking',
  'financeBanks.click_bank_info_mutation_bca':
    '2. Click Account Information menu - Account Mutation',
  'financeBanks.select_format_download_csv':
    '3. Select CSV download format, and click download button',
  'financeBanks.upload_downloaded_csv_on_form_below': '4. Upload downloaded CSV file on form below',
  'financeBanks.login_mandiri_business': '1. Login to your Mandiri Business internet banking',
  'financeBanks.click_bank_info_mutation_mandiri':
    '2. Click Account Information menu - Account Mutation',
  'financeBanks.select_excel_click_download':
    '3. Select Excel download format, and click download button',
  'financeBanks.select_download_check_file_download':
    '4. Click Download Report menu. Select file that will be downloaded, then click download button',
  'financeBanks.upload_downloaded_excel_on_form_below':
    '5. Upload downloaded Excel file on form below',
  'financeBanks.the_one_of_accounts_must_be_base_currency':
    'The one of selected accounts must be base currency',
  'financeBanks.data_has_been_reconciled': 'Data has been reconciled succesfully.',

  'purchases.purchases': 'Purchases Overview',
  'purchases.purchases_invoices': 'Purchases Invoices',
  'purchases.awaiting_payment': 'Awaiting Payment',
  'purchases.overdue': 'Overdue',
  'purchases.purchases_total_last_30_days': 'Purchases Total Last 30 Days',
  'purchases.purchases_total': 'Purchases Total',
  'purchases.product': 'Product',
  'purchases.qty': 'Qty',
  'purchases.value': 'Value',
  'purchases.payment_made': 'Payment Made',
  'purchases.purchases_orders': 'Purchases Orders',
  'purchases.draft': 'Draft',
  'purchases.awaiting_approval': 'Awaiting Approval',
  'purchases.approved': 'Approved',

  'sales.sales': 'Sales Overview',
  'sales.invoices': 'Invoices',
  'sales.awaiting_payment': 'Awaiting Payment',
  'sales.overdue': 'Overdue',
  'sales.purchases_total_last_30_days': 'Sales Total Last 30 Days',
  'sales.product_sales_last_30_days': 'Product Sales Last 30 Days',
  'sales.product_sales': 'Product Sales',
  'sales.sales_contact': 'Sales Per Customer',
  'sales.purchases_vendor': 'Purchases Per Vendor',
  'sales.payments.received': 'Payments Received',
  'sales.draft': 'Draft',
  'sales.sent': 'Sent',
  'sales.quotes': 'quotes',
  'sales.accepted': 'Accepted',

  'financeNumbers.automatic_sequencing': 'Automatic Sequencing',
  'financeNumbers.define_the_number_to_be_used':
    'Define the number to be used when creating your invoice, or purchase order expense. The number will automatically increment with each new document you create.',
  'financeNumbers.prefix': 'Prefix',
  'financeNumbers.suffix': 'Suffix',
  'financeNumbers.sequence': 'Current Number',
  'financeNumbers.please_input_the_next_number': 'Please input the current number',
  'financeNumbers.sequence_error': 'Automatic sequencing number error.',
  'financeNumbers.sequence_error': 'Error create sequence.',
  'financeNumbers.error_edit': 'Error edit sequence.',
  'financeNumbers.error_check_exist': 'Error check number.',
  'financeNumbers.number_is_used': 'Number is used, {link} to get new number.',
  'financeNumbers.number_format': 'Number Format',
  'financeNumbers.automatic_numbering_output_example': 'Automatic Numbering Output Example',
  'financeNumbers.reset_number_every': 'Reset Number Every',
  'financeNumbers.never_reset': 'Never reset',
  'financeNumbers.every_month': 'Every month',
  'financeNumbers.every_year': 'Every year',
  'financeNumbers.on_date': 'On date',
  'financeNumbers.add_numbering_code': 'Add Numbering Code',
  'financeNumbers.please_input_format': 'Please input the number format',
  'financeNumbers.please_input_date': 'Please input the date',
  'financeNumbers.please_input_month': 'Please input the month',
  'financeNumbers.trans_number_same_as_original_trans':
    'Set transaction number same as original transaction',
  'financeNumbers.trans_number_same_as_original_trans_desc':
    'If active, when create invoice from order, the number will be same as order. Likewise for other transaction.',

  'financeConversion.your_opening_balances_will_be_set_as_at':
    'Your opening balances will be set at {date}',
  'financeConversion.conversion_date': 'Conversion Date',
  'financeConversion.error_cannot_save_conversion_date': 'Error cannot save conversion date.',
  'financeConversion.show_zero_balances': 'Show zero balances',
  'financeConversion.conversion_balances': 'Conversion Balances',
  'financeConversion.add_comparative_balances': 'Add Comparative Balances',
  'financeConversion.error_cannot_add_comparative_balances':
    'Error cannot add comparative balances.',
  'financeConversion.error_cannot_save_comparative_balances':
    'Error cannot save comparative balances.',
  'financeConversion.are_you_sure': 'Are you sure ?',
  'financeConversion.warning': 'Warning',
  'financeConversion.reversal_detail': 'Reversal Detail',
  'financeConversion.opening_balance': 'Opening Balance',
  'financeConversion.error_cannot_delete_comparative_balances':
    'Error cannot delete conversion balances.',
  'financeConversion.confirm_balances': 'Confirm your {endDate} account balances.',
  'financeConversion.cannot_set_conversion_date':
    'Tidak dapat set tanggal konversi, karena telah dilakukan tutup buku pada bulan ini.',
  'financeConversion.your_debit_and_credit_total_must_be_balanced':
    'Your debit and credit total must be balanced. If you wish to publish anyway, differences of {diff} will be posted into Opening Balance Equity account.',
  'financeConversion.enter_the_date_that_you_began_processing': `Enter the date that you began processing all your transactions in Kledo. It's easiest when you set your conversion date to be the start of a Tax period.`,
  'financeConversion.back_to_account_list': 'Back to account list',
  'financeConversion.follow_these_steps_to_import_your_conversion_balance':
    'Follow these steps to import your conversion balance',
  'financeConversion.1_download_your_conversion_balance_template_file':
    '1. Download your conversion balance template file',
  'financeConversion.start_by_downloading_our_conversion_balance_csv':
    'Start by downloading our conversion balances Excel template file. This file has the correct column headings Kledo needs to import details of your conversion balances.',
  'financeConversion.download_template_file': 'Download Template File',
  'financeConversion.download_template_data_example_file': 'Download template data example file',
  'financeConversion.import': 'Import Conversion Balance',
  'financeConversion.2_copy_your_conversion_balance_into_the_template':
    '2. Copy your conversion balances into the template',
  'financeConversion.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your conversion balances. Make sure the product details match the column headings provided in the template.',
  'financeConversion.important': 'IMPORTANT',
  'financeConversion.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeConversion.upload': 'Upload',
  'financeConversion.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeConversion.a_maximum_of_500_conversion_balance_can_be_imported_at_a_time':
    'A maximum of 1.000 conversion balances can be imported at a time.',
  'financeConversion.import_actions_only_insert_new_conversion_balance_cannot_update_or_delete':
    'Import actions only insert new conversion balances, cannot update nor delete.',
  'financeConversion.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeConversion.conversion_balance_successfully_imported':
    '{rowCount} conversion balance imported successfully.',
  'financeConversion.go_to_conversion_balance_list': 'Go to conversion balance list',

  'financeManualJournal.add_manual_journal': 'Add Manual Journal',
  'financeManualJournal.financeManualJournal': 'Manual Journal',
  'financeManualJournal.successfully_added': 'successfully added.',
  'financeManualJournal.error_cannot_add': 'Cannot add manual journal.',
  'financeManualJournal.error_cannot_update': 'Cannot update manual journal.',
  'financeManualJournal.edit_manual_journal': 'Edit Manual Journal',
  'financeManualJournal.max_characters': 'Max {value} characters.',
  'financeManualJournal.min_characters': 'Min {value} characters.',
  'financeManualJournal.only_number_allowed': 'Only number allowed.',
  'financeManualJournal.description': 'Description',
  'financeManualJournal.tax': 'Tax',
  'financeManualJournal.select_tax': 'Select tax',
  'financeManualJournal.amount': 'Amount',
  'financeManualJournal.transaction_date': 'Transaction Date',
  'financeManualJournal.please_select_transaction_date': 'Please select transaction date',
  'financeManualJournal.due_date': 'Expiry',
  'financeManualJournal.please_select_due_date': 'Please select expiry',
  'financeManualJournal.price_include_tax': 'Price include tax',
  'financeManualJournal.add_more': 'Add more',
  'financeManualJournal.number': 'Number',
  'financeManualJournal.please_input_the_number': 'Please input the number.',
  'financeManualJournal.memo': 'Memo',
  'financeManualJournal.date': 'Date',
  'financeManualJournal.total': 'Total',
  'financeManualJournal.search_by_memo': 'Search',
  'financeManualJournal.detail_title': 'Manual Journal {number}',
  'financeManualJournal.reference': 'Reference',
  'financeManualJournal.attachments': 'Attachments',
  'financeManualJournal.please_input_the_amount': 'Please input the amount.',
  'financeManualJournal.account': 'Account',
  'financeManualJournal.debit': 'Debit',
  'financeManualJournal.credit': 'Credit',
  'financeManualJournal.select_account': 'Select Account',
  'financeManualJournal.diff': 'Total is out by {diff}',
  'financeManualJournal.total_debits_must_equal_total_credits':
    'Total debits must equal total credits.',
  'financeManualJournal.debit_or_credit_must_greater_than_0':
    'Debit or credit must greather than 0.',
  'financeManualJournal.detail_manual_journal': 'Detil Jurnal Manual',
  'financeManualJournal.follow_these_steps_to_import_your_manualJournal':
    'Follow these steps to import your manual journal',
  'financeManualJournal.1_download_your_manualJournal_template_file':
    '1. Download your manual journal template file',
  'financeManualJournal.start_by_downloading_our_manualJournal_csv':
    'Start by downloading our manual journals Excel template file. This file has the correct column headings Kledo needs to import details of your manual journals.',
  'financeManualJournal.download_template_file': 'Download Template File',
  'financeManualJournal.download_template_data_example_file': 'Download template data example file',
  'financeManualJournal.import': 'Import Manual Journal',
  'financeManualJournal.2_copy_your_manualJournal_into_the_template':
    '2. Copy your manual journals into the template',
  'financeManualJournal.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your manual journals. Make sure the product details match the column headings provided in the template.',
  'financeManualJournal.important': 'IMPORTANT',
  'financeManualJournal.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeManualJournal.upload': 'Upload',
  'financeManualJournal.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeManualJournal.a_maximum_of_500_manualJournal_can_be_imported_at_a_time':
    'A maximum of 1.000 manual journals can be imported at a time.',
  'financeManualJournal.import_actions_only_insert_new_manualJournal_cannot_update_or_delete':
    'Import actions only insert new manual journals, cannot update nor delete.',
  'financeManualJournal.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeManualJournal.manualJournal_successfully_imported':
    '{rowCount} manual journal imported successfully.',
  'financeManualJournal.go_to_manualJournal_list': 'Go to manual journal list',

  'financeFixedAssets.register': 'Register',
  'financeFixedAssets.fixed_assets': 'Fixed Assets',
  'financeFixedAssets.add_fixed_asset': 'Add Fixed Asset',
  'financeFixedAssets.asset_name': 'Asset Name',
  'financeFixedAssets.purchase_invoice': 'Purchase Invoice',
  'financeFixedAssets.purchase_date': 'Purchase Date',
  'financeFixedAssets.purchase_price': 'Purchase Price',
  'financeFixedAssets.book_value': 'Book Value',
  'financeFixedAssets.init_cost': 'Initial Cost',
  'financeFixedAssets.disposal_date': 'Disposal Date',
  'financeFixedAssets.disposal_price': 'Disposal Price',
  'financeFixedAssets.gain': 'Gain/Losses',
  'financeFixedAssets.run_depreciation': 'Run Depreciation',
  'financeFixedAssets.number': 'Number',
  'financeFixedAssets.purchase_price_from': 'From',
  'financeFixedAssets.purchase_price_to': 'To',
  'financeFixedAssets.details': 'Details',
  'financeFixedAssets.please_input_the_asset_name': 'Please input the asset name.',
  'financeFixedAssets.max_characters': 'Max {value} characters.',
  'financeFixedAssets.min_characters': 'Min {value} characters.',
  'financeFixedAssets.please_select_purchase_date': 'Please select purchase date.',
  'financeFixedAssets.please_input_the_purchase_price': 'Please input the purcahse price. ',
  'financeFixedAssets.fixed_asset_account': 'Fixed Asset Account',
  'financeFixedAssets.please_select_a_fixed_asset_account': 'Please select a fixed asset account.',
  'financeFixedAssets.select_fixed_asset_account': 'Select fixed asset account',
  'financeFixedAssets.credited_from_account': 'Credited From Account',
  'financeFixedAssets.please_select_a_credit_from_account': 'Please select a credited from account',
  'financeFixedAssets.select_credit_from_account': 'Select credit from account',
  'financeFixedAssets.description': 'Description',
  'financeFixedAssets.must_greater_than_0': 'Must greater than 0.',
  'financeFixedAssets.depreciation': 'Depreciation',
  'financeFixedAssets.no_depreciation': 'No depreciation',
  'financeFixedAssets.accumulated_depreciation_account': 'Accumulated Depreciation Account',
  'financeFixedAssets.select_accumulated_depreciation_account':
    'Select accumulated depreciation account',
  'financeFixedAssets.depreciation_expense_account': 'Depreciation Expense Account',
  'financeFixedAssets.select_depreciation_expense_account': 'Select depreciation expense account',
  'financeFixedAssets.depreciation_method': 'Depreciation Method',
  'financeFixedAssets.select_depreciation_method': 'Select depreciation method',
  'financeFixedAssets.rate': 'Rate',
  'financeFixedAssets.effective_life': 'Effective Life (years)',
  'financeFixedAssets.show_more_options': 'Show more options',
  'financeFixedAssets.depreciation_start_date': 'Depreciation start date',
  'financeFixedAssets.accumulated_depreciation': 'Accumulated Depreciation',
  'financeFixedAssets.cost_limit': 'Cost Limit',
  'financeFixedAssets.residual_value': 'Residual Value',
  'financeFixedAssets.hide_more_options': 'Hide more options',
  'financeFixedAssets.please_select_a_accumulated_depreciation_account':
    'Please select a accumulated depreciation account.',
  'financeFixedAssets.please_select_a_depreciation_expense_account':
    'Please select a depreciation expense account.',
  'financeFixedAssets.please_select_a_depreciation_method': 'Please select a depreciation method.',
  'financeFixedAssets.must_equal_or_higher_than_purchase_date':
    'Must equal or higher than purchase date.',
  'financeFixedAssets.must_equal_or_smaller_than_cost_limit_minus_residual_value':
    'Must equal or smaller than (cost limit) - (residual value).',
  'financeFixedAssets.must_equal_or_smaller_than_purchase_price':
    'Must equal or smaller than purchase price.',
  'financeFixedAssets.must_equal_or_smaller_than_cost_limit':
    'Must equal or smaller than cost limit.',
  'financeFixedAssets.error_cannot_add': 'Error cannot add fixed asset.',
  'financeFixedAssets.transactions': 'Transactions',
  'financeFixedAssets.detail_fixed_asset': 'Detail Fixed Asset',
  'financeFixedAssets.detail_title': 'Fixed Asset {detail}',
  'financeFixedAssets.date': 'Date',
  'financeFixedAssets.reference': 'Reference',
  'financeFixedAssets.debit': 'Debit',
  'financeFixedAssets.credit': 'Credit',
  'financeFixedAssets.undo_dispose': 'Undo Dispose',
  'financeFixedAssets.dispose': 'Dispose',
  'financeFixedAssets.rollback_depreciation': 'Rollback Depreciation',
  'financeFixedAssets.error_cannot_add': 'Error cannot add fixed asset.',
  'financeFixedAssets.error_cannot_update': 'Error cannot update fixed asset.',
  'financeFixedAssets.error_cannot_delete': 'Error cannot delete fixed asset.',
  'financeFixedAssets.edit_title': 'Edit Fixed Asset {detail}',
  'financeFixedAssets.dispose_fixed_asset': 'Dispose Fixed Asset',
  'financeFixedAssets.dispose_title': 'Dispose {title}',
  'financeFixedAssets.last_depreciation': 'Last Depreciation',
  'financeFixedAssets.sale_price': 'Sale Price',
  'financeFixedAssets.journal_entries': 'Journal Entries',
  'financeFixedAssets.name': 'Name',
  'financeFixedAssets.account': 'Account',
  'financeFixedAssets.acquisition_const': 'Acquisition cost',
  'financeFixedAssets.gain_on_disposal': 'Gain on disposal',
  'financeFixedAssets.loss_on_disposal': 'Loss on disposal',
  'financeFixedAssets.please_select_disposal_date': 'Please select disposal date.',
  'financeFixedAssets.please_input_the_sale_price': 'Please input the sale price.',
  'financeFixedAssets.deposit_to': 'Deposit to',
  'financeFixedAssets.deposit_to_account': 'Deposit to account',
  'financeFixedAssets.please_select_a_deposit_to_account': 'Please select a deposit to account.',
  'financeFixedAssets.must_equal_or_higher_than_purchase_date_and_last_depreciation_date':
    'Must equal or higher than purchase date and last depreciation date.',
  'financeFixedAssets.must_equal_or_higher_than_purchase_date':
    'Must equal or higher than purchase date.',
  'financeFixedAssets.register_fixed_asset': 'Register Fixed Asset',
  'financeFixedAssets.run_depreciation': 'Run Depreciation',
  'financeFixedAssets.period': 'Period',
  'financeFixedAssets.method': 'Method',
  'financeFixedAssets.depreciation_amount': 'Depreciation Amount',
  'financeFixedAssets.error_cannot_run_depreciation': 'Error cannot run depreciation.',
  'financeFixedAssets.please_select_at_least_a_depreciation':
    'Please select at least a depreciation.',
  'financeFixedAssets.depreciation_executed_successfully': 'Depreciation executed successfully.',
  'financeFixedAssets.back_to_asset_list': 'Back to asset list',
  'financeFixedAssets.apply_depreciation': 'Apply Depreciation',
  'financeFixedAssets.are_u_sure_to_undo_dispose': 'Are you sure to undo dispose ?',
  'financeFixedAssets.error_cannot_undo_dispose': 'Error cannot undo dispose.',
  'financeFixedAssets.error_cannot_rollback_depreciation': 'Error cannot rollback depreciation.',
  'financeFixedAssets.are_u_sure_to_rollback_depreciation':
    'Are you sure to rollback depreciation ?',
  'financeFixedAssets.please_input_the_number': 'Please input the number.',
  'financeFixedAssets.select_deposit_to_account': 'Select deposit to account',
  'financeFixedAssets.edit_fixed_asset': 'Edit Fixed Asset',
  'financeFixedAssets.detail_fixed_asset_transaction': 'Detail Fixed Asset Transaction',
  'financeFixedAssets.dispose_detail': 'Dispose',
  'financeFixedAssets.follow_these_steps_to_import_your_fixedAsset':
    'Follow these steps to import your fixed asset',
  'financeFixedAssets.1_download_your_fixedAsset_template_file':
    '1. Download your fixed asset template file',
  'financeFixedAssets.start_by_downloading_our_fixedAsset_csv':
    'Start by downloading our fixed assets Excel template file. This file has the correct column headings Kledo needs to import details of your fixed assets.',
  'financeFixedAssets.download_template_file': 'Download Template File',
  'financeFixedAssets.download_template_data_example_file': 'Download template data example file',
  'financeFixedAssets.import': 'Import Fixed Asset',
  'financeFixedAssets.2_copy_your_fixedAsset_into_the_template':
    '2. Copy your fixed assets into the template',
  'financeFixedAssets.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your fixed assets. Make sure the product details match the column headings provided in the template.',
  'financeFixedAssets.important': 'IMPORTANT',
  'financeFixedAssets.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeFixedAssets.upload': 'Upload',
  'financeFixedAssets.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeFixedAssets.a_maximum_of_500_fixedAsset_can_be_imported_at_a_time':
    'A maximum of 1.000 fixed assets can be imported at a time.',
  'financeFixedAssets.import_actions_only_insert_new_fixedAsset_cannot_update_or_delete':
    'Import actions only insert new fixed assets, cannot update nor delete.',
  'financeFixedAssets.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeFixedAssets.fixedAsset_successfully_imported':
    '{rowCount} fixed asset imported successfully.',
  'financeFixedAssets.go_to_fixedAsset_list': 'Go to fixed asset list',
  'financeFixedAssets.the_date_this_asset_was_purchased_or_acquired':
    'The date this asset was purchased or acquired.',
  'financeFixedAssets.the_total_cost_of_acquiring_this_asset':
    'The total cost of acquiring this asset, tax exempt.',
  'financeFixedAssets.account_to_hold_the_balance_of_this_fixed_asset':
    'Account to hold the balance of this fixed asset.',
  'financeFixedAssets.account_for_crediting_the_purchase_of_fixed_asset':
    'Account for crediting the purchase of fixed assets. For example Cash, Loans or Capital.',
  'financeFixedAssets.an_expense_account_to_record_monthly_depreciation':
    'An expense account to record monthly depreciation.',
  'financeFixedAssets.accounts_for_contra_assets_to_accommodate_accumulated_depreciation':
    'Accounts for contra assets to accommodate accumulated depreciation.',
  'financeFixedAssets.percent_depreciation_value_each_year':
    'Percent depreciation value each year.',
  'financeFixedAssets.the_longevity_of_the_asset_since_purchase_in_years':
    'The longevity of the asset since purchase in years.',
  'financeFixedAssets.method_of_calculating_assets_each_months':
    'Method of calculating assets each month {br}Straight Line = Depreciation on average over the useful life of the asset.{br}For example, if the purchase price of an asset is 1000 and the depreciation value is 20%, then the depreciation per year is 1000 * 20 % = 200{br}Declining Balance 100% = Depreciation with a greater rate of decline at the beginning of the asset is life.{br}For example, if the asset purchase price is 1000 and the depreciation value is 20%, then{br}First Year Depreciation = 1000 x 20% = 200{br}Depreciation in the Second Year = (1000 - 200) x 20% = 160{br}Depreciation in Third Year = (800 - 160) x 20% = 128<br />Declining Balance 150% calculates the depreciation by the depreciation value multiplied by 150%{br}200 % Declining Balance calculates the depreciation by the depreciation value multiplied by 200%',
  'financeFixedAssets.the_commencement_date_of_the_accumulated_depreciation_account_opening_balance':
    'The commencement date of the accumulated depreciation account opening balance. Can be filled with the same as the date of purchase of assets, or better to use the start date of the period or the date of migration.',
  'financeFixedAssets.beginning_balance_for_accumulated_depreciation_accounts':
    'Beginning balance for accumulated depreciation accounts from past books or records.',
  'financeFixedAssets.the_upper_limit_on_the_value_of_the_depreciated_assets':
    'The upper limit on the value of the depreciated assets. For example, if an asset is purchased at a price of IDR 1,000, then the cost limit is set at IDR 600, so only IDR 600 is depreciated.',
  'financeFixedAssets.the_residual_value_is_the_minimum_amount_of_assets':
    'The residual value is the minimum amount of assets that cannot be depreciated. For example, if an asset is purchased at a price of Rp1,000, then the residual value is set at Rp. 200. So when the asset is depreciated every month, and the final asset value is only Rp. 200, the asset is not depreciated.',
  'financeFixedAssets.image': 'Fixed Asset Image',
  'financeFixedAssets.show_image': 'Show fixed asset image',
  'financeFixedAssets.hide_image': 'Hide fixed asset image',

  'financeLockDate.lock_date': 'Lock Date',
  'financeLockDate.lock_date_will_stop_data_from_being_changed':
    'Lock date will stop data from being changed for a specific period. You can change these at any time.',
  'financeLockDate.stop_all_users_making_changes_on_and_before':
    'Stop all users making changes on and before',
  'financeLockDate.please_select_a_date': 'Please select a date.',
  'financeLockDate.error_cannot_set_lock_date': 'Error cannot set lock date.',
  'financeLockDate.cannot_higher_than_now': 'Cannot higher than now.',
  'financeLockDate.cannot_select_before_lock_date': 'Cannot select before lock date.',
  'financeLockDate.this_transaction_is_locked': 'This transaction is locked.',
  'financeLockDate.click_button_below_to_remove_the_lock_date':
    'Click button below to remove the lock date',
  'financeLockDate.remove_lock_date': 'Remove Lock Date',
  'financeLockDate.error_cannot_delete': 'Error cannot remove lock date.',

  'property.property': 'Property',
  'property.company': 'Company',
  'property.property_details': 'Property Details',
  'property.company_details': 'Company Details',
  'property.tax_details': 'Tax Details',
  'property.system_settings': 'System Settings',
  'property.property_logo': 'Property Logo',
  'property.company_logo': 'Company Logo',
  'property.error_cannot_update_tax_details': 'Cannot update tax details.',
  'property.property_details_successfully_updated': 'Property details successfully updated.',
  'property.company_details_successfully_updated': 'Company details successfully updated.',
  'property.error_cannot_update_property_details': 'Cannot update property details.',
  'property.error_cannot_update_company_details': 'Cannot update company details.',
  'property.system_details_successfully_updated': 'System details successfully updated.',
  'property.error_cannot_update_system_details': 'Cannot update system details.',
  'property.tax_details_successfully_updated': 'Tax details successfully updated.',
  'property.property_name': 'Name',
  'property.company_name': 'Name',
  'property.please_input_the_property_name': 'Please input the property name.',
  'property.please_input_the_company_name': 'Please input the company name.',
  'property.max_100_characters': 'Max 100 characters.',
  'property.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'property.emai_address': 'Emai Address',
  'property.invalid_email_address_format': 'Invalid email address format.',
  'property.phone_number': 'Phone Number',
  'property.max_20_characters': 'Max 20 characters.',
  'property.only_number_allowed': 'Only number allowed.',
  'property.address': 'Billing Address',
  'property.address_delivery': 'Delivery Address',
  'property.country': 'Country',
  'property.tax_number': 'Tax Number',
  'property.please_select_the_country': 'Please select the country.',
  'property.select_country': 'Select country',
  'property.arrival_time': 'Arrival Time',
  'property.departure_time': 'Departure Time',
  'property.image_file_size_maximal': 'Image file size maximal',
  'property.language': 'Language',
  'property.select_time_region': 'Select time region',
  'property.timezone': 'Timezone',
  'property.please_select_the_language': 'Please select the language.',
  'property.select_timezone': 'Select timezone',
  'property.please_select-the_timezone': 'Please select the timezone.',
  'property.date_format': 'Date Format',
  'property.please_select_the_date_format': 'Please select the date format.',
  'property.select_date_format': 'Select date format',
  'property.time_format': 'Time Format',
  'property.please_select_the_time_format': 'Please select the time format.',
  'property.select_time_format': 'Select time format',
  'property.Currency_Format': 'Currency Format',
  'property.please_select_the_currency_format': 'Please select the currency format.',
  'property.select_currency_format': 'Select currency format',
  'property.country_tax_identifier_name': 'Country Tax Identifier Name',
  'property.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'property.company_tax_number': 'Company Tax Number',
  'property.tax_name': 'Tax Name',
  'property.tax_rate': 'Tax Rate',
  'property.image_file_dimension_maximal': 'Image file dimension maximal',
  'property.click_or_drag_file_to_this_area_to_upload': 'Click or drag file to this area to upload',
  'property.preview': 'Preview',
  'property.file_uploaded_successfully': 'file uploaded successfully.',
  'property.file_upload_failed': 'file upload failed.',
  'property.you_can_only_upload_jpg_or_png_file_': 'You can only upload JPG/PNG file.',
  'property.image_must_smaller_than': 'Image must smaller than',
  'property.image_width_must_smaller_than': 'Image width must smaller than',
  'property.image_height_must_smaller_than': 'Image height must smaller than',
  'property.currency': 'Currency',
  'property.please_select_the_currency': 'Please select the currency.',
  'property.select_currency': 'Select currency',
  'property.number_after_comma': 'Number after comma',
  'property.please_select_the_number_after_comma': 'Please select number after comma.',
  'property.select_number_after_comma': 'Select number after comma',

  'financeWareHouses.add_warehouse': 'Add Warehouse',
  'financeWareHouses.financeWareHouses': 'Warehouse',
  'financeWareHouses.warehouse_name': 'Warehouse Name',
  'financeWareHouses.successfully_added': 'successfully added.',
  'financeWareHouses.error_cannot_add': 'Cannot add warehouse.',
  'financeWareHouses.error_cannot_update': 'Cannot update warehouse.',
  'financeWareHouses.error_cannot_move': 'Cannot move the warehouse.',
  'financeWareHouses.error_cannot_delete': 'Cannot delete the warehouse.',
  'financeWareHouses.error_cannot_change_status': 'Cannot change status the warehouse.',
  'financeWareHouses.error_cannot_load_warehouses': 'Cannot load warehouses.',
  'financeWareHouses.edit_warehouse': 'Edit Warehouse',
  'financeWareHouses.please_input_the_name': 'Please input the name.',
  'financeWareHouses.please_input_the_code': 'Please input the code.',
  'financeWareHouses.name': 'Name',
  'financeWareHouses.code': 'Code',
  'financeWareHouses.max_characters': 'Max {value} characters.',
  'financeWareHouses.min_characters': 'Min {value} characters.',
  'financeWareHouses.total_qty': 'Total Quantity',
  'financeWareHouses.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'financeWareHouses.desc': 'Description',
  'financeWareHouses.total_value': 'Total Value',
  'financeWareHouses.stock_adjustment': 'Stock Adjustment',
  'financeWareHouses.warehouse_transfer': 'Warehouse Transfer',
  'financeWareHouses.import_warehouse_transfer': 'Import Warehouse Transfer',
  'financeWareHouses.transfer_in': 'Transfer In',
  'financeWareHouses.transfer_out': 'Transfer Out',
  'financeWareHouses.total_products': 'Total Products',
  'financeWareHouses.total_product_value': 'Total Product Value',
  'financeWareHouses.product_name': 'Product Name',
  'financeWareHouses.qty': 'Quantity',
  'financeWareHouses.value': 'Value',
  'financeWareHouses.detail_warehouse': 'Detail Warehouse',
  'financeWareHouses.show_transactions': 'Show Transactions',
  'financeWareHouses.hide_transactions': 'Hide Transactions',
  'financeWareHouses.purchase_&_sale': 'Purchase & Sale',
  'financeWareHouses.transactions': 'Transactions',
  'financeWareHouses.date': 'Date',
  'financeWareHouses.type': 'Type',
  'financeWareHouses.number': 'Number',
  'financeWareHouses.reference': 'Reference',
  'financeWareHouses.debit': 'Debit',
  'financeWareHouses.credit': 'Credit',
  'financeWareHouses.from': 'From',
  'financeWareHouses.to': 'To',
  'financeWareHouses.adjustment_type': 'Adjustment Type',
  'financeWareHouses.please_select_date': 'Please select date',
  'financeWareHouses.warehouse_transfer_out': 'Warehouse Transfer Out',
  'financeWareHouses.warehouse_transfer_in': 'Warehouse Transfer In',
  'financeWareHouses.product': 'Product',
  'financeWareHouses.qty_before': 'Qty Before',
  'financeWareHouses.qty_after': 'Qty After',
  'financeWareHouses.transfer_qty': 'Transfer Qty',
  'financeWareHouses.add_more': 'Add more',
  'financeWareHouses.attachments': 'Attachments',
  'financeWareHouses.add_product': 'Add Product',
  'financeWareHouses.select_product': 'Select product',
  'financeWareHouses.select_warehouse': 'Select warehouse',
  'financeWareHouses.select_a_product': 'Select a product',
  'financeWareHouses.please_select_a_product': 'Please select a product.',
  'financeWareHouses.please_input_the_transfer_qty': 'Please input the transfer qty.',
  'financeWareHouses.please_select_a_warehouse': 'Please select a warehouse.',
  'financeWareHouses.must_greater_than_0': 'Must greater than 0.',
  'financeWareHouses.detail_transfer': 'Warehouse Transfer {number}',
  'financeWareHouses.detail_transfer_title': 'Warehouse Transfer Detail',
  'financeWareHouses.edit_warehouse_transfer': 'Edit Warehouse Transfer',
  'financeWareHouses.insufficient_stock': 'Insufficient stock',
  'financeWareHouses.detail_stockadjustment_title': 'Stock Adjustment Detail',
  'financeWareHouses.detail_stockadjustment': 'Stock Adjustment {number}',
  'financeWareHouses.difference': 'Difference',
  'financeWareHouses.average_price': 'Average Price',
  'financeWareHouses.actual_qty': 'Actual Qty',
  'financeWareHouses.account': 'Account',
  'financeWareHouses.actual_qty': 'Actual Qty',
  'financeWareHouses.add_stock_adjustment': 'Add Stock Adjustment',
  'financeWareHouses.import_stock_adjustment': 'Import Stock Adjustment',
  'financeWareHouses.download': 'Download',
  'financeWareHouses.please_select_adjustment_type': 'Please select adjustment type',
  'financeWareHouses.add_stock_adjustment': 'Add Stock Adjustment',
  'financeWareHouses.select_account': 'Select account',
  'financeWareHouses.recorded_qty': 'Recorded Qty',
  'financeWareHouses.please_input_the_actual_qty': 'Please input the actual qty',
  'financeWareHouses.please_input_the_difference_qty': 'Please input the difference qty',
  'financeWareHouses.please_input_the_average_price': 'Please input the average price',
  'financeWareHouses.error_cannot_update_stock_adjustment': 'Cannot update stock adjustment',
  'financeWareHouses.error_cannot_add_stock_adjustment': 'Cannot add stock adjustment',
  'financeWareHouses.edit_stock_adjustment': 'Edit Stock Adjustment',
  'financeWareHouses.transfer': 'Transfer',
  'financeWareHouses.cannot_less_than_0': 'Cannot less than 0.',
  'financeWareHouses.follow_these_steps_to_import_your_stockAdjustment':
    'Follow these steps to import your stock adjustment',
  'financeWareHouses.1_download_your_stockAdjustment_template_file':
    '1. Download your stock adjustment template file',
  'financeWareHouses.start_by_downloading_our_stockAdjustment_csv':
    'Start by downloading our stock adjustments Excel template file. This file has the correct column headings Kledo needs to import details of your stock adjustments.',
  'financeWareHouses.download_template_file': 'Download Template File',
  'financeWareHouses.download_template_data_example_file': 'Download template data example file',
  'financeWareHouses.import': 'Import Stock Adjustment',
  'financeWareHouses.2_copy_your_stockAdjustment_into_the_template':
    '2. Copy your stock adjustments into the template',
  'financeWareHouses.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your stock adjustments. Make sure the product details match the column headings provided in the template.',
  'financeWareHouses.important': 'IMPORTANT',
  'financeWareHouses.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeWareHouses.upload': 'Upload',
  'financeWareHouses.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeWareHouses.a_maximum_of_500_stockAdjustment_can_be_imported_at_a_time':
    'A maximum of 1.000 stock adjustments can be imported at a time.',
  'financeWareHouses.import_actions_only_insert_new_stockAdjustment_cannot_update_or_delete':
    'Import actions only insert new stock adjustments, cannot update nor delete.',
  'financeWareHouses.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeWareHouses.stockAdjustment_successfully_imported':
    '{rowCount} stock adjustment imported successfully.',
  'financeWareHouses.go_to_stockAdjustment_list': 'Go to stock adjustment list',
  'financeWareHouses.header_column_hpp_help':
    'This is the cost of the product, or the purchase price.',
  'financeWareHouses.ref_number_help':
    'Fill in the reference for internal records and make searching easier.',
  'financeWareHouses.account_id_help': 'Select stock adjustment account for journaling.',
  'financeWareHouses.count_stock_help':
    'If you want to do a stock take, select the type of stock calculation. With this type, the number of stock items will be locked so that all stock movements prior to this stock adjustment will be ignored.',
  'financeWareHouses.count_stock_in_out_help':
    'If you want to increase or decrease stock from a warehouse, select the In/Out Stock type.',
  'financeWareHouses.warehouse_transfer_successfully_imported':
    '{rowCount} warehouse transfer imported successfully.',
  'financeWareHouses.go_to_warehouse_page': 'Go to warehouse page',
  'financeWareHouses.follow_these_steps_to_import_your_warehouse_transfer':
    'Follow these steps to import your warehouse transfers',
  'financeWareHouses.1_download_your_warehouse_transfer_template_file':
    '1. Download your warehouse transfers template file',
  'financeWareHouses.start_by_downloading_our_warehouse_transfer_csv':
    'Start by downloading our warehouse transfer Excel template file. This file has the correct column headings Kledo needs to import details of your warehouse transfers.',
  'financeWareHouses.2_copy_your_warehouse_transfer_into_the_template':
    '2. Copy your warehouse transfers into the template',
  'financeWareHouses.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your warehouse transfer. Make sure the warehouse transfer details match the column headings provided in the template.',
  'financeWareHouses.a_maximum_of_500_warehouse_transfer_can_be_imported_at_a_time':
    'A maximum of 1.000 warehouse transfers can be imported at a time.',
  'financeWareHouses.change_access': 'Change Access Rights',
  'financeWareHouses.who_can_access_this_warehouse': 'Who can access this warehouse?',
  'financeWareHouses.all_user': 'All User',
  'financeWareHouses.access': 'Access Rights',

  'layoutInvoice.layout_invoice': 'Layout Invoice',
  'layoutInvoice.invoice': 'Invoice',
  'layoutInvoice.tax_invoice': 'Tax Invoice',
  'layoutInvoice.delivery': 'Delivery Slip',
  'layoutInvoice.order': 'Sales Order',
  'layoutInvoice.receipt': 'Receipt',
  'layoutInvoice.expense_slip': 'Expense Slip',
  'layoutInvoice.purchase_payment': 'Remittance Advice',
  'layoutInvoice.send_receive_money': 'Send/Receive Money',
  'layoutInvoice.remittance_advice': 'Remittance Advice',
  'layoutInvoice.cash_receipt': 'Cash Receipt',
  'layoutInvoice.account_code': 'Account Code',
  'layoutInvoice.account_name': 'Account Name',
  'layoutInvoice.purchase_invoice': 'Purchase Invoice',
  'layoutInvoice.error_cannot_load_data': 'Cannot load data.',
  'layoutInvoice.error_cannot_save_data': 'Cannot save data.',
  'layoutInvoice.error_cannot_reset_data': 'Cannot reset data.',
  'layoutInvoice.sale': 'Sale',
  'layoutInvoice.purchase': 'Purchase',
  'layoutInvoice.company_logo': 'Company Logo',
  'layoutInvoice.signature_logo': 'Signature Logo',
  'layoutInvoice.upload_logo': 'Upload logo',
  'layoutInvoice.upload_signature': 'Upload signature',
  'layoutInvoice.purchase_order': 'Purchase Order',
  'layoutInvoice.purchase_invoice': 'Purchase Invoice',
  'layoutInvoice.purchase_quote': 'Purchase Quote',
  'layoutInvoice.quote': 'Quote',
  'layoutInvoice.sell_invoice': 'Invoice',
  'layoutInvoice.show_discount': 'Show Discount',
  'layoutInvoice.show_price': 'Show Price',
  'layoutInvoice.show_tax_total': 'Show Tax Total',
  'layoutInvoice.show_subtotal': 'Show Subtotal',
  'layoutInvoice.show_total': 'Show Total',
  'layoutInvoice.show_tax': 'Show Tax',
  'layoutInvoice.show_product': 'Show Product',
  'layoutInvoice.show_ref_code_product': 'Show Product SKU',
  'layoutInvoice.show_product_image': 'Show Product Image',
  'layoutInvoice.show_qty': 'Show Quantity',
  'layoutInvoice.show_unit': 'Show Unit',
  'layoutInvoice.show_description': 'Show Description',
  'layoutInvoice.show_tags': 'Show Tags',
  'layoutInvoice.show_term': 'Show Term',
  'layoutInvoice.show_payment_detail': 'Show Payment Detail',
  'layoutInvoice.show_sales_person': 'Show Sales Person',
  'layoutInvoice.show_paid_status': 'Show Paid Status',
  'layoutInvoice.show_reference': 'Show Reference',
  'layoutInvoice.change_template': 'Change Template',
  'layoutInvoice.template': 'Template',
  'layoutInvoice.edit_header': 'Edit Header',
  'layoutInvoice.please_choose_template': 'Please choose template bellow :',
  'layoutInvoice.select_this_template': 'Select template',
  'layoutInvoice.current_template': 'Current template',
  'layoutInvoice.name': 'Name',
  'layoutInvoice.transfer_money': 'Transfer Money',
  'layoutInvoice.label_delivery': 'Label Delivery',
  'layoutInvoice.payment_recap': 'Payment Recap',
  'layoutInvoice.show_due_date': 'Show Due Date',
  'layoutInvoice.show_number': 'Show Number',
  'layoutInvoice.show_expedition': 'Show Expedition',
  'layoutInvoice.show_message': 'Show Message',
  'layoutInvoice.show_signature': 'Show Signature',
  'layoutInvoice.show_signature_detail': 'Show TTd Sender & Receiver',
  'layoutInvoice.font_size': 'Font Size',
  'layoutInvoice.additional_message': 'Additional Message',
  'layoutInvoice.additional_message_desc':
    "Message on this additional message will be added automatically on the bottom of the transaction's message.",
  'layoutInvoice.default_message': 'Default Message',
  'layoutInvoice.default_message_desc':
    'Default message will be loaded automatically on the message input when adding a new transaction.',

  'layoutReport.layout_report': 'Report Layout',
  'layoutReport.manage_your_finance_report_print_pdf_layout_here':
    'Manage your finance report print pdf layout here',
  'layoutReport.report_logo': 'Report Logo',
  'layoutReport.report_setting': 'Report Setting',
  'layoutReport.show_logo': 'Show Logo',
  'layoutReport.logo_position': 'Logo Position',
  'layoutReport.logo_position_left': 'Left',
  'layoutReport.logo_position_center': 'Center',
  'layoutReport.logo_position_right': 'Right',
  'layoutReport.show_comp_name': 'Show Company Name',
  'layoutReport.font_family': 'Font Family',
  'layoutReport.font_size': 'Font Size',
  'layoutReport.report_text': 'Report Text',
  'layoutReport.comp_name': 'Company Name',
  'layoutReport.report_title': 'Report Title',
  'layoutReport.report_date': 'Report Date',
  'layoutReport.click_here_to_reset_layout': 'Click here to reset report layout to the default',
  'layoutReport.show_comp_sign': 'Show Signature',
  'layoutReport.signature': 'Signature',

  'financeTag.add_tag': 'Add Tag',
  'financeTag.financeTag': 'Tag',
  'financeTag.all_tags': 'All tags',
  'financeTag.select_tag_type': 'Select tag type',
  'financeTag.successfully_added': 'successfully added.',
  'financeTag.error_cannot_add': 'Cannot add tag.',
  'financeTag.error_cannot_update': 'Cannot update tag.',
  'financeTag.edit_tag': 'Edit Tag',
  'financeTag.please_input_the_tag_name': 'Please input the tag name.',
  'financeTag.name': 'Name',
  'financeTag.sale': 'Sale',
  'financeTag.purchase': 'Purchase',
  'financeTag.expense': 'Expense',
  'financeTag.fixed_asset': 'Fixed Asset',
  'financeTag.other': 'Other',
  'financeTag.date': 'Date',
  'financeTag.transaction': 'Transaction',
  'financeTag.desc': 'Description',
  'financeTag.total': 'Total',
  'financeTag.max_characters': 'Max {value} characters.',
  'financeTag.min_characters': 'Min {value} characters.',
  'financeTag.only_alphanumeric_allowed': 'Only alphanumeric allowed.',
  'financeTag.error_cannot_search': 'Cannot search tag.',
  'financeTag.select_tag': 'Select tag',
  'financeTag.reference': 'Reference',

  'financeOrder.message': 'Message',
  'financeOrder.add_order': 'Add Order',
  'financeOrder.financeOrder': 'Order',
  'financeOrder.open_order': 'Open order',
  'financeOrder.order_waiting_approval': 'Order waiting approval',
  'financeOrder.order_partial_shipped': 'Order partial shipped',
  'financeOrder.successfully_added': 'successfully added.',
  'financeOrder.error_cannot_add': 'Cannot add order.',
  'financeOrder.error_cannot_print_pdf': 'Cannot print pdf order.',
  'financeOrder.error_cannot_update': 'Cannot update order.',
  'financeOrder.error_cannot_move': 'Cannot move the order.',
  'financeOrder.error_cannot_change_status': 'Cannot change status the order.',
  'financeOrder.edit_order': 'Edit Invoice',
  'financeOrder.max_characters': 'Max {value} characters.',
  'financeOrder.min_characters': 'Min {value} characters.',
  'financeOrder.only_number_allowed': 'Only number allowed.',
  'financeOrder.description': 'Description',
  'financeOrder.tax': 'Tax',
  'financeOrder.select_tax': 'Select tax',
  'financeOrder.amount': 'Amount',
  'financeOrder.please_select_a_contact': 'Please select a contact',
  'financeOrder.select_contact': 'Select contact',
  'financeOrder.add_contact': 'Add contact',
  'financeOrder.all_contacts': 'All contact',
  'financeOrder.transaction_date': 'Transaction Date',
  'financeOrder.please_select_transaction_date': 'Please select transaction date',
  'financeOrder.due_date': 'Due Date',
  'financeOrder.please_select_due_date': 'Please select due date',
  'financeOrder.term': 'Term',
  'financeOrder.select_term': 'Select term',
  'financeOrder.add_term': 'Add term',
  'financeOrder.price_include_tax': 'Price include tax',
  'financeOrder.add_more': 'Add more',
  'financeOrder.number': 'Number',
  'financeOrder.please_input_the_number': 'Please input the number.',
  'financeOrder.memo': 'Memo',
  'financeOrder.date': 'Date',
  'financeOrder.status': 'Status',
  'financeOrder.balance_due': 'Balance Due',
  'financeOrder.total': 'Total',
  'financeOrder.please_select_a_term': 'Please select a term.',
  'financeOrder.search_by_memo': 'Search',
  'financeOrder.the_due_date_max_higger_than_transaction_date':
    'The due date must same or after transaction date',
  'financeOrder.detail_title': ' Invoice {number}',
  'financeOrder.customer': 'Customer',
  'financeOrder.reference': 'Reference',
  'financeOrder.product': 'Product',
  'financeOrder.qty': 'Qty',
  'financeOrder.not_sent_yet': 'Not Sent Yet',
  'financeOrder.price': 'Price',
  'financeOrder.add_product': 'Add Product',
  'financeOrder.select_product': 'Select product',
  'financeOrder.attachments': 'Attachments',
  'financeOrder.save_&_approve': 'Save & approve',
  'financeOrder.save_as_draft': 'Save as draft',
  'financeOrder.save_&_submit_for_approval': 'Save & submit for approval',
  'financeOrder.please_select_a_product': 'Please select a product.',
  'financeOrder.please_input_the_qty': 'Please input the qty.',
  'financeOrder.please_input_the_price': 'Please input the price.',
  'financeOrder.please_input_the_amount': 'Please input the amount.',
  'financeOrder.approve': 'Approve',
  'financeOrder.are_you_sure_to_approve': 'Are you sure to approve ?',
  'financeOrder.submit_for_approval': 'Submit for approval',
  'financeOrder.are_you_sure_to_submit_for_approval': 'Are you sure to submit for approval ?',
  'financeOrder.create_order': 'Create Order',
  'financeOrder.amount_paid': 'Amount Paid',
  'financeOrder.paid_from': 'Paid From',
  'financeOrder.add_payment': 'Receive Payment',
  'financeOrder.make_a_payment': 'Receive a payment',
  'financeOrder.select_paid_from': 'Select paid to',
  'financeOrder.please_select_a_paid_from': 'Please select receive payment to.',
  'financeOrder.error_cannot_add_payment': 'Cannot add payment.',
  'financeOrder.payment': 'Payment',
  'financeOrder.detail_order': 'Detail Order',
  'financeOrder.number_quotes': 'Quotes Number',
  'financeOrder.amount': 'Amount',
  'financeOrder.dp': 'DP',
  'financeOrder.create_delivery': 'Create Delivery',
  'financeOrder.follow_these_steps_to_import_your_order': 'Follow these steps to import your order',
  'financeOrder.1_download_your_order_template_file': '1. Download your order template file',
  'financeOrder.start_by_downloading_our_order_csv':
    'Start by downloading our orders Excel template file. This file has the correct column headings Kledo needs to import details of your orders.',
  'financeOrder.download_template_file': 'Download Template File',
  'financeOrder.download_template_data_example_file': 'Download template data example file',
  'financeOrder.import': 'Import Order',
  'financeOrder.2_copy_your_order_into_the_template': '2. Copy your orders into the template',
  'financeOrder.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your orders. Make sure the product details match the column headings provided in the template.',
  'financeOrder.important': 'IMPORTANT',
  'financeOrder.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financeOrder.upload': 'Upload',
  'financeOrder.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financeOrder.a_maximum_of_500_order_can_be_imported_at_a_time':
    'A maximum of 1.000 orders can be imported at a time.',
  'financeOrder.import_actions_only_insert_new_order_cannot_update_or_delete':
    'Import actions only insert new orders, cannot update nor delete.',
  'financeOrder.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financeOrder.order_successfully_imported': '{rowCount} order imported successfully.',
  'financeOrder.go_to_order_list': 'Go to order list',
  'financeOrder.print_order': 'Print Order',
  'financeOrder.copy_link': 'Copy Order Link',
  'financeOrder.send_email': 'Send Order Email',
  'financeOrder.send_sms': 'Send Order SMS',
  'financeOrder.send_wa': 'Send Order Whatsapp',
  'financeOrder.insufficient_stock_warning':
    'Cannot add to the order, because there are products that have insufficient stock.',

  'financeDelivery.message': 'Message',
  'financeDelivery.add_delivery': 'Add Delivery',
  'financeDelivery.financeDelivery': 'Delivery',
  'financeDelivery.open_delivery': 'Open delivery',
  'financeDelivery.successfully_added': 'successfully added.',
  'financeDelivery.error_cannot_add': 'Cannot add delivery.',
  'financeDelivery.error_cannot_print_pdf': 'Cannot print pdf delivery.',
  'financeDelivery.error_cannot_update': 'Cannot update delivery.',
  'financeDelivery.error_cannot_move': 'Cannot move the delivery.',
  'financeDelivery.error_cannot_change_status': 'Cannot change status the delivery.',
  'financeDelivery.edit_delivery': 'Edit Invoice',
  'financeDelivery.max_characters': 'Max {value} characters.',
  'financeDelivery.min_characters': 'Min {value} characters.',
  'financeDelivery.only_number_allowed': 'Only number allowed.',
  'financeDelivery.description': 'Description',
  'financeDelivery.tax': 'Tax',
  'financeDelivery.select_tax': 'Select tax',
  'financeDelivery.amount': 'Amount',
  'financeDelivery.please_select_a_contact': 'Please select a contact',
  'financeDelivery.select_contact': 'Select contact',
  'financeDelivery.add_contact': 'Add contact',
  'financeDelivery.all_contacts': 'All contact',
  'financeDelivery.transaction_date': 'Transaction Date',
  'financeDelivery.please_select_transaction_date': 'Please select transaction date',
  'financeDelivery.due_date': 'Due Date',
  'financeDelivery.please_select_due_date': 'Please select due date',
  'financeDelivery.term': 'Term',
  'financeDelivery.select_term': 'Select term',
  'financeDelivery.add_term': 'Add term',
  'financeDelivery.price_include_tax': 'Price include tax',
  'financeDelivery.add_more': 'Add more',
  'financeDelivery.number': 'Number',
  'financeDelivery.please_input_the_number': 'Please input the number.',
  'financeDelivery.memo': 'Memo',
  'financeDelivery.date': 'Date',
  'financeDelivery.status': 'Status',
  'financeDelivery.balance_due': 'Balance Due',
  'financeDelivery.total': 'Total',
  'financeDelivery.please_select_a_term': 'Please select a term.',
  'financeDelivery.search_by_memo': 'Search',
  'financeDelivery.the_due_date_max_higger_than_transaction_date':
    'The due date must same or after transaction date',
  'financeDelivery.detail_title': ' Invoice {number}',
  'financeDelivery.customer': 'Customer',
  'financeDelivery.reference': 'Reference',
  'financeDelivery.product': 'Product',
  'financeDelivery.qty': 'Qty',
  'financeDelivery.price': 'Price',
  'financeDelivery.add_product': 'Add Product',
  'financeDelivery.select_product': 'Select product',
  'financeDelivery.attachments': 'Attachments',
  'financeDelivery.save_&_approve': 'Save & approve',
  'financeDelivery.save_as_draft': 'Save as draft',
  'financeDelivery.save_&_submit_for_approval': 'Save & submit for approval',
  'financeDelivery.please_select_a_product': 'Please select a product.',
  'financeDelivery.please_input_the_qty': 'Please input the qty.',
  'financeDelivery.please_input_the_price': 'Please input the price.',
  'financeDelivery.please_input_the_amount': 'Please input the amount.',
  'financeDelivery.approve': 'Approve',
  'financeDelivery.are_you_sure_to_approve': 'Are you sure to approve ?',
  'financeDelivery.submit_for_approval': 'Submit for approval',
  'financeDelivery.are_you_sure_to_submit_for_approval': 'Are you sure to submit for approval ?',
  'financeDelivery.create_delivery': 'Create Delivery',
  'financeDelivery.amount_paid': 'Amount Paid',
  'financeDelivery.paid_from': 'Paid From',
  'financeDelivery.add_payment': 'Receive Payment',
  'financeDelivery.make_a_payment': 'Receive a payment',
  'financeDelivery.select_paid_from': 'Select paid to',
  'financeDelivery.please_select_a_paid_from': 'Please select receive payment to.',
  'financeDelivery.error_cannot_add_payment': 'Cannot add payment.',
  'financeDelivery.payment': 'Payment',
  'financeDelivery.detail_delivery': 'Detail Delivery',
  'financeDelivery.number_quotes': 'Quotes Number',
  'financeDelivery.amount': 'Amount',
  'financeDelivery.dp': 'DP',
  'financeDelivery.create_delivery': 'Create Delivery',
  'financeDelivery.shipping_delivery': 'Shipping Delivery',
  'financeDelivery.print_delivery': 'Print Delivery Order',
  'financeDelivery.print_delivery_label': 'Print Delivery Label',
  'financeDelivery.copy_link': 'Copy Delivery Link',
  'financeDelivery.send_email': 'Send Delivery Email',
  'financeDelivery.send_sms': 'Send Delivery SMS',
  'financeDelivery.send_wa': 'Send Delivery Whatsapp',

  'shipping.show_shipping_information': 'Show shipping information',
  'shipping.hide_shipping_information': 'Hide shipping information',
  'shipping.shipping_date': 'Shipping date',
  'shipping.shipping_expedition': 'Expediton',
  'shipping.shipping_tracking': 'Tracking number',
  'shipping.select_expedition': 'Select expedition',
  'shipping.add_expedition': 'Add expedition',
  'shipping.shipping_cost': 'Shipping cost',
  'shipping.please_select_shipping_date': 'Please select shipping date.',
  'shipping.shipping_information': 'Shipping information',

  'creditMemo.add_credit_memo': 'Add Credit Memo',
  'creditMemo.credit_memo': 'Credit Memo',
  'creditMemo.detail_credit_memo': 'Detail Credit Memo',
  'creditMemo.customer': 'Customer',
  'creditMemo.add_transactions': 'Add Transaction',
  'creditMemo.successfully_added': 'successfully added.',
  'creditMemo.error_cannot_add': 'Cannot add credit memo.',
  'creditMemo.error_cannot_update': 'Cannot update credit memo.',
  'creditMemo.edit_credit_memo': 'Edit Credit Memo',
  'creditMemo.max_characters': 'Max {value} characters.',
  'creditMemo.min_characters': 'Min {value} characters.',
  'creditMemo.only_number_allowed': 'Only number allowed.',
  'creditMemo.description': 'Description',
  'creditMemo.amount': 'Amount',
  'creditMemo.transaction_date': 'Transaction Date',
  'creditMemo.please_select_transaction_date': 'Please select transaction date',
  'creditMemo.number': 'Number',
  'creditMemo.please_input_the_number': 'Please input the number.',
  'creditMemo.memo': 'Memo',
  'creditMemo.message': 'Message',
  'creditMemo.date': 'Date',
  'creditMemo.total': 'Total',
  'creditMemo.search_by_memo': 'Search',
  'creditMemo.detail_title': 'Credit Memo {number}',
  'creditMemo.reference': 'Reference',
  'creditMemo.attachments': 'Attachments',
  'creditMemo.please_input_the_amount': 'Please input the amount.',
  'creditMemo.account': 'Account',
  'creditMemo.select_account': 'Select Account',
  'creditMemo.credit_balance': 'Credit Balance',
  'creditMemo.make_a_payment': 'Make a payment',
  'creditMemo.return_number': 'Return Number',

  'return.add_return': 'Add Return',
  'return.return': 'Return',
  'return.detail_return': 'Detail Return',
  'return.customer': 'Customer',
  'return.vendor': 'Vendor',
  'return.add_transactions': 'Add Transaction',
  'return.successfully_added': 'successfully added.',
  'return.error_cannot_add': 'Cannot add credit memo.',
  'return.error_cannot_update': 'Cannot update credit memo.',
  'return.edit_return': 'Edit Return',
  'return.max_characters': 'Max {value} characters.',
  'return.min_characters': 'Min {value} characters.',
  'return.only_number_allowed': 'Only number allowed.',
  'return.description': 'Description',
  'return.amount': 'Amount',
  'return.transaction_date': 'Return Date',
  'return.please_select_transaction_date': 'Please select transaction date',
  'return.number': 'Number',
  'return.please_input_the_number': 'Please input the number.',
  'return.memo': 'Memo',
  'return.message': 'Message',
  'return.date': 'Date',
  'return.total': 'Total',
  'return.search_by_memo': 'Search',
  'return.detail_title': 'Return {number}',
  'return.reference': 'Reference',
  'return.attachments': 'Attachments',
  'return.please_input_the_amount': 'Please input the amount.',
  'return.invoice': 'Invoice',
  'return.faktur_balance': 'Faktur Balance',
  'return.qty_faktur': 'Qty Faktur',
  'return.available_return': 'Available Retur',
  'return.product': 'Product',
  'return.discount_percent': 'Discount',
  'return.tax': 'Tax',
  'return.qty_return': 'Qty Return',
  'return.price': 'Harga',
  'return.price': 'Harga',
  'return.please_input_the_qty': 'Please input the qty.',
  'return.price_include_tax': 'Price include tax',
  'return.cannot_greather_than_available': 'Cannot greather than available.',
  'return.balance_due': 'Balance Due',
  'return.total_return': 'Return Total',

  'financePurchaseQuotes.message': 'Message',
  'financePurchaseQuotes.add_quote': 'Add Quote',
  'financePurchaseQuotes.financePurchaseQuotes': 'Quote',
  'financePurchaseQuotes.successfully_added': 'successfully added.',
  'financePurchaseQuotes.error_cannot_add': 'Cannot add quote.',
  'financePurchaseQuotes.error_cannot_print_pdf': 'Cannot print pdf quote.',
  'financePurchaseQuotes.error_cannot_update': 'Cannot update quote.',
  'financePurchaseQuotes.error_cannot_move': 'Cannot move the quote.',
  'financePurchaseQuotes.error_cannot_change_status': 'Cannot change status the quote.',
  'financePurchaseQuotes.edit_quote': 'Edit Quote',
  'financePurchaseQuotes.max_characters': 'Max {value} characters.',
  'financePurchaseQuotes.min_characters': 'Min {value} characters.',
  'financePurchaseQuotes.only_number_allowed': 'Only number allowed.',
  'financePurchaseQuotes.description': 'Description',
  'financePurchaseQuotes.tax': 'Tax',
  'financePurchaseQuotes.select_tax': 'Select tax',
  'financePurchaseQuotes.amount': 'Amount',
  'financePurchaseQuotes.please_select_a_contact': 'Please select a contact',
  'financePurchaseQuotes.select_contact': 'Select contact',
  'financePurchaseQuotes.add_contact': 'Add contact',
  'financePurchaseQuotes.all_contacts': 'All contact',
  'financePurchaseQuotes.transaction_date': 'Transaction Date',
  'financePurchaseQuotes.please_select_transaction_date': 'Please select transaction date',
  'financePurchaseQuotes.due_date': 'Expiry',
  'financePurchaseQuotes.please_select_due_date': 'Please select expiry',
  'financePurchaseQuotes.term': 'Term',
  'financePurchaseQuotes.select_term': 'Select term',
  'financePurchaseQuotes.add_term': 'Add term',
  'financePurchaseQuotes.price_include_tax': 'Price include tax',
  'financePurchaseQuotes.add_more': 'Add more',
  'financePurchaseQuotes.number': 'Number',
  'financePurchaseQuotes.please_input_the_number': 'Please input the number.',
  'financePurchaseQuotes.memo': 'Memo',
  'financePurchaseQuotes.date': 'Date',
  'financePurchaseQuotes.status': 'Status',
  'financePurchaseQuotes.balance_due': 'Balance Due',
  'financePurchaseQuotes.total': 'Total',
  'financePurchaseQuotes.please_select_a_term': 'Please select a term.',
  'financePurchaseQuotes.search_by_memo': 'Search',
  'financePurchaseQuotes.the_due_date_max_higger_than_transaction_date':
    'The expiry date must same or after transaction date',
  'financePurchaseQuotes.detail_title': 'Quote {number}',
  'financePurchaseQuotes.customer': 'Customer',
  'financePurchaseQuotes.reference': 'Reference',
  'financePurchaseQuotes.product': 'Product',
  'financePurchaseQuotes.qty': 'Qty',
  'financePurchaseQuotes.price': 'Price',
  'financePurchaseQuotes.add_product': 'Add Product',
  'financePurchaseQuotes.select_product': 'Select product',
  'financePurchaseQuotes.attachments': 'Attachments',
  'financePurchaseQuotes.save_as_draft': 'Save as draft',
  'financePurchaseQuotes.save_&_submit_for_approval': 'Save & submit for approval',
  'financePurchaseQuotes.please_select_a_product': 'Please select a product.',
  'financePurchaseQuotes.please_input_the_qty': 'Please input the qty.',
  'financePurchaseQuotes.please_input_the_price': 'Please input the price.',
  'financePurchaseQuotes.please_input_the_amount': 'Please input the amount.',
  'financePurchaseQuotes.mark_as_sent': 'Mark as sent',
  'financePurchaseQuotes.are_you_sure_to_mark_as_sent': 'Are you sure to mark as sent ?',
  'financePurchaseQuotes.mark_as_accepted': 'Mark as accepted',
  'financePurchaseQuotes.are_you_sure_to_mark_as_accepted': 'Are you sure to mark as accepted ?',
  'financePurchaseQuotes.mark_as_declined': 'Mark as declined',
  'financePurchaseQuotes.are_you_sure_to_mark_as_declined': 'Are you sure to mark as declined ?',
  'financePurchaseQuotes.create_invoice': 'Create Invoice',
  'financePurchaseQuotes.send': 'Send',
  'financePurchaseQuotes.detail_quote': 'Detail Quote',
  'financePurchaseQuotes.create_order': 'Create Order',

  'financePurchaseQuotes.follow_these_steps_to_import_your_quotes':
    'Follow these steps to import your quotes',
  'financePurchaseQuotes.1_download_your_quotes_template_file':
    '1. Download your quotes template file',
  'financePurchaseQuotes.start_by_downloading_our_quotes_csv':
    'Start by downloading our quotes Excel template file. This file has the correct column headings Kledo needs to import details of your quotes.',
  'financePurchaseQuotes.download_template_file': 'Download Template File',
  'financePurchaseQuotes.download_template_data_example_file':
    'Download template data example file',
  'financePurchaseQuotes.import': 'Import Quotes',
  'financePurchaseQuotes.2_copy_your_quotes_into_the_template':
    '2. Copy your quotes into the template',
  'financePurchaseQuotes.using_excel_or_another_spreadsheet_editor':
    'Using Excel or another spreadsheet editor, fill the Kledo template with the details of your quotes. Make sure the product details match the column headings provided in the template.',
  'financePurchaseQuotes.important': 'IMPORTANT',
  'financePurchaseQuotes.3_upload_the_updated_template_file_here':
    '3. Upload the updated template file here',
  'financePurchaseQuotes.upload': 'Upload',
  'financePurchaseQuotes.save_your_updated_template_as_.csv_or_.txt_and_you_are_ready_to_go':
    'Save your updated template and you are ready to go',
  'financePurchaseQuotes.a_maximum_of_500_quotes_can_be_imported_at_a_time':
    'A maximum of 1.000 quotes can be imported at a time.',
  'financePurchaseQuotes.product_code_that_not_exist_will_be_inserted_as_a_new_product':
    'Product code that not exist will be inserted as a new product.',
  'financePurchaseQuotes.contact_name_that_not_exist_will_be_inserted_as_a_new_contact':
    'Contact name that not exist will be inserted as a new contact.',
  'financePurchaseQuotes.import_actions_only_insert_new_quotes_cannot_update_or_delete':
    'Import actions only insert new quotes, cannot update nor delete.',
  'financePurchaseQuotes.dont_change_the_column_heading_in_the_template_file':
    'Do not change the column headings in the template file. These need to be unchanged for the important to work in the next step.',
  'financePurchaseQuotes.you_can_only_upload_csv_file': 'You can only upload csv or excel file.',
  'financePurchaseQuotes.upload_file': 'Upload File',
  'financePurchaseQuotes.check_the_imported_data': 'Check Data',
  'financePurchaseQuotes.please_check_the_imported_data': 'Please check the imported data',
  'financePurchaseQuotes.import_quotes_done': 'Import Success',
  'financePurchaseQuotes.error_upload_file': 'Upload failed.',
  'financePurchaseQuotes.file_not_found': 'File not found',
  'financePurchaseQuotes.if_you_are_happy_to_continue_with_the_import_please_click_the_import_now_button': `If you are happy to continue with the import please click the "Import Now" button.`,
  'financePurchaseQuotes.valid': 'Valid',
  'financePurchaseQuotes.invalid': 'Invalid',
  'financePurchaseQuotes.total': 'Total',
  'financePurchaseQuotes.import_now': 'Import Now',
  'financePurchaseQuotes.contact_name': 'Contact name',
  'financePurchaseQuotes.email': 'Email',
  'financePurchaseQuotes.address': 'Address',
  'financePurchaseQuotes.phone': 'Phone',
  'financePurchaseQuotes.quote_date': 'Quote Date',
  'financePurchaseQuotes.quote_number': 'Quote Number',
  'financePurchaseQuotes.product_name': 'Product name',
  'financePurchaseQuotes.code_sku': 'Kode/SKU',
  'financePurchaseQuotes.tax_inclusive': 'Tax Inclusive',
  'financePurchaseQuotes.paid': 'Paid',
  'financePurchaseQuotes.paid_account': 'Paid To Account',

  'financePurchaseQuotes.inventory_account': 'Inventory Account',
  'financePurchaseQuotes.quotes_successfully_imported': '{rowCount} quote imported successfully.',
  'financePurchaseQuotes.view_detail': 'View detail',
  'financePurchaseQuotes.import_another_file': 'Import another file',
  'financePurchaseQuotes.go_to_quote_list': 'Go to quote list',
  'financePurchaseQuotes.success': 'Success',
  'financePurchaseQuotes.failed': 'Failed',
  'financePurchaseQuotes.error_cannot_import': 'Import failed',
  'financePurchaseQuotes.error': 'Error',
  'financePurchaseQuotes.vendor': 'Vendor',
  'debitMemo.add_debit_memo': 'Add Debit Memo',
  'debitMemo.debit_memo': 'Debit Memo',
  'debitMemo.detail_debit_memo': 'Detail Debit Memo',
  'debitMemo.customer': 'Customer',
  'debitMemo.add_transactions': 'Add Transaction',
  'debitMemo.successfully_added': 'successfully added.',
  'debitMemo.error_cannot_add': 'Cannot add debit memo.',
  'debitMemo.error_cannot_update': 'Cannot update debit memo.',
  'debitMemo.edit_debit_memo': 'Edit Debit Memo',
  'debitMemo.max_characters': 'Max {value} characters.',
  'debitMemo.min_characters': 'Min {value} characters.',
  'debitMemo.only_number_allowed': 'Only number allowed.',
  'debitMemo.description': 'Description',
  'debitMemo.amount': 'Amount',
  'debitMemo.transaction_date': 'Transaction Date',
  'debitMemo.please_select_transaction_date': 'Please select transaction date',
  'debitMemo.number': 'Number',
  'debitMemo.please_input_the_number': 'Please input the number.',
  'debitMemo.memo': 'Memo',
  'debitMemo.message': 'Message',
  'debitMemo.date': 'Date',
  'debitMemo.total': 'Total',
  'debitMemo.search_by_memo': 'Search',
  'debitMemo.detail_title': 'Debit Memo {number}',
  'debitMemo.reference': 'Reference',
  'debitMemo.attachments': 'Attachments',
  'debitMemo.please_input_the_amount': 'Please input the amount.',
  'debitMemo.account': 'Account',
  'debitMemo.select_account': 'Select Account',
  'debitMemo.debit_balance': 'Debit Balance',
  'debitMemo.make_a_payment': 'Receive Payment',
  'debitMemo.return_number': 'Return Number',

  'financePurchaseDelivery.message': 'Message',
  'financePurchaseDelivery.add_delivery': 'Add Delivery',
  'financePurchaseDelivery.financePurchaseDelivery': 'Delivery',
  'financePurchaseDelivery.successfully_added': 'successfully added.',
  'financePurchaseDelivery.error_cannot_add': 'Cannot add delivery.',
  'financePurchaseDelivery.error_cannot_print_pdf': 'Cannot print pdf delivery.',
  'financePurchaseDelivery.error_cannot_update': 'Cannot update delivery.',
  'financePurchaseDelivery.error_cannot_move': 'Cannot move the delivery.',
  'financePurchaseDelivery.error_cannot_change_status': 'Cannot change status the delivery.',
  'financePurchaseDelivery.edit_delivery': 'Edit Invoice',
  'financePurchaseDelivery.max_characters': 'Max {value} characters.',
  'financePurchaseDelivery.min_characters': 'Min {value} characters.',
  'financePurchaseDelivery.only_number_allowed': 'Only number allowed.',
  'financePurchaseDelivery.description': 'Description',
  'financePurchaseDelivery.tax': 'Tax',
  'financePurchaseDelivery.select_tax': 'Select tax',
  'financePurchaseDelivery.amount': 'Amount',
  'financePurchaseDelivery.please_select_a_contact': 'Please select a contact',
  'financePurchaseDelivery.select_contact': 'Select contact',
  'financePurchaseDelivery.add_contact': 'Add contact',
  'financePurchaseDelivery.all_contacts': 'All contact',
  'financePurchaseDelivery.transaction_date': 'Transaction Date',
  'financePurchaseDelivery.please_select_transaction_date': 'Please select transaction date',
  'financePurchaseDelivery.due_date': 'Due Date',
  'financePurchaseDelivery.please_select_due_date': 'Please select due date',
  'financePurchaseDelivery.term': 'Term',
  'financePurchaseDelivery.select_term': 'Select term',
  'financePurchaseDelivery.add_term': 'Add term',
  'financePurchaseDelivery.price_include_tax': 'Price include tax',
  'financePurchaseDelivery.add_more': 'Add more',
  'financePurchaseDelivery.number': 'Number',
  'financePurchaseDelivery.please_input_the_number': 'Please input the number.',
  'financePurchaseDelivery.memo': 'Memo',
  'financePurchaseDelivery.date': 'Date',
  'financePurchaseDelivery.status': 'Status',
  'financePurchaseDelivery.balance_due': 'Balance Due',
  'financePurchaseDelivery.total': 'Total',
  'financePurchaseDelivery.please_select_a_term': 'Please select a term.',
  'financePurchaseDelivery.search_by_memo': 'Search',
  'financePurchaseDelivery.the_due_date_max_higger_than_transaction_date':
    'The due date must same or after transaction date',
  'financePurchaseDelivery.detail_title': ' Invoice {number}',
  'financePurchaseDelivery.customer': 'Customer',
  'financePurchaseDelivery.vendor': 'Vendor',
  'financePurchaseDelivery.reference': 'Reference',
  'financePurchaseDelivery.product': 'Product',
  'financePurchaseDelivery.qty': 'Qty',
  'financePurchaseDelivery.price': 'Price',
  'financePurchaseDelivery.add_product': 'Add Product',
  'financePurchaseDelivery.select_product': 'Select product',
  'financePurchaseDelivery.attachments': 'Attachments',
  'financePurchaseDelivery.save_&_approve': 'Save & approve',
  'financePurchaseDelivery.save_as_draft': 'Save as draft',
  'financePurchaseDelivery.save_&_submit_for_approval': 'Save & submit for approval',
  'financePurchaseDelivery.please_select_a_product': 'Please select a product.',
  'financePurchaseDelivery.please_input_the_qty': 'Please input the qty.',
  'financePurchaseDelivery.please_input_the_price': 'Please input the price.',
  'financePurchaseDelivery.please_input_the_amount': 'Please input the amount.',
  'financePurchaseDelivery.approve': 'Approve',
  'financePurchaseDelivery.are_you_sure_to_approve': 'Are you sure to approve ?',
  'financePurchaseDelivery.submit_for_approval': 'Submit for approval',
  'financePurchaseDelivery.are_you_sure_to_submit_for_approval':
    'Are you sure to submit for approval ?',
  'financePurchaseDelivery.create_delivery': 'Create Delivery',
  'financePurchaseDelivery.amount_paid': 'Amount Paid',
  'financePurchaseDelivery.paid_from': 'Paid From',
  'financePurchaseDelivery.add_payment': 'Receive Payment',
  'financePurchaseDelivery.make_a_payment': 'Receive a payment',
  'financePurchaseDelivery.select_paid_from': 'Select paid to',
  'financePurchaseDelivery.please_select_a_paid_from': 'Please select receive payment to.',
  'financePurchaseDelivery.error_cannot_add_payment': 'Cannot add payment.',
  'financePurchaseDelivery.payment': 'Payment',
  'financePurchaseDelivery.detail_delivery': 'Detail Delivery',
  'financePurchaseDelivery.number_quotes': 'Quotes Number',
  'financePurchaseDelivery.amount': 'Amount',
  'financePurchaseDelivery.dp': 'DP',
  'financePurchaseDelivery.create_delivery': 'Create Delivery',
  'financePurchaseDelivery.shipping_delivery': 'Shipping Delivery',

  'businessFlow.businessFlow': 'Settings Business Flow',
  'businessFlow.sales_discount': 'Sales discount',
  'businessFlow.sales_discount_desc': 'Does your company can give sales discount?',
  'businessFlow.delivery_desc': 'Does your company make a pass at delivery?',
  'businessFlow.order_desc': 'Does your company accept sales orders?',
  'businessFlow.quote_desc': 'Have your company send out sales offers?',
  'businessFlow.down_payment': 'Down payment',
  'businessFlow.down_payment_desc': 'Does your company can accept sales down payment?',
  'businessFlow.purchase_down_payment_desc': 'Does your company can paid purchase down payment?',
  'businessFlow.purchase_discount': 'Purchase discount',
  'businessFlow.purchase_discount_desc': 'Does your company can accept purchase discount?',
  'businessFlow.purchase_delivery': 'Purchase delivery',
  'businessFlow.purchase_delivery_desc': 'Does purchase delivery feature activated?',
  'businessFlow.purchase_order': 'Purchase order',
  'businessFlow.purchase_order_desc': 'Does your company place a purchase order?',
  'businessFlow.purchase_quote': 'Purchase quote',
  'businessFlow.purchase_quote_desc': 'Does your company accept purchase orders?',
  'businessFlow.reconciliation': 'Reconciliation',
  'businessFlow.reconciliation_desc':
    'Do you reconcile transactions at Kledo with mutations in bank accounts?',
  'businessFlow.tracking_stock': 'Track inventory / stock by default',
  'businessFlow.tracking_stock_desc':
    'When adding products, by default set products to track stock',
  'businessFlow.error_cannot_update': 'Cannot change settings.',
  'businessFlow.decline_new_sales_if_stock_not_enough': 'Decline new invoice if stock not enough',
  'businessFlow.decline_new_order_if_stock_not_enough': 'Decline new order if stock not enough',
  'businessFlow.decline_new_delivery_if_stock_not_enough':
    'Decline new delivery if stock not enough',
  'businessFlow.show_available_stock_when_making_a_sale': 'Show available stock when making a sale',
  'businessFlow.whether_stock_is_in_the_warehouse':
    'Whether stock is in the warehouse needs to be displayed when making a new sale?',
  'businessFlow.show_available_stock_per_warehouse': 'Stock calculated by selected warehouse',
  'businessFlow.hide_products_that_are_out_of_stock': 'Hide products that are out of stock',
  'businessFlow.hide_products_that_are_out_of_stock_description':
    'Are out-of-stock products hidden when adding to a bill?',
  'businessFlow.show_profit_margin_when_making_transaction':
    'Show profit margin when making transaction',
  'businessFlow.show_details_of_average_price_and_profit_margin_when_making_a_new_transaction':
    'Show details of average price and profit margin when making a new transaction.',

  'businessFlow.whether_displayed_stock_needs_per_warehouse':
    'If active, then stock is calculated by selected warehouse instead of all stock',
  'businessFlow.decline_to_add_sales_if_the_total_stock_not_enough':
    'Decline to add invoice if the total stock in the warehouse is insufficient?',
  'businessFlow.decline_to_add_order_if_the_total_stock_not_enough':
    'Decline to add order if the total stock in the warehouse is insufficient?',
  'businessFlow.decline_to_add_delivery_if_the_total_stock_not_enough':
    'Decline to add delivery if the total stock in the warehouse is insufficient?',
  'businessFlow.discount_in_nominal': 'Discount input in numeric',
  'businessFlow.wether_discount_input_when_add_invoice_in_nominal_format_or_percent_format':
    'Wether discount input when add invoice in numeric format or percentage format',
  'businessFlow.product_price_dont_change': "Product price can't changed",
  'businessFlow.wether_product_price_can_be_changed_when_add_invoice':
    'Wether product price can be changed when add invoice',
  'businessFlow.sales_person': 'Sales person',
  'businessFlow.wether_save_sales_person_to_sales': 'Wether to save the sales person to the sales?',
  'businessFlow.default_sale_include_tax': 'Default sale price include tax',
  'businessFlow.wether_create_sale_invoice_include_tax':
    'Wether when creating invoice default sale price include tax?',
  'businessFlow.default_purchase_include_tax': 'Default purchase price include tax',
  'businessFlow.wether_create_purchase_invoice_include_tax':
    'Wether when creating invoice default purchase price include tax?',
  'businessFlow.min_stock': 'Minimal stock',
  'businessFlow.when_product_below_min_stock_email_notification':
    'When product stock below minimal stock, email notification will be sent',
  'businessFlow.decline_product_conv_if_stock_not_enough':
    'Decline product conversion if stok is not enough',
  'businessFlow.wether_decline_add_product_conv_if_stock_not_enough':
    'Wether decline add product conversion if stock not enough',
  'businessFlow.lang_date_currency': 'Language, Date, & Currency',
  'businessFlow.app_lang': 'Application language',
  'businessFlow.app_lang_desc': 'Default language for every staff in your company',
  'businessFlow.time_zone': 'Time zone',
  'businessFlow.time_zone_desc': 'Time zone location of your company',
  'businessFlow.date_format': 'Date format',
  'businessFlow.date_format_desc': 'Date display in Kledo app',
  'businessFlow.time_format': 'Time format',
  'businessFlow.time_format_desc': 'Hour and minute display in Kledo app',
  'businessFlow.number_after_comma': 'Number after comma',
  'businessFlow.number_after_comma_desc': 'How much number behind comma showed in Kledo app?',
  'businessFlow.currency_format': 'Currency format',
  'businessFlow.currency_format_desc': 'Currency display in Kledo app',
  'businessFlow.activate_multi_currency': 'Activate multi currency feature',
  'businessFlow.activate_multi_currency_desc': 'Does your company use more than one currency?',
  'businessFlow.base_currency': 'Base currency',
  'businessFlow.base_currency_desc': 'Currency used as main currency in your company',
  'businessFlow.show_reference': 'Show reference',
  'businessFlow.show_reference_desc': 'Wether show reference input when creating invoice',
  'businessFlow.show_tag': 'Show tag',
  'businessFlow.show_tag_desc': 'Wether show tag input when creating invoice',
  'businessFlow.input_tag_is_required': 'Input tag is required',
  'businessFlow.input_tag_is_required_desc': 'Is the tag required when adding a sales transaction?',
  'businessFlow.show_delivery_info': 'Show delivery information',
  'businessFlow.show_delivery_info_desc':
    'Wether show delivery information input when creating invoice',
  'businessFlow.lock_price_from_quote': 'Lock price from quote',
  'businessFlow.lock_price_from_quote_desc':
    'Wether when create invoice from quote, the price cannot be changed?',
  'businessFlow.lock_price_from_purchase_quote': 'Lock price from purchase quote',
  'businessFlow.lock_price_from_purchase_quote_desc':
    'Wether when create purchase invoice from quote, the price cannot be changed?',
  'businessFlow.use_fifo_method': 'Use FIFO method to calculate COGS',
  'businessFlow.use_fifo_method_desc':
    'Turn on to activate FIFO method to calculate COGS, or turn it off to use averaging method. You cannot change this setting after you have product with inventory tracking active. This settings only available on Elite Plan.',
  'businessFlow.auto_update_product_buy_price': 'Auto update product buy price',
  'businessFlow.auto_update_product_buy_price_desc':
    'When buying a product, the buy price is automatically updated to the product as the default buy price',
  'businessFlow.auto_update_product_sell_price': 'Auto update product selling price',
  'businessFlow.auto_update_product_sell_price_desc':
    'When selling a product, the selling price is automatically updated to the product as the default selling price',
  'businessFlow.reference_multiline': 'Multiline reference',
  'businessFlow.reference_multiline_desc': 'Can reference be multiline?',

  'salesPerson.sales_person': 'Sales Person',
  'salesPerson.show_sales_person': 'Show Sales Person',
  'salesPerson.hide_sales_person': 'Hide Sales Person',
  'salesPerson.select_sales_person': 'Select sales person',
  'salesPerson.sales_name': 'Sales Name',
  'salesPerson.sales_amount': 'Sales Amount',
  'salesPerson.sales_total': 'Sales Total',
  'salesPerson.customer': 'Customer',
  'salesPerson.sales_incentive': 'Sales Incentive',
  'salesPerson.sales_commission': 'Sales Commission',
  'salesPerson.manage_sales_commission': 'Manage Sales Commission',
  'salesPerson.commission_rule': 'Commission Rule',
  'salesPerson.faq_question_commission_calculated':
    'How is the commission calculated on this report?',
  'salesPerson.faq_answer_commission_calculated':
    'Commission is calculated from the total bill after discount. The tax and postage components are not used to calculate commissions.',
  'salesPerson.faq_question_give_different_commission':
    'How to give a different commission for each sales?',
  'salesPerson.faq_answer_give_different_commission':
    'You can set the amount of commission for each sales person through the {here}. In addition, the commission can also be set differently for each product.',
  'salesPerson.faq_answer_give_different_commission_menu_here': 'menu here',
  'salesPerson.faq_question_give_commission_progressively':
    'How to give commission progressively, the more sales the bigger the commission?',
  'salesPerson.faq_answer_give_commission_progressively':
    'You can set multilevel commission from settings, please {here}.',

  'massEmail.mass_email': 'Mass Send Email',
  'massEmail.cannot_mass_email': 'Cannot mass sent email',
  'massEmail.can_be_sent': 'Can be sent',
  'massEmail.cannot_be_sent': 'Cannot be sent',
  'massEmail.are_you_sure': 'Are you sure want to sent {count} {title}(s) email?',
  'massEmail.data_progress_sent': '{count} out of {total} email has been sent',
  'massEmail.data_has_been_sent': 'Total {count} email has been sent',

  'massDelete.mass_delete': 'Mass Delete',
  'massDelete.cannot_mass_delete': 'Cannot mass delete',
  'massDelete.can_be_deleted': 'Can be deleted',
  'massDelete.cannot_be_deleted': 'Cannot be deleted',
  'massDelete.archived': 'Archived',
  'massDelete.are_you_sure': 'Are you sure want to delete {count} {title}(s)?',
  'massDelete.are_you_sure_archive': 'Are you sure want to delete/archive {count} {title}(s)?',
  'massDelete.deleted_data_is_irreversible': 'Deleted data is irreversible',
  'massDelete.data_progress_deleted': '{count} out of {total} data has been deleted',
  'massDelete.data_has_been_deleted': 'Total {count} data has been deleted',
  'massDelete.transaction_before_lock_date_and_reconciled_cannot_be_deleted':
    '**Transaction before lock date and reconciled transaction cannot be deleted',
  'massDelete.transaction_before_lock_date_reconciled_and_related_cannot_be_deleted':
    '**Transaction before lock date, reconciled transaction, and transaction that has relation to another transaction cannot be deleted',

  'massPrint.cannot_mass_print': 'Cannot mass print',

  'onBoarding.greeting': 'Hallo {name},',
  'onBoarding.welcome_to_kledo': 'Welcome to Kledo!',
  'onBoarding.setup_kledo_account': "Let's go to setup Kledo account :)",
  'onBoarding.start_to_setup': 'Start to setup',
  'onBoarding.start_to_setup': 'Start to setup',
  'onBoarding.inventory_question':
    'Do you want to manage the stock and inventory of products sold ?',
  'onBoarding.quote_question': 'Do you need an offer letter to a customer ?',
  'onBoarding.order_question': 'Do you make a purchase order to a supplier ?',
  'onBoarding.delivery_question': 'Do you need a pass ?',
  'onBoarding.horee': 'Yeaah!',
  'onBoarding.setup_complete': 'Setup is complete, now you can start using Kledo.',
  'onBoarding.go_to_dashboard': 'Go to Dashboard',
  'onBoarding.cannot_access_onboarding': 'Cannot access on boarding',

  'recurring.add': 'Add Recurring {title}',
  'recurring.edit': 'Edit Recurring {title}',
  'recurring.add_invoice': 'Add recurring',
  'recurring.edit_invoice': 'Edit recurring',
  'recurring.add_order': 'Add recurring order',
  'recurring.edit_order': 'Edit recurring order',
  'recurring.add_purchaseinvoice': 'Add recurring purchase invoice',
  'recurring.edit_purchaseinvoice': 'Edit recurring purchase invoice',
  'recurring.add_purchaseorder': 'Add recurring purchase order',
  'recurring.edit_purchaseorder': 'Edit recurring purchase order',
  'recurring.add_expense': 'Add recurring expense',
  'recurring.edit_expense': 'Edit recurring expense',
  'recurring.add_manualjournal': 'Add recurring manual journal',
  'recurring.edit_manualjournal': 'Edit recurring manual journal',
  'recurring.add_cashbank': 'Add recurring {title}',
  'recurring.edit_cashbank': 'Edit recurring cash & bank',
  'recurring.recurring_transaction_periode': 'Repeat this transaction every',
  'recurring.start_date': 'Start date',
  'recurring.end_date': 'End date (Optional)',
  'recurring.end_date_column': 'End date',
  'recurring.next_date_transaction': 'Next of transaction date',
  'recurring.automatic_send_email_on_created_transaction':
    'Send automated email every time a transaction is made',
  'recurring.week': 'Week',
  'recurring.month': 'Month',
  'recurring.please_input_the_recurring': 'Please input the recurring',
  'recurring.please_select_start_date': 'Please select start date',
  'recurring.please_select_end_date': 'Please select end date',
  'recurring.title': 'Recurring {name}',
  'recurring.cannot_select_before_start_date': 'Cannot select before start date',
  'recurring.recurring': 'Recurring',
  'recurring.repeat_every': 'Repeat every',
  'recurring.next_date': 'Next Transaction',
  'recurring.total': 'Total',
  'recurring.error_load_data': 'Error on get list recurring',
  'recurring.error_cannot_recurring': 'Error cannot create recurring',
  'recurring.error_cannot_delete': 'Error cannot delete recurring',
  'recurring.error_load_detail': 'Error load detail transaction',
  'recurring.recurring': 'Recurring',
  'recurring.new_transaction': 'New Recurring Transaction',
  'recurring.cannot_select_before_current_date': 'Cannot select before current date',

  'tutorial.video_tutorial': 'Video Tutorial',

  'report.the_total_number_of_bills_issued_during_this_period':
    'The total number of bills issued during this period.',
  'report.average_value_of_invoices_issued_during_this_period':
    'Average value of invoices issued during this period.',
  'report.gross_profit_margin_is_the_ratio_of_gross_profit_to_total_revenue':
    'Gross profit margin is the ratio of gross profit to total revenue.',
  'report.gross_profit_margin_is_the_ratio_of_net_income_to_total_revenue':
    'Gross profit margin is the ratio of net income to total revenue.',
  'report.roi_is_the_ratio_of_net_income_to_net_assets_per_year':
    'ROI is the ratio of net income to net assets per year. Where net assets are total assets minus liabilities.',
  'report.the_average_number_of_days_it_takes_your_customer_to_pay_the_bill':
    'The average number of days it takes your customer to pay the bill.',
  'report.the_average_number_of_days_it_take_you_to_pay_a_bill_to_a_vendor':
    'The average number of days it takes you to pay a bill to a vendor.',
  'report.is_the_percentage_of_liabilities_divided_by_equity':
    'Is the percentage of liabilities divided by equity.',
  'report.is_the_percentage_of_current_assets_divided_by_labilities':
    'Is the percentage of current assets divided by liabilities.',

  'invoicePaymentCreditMemo.invoicePaymentCreditMemo': 'Invoice Payment Credit Memo',
  'purchaseInvoicePaymentDebitMemo.purchaseInvoicePaymentDebitMemo':
    'Purchase Invoice Payment Debit Memo',

  'unrealizedGain.unrealizedGain': 'Unrealized Gain',
  'financeProduct.profit_margin': 'Profit margin',
  'financeProduct.average_purchase_price': 'Average purchase price',
  'financeProduct.last_purchase_price': 'Last purchase price',
  'financeProduct.insufficient_payment': 'Insufficient payment',
  'settings.menu_cannot_be_found': 'Settings Menu Cannot Be Found',
  'report.avg': 'Avg',
  'bussinesFlow.default_tracking_stock': 'Default tracking stock',
  'bussinesFlow.desc_default_tracking_stock':
    'By default the track stock feature is active when creating a new product',
  'settings.reset': 'Reset',
  'financeContacts.taxable': 'Taxable',
  'tooltip.next': 'Next {title}',
  'tooltip.prev': 'Prev {title}',
  'financeBanks.bank_transaction': 'Bank Transaction',
  'picker.select_year': 'Select year',
  'picker.select_month': 'Select month',
}

export default {
  locale: 'en-US',
  antdData,
  localeData,
  messages,
}
