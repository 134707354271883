import React from 'react';
import { Button, Space } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';

function Filter({ showClearBtn, onClear, ...props }) {
  const title = props.title === 'button.filter' ? props.intl.formatMessage({ id: props.title }) : props.title
  return (
    <Space>
      <Button
        {...props}
        title={title}
      >
        {title}
      </Button>
      {showClearBtn && (
        <Button onClick={onClear} icon={<CloseOutlined />} danger>
          {props.intl.formatMessage({ id: 'button.clear_filter' })}
        </Button>
      )}
    </Space>
  )
}

Filter.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  showClearBtn: PropTypes.bool,
  onClear: PropTypes.func,
}

Filter.defaultProps = {
  icon: <FilterOutlined />,
  title: "button.filter",
  onClick: () => { },
  id: "btn-filter",
  showClearBtn: false,
  onClear: () => { },
}

export default injectIntl(Filter);