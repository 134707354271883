import React from 'react'
import { Row, Col } from 'antd'

const RowItemPrint = ({ label, content, style, contentAsHTML }) => {
  return (
    <Row
      style={{
        marginTop: '2mm',
        ...style,
      }}
    >
      <Col span={24}>
        <h5>{label}</h5>
        <h5>
          {!contentAsHTML ? (
            <strong>{content}</strong>
          ) : (
            <strong dangerouslySetInnerHTML={{ __html: contentAsHTML ? content : undefined }} />
          )}
        </h5>
      </Col>
    </Row>
  )
}

export default RowItemPrint
