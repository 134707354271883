import axios from 'axios';

export const getDashboards = (id, params, cancelToken) => {
	return axios.get(`/dashboards/${id}`, { params, cancelToken });
};

export const moveDashboards = (id, destId) => {
	return axios.patch(`/dashboards/${id}/move`, { dest_id: destId });
};

export const activateDashboards = (id) => {
	return axios.patch(`/dashboards/${id}/activate`);
};

export const deactivateDashboards = (id) => {
	return axios.patch(`/dashboards/${id}/deactivate`);
};

export const watchlistsDashboardPost = (data) => {
	return axios.post(`/dashboards/watchlists`, data);
};

export const watchlistsDashboardDelete = (id) => {
	return axios.delete(`/dashboards/watchlists/${id}`);
};

export const watchlistsDashboardMove = (id, destId) => {
	return axios.patch(`/dashboards/watchlists/${id}/move`, { dest_id: destId });
};

export const getAllDashboards = (requests) => {
	return axios.all(requests).then(axios.spread((...responses) => responses));
};

export const getCaches = (names=[], cancelToken) => {
	const nameString = names.join(',');
	return axios.get(`/caches?name=${nameString}`, { cancelToken });
};
