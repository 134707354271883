import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SaveOutlined } from '@ant-design/icons';

function Save(props) {
  const title = props.title === 'button.save' ? props.intl.formatMessage({ id: props.title }) : props.title
  return (
    <Button
      {...props}
      title={title}
    >
      {title}
    </Button>
  )
}

Save.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
}

Save.defaultProps = {
  type: "primary",
  icon: <SaveOutlined />,
  title: "button.save",
  onClick: () => { },
  id: "btn-save"
}

export default injectIntl(Save);