import axios from 'axios';
import { stringify } from 'query-string';
import { getOnce } from 'utils/helper';

export const getPurchaseReturns = (params) => {
  const query = stringify(params);
  return getOnce(`/finance/purchaseReturns?${query}`);
};

export const addPurchaseReturn = (data) => {
  return axios.post('/finance/purchaseReturns', data);
};

export const editPurchaseReturn = (data) => {
  return axios.put(`/finance/purchaseReturns/${data.id}`, data);
};

export const deleteOnAddPurchaseReturnAttachment = (url) => {
  return axios.delete('/finance/purchaseReturns/attachments', { data: { url } });
};

export const deleteOnEditPurchaseReturnAttachment = (id, url) => {
  return axios.delete(`/finance/purchaseReturns/${id}/attachments`, { data: { url } });
};

export const getPurchaseReturn = (id, params) => {
  const query = params ? `?${stringify(params)}` : '';
  return axios.get(`/finance/purchaseReturns/${id}${query}`);
};

export const deletePurchaseReturn = (id) => {
  return axios.delete(`/finance/purchaseReturns/${id}`);
};
