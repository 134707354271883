import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { filterOption } from 'utils/helper';

const {Option} = Select;

class AccountDropdown extends React.Component {
  getOptions = () => {
    const { accounts, filterCategoryIds } = this.props;
    let newAccounts = [];
    if (!isEmpty(filterCategoryIds)) {
      filterCategoryIds.forEach(category => {
        const filterAccounts = accounts.filter(item => category === item.finance_account_category_id);
        filterAccounts.forEach(account => {
          newAccounts.push(account)
        })
      })
    } else {
      newAccounts = accounts;
    }

    const options = newAccounts.map(item => {
      return <Option key={item.id} value={item.id}>{`${item.ref_code} ${item.name}`}</Option>;
    });

    return options;
  }

  render() {
    const {intl, accounts, filterCategoryIds, ...props} = this.props;

    return (
      <Select
        placeholder={intl.formatMessage({ id: 'financeAccounts.select_account' })}
        {...props}
      >
        {this.getOptions()}
      </Select>
    );
  }
}

AccountDropdown.propTypes = {
  showSearch: PropTypes.bool,
  optionFilterProp: PropTypes.string,
  filterOption: PropTypes.func,
  accounts: PropTypes.array,
  filterCategoryIds: PropTypes.array,
}

AccountDropdown.defaultProps = {
  showSearch: true,
  optionFilterProp: 'children',
  filterOption,
  accounts: [],
  filterCategoryIds: [],
}

export default injectIntl(AccountDropdown);

export const getAccountDefaultValue = (accounts, filterCategoryIds, defaultValue) => {
  let value;
  if (defaultValue >= 0) {
    const newAccounts = [];
    filterCategoryIds.forEach(category => {
      const filterAccounts = accounts.filter(item => category === item.finance_account_category_id);
      filterAccounts.forEach(account => {
        newAccounts.push(account)
      })
    })
    if (newAccounts.length > 0) {
      value = newAccounts[0].id;
      const findAccount = newAccounts.find(item => item.id === defaultValue);
      if (findAccount) {
        value = findAccount.id;
      }
    }
  } else {
    value = undefined;
  }

  return value;
}
