import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Router from 'router'
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'
import SentryErrorBoundary from 'components/SentryErrorBoundary'
import { initializeFp } from 'utils/withTracker'
import useResponsive from 'utils/hooks/useResponsive'
import { userSelector } from 'redux/selectors'
import InstallPromptWrapper from './InstallPromptWrapper'
import UpdateAppWrapper from './UpdateAppWrapper'
import { ServiceWorkerProvider } from './ServiceWorkerProvider'

const MainApp = ({ history }) => {
  return (
    <>
      <Router history={history} />
      <UpdateAppWrapper />
      <InstallPromptWrapper />
    </>
  )
}

const App = ({ history }) => {
  const user = useSelector(userSelector)
  const { setLayoutView } = useResponsive()

  useEffect(() => {
    setLayoutView(true)
    window.addEventListener('resize', () => {
      setLayoutView()
    })

    return () => window.removeEventListener('resize', setLayoutView)
  }, [setLayoutView])

  useEffect(() => {
    if (user) {
      if (process.env.NODE_ENV === 'production') {
        initializeFp(user.email)
      }
    }
  }, [user])

  return (
    <SentryErrorBoundary
      showDialog
      dialogOptions={{
        user: {
          name: user?.name,
          email: user?.email,
        },
      }}
    >
      <ServiceWorkerProvider>
        {window.Cypress ? (
          <MainApp history={history} />
        ) : (
          <ClearBrowserCacheBoundary duration={900000}>
            <MainApp history={history} />
          </ClearBrowserCacheBoundary>
        )}
      </ServiceWorkerProvider>
    </SentryErrorBoundary>
  )
}

export default App
