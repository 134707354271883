import axios from 'axios'
import { stringify } from 'query-string'

export const getLayoutReport = () => {
  return axios.get('/layoutReporting')
}

export const getLayoutReportVariable = (type) => {
  return axios.get(`/layoutReporting/updateVariable?type=${type}`)
}

export const editLayoutReportVariable = (payload, type) => {
  const query = stringify({ ...payload, type })
  return axios.put(`/layoutReporting/updateVariable?${query}`)
}

export const editLayoutReport = (data) => {
  return axios.put('/layoutReporting', data)
}

export const resetLayoutReport = () => {
  return axios.post('/layoutReporting/reset')
}

export const uploadLogoLayoutReport = (data) => {
  return axios.post('/layoutReporting/uploadLogo', data)
}
