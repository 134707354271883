import React, {useState, useEffect} from 'react'
import { injectIntl } from 'react-intl';
import { Layout, Alert } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
// import { connect } from 'react-redux'
// import { isEmpty } from 'lodash'
import NProgress from 'nprogress'
import moment from 'moment';

import { inits } from 'redux/actions';
import { changeSetting } from 'redux/settings/actions';
import {
  optionsSelector,
  userSelector,
  settingsSelector,
  initsSelector,
} from 'redux/selectors';
import Logo from './Logo';

import styles from './style.module.scss'

const InnerLink = ({children, url}) => {
  if (url) {
    return (
      <Link to="/">{children}</Link>
    );
  }
  return (
    <a target="_blank" rel="noopener noreferrer" href="https://kledo.com/">
      {children}
    </a>
  );
};

const SelectCompanyLayout = ({intl, children}) => {
  const dispatch = useDispatch();
  const [backgroundEnabled, setBackgroundEnabled] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [backgroundNumber, setBackgroundNumber] = useState(7);
  const options = useSelector(optionsSelector);
  const {id} = useSelector(userSelector);
  const {locale} = useSelector(settingsSelector);
  const {loading} = useSelector(initsSelector);

  // Loader init
  useEffect(() => {
    if (loading) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  },
    [loading]
  );

  useEffect(() => {
    // Jika init belum di load, maka di ambil dari api
    const loadInits = async () => {
      const onInits = () => dispatch(inits());
      const response = await onInits();
      if (!response) {
        setNetworkError(true);
      }
    };

    if (!id && !loading) {
      loadInits();
    }
  },
  [id, dispatch, inits, setNetworkError]
  );

  useEffect(() => {
    // Jika locale di setting tidak sama dg di init di update
    if (locale && options.property_language && locale !== options.property_language) {
      dispatch(changeSetting({
        setting: 'locale',
        value: options.property_language,
      }));
    }
  },
  [locale, dispatch, options.property_language]
  );

  return (
    <Layout>
      <Layout.Content>
        <div
          className={styles.layout}
          style={{
            backgroundImage: backgroundEnabled
              ? `url('resources/images/photos/${backgroundNumber}.jpeg')`
              : `none`,
          }}
        >
          <div className={styles.content}>
            {
              networkError === true ?
                <div className={styles.inner}>
                  <Alert
                    message={intl.formatMessage({ id: 'users.failed_to_connect_to_kledo' })}
                    type="warning"
                    description={intl.formatMessage({ id: 'users.please_check_your_internet_connection_please_contact_wa' })}
                    showIcon
                  />
                  <a href="https://kledo.com" className="btn mt-3">
                    &larr; {intl.formatMessage({ id: 'users.go_back_to_home_page' })}
                  </a>
                </div>
                :
                children
            }
          </div>
          <div className={`${styles.footer} text-center`}>
            <div>Powered by
                <div className={styles.logoKledoFooter}>
                <a target="_blank" rel="noopener noreferrer" href="https://kledo.com">
                  <img src="resources/images/logo-color-kledo-front.png" alt="Kledo" />
                </a>
              </div>
            </div>

            <p>&copy;{` ${moment().format('YYYY')} Kledo. All rights reserved.`}</p>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default injectIntl(SelectCompanyLayout)
