import axios from 'axios';

export const getCanDeletedFinanceTax = () => {
  return axios.get('/finance/taxes/canBeDeleted');
};

export const addFinanceTax = (data) => {
  return axios.post('/finance/taxes', data);
};

export const editFinanceTax = (data) => {
  return axios.put(`/finance/taxes/${data.id}`, data);
};

export const moveFinanceTax = (srcId, destId) => {
  return axios.patch(`/finance/taxes/${srcId}/move`, { dest_id: destId });
};

export const activateFinanceTax = (id) => {
  return axios.patch(`/finance/taxes/${id}/activate`);
};

export const deactivateFinanceTax = (id) => {
  return axios.patch(`/finance/taxes/${id}/deactivate`);
};

export const getFinanceTax = (id) => {
  return axios.get(`/finance/taxes/${id}`);
};

export const deleteFinanceTax = (id) => {
  return axios.delete(`/finance/taxes/${id}`);
};