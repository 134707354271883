import React from 'react';
import { injectIntl } from 'react-intl';
import { Select } from 'antd';
import { EditorState, Modifier } from 'draft-js';

const { Option, OptGroup } = Select;

class TagsOption extends React.Component {
  componentDidMount() {
    if (this.props.emailId) {
      this.props.getTagsHandler(this.props.emailId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.emailId !== prevProps.emailId && this.props.emailId) {
      this.props.getTagsHandler(this.props.emailId);
    }
  }

  changeHandler = (value) => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `[${value}]`,
      editorState.getCurrentInlineStyle(),
    );
    const newEditorState = EditorState.push(editorState, contentState);

    onChange(EditorState.forceSelection(
      newEditorState,
      newEditorState.getSelection()
    ));
  };

  renderOptionGroups() {
    const { tags } = this.props;
    const options = [];

    Object.keys(tags).forEach((field) => {
      options.push(
        <OptGroup key={field} label={field}>
          {tags[field].map(row => {
            return (
              <Option key={row.value} value={row.value}>{row.name}</Option>
            )
          })
          }
        </OptGroup>
      )
    })

    return options;
  }

  render() {
    const { loading, intl } = this.props;
    return (
      <div className="rdw-tags-wrapper">
        <Select
          loading={loading}
          value={undefined}
          showSearch
          placeholder={intl.formatMessage({ id: 'emails.insert_tagsment' })}
          onChange={this.changeHandler}
        >
          {this.renderOptionGroups()}
        </Select>
      </div>
    );
  }
}

export default injectIntl(TagsOption);
