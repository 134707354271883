import { combineReducers } from 'redux';
import _ from 'lodash';
import { arraymove } from 'utils/helper';
import { updateObject } from '../utility';

import * as actionTypes from '../actionTypes';
import financeExpenseTerms from 'redux/financeExpenseTerms/reducers';
import financeTaxes from 'redux/financeTaxes/reducers';
import financeProducts from 'redux/financeProducts/reducers';
import financeWareHouses from 'redux/financeWareHouses/reducers';
import financeUnits from 'redux/financeUnits/reducers';
import shippingComps from 'redux/shippingComps/reducers';

const config = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_CONFIG:
      return updateObject(state, {
        ...action.payload
      });

    case actionTypes.SET_FINANCE_CONFIG_DEFAULT_ACCOUNT:
      const newState = _.cloneDeep(state)
      if (newState.product && action.payload && action.payload.mapping_product_purchase) {
        newState.product.purchase_account.default = action.payload.mapping_product_purchase.id
        newState.product.purchase_account.default_name = action.payload.mapping_product_purchase.name
        newState.product.purchase_account.default_ref_code = action.payload.mapping_product_purchase.ref_code
      }
      if (newState.product && action.payload && action.payload.mapping_product_sales) {
        newState.product.sell_account.default = action.payload.mapping_product_sales.id
        newState.product.sell_account.default_name = action.payload.mapping_product_sales.name
        newState.product.sell_account.default_ref_code = action.payload.mapping_product_sales.ref_code
      }
      if (newState.product && action.payload && action.payload.mapping_product_inventory) {
        newState.product.track_account.default = action.payload.mapping_product_inventory.id
        newState.product.track_account.default_name = action.payload.mapping_product_inventory.name
        newState.product.track_account.default_ref_code = action.payload.mapping_product_inventory.ref_code
      }
      if (newState.contact && action.payload && action.payload.mapping_contact_account_receivable) {
        newState.contact.receivable_contact.default = action.payload.mapping_contact_account_receivable.id
        newState.contact.receivable_contact.default_name = action.payload.mapping_contact_account_receivable.name
        newState.contact.receivable_contact.default_ref_code = action.payload.mapping_contact_account_receivable.ref_code
      }
      if (newState.contact && action.payload && action.payload.mapping_contact_account_payable) {
        newState.contact.payable_account.default = action.payload.mapping_contact_account_payable.id
        newState.contact.payable_account.default_name = action.payload.mapping_contact_account_payable.name
        newState.contact.payable_account.default_ref_code = action.payload.mapping_contact_account_payable.ref_code
      }
      if (newState.fixed_asset && action.payload && action.payload.mapping_fixed_asset) {
        newState.fixed_asset.fixed_asset_account.default = action.payload.mapping_fixed_asset.id
        newState.fixed_asset.fixed_asset_account.default_name = action.payload.mapping_fixed_asset.name
        newState.fixed_asset.fixed_asset_account.default_ref_code = action.payload.mapping_fixed_asset.ref_code
      }
      if (newState.stock_adjustment && action.payload && action.payload.mapping_stock_adjustment_inventory) {
        newState.stock_adjustment.account.default = action.payload.mapping_stock_adjustment_inventory.id
        newState.stock_adjustment.account.default_name = action.payload.mapping_stock_adjustment_inventory.name
        newState.stock_adjustment.account.default_ref_code = action.payload.mapping_stock_adjustment_inventory.ref_code
      }
      return { ...newState };

    default: return state;
  }
}

const accountCategories = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_ACCOUNT_CATEGORIES:
      return [
        ...action.payload
      ];

    default: return state;
  }
}

const financeBankAccounts = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_BANK_ACCOUNTS:
      return [
        ...action.payload
      ];
    case actionTypes.ADD_FINANCE_BANK_ACCOUNT:
      return [
        action.payload,
        ...state.filter(row => row.id !== action.payload.parent_id)
      ];
    case actionTypes.DELETE_FINANCE_BANK_ACCOUNT: {
      const accountIds = action.payload.ids || [];
      const bankAccounts = state.filter(row => !_.includes(accountIds, row.id));

      return [...bankAccounts];
    }
    case actionTypes.EDIT_FINANCE_BANK_ACCOUNT: {
      const bankAccounts = state.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });

      return [...bankAccounts.filter(row => row.id !== action.payload.parent_id)]
    }
    

    default: return state;
  }
}

const contactGroups = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_CONTACT_GROUP:
      return [
        ...action.payload
      ];

    case actionTypes.ADD_FINANCE_CONTACT_GROUP:
      return [
        ...state,
        action.payload
      ];
    
    case actionTypes.EDIT_FINANCE_CONTACT_GROUP: {
      const items = state.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });

      return [...items]
    }

    case actionTypes.MOVE_FINANCE_CONTACT_GROUP: {
      const { dragIndex, hoverIndex } = action.payload;
      const prevCategories = [...state];
      const srcFinanceTax = prevCategories[dragIndex];
      prevCategories.splice(dragIndex, 1);
      prevCategories.splice(hoverIndex, 0, srcFinanceTax);

      return [...prevCategories]
    }

    case actionTypes.DELETE_FINANCE_CONTACT_GROUP: {
      const newCategories = state.filter(row => row.id !== action.payload.id);

      return [...newCategories]
    }

    default: return state;
  }
}

const productCategories = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_PRODUCT_CATEGORIES:
      return [
        ...action.payload
      ];

    case actionTypes.ADD_FINANCE_PRODUCT_CATEGORIES:
      return [
        ...state,
        action.payload
      ];
    
    case actionTypes.EDIT_FINANCE_PRODUCT_CATEGORIES: {
      const items = state.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });

      return [...items]
    }

    case actionTypes.MOVE_FINANCE_PRODUCT_CATEGORIES: {
      const { dragIndex, hoverIndex } = action.payload;
      const prevCategories = [...state];
      const srcFinanceTax = prevCategories[dragIndex];
      prevCategories.splice(dragIndex, 1);
      prevCategories.splice(hoverIndex, 0, srcFinanceTax);

      return [...prevCategories]
    }

    case actionTypes.DELETE_FINANCE_PRODUCT_CATEGORIES: {
      const newCategories = state.filter(row => row.id !== action.payload.id);

      return [...newCategories]
    }

    default: return state;
  }
}

const dashboard = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_DASHBOARD:
      return setDashboard(state, action)
    case actionTypes.SET_A_DASHBOARD:
      return setADashboard(state, action)
    case actionTypes.RESET_DASHBOARD:
      return resetDashboard(state);
    case actionTypes.MOVE_ACCOUNT_WATCH_LIST:
      return moveAccountWatchList(state, action);
    case actionTypes.ADD_ACCOUNT_WATCH_LIST:
      return addMoveAccountWatchList(state, action);
    case actionTypes.DELETE_ACCOUNT_WATCH_LIST:
      return deleteAccountWatchList(state, action);
    case actionTypes.ADD_DASHBOARD:
      return addDashboard(state, action);
    case actionTypes.DELETE_DASHBOARD:
      return deleteDashboard(state, action)
    case actionTypes.EDIT_DASHBOARD:
      return editDashboard(state, action)

    default: return state;
  }
}

const setDashboard = (state, action) => {
  let dashboard = action.payload;
  if (isLoadedCache(state)) {
    dashboard = dashboard.map(row => {
      const findDashboard = state.find(item => item.id === row.id);
      return {
        ...findDashboard,
        ...row
      }
    })
  }

  return dashboard;
}

const addDashboard = (state, action) => {
  return [
    action.payload,
    ...state
  ]
}

const editDashboard = (state, action) => {
  const dashboards = state.map(item => {
    if (item.id === action.payload.id) {
      return { ...item, ...action.payload};
    }
    return item;
  });
  return [...dashboards]
}

const deleteDashboard = (state, action) => {
  const accountIds = action.payload.ids || [];
  const dashboards = state.filter(row => !_.includes(accountIds, row.type_id));
  return [...dashboards];
}

const setADashboard = (state, action) => {
  return state.map(e => {
    if (e.id === action.payload.id) {
      return { ...e, ...action.payload, loaded: true };
    }
    return e;
  });
}

const resetDashboard = () => {
  return [];
}

const isLoadedCache = (dashboard) => {
  let isLoaded = false;
  for (let i = 0; i < dashboard.length; i++) {
    if (dashboard[i].data) {
      isLoaded = true;
    } else {
      isLoaded = false;
      break;
    }
  }

  return isLoaded;
}

const moveAccountWatchList = (state, action) => {
  const { dragIndex, hoverIndex } = action.payload;
  const dashboard = [...state];
  const findIndex = dashboard.findIndex(row => row.type === 'account_watchlist');
  const data = dashboard[findIndex] ? dashboard[findIndex].data : [];
  const newData = arraymove(data, dragIndex, hoverIndex);
  dashboard[findIndex].data = newData;
  return dashboard;
};

const addMoveAccountWatchList = (state, action) => {
  const dashboard = [...state];
  const findIndex = dashboard.findIndex(row => row.type === 'account_watchlist');
  const data = dashboard[findIndex] ? dashboard[findIndex].data : [];
  dashboard[findIndex].data = [...data, action.payload];
  return dashboard;
};

const deleteAccountWatchList = (state, action) => {
  const dashboard = [...state];
  const findIndex = dashboard.findIndex(row => row.type === 'account_watchlist');
  const data = dashboard[findIndex] ? dashboard[findIndex].data : [];
  dashboard[findIndex].data = data.filter(row => row.id !== action.payload.id);
  return dashboard;
};

const accountTransactionReport = (state = {show: false, params: {}}, action) => {
  switch (action.type) {
    case actionTypes.SHOW_ACCOUNTTRANSACTION_REPORT:
      return {
        ...state,
        show: true,
        params: {...action.payload}
      };
    case actionTypes.HIDE_ACCOUNTTRANSACTION_REPORT:
      return {
        ...state,
        show: false,
        params: {}
      };

    default: return state;
  }
}

const auditModal = (state = { show: false, getData: () => {} }, action) => {
  switch (action.type) {
    case actionTypes.SHOW_AUDIT_MODAL:
      return {
        ...state,
        show: true,
        getData: action.payload,
      };
    case actionTypes.HIDE_AUDIT_MODAL:
      return {
        ...state,
        show: false,
        getData: () => {},
      };

    default: return state;
  }
}

export default combineReducers({
  expenseTerm: financeExpenseTerms,
  tax: financeTaxes,
  unit: financeUnits,
  config,
  accountCategories,
  bankAccounts: financeBankAccounts,
  products: financeProducts,
  contactGroups,
  productCategories,
  dashboard,
  warehouse: financeWareHouses,
  shippingComp: shippingComps,
  accountTransactionReport,
  auditModal,
})