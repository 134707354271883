import React from 'react'
import { Layout, Alert, Button } from 'antd'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { FormattedMessage, injectIntl } from 'react-intl'
import { WhatsAppOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import NProgress from 'nprogress'
import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Footer from 'components/LayoutComponents/Footer'
import Breadcrumbs from 'components/LayoutComponents/Breadcrumbs'
import AccountTransactionModal from 'containers/Reports/AccountTransactions/AccountTransactionModal'
import BroadcastModal from 'containers/Broadcast'
import NPS from 'containers/NPS'
import { Welcome } from 'containers/ProductTour'
import LoginModal from 'pages/user/login/LoginModal'

import { inits } from 'redux/actions'
import { showBroadcast } from 'redux/broadcast/actions'
import { setSelectedEndpoint } from 'redux/companies/actions'
import { resetDashboard } from 'redux/finance/actions'
import { getModulePermissions } from 'redux/user/selectors'
import OneSignalApp from 'utils/oneSignal'

import styles from './style.module.scss'

const mapStateToProps = ({
  settings,
  user: { id, name, role_id, permissions },
  inits: { loading, loadedInit, attemp },
  options: { options },
  billing,
  broadcast: { data: broadcast },
  translation,
}) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
  isMobileView: settings.isMobileView,
  id,
  name,
  role_id,
  loading,
  company: options.property_name,
  demo: options.demo,
  chatSupport: options.chat_support,
  chatNumber: options.chat_number,
  expiryDate: billing.expiry_date,
  isProductTour: !options.onboarding && options.product_tour,
  broadcast,
  loadedInit,
  attemp,
  translation,
  invoicePermissions: getModulePermissions({ user: { permissions } }, 'invoices'),
  purchaseInvoicePermissions: getModulePermissions({ user: { permissions } }, 'purchase_invoices'),
  expensePermissions: getModulePermissions({ user: { permissions } }, 'expenses'),
})

const mapDispatchToProps = (dispatch) => {
  return {
    onInits: () => dispatch(inits()),
    onShowBroadcast: () => dispatch(showBroadcast()),
    push: (path) => dispatch(push(path)),
    onChangeCompany: (payload) => {
      dispatch(resetDashboard())
      dispatch(setSelectedEndpoint(payload))
    },
  }
}

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class MainLayout extends React.PureComponent {
  scrollBarRef = React.createRef()

  state = {
    initializeOneSignal: false,
  }

  componentDidMount() {
    if (!this.props.id && !this.props.loading && this.props.attemp <= 4) {
      this.props.onInits()
    }
  }

  componentDidUpdate(prevProps) {
    const { loadedInit, broadcast, onShowBroadcast, id } = this.props
    if (prevProps.loadedInit !== loadedInit && loadedInit && !_.isEmpty(broadcast)) {
      onShowBroadcast()
    }
    if (prevProps.loadedInit !== loadedInit && loadedInit && id) {
      if (process.env.REACT_APP_ONESIGNAL_APP_ID && !this.state.initializeOneSignal) {
        OneSignalApp.init({
          appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
          userId: id,
          onOpenNotification: (data) => console.log(data),
          initialized: this.state.initializeOneSignal,
          setInizialized: () => this.setState({ initializeOneSignal: true }),
        })
      }
    }
  }

  isDashboard = () => {
    return this.props.location.pathname === '/dashboard'
  }

  render() {
    const {
      children,
      isBorderless,
      isSquaredBorders,
      isFixedWidth,
      isMenuShadow,
      isMenuTop,
      loading,
      demo,
      chatSupport,
      chatNumber,
      expiryDate,
      isMobileView,
      intl,
      name,
      company,
      isProductTour,
      companies,
      onChangeCompany,
      push,
      role_id,
      translation,
      expensePermissions,
      invoicePermissions,
      purchaseInvoicePermissions,
    } = this.props

    if (loading) {
      NProgress.start()
    } else {
      NProgress.done()
    }

    let styleCustom = {}
    if (this.isDashboard()) {
      styleCustom = {
        style: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        },
      }
    }

    return (
      <>
        <Layout
          className={classNames({
            settings__borderLess: isBorderless,
            settings__squaredBorders: isSquaredBorders,
            settings__fixedWidth: isFixedWidth,
            settings__menuShadow: isMenuShadow,
            settings__menuTop: isMenuTop,
          })}
        >
          <Menu scrollBarRef={this.scrollBarRef} />
          <Layout>
            {expiryDate && moment().subtract(1, 'days').isAfter(expiryDate) && (
              <Alert
                style={{ backgroundColor: '#ffefb3' }}
                message={
                  <FormattedMessage
                    id="billing.expired"
                    values={{
                      setup: (
                        <Link to="/settings/billing/upgrade">
                          <strong>
                            <FormattedMessage id="billing.expired_link" />
                          </strong>
                        </Link>
                      ),
                    }}
                  />
                }
                type="warning"
                showIcon
              />
            )}
            <Layout.Header>
              <TopBar
                intl={intl}
                push={push}
                role_id={role_id}
                companies={companies}
                translation={translation}
                onChangeCompany={onChangeCompany}
                expensePermissions={expensePermissions}
                invoicePermissions={invoicePermissions}
                purchaseInvoicePermissions={purchaseInvoicePermissions}
                isMobileView={isMobileView}
              />
            </Layout.Header>
            <Layout.Content style={{ height: '100%', position: 'relative' }}>
              <Breadcrumbs />
              <div className="utils__content" {...styleCustom}>
                {children}
              </div>
            </Layout.Content>
            <Layout.Footer>
              <Footer />
            </Layout.Footer>
          </Layout>
        </Layout>
        {chatSupport === 1 && (
          <Button
            href={`https://api.whatsapp.com/send?phone=${chatNumber}&text=Hallo,%20saya%20${name}%20dari%20${company}%20ingin%20tanya%20tentang%20Kledo.`}
            target="_blank"
            rel="noopener noreferrer"
            type="primary"
            shape={isMobileView ? 'circle' : 'round'}
            icon={<WhatsAppOutlined style={{ paddingTop: '.5rem' }} />}
            className={styles.waBtn}
          >
            {isMobileView ? null : intl.formatMessage({ id: 'button.hello_can_i_help' })}
          </Button>
        )}
        <NPS />
        <AccountTransactionModal />
        <BroadcastModal />
        {isProductTour && <Welcome scrollBarRef={this.scrollBarRef} />}
        <LoginModal />
      </>
    )
  }
}

export default injectIntl(MainLayout)
