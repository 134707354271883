import React from 'react'
import { Tabs } from 'antd'
import {useSelector} from 'react-redux';
import { injectIntl } from 'react-intl';
import News from 'containers/Notification/News'
import Timeline from 'containers/Notification/Timeline'
import { checkPermission } from 'redux/user/selectors';
import { mobileViewSelector } from 'redux/selectors';
import style from './style.module.scss'

const { TabPane } = Tabs;

const Notification = ({intl, onHideParent, showParent}) => {
  const [reloadTimeline, setReloadTimeline] = React.useState(true);
  const [activeKey, setActiveKey] = React.useState('1');
  const canViewAudit = useSelector(state => checkPermission(state, 'audits'));
  const isMobileView = useSelector(mobileViewSelector);

  const changeTabHandler = (currentKey) => {
    setActiveKey(currentKey);
    if (currentKey === '2') {
      setReloadTimeline(true);
    } else if (currentKey === '1') {
      setReloadTimeline(false);
    }
  };

  React.useEffect(() => {
    if (showParent && activeKey !== '1' && !reloadTimeline) {
      setActiveKey('1')
    }
  }, [activeKey, showParent, reloadTimeline]);

  React.useEffect(() => {
    if (!showParent) {
      setReloadTimeline(false);
    }
  }, [showParent]);

  const stylePane = {height: 400, overflow: 'auto' };
  if (!isMobileView) {
    stylePane.width = 400;
  }

  return (
    <div>
      <Tabs
        className={`${style.tabs} kit-tabs-bordered`}
        activeKey={activeKey}
        onChange={changeTabHandler}
      >
        <TabPane
          tab={intl.formatMessage({ id: 'notification.updates' })}
          key="1"
          style={stylePane}
        >
          <News />
        </TabPane>
        {canViewAudit &&
        <TabPane
          tab={intl.formatMessage({ id: 'notification.timeline' })}
          key="2"
          style={stylePane}
        >
          <Timeline isMobileView={isMobileView} onHideParent={onHideParent} isReload={reloadTimeline} />
        </TabPane>
        }
      </Tabs>
    </div>
  )
}

export default injectIntl(Notification);
