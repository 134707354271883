import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const HelpTooltip = ({title, isMobileView, ...props}) => {
  return (
    <Tooltip trigger={['hover', 'click']} title={title} {...props}>
      <QuestionCircleOutlined style={isMobileView ? {fontSize: 20} : {}} />
    </Tooltip>
  )
}

HelpTooltip.propTypes = {
  title: PropTypes.string.isRequired
}

export default React.memo(HelpTooltip);
