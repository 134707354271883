import React, {memo} from 'react'

const Logo = ({ backgroundEnabled, url, title }) => {
  if (backgroundEnabled) {
    if (url) {
      return <img src={url} alt={title} />;
    }
    return <img src="resources/images/logo-color-kledo-front.png" alt="Kledo" />;
  }
  return (
    <img
      src="resources/images/logo-inverse.png"
      alt="Kledo"
    />
  );
};

export default memo(Logo);
