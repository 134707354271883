import React from 'react'
import { Select, Form } from 'antd'
import { injectIntl } from 'react-intl'
import { formatDateText } from 'utils/formatting'
import { filterOption } from 'utils/helper'
import { RangePicker } from 'components/UI'

const { Option } = Select

class Filter extends React.Component {
  onChange = (value) => {
    this.props.setParams({ date_from: value[0], date_to: value[1] })
  }

  filterVisibleChangeHandler = (visible) => {
    if (!visible) {
      const { setParams, innerRef } = this.props
      const form = innerRef.current

      form
        .validateFields((values) => {
          setParams(values)
        })
        .catch((info) => {
          form.scrollToField(info.errorFields[0].name)
        })
    }
  }

  renderFilter = () => {
    const { params, intl, accounts, innerRef } = this.props

    const children = (accounts || []).map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name || '-'}
      </Option>
    ))
    return (
      <Form
        style={{ width: 200 }}
        name="FilterForm"
        ref={innerRef}
        initialValues={{
          account_id: params.account_id || [],
        }}
      >
        <Form.Item label={intl.formatMessage({ id: 'report.account' })}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
            filterOption={filterOption}
          >
            {children}
          </Select>
        </Form.Item>
      </Form>
    )
  }

  render() {
    const { isMobileView, params } = this.props
    const { date_from: dateFrom, date_to: dateTo } = params

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6 mb-3" />
          <div className="col-md-6">
            <div
              style={{ marginBottom: 8, float: isMobileView ? 'left' : 'right', display: 'flex' }}
            >
              <div className="ml-3">
                <RangePicker
                  format={formatDateText()}
                  value={[dateFrom, dateTo]}
                  placeholder="End"
                  allowClear={false}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const Component = injectIntl(Filter)

export default React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
