import axios from "axios";
import { stringify } from "query-string";

export const getOauthClients = (params) => {
  const query = stringify(params);
  return axios.get(`/oauth/clients?${query}`);
};

export const getOauthClient = (id, params) => {
  const query = stringify(params);
  return axios.get(`/oauth/clients/${id}?${query}`);
};

export const addOauthClient = (data) => {
  return axios.post('/oauth/clients', data);
};

export const editOauthClient = (data) => {
  return axios.put(`/oauth/clients/${data.id}`, data);
};

export const deleteOauthClient = (id) => {
  return axios.delete(`/oauth/clients/${id}`);
};
