import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ImportOutlined } from '@ant-design/icons';

function Import(props) {
  const title = props.title === 'button.import' ? props.intl.formatMessage({ id: props.title }) : props.title
  return (
    <Button
      {...props}
      title={title}
    >
      {title}
    </Button>
  )
}

Import.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string
}

Import.defaultProps = {
  type: "default",
  icon: <ImportOutlined />,
  title: "button.import",
  onClick: () => { },
  id: "btn-import"
}

export default injectIntl(Import);