import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createHashHistory } from 'history'
import axios from 'axios'

import Localization from 'components/LayoutComponents/Localization'
import { generateBaseUri } from 'utils/helper'
import { requestHandler, successHandler, errorHandler } from 'utils/axios'
import { initializeFp, initializeGa } from 'utils/withTracker'
import App from 'app'
import { getToken } from 'utils/cookies'
import { storedSettings } from 'utils/localStorage'
import setupSentry from 'utils/sentry'
import configureStore from 'redux/store'
import { AppProvider } from 'components/AppProvider'
import createIcons from './iconLibraries'

// app styles
import './global.scss'

setupSentry()
const history = createHashHistory()

if (process.env.NODE_ENV === 'production') {
  // Initialize tracker
  initializeGa()
  initializeFp()
}

export const store = configureStore(history)

axios.defaults.baseURL = generateBaseUri()
axios.defaults.headers.Authorization = `Bearer ${getToken()}`
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common['X-App'] = 'finance'
axios.defaults.timeout = 100000

axios.interceptors.request.use((request) => requestHandler(request))

axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error, store, history),
)

// Use query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

createIcons()

const AppContainer = () => {
  return (
    <Provider store={store}>
      <AppProvider
        value={{
          settings: storedSettings({
            isMobileMenuOpen: false,
            isMenuCollapsed: false,
          }),
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Localization>
            <App history={history} />
          </Localization>
        </QueryClientProvider>
      </AppProvider>
    </Provider>
  )
}

ReactDOM.render(<AppContainer />, document.getElementById('root'))

if (window.Cypress) {
  window.store = store
}

export default { store, history }
