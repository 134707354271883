import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
// import NProgress from 'nprogress'
import store from 'store'
import _ from 'lodash';
import { parse } from 'query-string';

// import Loader from 'components/LayoutComponents/Loader'
import { Helmet } from 'components/UI';
import { isTokenExist, setToken } from 'utils/cookies';
import { setSelectedEndpoint } from 'redux/companies/actions';
import { inits } from 'redux/actions';
import { hideAccountTransactionReport } from 'redux/finance/actions';
import { setErrorLoginForm } from 'redux/loginForm/actions';
import moment from 'moment';
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import SelectCompanyLayout from './SelectCompany'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
  selectCompany: SelectCompanyLayout,
};

const avoidRouteWhenAuthorized = [
  '/user/select-company',
  '/user/billing/success',
  '/user/billing/failed',
];

@withRouter
@connect(({ user, options: { options }, companies, billing, finance: { accountTransactionReport } }) => ({
  user,
  options,
  companies,
  expiryDate: billing.expiry_date,
  accountTransactionReport
}), { setSelectedEndpoint, inits, hideAccountTransactionReport, setErrorLoginForm })
class IndexLayout extends React.PureComponent {
  previousPath = '';

  state = {
    containerKey: 0,
  }

  componentDidMount() {
    const searchs = parse(location.search);

    // if there is error params, show error
    if (searchs.error) {
      this.props.setErrorLoginForm(searchs.error);
      history.replaceState(null, null, `${location.pathname}${location.hash}`);
    }
    // if there is token params, set token
    if (searchs.token) {
      setToken(searchs.token);
      // if there is endpoint params, set endpoint
      if (searchs.endpoint) {
        store.set('app.endpoint', searchs.endpoint);
        this.props.setSelectedEndpoint(searchs.endpoint);
      }
      history.replaceState(null, null, `${location.pathname}${location.hash}`);
    }
  }

  componentDidUpdate(prevProps) {
    const { location, user } = this.props
    const { location: prevLocation } = prevProps;

    if ((location.pathname !== prevLocation.pathname) && location.pathname !== '/user/login') {
      if (location.state) {
        if (location.state.resetscroll) {
          window.scrollTo(0, 0);
        }
      } else {
        window.scrollTo(0, 0);
      }
      // Untuk hide modal report accountTransaction
      if (this.props.accountTransactionReport.show) {
        this.props.hideAccountTransactionReport();
      }
    }

    if (prevProps.user.email !== user.email) {
      // set inspectlet identity
      // eslint-disable-next-line no-underscore-dangle
      if (window.__insp) {
        // eslint-disable-next-line no-underscore-dangle
        window.__insp.push(['identify', user.email]);
      }
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.companies.selectedEndpoint !== this.props.companies.selectedEndpoint && prevProps.companies.selectedEndpoint && this.props.companies.selectedEndpoint) {
      this.props.inits().then(() => {
        this.setState({ containerKey: this.props.companies.selectedEndpoint });
      });
    }
    return null;
  }

  getLayout = () => {
    const { location: { pathname } } = this.props;
    if (pathname === '/') {
      return 'public'
    }
    if (pathname === '/user/select-company') {
      return 'selectCompany'
    }
    if (/^\/user(?=\/|$)/i.test(pathname)) {
      return 'login'
    }
    return 'main'
  }

  bootstrappedLayout = () => {
    const {
      children,
      location: { pathname },
      user,
      options,
      companies,
      expiryDate,
    } = this.props;
    const storeEndpoint = store.get('app.endpoint');
    // Layout Rendering
    const layout = this.getLayout();
    const Container = Layouts[layout];
    const currentLayout = <Container key={this.state.containerKey} companies={companies}>{children}</Container>;
    // const isUserAuthorized = user.authorized
    const isUserAuthorized = isTokenExist();
    const isLoginLayout = layout === 'login';
    // show loader when user in check authorization process, not authorized yet and not on login pages
    // if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
    //   return <Loader />
    // }
    // redirect to login page if current is not login page and user not authorized
    if ((!isLoginLayout || pathname === '/user/select-company') && !isUserAuthorized) {
      // Jika tidak ada token dan tidak melakukan logout, hapus localstorage endpoint
      if (!user.logout) {
        store.remove('app.endpoint');
      }
      if (pathname !== '/user/select-company' && pathname !== '/') {
        store.set('app.previousPathname', pathname)
      } else {
        return <Redirect to="/user/login" />
      }
      return currentLayout;
    }
    // Jika sudah login dan belum memilih perushaan (kalau perusahaan lebih dari satu) dan posisinya tidak di billing dan select company
    if (isUserAuthorized && (!companies.selectedEndpoint && !storeEndpoint) && !_.includes(avoidRouteWhenAuthorized, pathname)) {
      if (!isLoginLayout && pathname !== '/user/select-company' && pathname !== '/') {
        store.set('app.previousPathname', pathname)
      }

      return <Redirect to="/user/select-company" />
    }

    if (!companies.selectedEndpoint && storeEndpoint) {
      this.props.setSelectedEndpoint(storeEndpoint);
    }

    // if user login then initialize inspectlet
    if (isUserAuthorized && (companies.selectedEndpoint || storeEndpoint)) {
      // Initialize inspectlet
      if (process.env.NODE_ENV === 'production') {
        // Initialize inspectlet
        // eslint-disable-next-line func-names
        (function () {
          // eslint-disable-next-line no-underscore-dangle
          window.__insp = window.__insp || [];
          // eslint-disable-next-line no-undef
          __insp.push(['wid', 476925044]);
          // eslint-disable-next-line prefer-const, func-names
          let ldinsp = function () {
            // eslint-disable-next-line no-underscore-dangle, prefer-const, eqeqeq, yoda, prefer-template
            if (typeof window.__inspld != "undefined") return; window.__inspld = 1; let insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=476925044&r=' + Math.floor(new Date().getTime() / 3600000); let x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x);
          };
          setTimeout(ldinsp, 0);
        })();
      }
    }
    
    const isExpired = expiryDate ? moment(expiryDate).isBefore(moment()) : false;
    // moment().subtract(1, 'days').isAfter(expiryDate)
    // Jika sudah login dan expired serta sudah ada endpoint
    if (isUserAuthorized && !isLoginLayout && (companies.selectedEndpoint || storeEndpoint)) {
      if (isExpired && !pathname.includes('/billing')) {
        return <Redirect to="/settings/billing" />
      }
    }

    // Redirect ke onboarding jika onboarding 1
    if (isUserAuthorized && !isLoginLayout && options.onboarding && pathname !== '/onboarding' && (companies.selectedEndpoint || storeEndpoint)) {
      if (!isExpired) {
        return <Redirect to="/onboarding" />
      }
    }

    // redirect to main dashboard when user on login page or select company page and authorized
    if (
      (isLoginLayout || pathname === '/user/select-company') &&
      !/^\/user\/invite(?=\/|$)/i.test(pathname) &&
      pathname !== '/user/billing/success' && pathname !== '/user/billing/failed' &&
      isUserAuthorized && (companies.selectedEndpoint || store.get('app.endpoint'))
    ) {
      if (store.get('app.previousPathname')) {
        return <Redirect to={store.get('app.previousPathname')} />
      }

      return <Redirect to="/dashboard" />
    }

    // in other case render previously set layout
    return currentLayout;
  }

  render() {
    return (
      <Fragment>
        <Helmet titleTemplate="Kledo | %s" title="Kledo Software" />
        {this.bootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
