import React from 'react'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { DatePicker } from 'antd'
import { formatDateText } from 'utils/formatting'
import { connect } from 'react-redux'

const { RangePicker: RangeAntd } = DatePicker

function RangePicker({ intl, ...props }) {
  return (
    <RangeAntd
      format={formatDateText()}
      ranges={{
        [intl.formatMessage({ id: 'date.yesterday' })]: [
          moment().subtract(1, 'day'),
          moment().subtract(1, 'day'),
        ],
        [intl.formatMessage({ id: 'date.today' })]: [moment(), moment()],
        [intl.formatMessage({ id: 'date.this_month' })]: [
          moment().startOf('month'),
          moment().endOf('month'),
        ],
        [intl.formatMessage({ id: 'date.last_month' })]: [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
        [intl.formatMessage({ id: 'date.last_7_days' })]: [moment().subtract(7, 'days'), moment()],
        [intl.formatMessage({ id: 'date.last_30_days' })]: [
          moment().subtract(30, 'days'),
          moment(),
        ],
        [intl.formatMessage({ id: 'date.this_year' })]: [
          moment().startOf('year'),
          moment().endOf('year'),
        ],
        [intl.formatMessage({ id: 'date.last_year' })]: [
          moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year'),
        ],
      }}
      {...props}
    />
  )
}

export default connect()(injectIntl(RangePicker))
