import axios from 'axios';
import { stringify } from 'query-string';
import { cleanBlankValue, getOnce } from 'utils/helper';

export const getFinanceFixedAssets = (params) => {
  const query = stringify(params);
  return getOnce(`/finance/fixedAssets?${query}`);
};

export const addFinanceFixedAsset = (data) => {
  return axios.post('/finance/fixedAssets', data);
};

export const editFinanceFixedAsset = (data) => {
  return axios.put(`/finance/fixedAssets/${data.id}`, data);
};

export const getFinanceFixedAsset = (id) => {
  return axios.get(`/finance/fixedAssets/${id}`);
};

export const getFinanceFixedAssetLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : '';
  return axios.get(`/finance/fixedAssets/${id}/logs${query}`);
};

export const deleteFinanceFixedAsset = (id) => {
  return axios.delete(`/finance/fixedAssets/${id}`);
};

export const disposeFinanceFixedAsset = ({id, ...data}) => {
  return axios.post(`/finance/fixedAssets/${id}/dispose`, data);
};

export const registerFinanceFixedAsset = ({ id, ...data }) => {
  return axios.post(`/finance/fixedAssets/${id}/register`, data);
};

export const getFinanceFixedAssetToDepreciates = (params) => {
  const query = stringify(params);
  return axios.get(`/finance/fixedAssets/toDepreciates?${query}`);
};

export const depreciateFinanceFixedAsset = (data) => {
  return axios.post('/finance/fixedAssets/depreciate', data);
};

export const revertDisposeFinanceFixedAsset = (id) => {
  return axios.post(`/finance/fixedAssets/${id}/revertDispose`);
};

export const rollbackDepreciationFinanceFixedAsset = (id) => {
  return axios.post(`/finance/fixedAssets/${id}/rollbackDepreciation`);
};

export const massDeleteFinanceFixedAsset = (ids) => {
  return axios.delete('/finance/fixedAssets/massDelete', { data: { id: ids }});
};

export const getFinanceFixedAssetTransaction = (tranId) => {
  return axios.get(`/finance/fixedAssets/transactions/${tranId}`);
};

export const uploadImportFinanceFixedAsset = (data) => {
  return axios.post('/finance/fixedAssets/uploadImport', data);
};

export const executeImportFinanceFixedAsset = (data) => {
  return axios.post('/finance/fixedAssets/executeImport', data);
};

export const exportFinanceFixedAsset = (params) => {
  params = cleanBlankValue(params);
  const query = stringify(params);
  return axios.get(`/finance/fixedAssets/export?${query}`, { responseType: 'arraybuffer' });
}

export const inputMassDeleteFinanceFixedAsset = (data) => {
  return axios.post('/finance/fixedAssets/inputMassDelete', data);
};

export const executeMassDeleteFinanceFixedAsset = (data) => {
  return axios.delete('/finance/fixedAssets/executeMassDelete', { data });
};
