import 'rc-drawer/assets/index.css'
import React from 'react'
import DrawerMenu from 'rc-drawer'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { changeSetting } from 'redux/settings/actions'
import { withAppConsumer } from 'components/AppProvider'
import MenuLeft from './MenuLeft'

const mapStateToProps = ({ settings }) => ({
  isMenuTop: settings.isMenuTop,
  // isMobileMenuOpen: settings.isMobileMenuOpen,
  isMobileView: settings.isMobileView,
  isLightTheme: settings.isLightTheme,
})

// const mapDispatchToProps = dispatch => {
//   return {
//     onChangeSetting: (payload) => dispatch(changeSetting(payload))
//   };
// }

@withRouter
@connect(mapStateToProps)
@withAppConsumer
class AppMenu extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isMobileView !== this.props.isMobileView &&
      !this.props.isMobileView &&
      this.props.appContext.settings.isMobileMenuOpen
    ) {
      this.toggleOpen()
    }
  }

  toggleOpen = () => {
    const {
      appContext: {
        settings: { isMobileMenuOpen },
        onChangeSettings,
      },
    } = this.props
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      )
    onChangeSettings({ isMobileMenuOpen: !isMobileMenuOpen })
  }

  render() {
    const {
      appContext: {
        settings: { isMobileMenuOpen },
      },
      isMobileView,
      isLightTheme,
      scrollBarRef,
    } = this.props

    const BootstrappedMenu = () => {
      if (isMobileView) {
        return (
          <DrawerMenu
            getContainer={null}
            level={null}
            open={isMobileMenuOpen}
            onClose={this.toggleOpen}
            onHandleClick={this.toggleOpen}
            className={isLightTheme ? 'drawer-light' : ''}
          >
            <MenuLeft scrollBarRef={scrollBarRef} />
          </DrawerMenu>
        )
      }

      return <MenuLeft scrollBarRef={scrollBarRef} />
    }

    return BootstrappedMenu()
  }
}

export default AppMenu
