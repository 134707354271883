import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { EditOutlined } from '@ant-design/icons';

function Edit(props) {
  const title = props.title === 'button.edit' ? props.intl.formatMessage({ id: props.title }) : props.title
  return (
    <Button
      {...props}
      title={title}
    >
      {title}
    </Button>
  )
}

Edit.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
}

Edit.defaultProps = {
  type: "primary",
  icon: <EditOutlined />,
  title: "button.edit",
  onClick: () => { },
  id: "btn-edit"
}

export default injectIntl(Edit);