import * as actionTypes from '../actionTypes';
import { resetDashboard } from 'redux/finance/actions';

export const setEndpoint = (payload) => dispatch => {
  // dispatch(resetDashboard());
  dispatch({
    type: actionTypes.SET_ENDPOINT,
    payload
  });
};

export const setSelectedEndpoint = (payload) => dispatch => {
  // dispatch(resetDashboard());
  dispatch({
    type: actionTypes.SET_SELECTED_ENDPOINT,
    payload
  });
};

export const setWebsiteId = (payload) => dispatch => {
  // dispatch(resetDashboard());
  dispatch({
    type: actionTypes.SET_WEBSITE_ID,
    payload
  });
};

export const editCompanyName = (payload) => dispatch => {
  dispatch({
    type: actionTypes.EDIT_COMPANY_NAME,
    payload
  });
};
