import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message, Typography } from 'antd';
import { Cancel, Send } from 'components/UI';
import { sendEmailConfirmation, getOption } from 'utils/apis';
import {optionsSelector} from 'redux/selectors';
import {editOptionsRedux} from 'redux/options/actions';

const {Text} = Typography;
let interval;
let intervalCountDown;
const ConfirmEmailModal = ({ intl, show, onClose, afterUpdatedOption, closeAfterUpdate}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const [runInterval, setRunInterval] = React.useState(false);
  const [countDown, setCountDown] = React.useState(120);
  const [runCountDown, setRunCountDown] = React.useState(false);
  const [countCheckOption, setCountCheckOption] = React.useState(0);

  const options = useSelector(optionsSelector);

  const confirmEmailHandler = async () => {
    setLoading(true);
    try {
      const response = await sendEmailConfirmation();
      setLoading(false);
      if (response.data.success) {
        if (emailSent) {
          setCountDown(120);
          setCountCheckOption(0);
        }
        setEmailSent(true);
        message.success(response.data.message);
        setRunInterval(true);
        setRunCountDown(true);
      } else {
        message.error(response.data.message);
      }
    } catch (e) {
      setLoading(false);
      message.error(intl.formatMessage({ id: 'email.cannot_send_email' }));
    }
  }

  const checkOptionHandler = async () => {
    try {
      const response = await getOption('email_confirmed');
      if (response.data.success) {
        const {data} = response.data;
        if (data) {
          dispatch(editOptionsRedux({email_confirmed: data}));
          if (closeAfterUpdate) {
            onClose();
          }
          afterUpdatedOption();
        }
      }
      setCountCheckOption(prev => prev + 1);
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    if (runInterval) {
      interval = setInterval(() => {
        checkOptionHandler();
      }, 10000);
      return () => clearInterval(interval);
    }
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runInterval]);

  React.useEffect(() => {
    if (options.email_confirmed || countCheckOption === 200) {
      clearInterval(interval);
    }
  }, [options.email_confirmed, countCheckOption]);

  React.useEffect(() => {
    if (runCountDown) {
      intervalCountDown = setInterval(() => {
        setCountDown(prev => prev - 1);
      }, 1000);
      return () => clearInterval(intervalCountDown);
    }
    return () => {}
  }, [runCountDown]);

  React.useEffect(() => {
    if (countDown === 0 || options.email_confirmed) {
      setRunCountDown(false);
    }
  }, [countDown, options.email_confirmed]);

  return (
    <Modal
      title={intl.formatMessage({ id: 'email.email_confirmation' })}
      visible={show}
      onCancel={onClose}
      maskTransitionName=""
      footer={[
        <Cancel key={`_${0}`} onClick={onClose} />,
        <Send
          key="submit"
          loading={loading}
          onClick={confirmEmailHandler}
          title={intl.formatMessage({ id: emailSent ? 'email.resend_confirmation_email' : 'email.send_confirmation_email' })}
          disabled={runCountDown}
        />
      ]}
    >
      <div className="row">
        <div className="col">
          <Text level={5}>
            {intl.formatMessage({
              id: emailSent ? 'email.we_have_sent_confirmation_email' : 'email.please_confirm_email'
            },
              { email: options.reply_email_address }
            )}
          </Text>
        </div>
      </div>
      
      {(runCountDown && countDown > 0) &&
      <div className="row">
        <div className="col">
          <Text level={5}>
            {intl.formatMessage({ id: 'email.you_can_resend_an_email_after' }, { countDown})}
          </Text>
        </div>
      </div>
      }
    </Modal>
  );
};

ConfirmEmailModal.defaultProps = {
  closeAfterUpdate: true
};

export default injectIntl(ConfirmEmailModal);