import axios from 'axios';

export const getCanDeletedFinanceUnit = () => {
  return axios.get('/finance/units/canBeDeleted');
};

export const addFinanceUnit = (data) => {
  return axios.post('/finance/units', data);
};

export const editFinanceUnit = (data) => {
  return axios.put(`/finance/units/${data.id}`, data);
};

export const moveFinanceUnit = (srcId, destId) => {
  return axios.patch(`/finance/units/${srcId}/move`, { dest_id: destId });
};

export const getFinanceUnit = (id) => {
  return axios.get(`/finance/units/${id}`);
};

export const deleteFinanceUnit = (id) => {
  return axios.delete(`/finance/units/${id}`);
};